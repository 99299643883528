import { BaseSpinner, Layout } from '@di-nxt/components'
import React, { useEffect, useContext } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import NoWorkspaceAccess from '../../components/Workspaces/NoWorkspaceAccess'
import { withHeader, withSidebar } from '../../hooks'
import { ApiContext } from '../../providers/api.provider'
import WorkspaceTabs from './WrokspaceTabs'
import { PageHeaderContext } from '../../providers/pageHeader.provider'

/**
 *
 * A placeholder for displaying information related to a workspace
 * Workspace has four Tabbed sub-components
 * 1 - Workspace home
 * 2 - Workspace data
 * 2 - Workspace Users
 * 3 - Workspace Settings
 */
const WorkspaceContainer: React.FC = () => {
  // React router dom hook
  const routeParams = useParams()

  // Attach the api context
  const {
    workspaceService,
    organizationService,
    queryService,
    workspaceDataService,
    workspaceUsersService,
    workspaceCloudApiService,
  } = useContext(ApiContext)

  const {
    workspace,
    loadingWorkspace,
    errorLoadingWorkspace,
    getWorkspace,
  } = workspaceService

  const {
    status: cloudApiStatus,
    loading: loadingCloudApiStatus,
    getStatus: getCloudApiStatus,
  } = workspaceCloudApiService

  const {
    organization,
    loadingOrganization,
    getOrganization,
  } = organizationService

  const { getWorkspaceUsers } = workspaceUsersService

  const {
    setDynamicBreadcrumb,
    removeDynamicBreadcrumb,
    setDynamicTitle,
    removeDynamicTitle,
  } = useContext(PageHeaderContext)

  const { getWorkspaceData } = workspaceDataService

  useEffect(() => {
    if (workspace?.workspaceId !== routeParams.workspaceId!) {
      getWorkspace(routeParams.workspaceId!)
    }
  }, [routeParams.workspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workspace?.workspaceId === routeParams.workspaceId!) {
      getCloudApiStatus(workspace.organizationId, workspace.workspaceId)
    }
  }, [workspace?.workspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      workspace?.workspaceId === routeParams.workspaceId! &&
      organization?.organizationId !== workspace.organizationId
    ) {
      getOrganization(workspace.organizationId)
      queryService.updateSchemaAvailable(false)
    }
  }, [workspace?.organizationId, organization?.organizationId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      workspace?.workspaceId === routeParams.workspaceId! &&
      organization?.organizationId === workspace.organizationId
    ) {
      queryService.loadResources(
        `${organization.organizationKey}_${workspace.workspaceKey ?? ''}`,
      )
    }
  }, [workspace?.organizationId, organization?.organizationId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWorkspaceData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWorkspaceUsers(routeParams.workspaceId!)
  }, [routeParams.workspaceId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!workspace || workspace.workspaceId !== routeParams.workspaceId) {
      return
    }
    setDynamicTitle(workspace.workspaceName)
    setDynamicBreadcrumb('workspaceName', workspace.workspaceName)
    return () => {
      removeDynamicTitle()
      removeDynamicBreadcrumb('workspaceName')
    }
  }, [workspace, workspace?.workspaceId, routeParams.workspaceId]) // eslint-disable-line

  if (
    !loadingWorkspace &&
    workspace?.workspaceId === routeParams.workspaceId! &&
    errorLoadingWorkspace?.statusCode === 403
  ) {
    return <NoWorkspaceAccess />
  }

  return loadingWorkspace ||
    loadingOrganization ||
    loadingCloudApiStatus ||
    workspace?.workspaceId !== routeParams.workspaceId! ||
    workspace?.organizationId !== organization?.organizationId ||
    !cloudApiStatus ? (
    <Layout vertical justify="center" align="center" minHeight="100%" flex>
      <BaseSpinner size={32} />
    </Layout>
  ) : (
    <Outlet />
  )
}

WorkspaceContainer.displayName = 'WorkspaceContainer'

export default withSidebar(withHeader(WorkspaceContainer, WorkspaceTabs))
