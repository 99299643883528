import * as amplitude from '@amplitude/analytics-browser'
import { createInstance } from '@amplitude/analytics-browser'
import { EnrichmentPlugin } from '@amplitude/analytics-types'

import { User } from '../types/user.type'
import { Organization } from '../types/organization.type'

const PATH_PROPERTY_KEY = '[DAP APP] Page Path'
const USER_ROLE_PROPERTY_KEY = '[DAP APP] User Role'
const USER_ORGANIZATION_PROPERTY_KEY = '[DAP APP] User Organization'

const pageViewTrackingEnrichment = (): EnrichmentPlugin => ({
  name: 'page-view-tracking-enrichment',
  type: 'enrichment',
  setup: async () => undefined,
  execute: async (event) => {
    if (event.event_type !== '[Amplitude] Page Viewed') {
      return event
    }
    event.event_properties = {
      ...event.event_properties,
      [PATH_PROPERTY_KEY]: window.location.pathname.replaceAll(
        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g,
        '*',
      ),
    }
    return event
  },
})

class AmplitudeService {
  private static instance = createInstance()
  private static initialized = this.init()

  private static init(): boolean {
    if (!process.env.REACT_APP_AMPLITUDE_API_KEY) {
      return false
    }

    this.instance.add(pageViewTrackingEnrichment())

    this.instance.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      serverZone: 'EU',
      defaultTracking: {
        pageViews: {
          trackHistoryChanges: 'all',
        },
      },
    })

    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        //this.instance.setTransport('beacon')
        this.instance.flush()
      }
    })

    return true
  }

  static setUser(user: User): void {
    if (!this.initialized) {
      return
    }

    this.instance.setUserId(user.email)
    const userIdentifyEvent = new amplitude.Identify()
    userIdentifyEvent.set(
      USER_ROLE_PROPERTY_KEY,
      user.diAdmin ? 'DI Admin' : user.role.name,
    )
    userIdentifyEvent.set(
      USER_ORGANIZATION_PROPERTY_KEY,
      user.organization?.organizationName ?? '',
    )
    this.instance.identify(userIdentifyEvent)
  }

  static setOrganization(organization: Organization): void {
    if (!this.initialized) {
      return
    }

    this.instance.setGroup(
      USER_ORGANIZATION_PROPERTY_KEY,
      organization.organizationName,
    )
  }

  static logoutUser(): void {
    if (!this.initialized) {
      return
    }

    this.instance.flush()
  }
}

export const useAmplitude = () => AmplitudeService
