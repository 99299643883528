import React, { useState } from 'react'
import { DropdownItem } from '../../types/dropdown.type'
import { useDropdown } from '../../hooks/definitions/useDropdown'
import { User } from '../../types/user.type'
import TableCellDropdown from '../TableCellDropdown'
import { Text } from '@di-nxt/components'
import { TableauUserSiteRole } from '../../types/tableau.api.type'

type UserTableauRoleSelectorProps = {
  user: User
  onEditUser: (user: User, siteRole: TableauUserSiteRole) => Promise<void>
  currentRole: TableauUserSiteRole
  editable: boolean
}

const UserTableauRoleSelector: React.FC<UserTableauRoleSelectorProps> = ({
  user,
  onEditUser,
  currentRole,
  editable,
}) => {
  const [spinning, setSpinning] = useState(false)

  // Tableau roles applicable to the users
  const { tableauRoles } = useDropdown()

  /**
   *
   * Change the Tableau role of the user
   */
  const handleChangeRole = (item: DropdownItem) => {
    setSpinning(true)
    onEditUser(user, item.key as TableauUserSiteRole).finally(() =>
      setSpinning(false),
    )
  }

  return editable ? (
    <TableCellDropdown
      items={tableauRoles}
      onSelect={handleChangeRole}
      selected={currentRole}
      spinning={spinning}
      maxWidth="280px"
      showCheckmark={true}
    />
  ) : (
    <Text variant="inline_14_default">{currentRole}</Text>
  )
}

UserTableauRoleSelector.displayName = 'UserTableauRoleSelector'

export default UserTableauRoleSelector
