import { useNavigate } from 'react-router-dom'
import { Button, Checkbox, Dialog, Spinner } from '@di-nxt/components'
import { RoutePathes } from '../../../../../constants/routes'
import { TableauPathParams } from '../../../../../hooks/api/useTableauRestClient'
import useTableauFetch from '../../../../../hooks/api/useTableauFetch'
import { useEffect, useRef, useState } from 'react'
import { TableauSelectedView } from '../../../../../types/tableau.api.type'
import { Thumbnail, Image, Title, Footer } from './style'
import EmbeddedView from '../../../EmbeddedView'

const ViewThumbnail: React.FC<{
  siteContentUrl: string
  view: TableauSelectedView
  handleSelection?: (view: TableauSelectedView) => void
}> = ({ siteContentUrl, view, handleSelection }) => {
  const navigate = useNavigate()

  const { data: image, loading } = useTableauFetch<ArrayBuffer>(
    siteContentUrl,
    `sites/${TableauPathParams.siteId}/workbooks/${view.workbook.id}/views/${view.id}/previewImage`,
    { responseType: 'arraybuffer' },
  )

  const imageRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (image) {
      const base64Encoded = Buffer.from(image).toString('base64')
      imageRef.current.style.backgroundImage = `url(data:image/png;base64,${base64Encoded})`
    }
  })

  const [isViewExpanded, setViewExpanded] = useState(false)

  const expandView = () => {
    setViewExpanded(true)
  }

  const navigateToView = () => {
    navigate(
      `/${RoutePathes.TableauView}?siteContentUrl=${siteContentUrl}&viewId=${view.id}`,
    )
  }

  return (
    <>
      {isViewExpanded && (
        <Dialog
          title={view.name}
          size="Large"
          okButtonTitle="Close"
          onOkButton={() => setViewExpanded(false)}
        >
          <EmbeddedView
            siteContentUrl={siteContentUrl}
            viewContentUrl={view.contentUrl}
          ></EmbeddedView>
        </Dialog>
      )}
      <Thumbnail>
        <Image ref={imageRef} onClick={() => expandView()}>
          {loading && <Spinner kind="Primary" />}
        </Image>
        <Footer>
          {handleSelection ? (
            <Checkbox
              checked={view.selected}
              label={<Title title={view.name}>{view.name}</Title>}
              onChange={(checked) =>
                handleSelection({ ...view, selected: checked })
              }
            />
          ) : (
            <Title title={view.name}>{view.name}</Title>
          )}
          <Button
            leftIcon="arrowRightSmall"
            kind="Clear"
            onPress={() => navigateToView()}
          />
        </Footer>
      </Thumbnail>
    </>
  )
}

export default ViewThumbnail
