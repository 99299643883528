import styled from 'styled-components'

export const StyledNumberedItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  flex-wrap: wrap;
  max-height: 120px;
  overflow-y: scroll;
`
