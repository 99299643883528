import SubItem from './SubItem'
import { SubItemsProps } from './SubItems.type'

const SubItems: React.FC<SubItemsProps> = ({
  subItems,
  isEditable,
  matchedParts,
  tableName,
  onSelected,
}) => {
  return (
    <>
      {subItems.map((subItem, i) => (
        <SubItem
          item={subItem}
          key={subItem.columnName}
          matchedPart={matchedParts[i] !== null ? matchedParts[i]![0] : null}
          tableName={tableName}
          onSelected={onSelected}
          isEditable={isEditable}
        />
      ))}
    </>
  )
}

SubItems.displayName = 'SubItems'

export default SubItems
