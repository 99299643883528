import { useState, useEffect, useRef } from 'react'
import { Input, Layout, Spacer, Text } from '@di-nxt/components'
import {
  ArrayInputProps,
  ArrayNumberInputProps,
  ArrayStringInputProps,
} from './index.type'
import ArrayItem from './ArrayItem'
import { StyledNumberedItems } from './style'
import { useIntl } from 'react-intl'

const ArrayInput = <T,>({
  value,
  onChangeValue,
  isValidString,
  stringToValue,
  valueToString,
  errorMessage,
}: ArrayInputProps<T>) => {
  const [items, setItems] = useState<T[]>(value ?? [])
  const [inputValue, setInputValue] = useState<string>('')
  const [focused, setFocused] = useState<boolean>(false)
  const [isWrongInput, setIsWrongInput] = useState<boolean>(false)

  const inputRef = useRef(null)

  const isValidInput = (inputValue: string) =>
    !/[,.\s]/.test(inputValue) && isValidString(inputValue)

  const handleEnterValue = (inputValue: string) => {
    inputValue = inputValue.trim()
    if (inputValue === '' || isValidInput(inputValue)) {
      setInputValue(inputValue)
    }
  }

  const handleKeyDown = (key: KeyboardEvent) => {
    setIsWrongInput(false)
    if ((focused && key.code === 'Space') || key.code === 'Enter') {
      if (inputValue) {
        const newItems = [...items, stringToValue(inputValue)]
        setItems(newItems)
        onChangeValue(newItems)
        setInputValue('')
      }
    }
  }

  const handlePaste = (event: Event) => {
    const clipboardData = (event as ClipboardEvent).clipboardData
    const pastedData = clipboardData?.getData('Text')

    if (pastedData && focused) {
      const listOfInputValues = pastedData
        .split(/[,.\s]+/)
        .filter((value) => value !== '')
      const isListValid = listOfInputValues.every((value) =>
        isValidString(value),
      )
      if (isListValid) {
        const newItems = [
          ...items,
          ...listOfInputValues.map((value) => stringToValue(value)),
        ]
        setItems(newItems)
        onChangeValue(newItems)
      } else {
        setIsWrongInput(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('paste', handlePaste)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('paste', handlePaste)
    }
  })

  const handleSetFocus = () => setFocused(true)

  const handleRemoveFocus = () => setFocused(false)

  const handleOnBlur = () => {
    handleRemoveFocus()
    if (inputValue) {
      const newItems = [...items, stringToValue(inputValue)]
      setItems(newItems)
      onChangeValue(newItems)
      setInputValue('')
    }
  }

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((item, i) => i !== index)
    setItems(newItems)
    onChangeValue(newItems)
  }

  return (
    <Layout width="100%" vertical>
      <StyledNumberedItems>
        {items.map((value: T, index: number) => {
          const stringValue = valueToString(value)
          return (
            <ArrayItem
              value={stringValue}
              index={index}
              key={`value-${stringValue}-index${index}`}
              onRemoveItem={handleRemoveItem}
            />
          )
        })}
      </StyledNumberedItems>
      <Spacer height="8" />
      <Input
        innerRef={inputRef}
        value={inputValue}
        onChange={handleEnterValue}
        onFocus={handleSetFocus}
        onBlur={handleOnBlur}
        state={isWrongInput ? 'Error' : 'Default'}
      />
      {isWrongInput && (
        <Text
          variant="paragraph_12_default"
          textColor={'content_negative_default'}
        >
          {errorMessage}
        </Text>
      )}
    </Layout>
  )
}
ArrayInput.displayName = 'ArrayInput'

const ArrayNumberInput: React.FC<ArrayNumberInputProps> = ({
  value,
  onChangeValue,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  return (
    <ArrayInput<number>
      value={value}
      onChangeValue={onChangeValue}
      isValidString={(s) => Number.isInteger(Number(s))}
      stringToValue={(s) => Number(s)}
      valueToString={(v) => v.toString()}
      errorMessage={t({ id: 'sharedDataOnlyNumbersErrorMessage' })}
    ></ArrayInput>
  )
}
ArrayNumberInput.displayName = 'ArrayNumberInput'
export { ArrayNumberInput }

const ArrayStringInput: React.FC<ArrayStringInputProps> = ({
  value,
  onChangeValue,
}) => {
  return (
    <ArrayInput<string>
      value={value}
      onChangeValue={onChangeValue}
      isValidString={(s) => true}
      stringToValue={(s) => s}
      valueToString={(v) => v}
      errorMessage=""
    ></ArrayInput>
  )
}
ArrayStringInput.displayName = 'ArrayStringInput'
export { ArrayStringInput }
