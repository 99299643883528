import React, { useContext, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Layout,
  Icon,
  Text,
  Popup,
  LayoutProps,
  Button,
  Spacer,
} from '@di-nxt/components'

import { RedshiftOrganizationErrorProps } from './RedshiftOrganizationError.type'
import { ApiContext } from '../../providers/api.provider'
import { UpdateOrganizationInput } from '../../types/organization.type'

/**
 *
 * Component that renders the organization redshift error
 */
const RedshiftOrganizationError: React.FC<RedshiftOrganizationErrorProps> = ({
  user,
  organization,
  location,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Use ref for popup
  const popupRef = useRef(null)

  // API context
  const { organizationService } = useContext(ApiContext)
  const { getOrganizations, modifyOrganization } = organizationService

  // Flag to determine whether creating organization failed
  const creationFailed = organization.organizationCreated?.Status === 'FAILED'
  const creating = !('organizationCreated' in organization)

  // Common props
  const popupProps: LayoutProps = {
    backgroundColor: 'background_mono_primary',
    padding: '16',
    borderRadius: 'small',
    shadow: true,
    vertical: true,
  }

  /**
   *
   * Resolve the error manually
   */
  const handleResolveError = () => {
    const payload: UpdateOrganizationInput = {
      organizationCreated: {
        Status: 'FINISHED',
        TriggeredManually: true,
        TriggeredByUser: user?.userId ?? '',
      },
    }
    modifyOrganization(organization.organizationId, payload).then(() => {
      getOrganizations()
    })
  }

  /**
   *
   * Render and return the resolution button
   */
  const renderResolveIssue = () => (
    <Button
      title={t({ id: 'markAsResolved' })}
      size="Small"
      onPress={handleResolveError}
    />
  )

  // If it failed and location is table
  if (creationFailed && location === 'table') {
    return (
      <Popup
        trigger={
          <div ref={popupRef}>
            <Layout align="center" justify="center">
              <Icon
                size="16"
                name="warning"
                color="background_warning_default"
              />
            </Layout>
          </div>
        }
      >
        {() => (
          <Layout {...popupProps} maxWidth="250px">
            <Text>
              <FormattedMessage
                id="organizationRedshiftError"
                values={{
                  br: <br />,
                  adminMessage: user?.diAdmin ? (
                    <FormattedMessage id="redshiftErrorAdmin" />
                  ) : (
                    <FormattedMessage id="organizationRedshiftErrorUser" />
                  ),
                }}
              />
            </Text>
            {user?.diAdmin && (
              <>
                <Spacer height="8" />
                {renderResolveIssue()}
              </>
            )}
          </Layout>
        )}
      </Popup>
    )
  }

  // If the organization is being created
  if (creating && location === 'table') {
    return (
      <Popup trigger={<Icon size="16" name="warning" />}>
        {() => (
          <Layout {...popupProps} maxWidth="250px">
            <Text>
              <FormattedMessage id="organizationRedshiftCreating" />
            </Text>
          </Layout>
        )}
      </Popup>
    )
  }

  return null
}

RedshiftOrganizationError.displayName = 'RedshiftOrganizationError'
export default RedshiftOrganizationError
