export enum RoutePathes {
  Index = '/',
  Organizations = 'organizations',
  OrganizationCostTracker = 'organization/costTracker',
  OrganizationUsers = 'organization/userManagement',
  OrganizationUsersOld = 'organization/users',
  OrganizationSharedData = 'organization/sharedData',
  OrganizationTableau = 'organization/tableau',
  Workspace = 'workspace',
  Home = 'home',
  Data = 'data',
  Tableau = 'tableau',
  Users = 'users',
  Settings = 'settings',
  Account = 'account',
  PrivacyPolicy = 'privacy',
  ModifyUser = 'modifyUser',
  TableauView = 'tableau/view',
}
