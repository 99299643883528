import { ThemeType } from '@di-nxt/components'
import styled, { css, ThemeProps } from 'styled-components'

export const Thumbnail = styled.div`
  ${(props: ThemeProps<ThemeType>) => {
    return css`
      display: flex;
      flex-direction: column;
      width: 250px;
      border-radius: ${props.theme.radii.medium}px;
      border: ${props.theme.borders.hairline}px solid
        ${props.theme.colors.border_mono_10};
      background-color: ${props.theme.colors.background_mono_primary};

      &:hover {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
        transition: 0.3s box-shadow ease-out;
      }

      & label {
        margin-bottom: 0;
        min-width: 0;

        & > div > div:last-child {
          display: flex;
          flex: 1;
          min-width: 0;
        }
      }
    `
  }}
`
export const Image = styled.div`
  ${(props: ThemeProps<ThemeType>) => {
    return css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top-left-radius: ${props.theme.radii.medium}px;
      border-top-right-radius: ${props.theme.radii.medium}px;
      width: 100%;
      height: 155px;
      background-size: cover;
      cursor: pointer;
    `
  }}
`

export const Footer = styled.div`
  ${(props: ThemeProps<ThemeType>) => {
    return css`
      display: flex;
      flex: 1;
      padding: ${props.theme.spacing[14]}px ${props.theme.spacing[16]}px;
      align-items: center;
      justify-content: space-between;
      gap: ${props.theme.spacing[10]}px;

      & button:hover {
        background-color: unset;
        transform: scale(1.5);
      }
    `
  }}
`

export const Title = styled.div`
  ${(props: ThemeProps<ThemeType>) => {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${props.theme.typography.paragraph_14_default.fontSizePx}px;
      font-weight: 500;
    `
  }}
`
