import { UpdateRedshiftStatus } from './redshift.type'

/**
 *
 * Enum for workspace roles
 */
export enum WorkspaceUserRole {
  owner = 'Owner',
  editor = 'Editor',
  viewer = 'Viewer',
}

/**
 * Workspace type definition
 */
export type Workspace = {
  /**
   *
   * Name of the workspace
   */
  workspaceName: string

  /**
   *
   * Unique key of the workspace
   */
  workspaceKey?: string

  /**
   *
   * Description of the workspace
   */
  workspaceDescription: string

  /**
   *
   * Date the workspace was created
   */
  dateCreated?: number

  /**
   *
   * Date the workspace was last modified
   */
  dateModified?: number

  /**
   *
   * Owners of the workspace
   */
  workspaceOwners?: { name: string; id: string }[]

  /**
   *
   * UUID of the workspace
   */
  workspaceId: string

  /**
   *
   * Users in a workspace
   */
  workspaceUsers: WorkspaceUserInput[]

  /**
   *
   * ID of the organization workspace belongs to
   */
  organizationId: string

  /**
   *
   * Logs from redshift executor
   */
  workspaceCreated?: any

  /**
   * Selected resources
   */
  selectedResources?: SelectedResource[]
}

export type SelectedResource = {
  tableName: string
  columns: Column[]
}
export type Column = {
  columnName: string
}

/**
 *
 * Create workspace payload
 */
export type CreateWorkspaceInput = {
  workspaceName: string
  workspaceDescription: string
  workspaceKey: string
  workspaceUsers: WorkspaceUserInput[]
}

/**
 *
 * Workspace user input
 */
export type WorkspaceUserInput = {
  userId: string
  workspaceRole?: WorkspaceUserRole
  username: string
  workspaceOldRole?: WorkspaceUserRole
  email: string
}

/**
 *
 * Create workspace user input
 */
export type CreateWorkspaceUserInput = {
  users: WorkspaceUserInput[]
  workspaceName: string
  workspaceKey?: string
  organizationKey?: string
}

/**
 *
 * Type of update payload for workspace
 */
export type UpdateWorkspaceInput = {
  /**
   *
   * Updated description of the workspace
   */
  workspaceDescription?: string

  /**
   *
   * Workspace name may also be updated
   */
  workspaceName?: string

  /**
   *
   * Updated list of users belonging to a workspace
   */
  workspaceUsers?: WorkspaceUserInput[]

  /**
   *
   * Admins can update the workspace redshift status
   */
  workspaceCreated?: UpdateRedshiftStatus
}
