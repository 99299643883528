import React from 'react'
import { Popup, Text, Icon } from '@di-nxt/components'

import {
  StyledHyperlink,
  StyledPopupContent,
  StyledPopupIcon,
  WrapSpan,
} from './style'
import { HyperlinkPopupProps } from './index.type'

/**
 * Popover to display information about an organization
 */
const HyperlinkPopup: React.FC<HyperlinkPopupProps> = ({
  showIcon,
  icon,
  message,
  hyperlinkText,
}) => {
  return (
    <WrapSpan>
      <Popup trigger={<StyledHyperlink>{hyperlinkText}</StyledHyperlink>}>
        {() => (
          <StyledPopupContent>
            {showIcon && icon && (
              <StyledPopupIcon>
                <Icon name={icon} color="content_accent_default" size="24" />
              </StyledPopupIcon>
            )}

            <Text
              variant="inline_14_default"
              textColor="content_accent_default"
            >
              {message}
            </Text>
          </StyledPopupContent>
        )}
      </Popup>
    </WrapSpan>
  )
}

export default HyperlinkPopup
