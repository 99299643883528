import React, { useContext } from 'react'
import { Layout, Spacer } from '@di-nxt/components'
import { WorkspaceSettingsIntroduction } from './WorkspaceSettingsMessages'
import { DeleteWorkspace, LeaveWorkspace, WorkspaceDetails } from './Cards'
import { ApiContext } from '../../../providers/api.provider'
import { WorkspaceUserRole } from '../../../types/workspace.type'
import { UserRoles } from '../../../types/user.type'

/**
 *
 * Each workspace has its own settings and configurations page.
 * Render those options to owners of the workspace and editors.
 */
const WorkspaceSettingsContainer: React.FC = () => {
  const { user, workspaceService } = useContext(ApiContext)
  const { workspace, userWorkspaceRole } = workspaceService

  const isUserPartOfWorkspace = workspace?.workspaceUsers?.some(
    (workspaceUser) => workspaceUser.userId === user?.userId,
  )
  const isUserOwnerOfWorkspace = userWorkspaceRole === WorkspaceUserRole.owner

  return (
    <Layout paddingTop="32" vertical>
      <WorkspaceSettingsIntroduction />
      <Layout horizontal paddingTop="48">
        <Layout flex={1} vertical maxWidth="600px" minWidth="400px">
          {(user?.diAdmin ||
            user?.role.name === UserRoles.admin ||
            isUserOwnerOfWorkspace) && <WorkspaceDetails />}
          {isUserPartOfWorkspace && <LeaveWorkspace />}
          {(user?.diAdmin ||
            user?.role.name === UserRoles.admin ||
            isUserOwnerOfWorkspace) && <DeleteWorkspace />}
        </Layout>
        <Spacer width="32" />
        <Layout flex={1} maxWidth="600px" minWidth="400px"></Layout>
      </Layout>
    </Layout>
  )
}

WorkspaceSettingsContainer.displayName = 'WorkspaceSettingsContainer'

export default WorkspaceSettingsContainer
