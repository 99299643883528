import React, { useState, useEffect, useContext } from 'react'
import { Layout, Text, Input, Button } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import { AdminUserDetailsProps } from './index.type'
import { ApiContext } from '../../../../providers/api.provider'
import { SnackbarContext } from '../../../../providers/snackbar.provider'

/**
 *
 * A component that renders basic information about a user
 */
const AdminUserDetails: React.FC<AdminUserDetailsProps> = ({ user }) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()
  // Local state management to handle changes in fields
  const [firstName, setFirstName] = useState<string>(user?.firstName || '')
  const [lastName, setLastName] = useState<string>(user?.lastName || '')
  const [updatingUser, setUpdatingUser] = useState<boolean>(false)

  // Context api for api services
  const { organizationUserService } = useContext(ApiContext)
  const { handleSetContent, handleSetSnackbarType } = useContext(
    SnackbarContext,
  )

  const { updateOrganizationUser } = organizationUserService

  /**
   *
   * Monitor user information for changes
   */
  useEffect(() => {
    if (!firstName && !lastName && user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
    }
  }, [user, firstName, lastName])

  /**
   *
   * Handlers to update local state variables
   */
  const handleChangeFirstName = (value: string) => setFirstName(value)
  const handleChangeLastName = (value: string) => setLastName(value)

  /**
   *
   * Handle to save user information
   */
  const handleSaveUser = () => {
    if (user) {
      setUpdatingUser(true)
      updateOrganizationUser({ firstName, lastName }, user?.userId)
        .then(() => {
          handleSetSnackbarType('Success')
          handleSetContent(t({ id: 'valuesSaved' }))
        })
        .catch(() => {
          handleSetSnackbarType('Error')
          handleSetContent(t({ id: 'valuesSavedError' }))
        })
        .finally(() => setUpdatingUser(false))
    }
  }

  return (
    <>
      <>
        <Layout paddingBottom="32">
          <Text variant="inline_16_emphasis">
            {t({ id: 'personalDetails' })}
          </Text>
        </Layout>
        <Layout vertical paddingBottom="24">
          <label>{t({ id: 'email' })}</label>
          <Input state="Disabled" value={user?.email} />
        </Layout>
        <Layout vertical paddingBottom="24">
          <label>{t({ id: 'firstName' })}</label>
          <Input value={firstName} onChange={handleChangeFirstName} />
        </Layout>
        <Layout vertical paddingBottom="24">
          <label>{t({ id: 'lastName' })}</label>
          <Input value={lastName} onChange={handleChangeLastName} />
        </Layout>
        <Layout horizontal paddingBottom="24" justify="flex-end" width="100%">
          <Button
            title={t({ id: 'save' })}
            kind="Inverse"
            onPress={handleSaveUser}
            spinning={updatingUser}
            state={updatingUser ? 'Disabled' : 'Default'}
          />
        </Layout>
      </>
    </>
  )
}

AdminUserDetails.displayName = 'AdminUserDetails'

export default AdminUserDetails
