import React, { useRef, useContext } from 'react'
import { Layout, Menu, Popup } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import { TableOrganizationSharedDataActionsProps } from './TableOrganizationSharedDataActions.type'
import { AppStateContext } from '../../providers/app.state.provider'

import MoreInActive from '../../assets/icons/more_inactive_icon.svg'

const TableOrganizationSharedDataActions: React.FC<
  TableOrganizationSharedDataActionsProps
> = ({ data }) => {
  // Create a reference for the image button
  const moreRef = useRef(null)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // App state context for dialog state management
  const { manageDialog } = useContext(AppStateContext)

  // Menu items to render in the popup
  const menuItems = [t({ id: 'sharedDataUpdate' })]

  /**
   *
   * Open a dailog to display infromation about updating shared data
   */
  const handleUpdateData = () => {
    manageDialog({
      organizationSharedData: data,
      updateOrganizationSharedData: true,
    })
  }

  /**
   *
   * Handler to select an option from the menu
   */
  const handleSelectOption = (index: number) => {
    switch (index) {
      case 0:
        handleUpdateData()
        break
    }

    if (moreRef.current) {
      ;(moreRef.current as any).click()
    }
  }

  return (
    <Layout align="center" justify="center" width="100%" cursor="pointer">
      <Popup trigger={<img src={MoreInActive} alt="more" ref={moreRef} />}>
        {() => <Menu items={menuItems} onChange={handleSelectOption} />}
      </Popup>
    </Layout>
  )
}

TableOrganizationSharedDataActions.displayName =
  'TableOrganizationSharedDataActions'

export default TableOrganizationSharedDataActions
