import React from 'react'
import { Layout } from '@di-nxt/components'
import Breadcrumb from './Breadcrumb'
import HomeBreadcrumbIcon from '../../assets/icons/home_breadcrumb_icon.svg'
import { BreadcrumbsProps } from './index.type'

/**
 *
 * Generic breadcrumbs component
 */
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const homeBreadcrumb = {
    text: '',
    icon: HomeBreadcrumbIcon,
    path: `/`,
  }

  breadcrumbs = [homeBreadcrumb, ...breadcrumbs]

  return (
    <Layout horizontal>
      {breadcrumbs.map((item, index) => (
        <Breadcrumb
          breadcrumb={item}
          isLast={index === breadcrumbs.length - 1}
          key={`Breadcrumb-${index}-${item.text}`}
        />
      ))}
    </Layout>
  )
}

Breadcrumbs.displayName = 'Breadcrumbs'
export default Breadcrumbs
