import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router'

import { ApiContext } from '../../providers/api.provider'
import { useUser } from '../../hooks'
import { UserRoles } from '../../types/user.type'
import { ProtectedRouteProps } from './index.type'

/**
 * A simple router component which checks for
 * - Authentication
 * - Authorization
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  isAccessAllowed,
}) => {
  const { user, selectedOrganization } = useContext(ApiContext)

  const { login, initialized, authenticated } = useUser()

  if (initialized && !authenticated) {
    login()
    return null
  }

  if (!user || !selectedOrganization) {
    return <Navigate to="/" />
  }

  const isDiAdmin = !!user.diAdmin
  const role =
    user.organizationId === selectedOrganization.organizationId
      ? (user.role.name as UserRoles)
      : undefined

  if (isAccessAllowed && !isAccessAllowed(isDiAdmin, role)) {
    return <Navigate to="/" />
  }

  return children ? <>{children}</> : <Outlet />
}

ProtectedRoute.displayName = 'ProtectedRoute'
export default ProtectedRoute
