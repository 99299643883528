import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Layout, Input, Button } from '@di-nxt/components'
import { WorkspaceUsersTableControlsProps } from './index.type'
import { ApiContext } from '../../../providers/api.provider'
import { AppStateContext } from '../../../providers/app.state.provider'
import DeleteWorkspaceUsersConfirm from '../../Dialogs/DeleteWorkspaceUsersConfirm'

/**
 *
 * Table control elements for workspace users table
 */
const WorkspaceUsersTableControls: React.FC<
  WorkspaceUsersTableControlsProps
> = ({ canEditUsers }) => {
  const { workspaceUsersService, workspaceService, user } =
    useContext(ApiContext)
  const { manageDialog, openDialogs } = useContext(AppStateContext)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  const handleOpenDialog = () => {
    manageDialog({ deleteWorkspaceUsersConfirm: true })
  }

  const handleCloseDialog = () => {
    manageDialog({ deleteWorkspaceUsersConfirm: false })
  }

  const handleDeleteWorkspaceUsers = () => {
    manageDialog({ deleteWorkspaceUsersConfirm: false })

    if (workspaceService.workspace?.workspaceId) {
      const workspaceId = workspaceService.workspace?.workspaceId
      // Users to be removed
      const workspaceUsersToRemove =
        workspaceUsersService.workspaceUsers?.filter(
          (workspaceUser) => workspaceUser.isMarkedForDeletion,
        ) ?? []

      // Users left in the workspace after deletion
      const workspaceUsersLeft =
        workspaceService.workspace.workspaceUsers.filter(
          (workspaceUser) =>
            !workspaceUsersToRemove
              ?.map((workspaceUserToRemove) => workspaceUserToRemove.userId)
              .includes(workspaceUser.userId),
        )

      // Callback function executed to update the users in the workspace after successful deletion
      const onSuccess = () => {
        workspaceService.removeUsersFromWorkspace(workspaceUsersLeft)
      }

      workspaceUsersService.deleteWorkspaceUsers(
        workspaceId,
        workspaceUsersToRemove,
        workspaceUsersLeft,
        onSuccess,
        workspaceService.workspace.workspaceKey ?? '',
        user?.organization?.organizationKey ?? '',
      )
    }
  }

  return (
    <Layout horizontal width="100%">
      <Layout vertical flex={0.2}>
        <label>{t({ id: 'search' })}</label>
        <Input
          rightIcon="search"
          placeholder={t({ id: 'searchWorkspaceUsersPlaceholder' })}
          width={350}
        />
      </Layout>
      <Layout paddingLeft="32" align="flex-end" flex={0.75}>
        {canEditUsers && (
          <Button
            leftIcon="remove"
            onPress={handleOpenDialog}
            title={t({ id: 'removeUsers' })}
            state={
              workspaceUsersService.deletingWorkspaceUsers ||
              workspaceUsersService.loadingWorkspaceUsers ||
              workspaceUsersService.workspaceUsers?.filter(
                (user) => user.isMarkedForDeletion,
              ).length === 0
                ? 'Disabled'
                : 'Default'
            }
            spinning={workspaceUsersService.deletingWorkspaceUsers}
          />
        )}
      </Layout>
      {openDialogs.deleteWorkspaceUsersConfirm && (
        <DeleteWorkspaceUsersConfirm
          onOk={handleDeleteWorkspaceUsers}
          onCancel={handleCloseDialog}
        />
      )}
    </Layout>
  )
}

WorkspaceUsersTableControls.displayName = 'WorkspaceUsersTableControls'
export default WorkspaceUsersTableControls
