import { useMemo } from 'react'
import { UIMatch, useMatches } from 'react-router-dom'

type RouteType = UIMatch<
  unknown,
  {
    module: (data: unknown) => string
  }
>

const useModule = (): string | undefined => {
  const matches = useMatches() as RouteType[]

  const module: string | undefined = useMemo(() => {
    const filteredMatches = matches.filter((match) =>
      Boolean(match.handle?.module),
    )

    const lastMatch =
      filteredMatches.length > 0
        ? filteredMatches[filteredMatches.length - 1]
        : null

    return lastMatch?.handle.module(lastMatch.data)
  }, [matches])

  return module
}

export default useModule
