import { useState, useContext } from 'react'
import { useIntl } from 'react-intl'

import { AppStateContext } from '../../providers/app.state.provider'
import { SnackbarContext } from '../../providers/snackbar.provider'
import { ENDPOINTS } from '../../types/config.api.type'
import {
  Organization,
  OrganizationInput,
  UpdateOrganizationInput,
} from '../../types/organization.type'

import useApi from './useApi'
import { UseOrganization } from './useOrganization.type'

export const defaultUseOrganization: UseOrganization = {
  loadingOrganizations: false,
  organizations: [],
  getOrganizations: () => null,
  createOrganization: () => null,
  creatingOrganization: false,
  modifyingOrganization: false,
  deleteOrganization: () => Promise.resolve(),
  modifyOrganization: () => Promise.resolve(),
  getOrganization: () => Promise.resolve(),
  loadingOrganization: false,
  organization: undefined,
}

/**
 *
 * Hook with all things organizations related
 * 1 - Getting list of organizations
 * 2 - Creating a new organization
 */
const useOrganization = (): UseOrganization => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Local state management
  const [loadingOrganizations, setLoadingOrganizations] =
    useState<boolean>(false)
  const [organizations, setOrganizations] = useState<Organization[]>([])

  // Loading state for create organization action
  const [creatingOrganization, setCreatingOrganization] =
    useState<boolean>(false)

  // Loading state for delete organization action
  const [deletingOrganization, setDeletingOrganization] =
    useState<boolean>(false)

  // Loading state for modify organization action
  const [modifyingOrganization, setModifyingOrganization] =
    useState<boolean>(false)

  // Loading state while getting a specific organization
  const [loadingOrganization, setLoadingOrganization] = useState<boolean>(false)

  const [organization, setOrganization] = useState<Organization | undefined>(
    undefined,
  )

  // App state context
  const { manageDialog } = useContext(AppStateContext)

  // Notification context
  const { handleSetContent, handleSetSnackbarType } =
    useContext(SnackbarContext)

  // Fetcher hook
  const { fetcher } = useApi()

  /**
   *
   * Function that fetches an organization
   * @param {String} organizationId - id of the organization to get
   */
  const getOrganization = async (organizationId: string) => {
    setLoadingOrganization(true)
    try {
      const organization = await fetcher.get(
        ENDPOINTS.ORGANIZATION.replace('$organizationId', organizationId),
      )
      setOrganization(organization.data.organization)
      return organization.data
    } catch (error) {
      console.log(error)
      return error
    } finally {
      setLoadingOrganization(false)
    }
  }

  /**
   *
   * Function that fetches list of organizations
   */
  const getOrganizations = () => {
    setLoadingOrganizations(true)
    fetcher
      .get(ENDPOINTS.ORGANIZATIONS)
      .then((response) => {
        setOrganizations(response.data)
      })
      .finally(() => {
        setLoadingOrganizations(false)
      })
  }

  /**
   *
   * Function that creates a new organizations
   */
  const createOrganization = (organization: OrganizationInput) => {
    setCreatingOrganization(true)

    // Send the post request
    fetcher
      .post(ENDPOINTS.ORGANIZATIONS, organization)
      .then(() => {
        handleSetSnackbarType('Success')
        handleSetContent(
          t(
            { id: 'addOrganizationSuccess' },
            { name: organization.organizationName },
          ),
        )
      })
      .finally(() => {
        setCreatingOrganization(false)
        getOrganizations()
        manageDialog({ addNewOrganization: false })
      })
  }

  /**
   *
   * Function that updates an exisitng organization
   * @param {String} organizationId - id of the organization being updated
   * @param {OrganizationInput} data - data to be sent to the backend
   */
  const modifyOrganization = (
    organizationId: string,
    data: UpdateOrganizationInput,
  ) => {
    setModifyingOrganization(true)

    return fetcher
      .put(
        ENDPOINTS.ORGANIZATION.replace('$organizationId', organizationId),
        data,
      )
      .then(() => {
        handleSetSnackbarType('Success')
        handleSetContent(
          t({ id: 'modifyOrganizationSuccess' }, { id: organizationId }),
        )
      })
      .finally(() => {
        setModifyingOrganization(false)
        getOrganizations()
      })
  }

  /**
   *
   * Function that deletes an exisiting organization
   * @param {String} organizationId - id of the organization to be deleted
   */
  const deleteOrganization = async (organizationId: string) => {
    setDeletingOrganization(true)
    try {
      const deleteOrg = await fetcher.delete(
        ENDPOINTS.ORGANIZATION.replace('$organizationId', organizationId),
      )
      handleSetSnackbarType('Success')
      handleSetContent(t({ id: 'organizationDeleted' }))
      getOrganizations()
      return deleteOrg
    } catch (error) {
      handleSetSnackbarType('Error')
      handleSetContent(t({ id: 'errorDeletingOrganization' }))
      return error
    } finally {
      setDeletingOrganization(false)
    }
  }

  return {
    loadingOrganization,
    organization,
    loadingOrganizations,
    organizations,
    getOrganization,
    getOrganizations,
    createOrganization,
    deleteOrganization,
    deletingOrganization,
    creatingOrganization,
    modifyOrganization,
    modifyingOrganization,
  }
}

export default useOrganization
