import { Layout, Spacer, Table, Text } from '@di-nxt/components'
import TableIcon from '../../../../../assets/icons/table_black_icon.svg'
import ColumnsIcon from '../../../../../assets/icons/columns_black_icon.svg'
import { useDefinitions } from '../../../../../hooks'
import { WhiteTable } from './styles/whiteTable'
import TableDescription from './TableDescription'
import { useIntl } from 'react-intl'
import { DataDescriptionProps } from './DataDescription.type'

/**
 * Component showing detailed information about a selected table
 *
 */
const DataDescription: React.FC<DataDescriptionProps> = ({ selectedTable }) => {
  const { formatMessage: t } = useIntl()

  const { columnsTableDef } = useDefinitions()

  return (
    <Layout vertical paddingTop="48">
      <Layout>
        <img src={TableIcon} alt="table" />
        <Spacer width="8" />
        <Text variant="inline_16_emphasis">
          {selectedTable?.schemaName}.{selectedTable?.tableName}
        </Text>
      </Layout>
      <TableDescription description={selectedTable?.description ?? ''} />
      <Layout paddingTop="48">
        <img src={ColumnsIcon} alt="columns" />
        <Spacer width="8" />
        <Text variant="inline_16_emphasis">{t({ id: 'columnsText' })}</Text>
      </Layout>
      <Layout paddingTop="18">
        <WhiteTable>
          <Table
            columns={columnsTableDef}
            data={selectedTable?.columns ?? []}
          />
        </WhiteTable>
      </Layout>
    </Layout>
  )
}

DataDescription.displayName = 'DataDescription'

export default DataDescription
