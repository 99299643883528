import React, { useContext, useState, useEffect } from 'react'
import { Checkbox } from '@di-nxt/components'

import { ApiContext } from '../../providers/api.provider'
import { DeleteUserToggleProps } from './DeleteUserToggle.type'
import { WorkspaceUserRole } from '../../types/workspace.type'

/**
 *
 * A component rendered in Table Component
 * Responsible for toggling deletion status of a user
 */
const DeleteUserToggle: React.FC<DeleteUserToggleProps> = ({
  workspaceUser,
}) => {
  const { workspaceUsersService } = useContext(ApiContext)
  // State for the toggle button
  const [isMarkedForDeletion, setIsMarkedForDeletion] = useState<boolean>(
    workspaceUser.isMarkedForDeletion ?? false,
  )

  /**
   *
   * Use effect hook to monitor changes in user
   */
  useEffect(() => {
    if (workspaceUsersService.workspaceUsers) {
      const currentUser = workspaceUsersService.workspaceUsers.find(
        (user) => user.userId === workspaceUser.userId,
      )
      if (
        currentUser &&
        currentUser.isMarkedForDeletion !== isMarkedForDeletion
      ) {
        setIsMarkedForDeletion(currentUser.isMarkedForDeletion ?? false)
      }
    }
  }, [
    workspaceUsersService.workspaceUsers,
    isMarkedForDeletion,
    workspaceUser.userId,
  ])

  /**
   *
   * Handle on press enabled toggle
   */
  const handleToggle = () => {
    if (workspaceUsersService.deletingWorkspaceUsers) {
      return
    }
    workspaceUsersService.markUserForDeletion(
      workspaceUser.userId,
      !isMarkedForDeletion,
    )
  }

  /**
   *
   * Toggle the state of
   */
  return (
    <Checkbox
      onChange={handleToggle}
      checked={isMarkedForDeletion}
      disabled={
        workspaceUser.workspaceRole === WorkspaceUserRole.owner &&
        workspaceUsersService.workspaceUsers?.every(
          (user) =>
            user.userId === workspaceUser.userId ||
            user.workspaceRole !== WorkspaceUserRole.owner,
        )
      }
    />
  )
}

DeleteUserToggle.displayName = 'DeleteUserToggle'
export default DeleteUserToggle
