import React, { useState, useEffect } from 'react'
import { Layout, Spacer, Text, ThemeColorName } from '@di-nxt/components'

import { TemplateCardProps } from './index.type'

/**
 *
 * Renders the template card
 */
const TemplateCard: React.FC<TemplateCardProps> = ({
  icon,
  description,
  state,
  onSelectTemplate,
  selectedTemplate,
}) => {
  // Set the default state
  const templateState = state ?? 'DEFAULT'

  // Use state to monitor hover state
  const [isSelected, setIsSelected] = useState<boolean>(false)

  // On render determine whether the card was selected
  useEffect(() => {
    if (selectedTemplate === 'Blank' && templateState !== 'DISABLED') {
      setIsSelected(true)
    }
  }, []) // eslint-disable-line

  // Handle mouse hover on card
  const handleSelect = () => {
    if (templateState !== 'DISABLED' && !isSelected) {
      setIsSelected(true)
      onSelectTemplate('Blank')
    } else {
      setIsSelected(false)
      onSelectTemplate(undefined)
    }
  }

  // Determines the background color
  const getBackgroundColor = (): ThemeColorName => {
    if (isSelected) {
      return 'background_accent_light'
    }

    return templateState === 'DISABLED'
      ? 'background_mono_secondary'
      : 'background_mono_primary'
  }

  return (
    <Layout
      flex={1}
      borderWidth="hairline"
      borderStyle="solid"
      borderColor="border_mono_15"
      borderRadius="small"
      align="center"
      justify="center"
      vertical
      cursor="pointer"
      hover={!isSelected}
      onPress={handleSelect}
      backgroundColor={getBackgroundColor()}
    >
      <img src={icon} alt="BlankTemplate" />
      <Spacer height="24" />
      <Text
        variant="inline_12_default"
        align="center"
        textColor={
          templateState === 'DISABLED'
            ? 'content_mono_disabled'
            : 'content_mono_primary'
        }
      >
        {typeof description === 'string' ? description : description()}
      </Text>
    </Layout>
  )
}

export default TemplateCard
TemplateCard.displayName = 'TemplateCard'
