import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { DatePicker, Dialog, Input, Layout, Spacer } from '@di-nxt/components'
import { format, isAfter, isBefore } from 'date-fns'

import { AddOrganizationDialogProps } from './index.type'
import { OrganizationInputState } from '../../../types/organization.type'
import { ApiContext } from '../../../providers/api.provider'
import { ArrayNumberInput } from '../../ArrayInput'
import TrashIcon from '../../../assets/icons/trash_icon.svg'
import { DatePickerContainer, TrashIconStyled } from '../style'

/**
 *
 * Dialog that presents a form to create a new organization
 */
const AddOrganizationDialog: React.FC<AddOrganizationDialogProps> = ({
  onCloseDialog: handleCloseDialog,
}) => {
  // State to store organization name and sybase customer ids
  const [organizationDetails, setOrganizationDetails] =
    useState<OrganizationInputState>({
      organizationName: '',
      sybaseCustomers: [],
      organizationKey: '',
      products: [],
      toDate: undefined,
      fromDate: undefined,
      ownerPartyIDs: [],
    })

  // Context api to get the services
  const { organizationService } = useContext(ApiContext)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Handler to initiate process to create a new organization
  const handleCreateOrganization = () => {
    if (
      (organizationDetails.fromDate !== undefined &&
        organizationDetails.toDate === undefined) ||
      (organizationDetails.fromDate === undefined &&
        organizationDetails.toDate !== undefined)
    ) {
      return
    }

    organizationService.createOrganization({
      ...organizationDetails,
      fromDate:
        organizationDetails.fromDate !== undefined
          ? format(organizationDetails.fromDate, 'd.M.yyyy')
          : '',
      toDate:
        organizationDetails.toDate !== undefined
          ? format(organizationDetails.toDate, 'd.M.yyyy')
          : '',
    })
  }

  // Handler to update organization name in the state
  const handleUpdateName = (value: string) =>
    setOrganizationDetails({ ...organizationDetails, organizationName: value })

  // Handler to update from date in the state
  const handleFromDate = (value?: Date) => {
    if (
      organizationDetails.toDate !== undefined &&
      value &&
      isAfter(value, organizationDetails.toDate)
    ) {
      return
    }
    setOrganizationDetails({
      ...organizationDetails,
      fromDate: value,
    })
  }

  // Handler to update to date in the state
  const handleToDate = (value?: Date) => {
    if (
      organizationDetails.fromDate !== undefined &&
      value &&
      isBefore(value, organizationDetails.fromDate)
    ) {
      return
    }
    setOrganizationDetails({
      ...organizationDetails,
      toDate: value,
    })
  }

  // Hadnler to update sybase customer ids
  const handleUpdateCustomerIds = (value: number[]) => {
    setOrganizationDetails({
      ...organizationDetails,
      sybaseCustomers: value,
    })
  }

  // handler to update products
  const handleUpdateProducts = (value: number[]) => {
    setOrganizationDetails({
      ...organizationDetails,
      products: value,
    })
  }

  // Update the owner party ids
  const handleUpdateOwnerPartIds = (value: number[]) => {
    setOrganizationDetails({
      ...organizationDetails,
      ownerPartyIDs: value,
    })
  }

  // Handle update organization key
  const hanldeUpdateKey = (value: string) => {
    if (value.length <= 12) {
      setOrganizationDetails({
        ...organizationDetails,
        organizationKey: value.toLocaleLowerCase().replace(' ', '_'),
      })
    }
  }

  // When ever the name of organization is updated, recommend a key
  useEffect(() => {
    if (organizationDetails.organizationName.length <= 12) {
      setOrganizationDetails({
        ...organizationDetails,
        organizationKey: organizationDetails.organizationName
          .toLocaleLowerCase()
          .replaceAll(' ', '_'),
      })
    }
  }, [organizationDetails.organizationName, setOrganizationDetails]) //eslint-disable-line

  return (
    <Dialog
      title={t({ id: 'addOrganization' })}
      okButtonTitle={t({ id: 'createOrganization' })}
      onOkButton={handleCreateOrganization}
      onCancelButton={handleCloseDialog}
      cancelButtonTitle={t({ id: 'cancel' })}
      okButtonDisabled={
        organizationDetails.organizationName.length === 0 ||
        organizationService.creatingOrganization
      }
    >
      <Layout vertical width="100%">
        <Layout vertical width="100%">
          <label>{t({ id: 'organizationName' })}</label>
          <Input
            value={organizationDetails.organizationName}
            onChange={handleUpdateName}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'organizationKey' })}</label>
          <Input
            value={organizationDetails.organizationKey}
            onChange={hanldeUpdateKey}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'sybaseCustomersInput' })}</label>
          <ArrayNumberInput
            value={organizationDetails.sybaseCustomers}
            onChangeValue={handleUpdateCustomerIds}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'productsInput' })}</label>
          <ArrayNumberInput
            value={organizationDetails.products}
            onChangeValue={handleUpdateProducts}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'ownerPartyIDs' })}</label>
          <ArrayNumberInput
            value={organizationDetails.ownerPartyIDs}
            onChangeValue={handleUpdateOwnerPartIds}
          />
        </Layout>
        <Spacer height="16" />
        <Layout horizontal width="100%">
          <DatePickerContainer>
            <label>{t({ id: 'fromDate' })}</label>
            <DatePicker
              onDateChanged={handleFromDate}
              selectedDate={organizationDetails.fromDate}
            />
            {organizationDetails.fromDate && (
              <TrashIconStyled
                onClick={() => handleFromDate(undefined)}
                src={TrashIcon}
              />
            )}
          </DatePickerContainer>
          <Spacer width="24" />
          <DatePickerContainer>
            <label>{t({ id: 'toDate' })}</label>
            <DatePicker
              onDateChanged={handleToDate}
              selectedDate={organizationDetails.toDate}
            />
            {organizationDetails.toDate && (
              <TrashIconStyled
                onClick={() => handleToDate(undefined)}
                src={TrashIcon}
              />
            )}
          </DatePickerContainer>
        </Layout>
      </Layout>
    </Dialog>
  )
}

AddOrganizationDialog.displayName = 'AddOrganizationDialog'
export default AddOrganizationDialog
