import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Text } from '@di-nxt/components'
import { format, isYesterday, isToday } from 'date-fns'
import { ApiContext } from '../../providers/api.provider'

import { WorkspaceLastAccessedProps } from './WorkspaceLastAccessed.type'

/**
 *
 * Render the last accessed column for a workspace
 */
const WorkspaceLastAccessed: React.FC<WorkspaceLastAccessedProps> = ({
  workspace,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Get the user from the context as it contains activity information
  const { user } = useContext(ApiContext)
  // Get the activity for the workspace
  let workspaceActivity: any | undefined = undefined

  if (user?.activity?.activityData) {
    workspaceActivity = user?.activity?.activityData[workspace.workspaceId]
  }

  /**
   *
   * Get the last accessed data
   */
  const getLastAccessed = () => {
    if (workspaceActivity?.LastAccessed) {
      if (isYesterday(workspaceActivity.LastAccessed)) {
        return t({ id: 'yesterday' })
      } else if (isToday(workspaceActivity.LastAccessed)) {
        return format(new Date(workspaceActivity?.LastAccessed), 'HH:mm')
      } else {
        return format(new Date(workspaceActivity?.LastAccessed), 'd.M.yyyy')
      }
    }
    return t({ id: 'never' })
  }
  return <Text variant="inline_14_default">{getLastAccessed()}</Text>
}

WorkspaceLastAccessed.displayName = 'WorkspaceLastAccessed'
export default WorkspaceLastAccessed
