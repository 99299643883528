import { Layout, Text } from '@di-nxt/components'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { BlackUnderlineLink } from '../../../styles/blackUnderlineLink'
import WorkspaceDataContent from './WorkspaceDataContent'

/**
 * Component rendering tree view and information about a selected table
 *
 */
const WorkspaceDataContainer: React.FC = () => {
  const { workspaceId } = useParams()

  return (
    <Layout paddingTop="32" vertical>
      <Layout vertical justify="space-between">
        <Layout vertical flex={8}>
          <Text variant="paragraph_16_default">
            <FormattedMessage
              id="workspaceDataDescription"
              values={{
                'link-to-homepage': (...chunks: string[]) => (
                  <BlackUnderlineLink to={`/workspace/${workspaceId}`}>
                    <Text variant="paragraph_16_default">{chunks}</Text>
                  </BlackUnderlineLink>
                ),
              }}
            />
          </Text>
          <WorkspaceDataContent />
        </Layout>
      </Layout>
    </Layout>
  )
}

WorkspaceDataContainer.displayName = 'WorkspaceDataContainer'

export default WorkspaceDataContainer
