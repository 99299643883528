import { AxiosInstance } from 'axios'
import { CheckQueryResponse, Mode, QueryReturn } from './poll.type'
import { checkQuery, getQueryResults, runQuery } from './runQuery'

const LOAD_RESOURCES_TIMEOUT = 1 * 1000
const INITIAL_TIMEOUT = 3 * 1000
const MEDIUM_TIMEOUT = 60 * 1000
const LONG_TIMEOUT = 5 * 60 * 1000

/**
 *
 * Runs and the polls for the results of a query.
 */
export const poll = async (
  fetcher: AxiosInstance,
  query: string,
  mode: Mode,
  isLoadingResources = false,
) => {
  return new Promise<QueryReturn>((resolve, reject) => {
    runQuery(fetcher, query, mode)
      .then((result) => {
        const queryId = result.data.QueryId
        check(fetcher, queryId, mode, resolve, reject, isLoadingResources)
      })
      .catch((e) => reject(e))
  })
}

export const getPollPromise = (
  fetcher: AxiosInstance,
  query: string,
  mode: Mode,
  cb: (queryId: string) => void,
): [Promise<QueryReturn>, (value: QueryReturn) => void] => {
  let resolver: (value: QueryReturn) => void = () => {}
  return [
    new Promise<QueryReturn>((resolve, reject) => {
      resolver = resolve
      runQuery(fetcher, query, mode)
        .then((result) => {
          const queryId = result.data.QueryId
          cb(queryId)
          check(fetcher, queryId, mode, resolve, reject)
        })
        .catch((e) => reject(e))
    }),
    resolver,
  ]
}

/**
 *
 * Checks for the results of a running query
 * The first 10 times it tries every 3 seconds
 * The second 10 times it tries every 1 minute
 * After that it tries every 5 minutes until the query returns a result
 */
const check = async (
  fetcher: AxiosInstance,
  queryId: string,
  mode: Mode,
  resolve: (value: QueryReturn) => void,
  reject: (reason?: any) => void,
  isLoadingResources = false,
) => {
  try {
    let count = 0
    do {
      count++

      await wait(
        count < 10
          ? isLoadingResources
            ? LOAD_RESOURCES_TIMEOUT
            : INITIAL_TIMEOUT
          : count < 20
          ? MEDIUM_TIMEOUT
          : LONG_TIMEOUT,
      )

      const response = await checkQuery(fetcher, queryId)

      const redshiftResponse = response.data
        .RedshiftResponse as CheckQueryResponse
      const { Status: status, Error: error } = redshiftResponse

      if (status === 'ABORTED') {
        return resolve({ data: 'aborted', error: null })
      }
      if (status === 'FAILED') {
        return resolve({ data: null, error })
      }
      if (status === 'FINISHED') {
        const redshiftResult = await getQueryResults(fetcher, queryId)
        return resolve({
          data:
            mode === 'export'
              ? redshiftResult.data.Result.PresignedS3Urls
              : redshiftResult.data.Result.RedshiftResponse,
          error: null,
        })
      }
    } while (true)
  } catch (e) {
    reject(e)
  }
}

const wait = (ms = INITIAL_TIMEOUT) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
