import styled from 'styled-components'

/**
 * Table with only white background
 */
export const WhiteTable = styled.div`
  width: 100%;
  & * {
    background-color: white;
  }
  & > div {
    border-left: 1px solid rgb(232, 232, 238);
    border-top: 1px solid rgb(232, 232, 238);
  }
`
