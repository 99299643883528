import { Layout, Spacer, Table, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import CustomDialog from '../../../../../components/Dialogs/AddUserDialog/CustomDialog'
import { awsCostData } from './constants'
import useAwsCostDialogDefinitions from './useAwsCostDialogDefinitions'

type Props = {
  setIsAwsCostDialogShown: (isOpen: boolean) => void
}

const AwsCostDialog: React.FC<Props> = ({ setIsAwsCostDialogShown }) => {
  const { formatMessage: t } = useIntl()
  const { awsCostDialogDef } = useAwsCostDialogDefinitions()

  const handleCloseDialog = () => setIsAwsCostDialogShown(false)

  return (
    <CustomDialog
      hasCloseButton
      width={870}
      onOkButton={handleCloseDialog}
      okButtonTitle=""
      data-cy="aws-cost-dialog"
      title={t({ id: 'awsCost' })}
      cancelButtonTitle={t({ id: 'close' })}
      onCancelButton={handleCloseDialog}
    >
      <Layout direction="Vertical">
        <Table columns={awsCostDialogDef as any} data={awsCostData} />
        <Spacer height="24" />
        <Text variant="paragraph_14_default">
          {t({ id: 'exanpleAwsCostCalculations' })}
        </Text>
      </Layout>
    </CustomDialog>
  )
}

export default AwsCostDialog
