import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Text, Layout, Avatar, Spacer } from '@di-nxt/components'

import { WorkspaceOverviewProps } from './index.type'
import { StyledLayout } from './style'
import { ApiContext } from '../../../../providers/api.provider'

/**
 *
 * Create workspace wizard step 1
 * Allows entry of name and description for a new workspace entry
 */
const WorkspaceOverview: React.FC<WorkspaceOverviewProps> = ({
  workspaceName,
  workspaceDescription,
  workspaceUsers,
  workspaceTemplate,
  workspaceKey,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  const { user } = useContext(ApiContext)

  /**
   *
   * Generate label and text for each field
   */
  const getContent = (
    type: 'workspaceType' | 'workspaceName' | 'workspaceKey' | 'description',
  ): React.ReactNode => {
    let label
    let description

    switch (type) {
      case 'workspaceType':
        label = t({ id: 'workspaceType' })
        description = workspaceTemplate
        break
      case 'workspaceName':
        label = t({ id: 'workspaceName' })
        description = workspaceName
        break
      case 'workspaceKey':
        label = t({ id: 'workspaceKey' })
        description = workspaceKey
        break
      case 'description':
        label = t({ id: 'description' })
        description = workspaceDescription
        break
      default:
        return null
    }

    return (
      <Layout vertical paddingBottom="12">
        <Text variant="inline_14_emphasis">{label}</Text>
        <Layout paddingTop="8">
          <Text variant="inline_14_default">{description}</Text>
        </Layout>
      </Layout>
    )
  }
  return (
    <Layout vertical paddingTop="20">
      {getContent('workspaceType')}
      {getContent('workspaceName')}
      {getContent('workspaceKey')}
      {getContent('description')}
      <Layout paddingBottom="12" vertical>
        <Text variant="inline_14_emphasis">{t({ id: 'addedUsers' })}</Text>
        <Layout horizontal paddingTop="8">
          {workspaceUsers
            .filter((u) => u.userId !== user?.userId)
            .map((user) => (
              <StyledLayout key={user.userId}>
                <Avatar name={user.username.toLocaleUpperCase()} />
                <Spacer width="12" />
              </StyledLayout>
            ))}
        </Layout>
      </Layout>
    </Layout>
  )
}

WorkspaceOverview.displayName = 'WorkspaceOverview'

export default WorkspaceOverview
