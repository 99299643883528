import { TableColumn } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { AwsCostItem } from './types'

const useAwsCostDialogDefinitions = () => {
  const { formatMessage: t } = useIntl()

  const awsCostDialogDef: TableColumn<AwsCostItem>[] = [
    {
      key: 'organization',
      title: '',
      cellStyle: {
        minWidth: '125px',
      },
    },
    {
      key: 'type',
      title: t({ id: 'type' }),
      cellStyle: {
        minWidth: '147px',
      },
    },
    {
      key: 'awsCost',
      title: t({ id: 'awsCost' }),
      cellStyle: {
        minWidth: '146px',
      },
    },
    {
      key: 'markup',
      title: t({ id: 'markup' }),
      cellStyle: {
        minWidth: '121px',
      },
    },
    {
      key: 'customerPays',
      title: t({ id: 'customerPays' }),
      cellStyle: {
        minWidth: '166px',
      },
    },
  ]

  return {
    awsCostDialogDef,
  }
}

export default useAwsCostDialogDefinitions
