import { LayoutProps } from '@di-nxt/components'
import { InformationPanelProps } from '../components/InformationPanel/index.type'

/**
 *
 * Workspace selector and organization selector share the same layout props
 */
export const SelectorLayoutProps: LayoutProps = {
  width: '350px',
  maxHeight: '450px',
  backgroundColor: 'background_mono_primary',
  shadow: true,
  borderRadius: 'small',
  overflow: 'hidden',
  borderColor: 'border_mono_30',
  borderWidth: 'hairline',
  borderStyle: 'solid',
  vertical: true,
}

/**
 *
 * Information panel configuration for accent type background
 */
export const InformationPanelAccent: Pick<
  InformationPanelProps,
  'backgroundColor' | 'borderColor' | 'textColor'
> = {
  backgroundColor: 'background_accent_light',
  borderColor: 'border_accent_default',
  textColor: 'content_accent_default',
}

/**
 *
 * Information pabel configuration for warning type background
 */
export const InformationPanelWarn: Pick<
  InformationPanelProps,
  'backgroundColor' | 'borderColor' | 'textColor'
> = {
  backgroundColor: 'background_warning_light',
  borderColor: 'border_warning_default',
  textColor: 'content_warning_default',
}

/**
 *
 * Information pabel configuration for success type background
 */
export const InformationPanelSuccess: Pick<
  InformationPanelProps,
  'backgroundColor' | 'borderColor' | 'textColor'
> = {
  backgroundColor: 'background_positive_light',
  borderColor: 'border_positive_default',
  textColor: 'content_positive_default',
}

/**
 *
 * Information pabel configuration for error type background
 */
export const InformationPanelError: Pick<
  InformationPanelProps,
  'backgroundColor' | 'borderColor' | 'textColor'
> = {
  backgroundColor: 'background_negative_light',
  borderColor: 'border_negative_default',
  textColor: 'content_negative_default',
}
