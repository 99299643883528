import { useState } from 'react'
import {
  UseWorkspaceData,
  WorkspaceData,
  WorkspaceDataTable,
} from './useWorkspaceData.type'
import { parseModel } from './parseModel'
import { ENDPOINTS } from '../../types/config.api.type'
import useApi from './useApi'

export const defaultUseWorkspaceData: UseWorkspaceData = {
  loadingWorkspaceData: false,
  errorLoadingWorkspaceData: false,
  workspaceData: { database: '', tables: [], dataId: undefined },
  selectedTable: null,
  changeSelectedTable: (_: string) => null,
  getWorkspaceData: () => null,
  clearData: () => null,
}

const useWorkspaceData = (): UseWorkspaceData => {
  // Local state management to store various states of information
  const [loadingWorkspaceData, setLoadingWorkspaceData] =
    useState<boolean>(false)
  const [errorLoadingWorkspaceData, setErrorLoadingWorkspaceData] =
    useState<boolean>(false)
  const [workspaceData, setWorkspaceData] = useState<WorkspaceData>({
    database: '',
    tables: [],
    dataId: undefined,
  })

  const [selectedTable, setSelectedTable] = useState<WorkspaceDataTable | null>(
    null,
  )

  const { fetcher } = useApi()

  /**
   *
   * Function to clear data from local state
   */
  const clearData = () =>
    setWorkspaceData({ database: '', tables: [], dataId: undefined })

  const changeSelectedTable = (tableName: string) => {
    const index = workspaceData.tables.findIndex(
      (table) => table.tableName === tableName,
    )
    if (index !== -1) {
      setSelectedTable(workspaceData.tables[index])
    }
  }

  const getWorkspaceData = async () => {
    setLoadingWorkspaceData(true)
    fetcher
      .get(
        workspaceData.dataId
          ? ENDPOINTS.WORKSPACE_DATA_MODEL.replace(
              '$dataId',
              workspaceData.dataId,
            )
          : ENDPOINTS.WORKSPACE_DATA,
      )
      .then((response) => {
        const { model, dataId } = response.data
        const data = parseModel(JSON.parse(model))
        setWorkspaceData({ ...data, dataId })
        if (data.tables.length !== 0) {
          setSelectedTable(data.tables[0])
        }
      })
      .catch((error) => setErrorLoadingWorkspaceData(true))
      .finally(() => setLoadingWorkspaceData(false))
  }

  return {
    loadingWorkspaceData,
    errorLoadingWorkspaceData,
    workspaceData,
    getWorkspaceData,
    selectedTable,
    changeSelectedTable,
    clearData,
  }
}

export default useWorkspaceData
