import { Marked } from '../styles/Marked'
import { Text } from '@di-nxt/components'

/**
 *
 * Returns highlighted and unhighlighted parts of the text
 */
const getParts = (
  text: string,
  matchedPart: string,
  isDisabled: boolean = false,
) => {
  const parts = new RegExp(`(.*?)(${matchedPart})(.*)`).exec(text)!
  return parts
    .slice(1)
    .filter((part) => part !== '')
    .map((part, i) => ({
      jsx: (
        <Text
          key={`${part}${i}`}
          variant="inline_14_default"
          textColor={
            isDisabled && part !== matchedPart
              ? 'content_mono_disabled'
              : undefined
          }
        >
          {part}
        </Text>
      ),
      isMarked: part === matchedPart,
    }))
}

/**
 *
 * Returns jsx which renders highlighted and unhighlighted parts of the text
 */
export const getMarkedText = (
  text: string,
  matchedPart: string | null,
  isDisabled: boolean = false,
): JSX.Element => {
  if (matchedPart) {
    const textParts = getParts(text, matchedPart, isDisabled)
    return (
      <Marked states={textParts.map((part) => ({ marked: part.isMarked }))}>
        {textParts.map((part) => part.jsx)}
      </Marked>
    )
  }
  return (
    <Marked states={[{ marked: false }]}>
      <Text
        variant="inline_14_default"
        textColor={isDisabled ? 'content_mono_disabled' : undefined}
      >
        {text}
      </Text>
    </Marked>
  )
}
