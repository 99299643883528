import styled from 'styled-components'

export const StyledBadge = styled.div<StyledBadgeProps>`
  > div {
    background-color: ${(props) => props.backgroundColor};
    ${(props) => (props.height ? `height: ${props.height}` : '')}
  }
  > div > span {
    background-color: transparent;
    color: ${(props) => props.textColor};
  }
  ${(props) => (props.center ? `display: flex; align-items: center;` : '')}
`
export type StyledBadgeProps = {
  textColor: string
  backgroundColor: string
  height?: string
  center?: boolean
}
