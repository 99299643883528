import { useState } from 'react'
import { format } from 'date-fns'
import { QueryMetric } from '../../containers/CostTracker/types'
import { ENDPOINTS } from '../../types/config.api.type'
import useApi from './useApi'

const PAST_MONTHS_COUNT = 5

const useQueryMetrics = (organizationId?: string) => {
  const { fetcher } = useApi()

  const [isQueryMetricsLoading, setQueryMetricsLoading] = useState(false)
  const [queryMetrics, setQueryMetrics] = useState<QueryMetric[]>([])

  const getQueryMetrics = () => {
    if (!organizationId) {
      console.error('No organization selected')
      return
    }

    const fromDate = new Date()
    fromDate.setDate(1)
    fromDate.setMonth(fromDate.getMonth() - PAST_MONTHS_COUNT)
    const fromDateString = format(fromDate, 'yyyy-MM-dd')

    setQueryMetricsLoading(true)
    fetcher
      .get(
        ENDPOINTS.QUERY_METRICS.replace(
          '$organizationId',
          organizationId,
        ).replace('$fromDate', fromDateString),
      )
      .then((queriesResponse) => {
        setQueryMetrics(queriesResponse.data)
      })
      .catch((e) => {
        console.error(e)
        setQueryMetrics([])
      })
      .finally(() => setQueryMetricsLoading(false))
  }

  return { queryMetrics, isQueryMetricsLoading, getQueryMetrics }
}

export default useQueryMetrics
