import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { Layout, Text } from '@di-nxt/components'
import { SelectedUsersProps } from './index.type'
import SelectedUser from './SelectedUser'
import { ApiContext } from '../../../providers/api.provider'
/**
 *
 * Render the list of users that have already been selected
 */
const SelectedUsers: React.FC<SelectedUsersProps> = ({
  users,
  onRemoveUser,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  const { user } = useContext(ApiContext)
  const filteredUsers = users.filter((u) => u.userId !== user?.userId)

  let padding = {}

  if (filteredUsers.length > 0) {
    padding = {
      paddingLeft: '8',
      paddingRight: '8',
    }
  }

  return (
    <Layout
      width="100%"
      horizontal
      backgroundColor="background_mono_secondary"
      {...padding}
      paddingLeft="16"
      paddingRight="16"
      minHeight="0"
      height={filteredUsers.length === 0 ? '0' : 'auto'}
    >
      <Layout flex={3.5} vertical align="center" justify="center">
        <Text variant="paragraph_14_emphasis">
          {filteredUsers.length > 0 &&
            t(
              { id: 'usersSelected' },
              {
                amount: filteredUsers.length,
                postfix: filteredUsers.length > 1 ? 's' : '',
              },
            )}
        </Text>
      </Layout>
      <Layout flex={6.5} spacing="8">
        {filteredUsers.map((user) => (
          <SelectedUser user={user} key={user.userId} />
        ))}
      </Layout>
    </Layout>
  )
}

SelectedUsers.displayName = 'SelectedUsers'
export default SelectedUsers
