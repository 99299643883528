import styled from 'styled-components'

/**
 *
 * Styled container
 */
export const StyledListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: scroll;
`
