import { Icon } from '@di-nxt/components'
import { FormattedMessage } from 'react-intl'
import { OrganizationRoles } from '../../types/organization.type'
import { WorkspaceUserRole } from '../../types/workspace.type'
import { UseDropdown } from './useDropdown.type'
import { TableauUserSiteRole } from '../../types/tableau.api.type'

/**
 *
 * Hook to manage configurations related to different drop down items
 */
export const useDropdown = (): UseDropdown => {
  /**
   *
   * Dropdwon for selecting role of user in an organization
   * A role can be "Admin" | "Editor" | "User"
   */
  const organizationRoles = [
    {
      title: <FormattedMessage id="admin" />,
      description: <FormattedMessage id="adminRoleDescription" />,
      key: OrganizationRoles.Admin,
    },
    {
      title: <FormattedMessage id="editor" />,
      description: <FormattedMessage id="editorRoleDescription" />,
      key: OrganizationRoles.Editor,
    },
    {
      title: <FormattedMessage id="user" />,
      description: <FormattedMessage id="userRoleDescription" />,
      key: OrganizationRoles.User,
    },
  ]

  /**
   *
   * Dropdown for selecting role of a user in a workspace
   * A role can be "Owner" | "Editor" | "Viewer"
   */
  const workspaceRoles = [
    {
      title: <FormattedMessage id="owner" />,
      description: <FormattedMessage id="ownerDescription" />,
      icon: <Icon name="globe" size="16" />,
      key: WorkspaceUserRole.owner,
    },
    {
      title: <FormattedMessage id="editor" />,
      description: <FormattedMessage id="editorDescription" />,
      icon: <Icon name="edit" size="16" />,
      key: WorkspaceUserRole.editor,
    },
    {
      title: <FormattedMessage id="viewer" />,
      description: <FormattedMessage id="viewerDescription" />,
      icon: <Icon name="image" size="16" />,
      key: WorkspaceUserRole.viewer,
    },
  ]

  /**
   *
   * Dropdwon for selecting Tableau site role of user in an organization
   */
  const tableauRoles = [
    {
      title: <FormattedMessage id="tableauSiteRoleUnassigned" />,
      description: <FormattedMessage id="tableauSiteRoleUnassignedDescription" />,
      key: TableauUserSiteRole.Unassigned,
    },
    {
      title: <FormattedMessage id="tableauSiteRoleViewer" />,
      description: <FormattedMessage id="tableauSiteRoleViewerDescription" />,
      key: TableauUserSiteRole.Viewer,
    },
    {
      title: <FormattedMessage id="tableauSiteRoleCreator" />,
      description: <FormattedMessage id="tableauSiteRoleCreatorDescription" />,
      key: TableauUserSiteRole.Creator,
    },
    {
      title: <FormattedMessage id="tableauSiteRoleSiteAdministratorCreator" />,
      description: <FormattedMessage id="tableauSiteRoleSiteAdministratorCreatorDescription" />,
      key: TableauUserSiteRole.SiteAdministratorCreator,
    },
  ]

  return {
    organizationRoles,
    workspaceRoles,
    tableauRoles,
  }
}
