import { Layout, Text } from '@di-nxt/components'
import React from 'react'
import { NoContentProps } from './index.type'

/**
 *
 * A generic component that renders a message
 */
const NoContent: React.FC<NoContentProps> = ({ layout, message }) => {
  return (
    <Layout
      padding="24"
      backgroundColor="background_mono_primary"
      width="100%"
      align="center"
      justify="center"
      borderWidth="hairline"
      borderColor="border_mono_10"
      borderStyle="solid"
      {...layout}
    >
      <Text variant="inline_14_default">{message}</Text>
    </Layout>
  )
}

NoContent.displayName = 'NoContent'

export default NoContent
