import { Layout, Spinner } from '@di-nxt/components'
import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import useTableauFetch from '../../../hooks/api/useTableauFetch'
import { withHeader, withSidebar } from '../../../hooks'
import EmbeddedView from '../../../components/Tableau/EmbeddedView'
import { TableauPathParams } from '../../../hooks/api/useTableauRestClient'
import { TableauView } from '../../../types/tableau.api.type'
import { PageHeaderContext } from '../../../providers/pageHeader.provider'

const TableauEmbeddedViewContainer: React.FC = () => {
  const [searchParams] = useSearchParams()
  const siteContentUrl = searchParams.get('siteContentUrl')!
  const viewId = searchParams.get('viewId')!

  const { data: viewData, loading, errorStatus } = useTableauFetch<{
    view: TableauView
  }>(siteContentUrl, `sites/${TableauPathParams.siteId}/views/${viewId}`)

  const {
    setDynamicBreadcrumb,
    removeDynamicBreadcrumb,
    setDynamicTitle,
    removeDynamicTitle,
  } = useContext(PageHeaderContext)

  useEffect(() => {
    if (!viewData?.view) {
      return
    }
    setDynamicTitle(viewData.view.name)
    setDynamicBreadcrumb('tableauViewName', viewData.view.name)
    return () => {
      removeDynamicTitle()
      removeDynamicBreadcrumb('tableauViewName')
    }
  }, [viewData?.view]) // eslint-disable-line

  return (
    <Layout flex={loading ? 1 : 0} vertical centered>
      {loading ? (
        <Spinner size="Large" />
      ) : errorStatus ? (
        'Error'
      ) : (
        <EmbeddedView
          siteContentUrl={siteContentUrl}
          viewContentUrl={viewData!.view.contentUrl}
        ></EmbeddedView>
      )}
    </Layout>
  )
}

TableauEmbeddedViewContainer.displayName = 'TableauEmbeddedViewContainer'

export default withSidebar(withHeader(TableauEmbeddedViewContainer))
