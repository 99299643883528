import React from 'react'
import { Table, Layout } from '@di-nxt/components'
import { WorkspaceUsersTableProps } from './index.type'
import { useDefinitions } from '../../../hooks'

/**
 *
 * Display a table that renders users belonging to a workspace
 */
const WorkspaceUsersTable: React.FC<WorkspaceUsersTableProps> = ({
  workspaceUsers,
  canEditUsers,
}) => {
  // Get the table definitions
  const { workspaceUsersTableDef } = useDefinitions()
  return (
    <Layout backgroundColor="background_mono_primary">
      <Table
        columns={workspaceUsersTableDef(canEditUsers)}
        data={workspaceUsers}
      />
    </Layout>
  )
}

WorkspaceUsersTable.displayName = 'WorkspaceUsersTable'
export default WorkspaceUsersTable
