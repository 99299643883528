import { useMemo, useState } from 'react'
import {
  Layout,
  Sorting,
  Spacer,
  Spinner,
  Table,
  Text,
} from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { monthlyCostRowsPerPage } from './constants'
import useMonthlyCostDefinitions from './useMonthlyCostDefinitions'
import { QueryMetric } from '../../types'

type Comparator<T> = (o1: T, o2: T) => number

type Props = {
  queryMetrics: QueryMetric[]
  isQueryMetricsLoading: boolean
}

const MonthlyCost: React.FC<Props> = ({
  queryMetrics,
  isQueryMetricsLoading,
}) => {
  const { formatMessage: t } = useIntl()
  const { monthlyCostTableDef } = useMonthlyCostDefinitions()
  const [sorting, setSorting] = useState<Sorting<QueryMetric> | undefined>(
    undefined,
  )

  const sortedQueryMetrics = useMemo(() => {
    let sortedQueryMetrics = queryMetrics
    if (sorting) {
      let comparator: Comparator<QueryMetric> | undefined = undefined
      switch (sorting.key) {
        case 'month':
          comparator = (q1, q2) =>
            q1.Year * 12 + q1.Month - (q2.Year * 12 + q2.Month)
          break
        case 'dataRows':
          comparator = (q1, q2) => q1.DataRows - q2.DataRows
          break
      }

      if (comparator) {
        const direction = sorting.direction === 'asc' ? 1 : -1
        sortedQueryMetrics = [...queryMetrics].sort(
          (q1, q2) => direction * comparator!(q1, q2),
        )
      }
    }
    return sortedQueryMetrics
  }, [queryMetrics, sorting])

  return (
    <Layout direction="Vertical">
      <Text variant="paragraph_20_emphasis">
        {t({ id: 'monthlyCostTable' })}
      </Text>
      <Spacer height="32" />
      <Table
        columns={monthlyCostTableDef as any}
        data={sortedQueryMetrics}
        onSort={(sorting) => setSorting(sorting)}
        state={{ sorting }}
        pagination
        rowsPerPage={monthlyCostRowsPerPage}
      />
      {isQueryMetricsLoading && (
        <Layout justify="center" paddingTop="12">
          <Spinner />
        </Layout>
      )}
    </Layout>
  )
}

export default MonthlyCost
