import React from 'react'
import { Layout, Spacer, Text } from '@di-nxt/components'
import { DropdownItemProps } from './index.type'

/**
 *
 * User role selector item
 */
const DropdownItem: React.FC<DropdownItemProps> = ({
  item,
  selected,
  onSelect,
  parentRef,
}) => {
  /**
   *
   * Select user role
   */
  const handleSelectUserRole = () => {
    if (parentRef && parentRef.current) {
      parentRef.current.click()
    }
    onSelect(item)
  }

  return (
    <Layout
      width="100%"
      vertical
      cursor="pointer"
      backgroundColor={
        selected ? 'background_mono_secondary' : 'background_mono_primary'
      }
      onPress={handleSelectUserRole}
      hover
    >
      <Layout
        horizontal
        align="center"
        paddingTop="16"
        paddingLeft="16"
        paddingRight="16"
      >
        {item.icon && (
          <>
            {item.icon}
            <Spacer width="6" />
          </>
        )}
        <Text variant="inline_14_emphasis">{item.title}</Text>
      </Layout>
      <Layout paddingTop="6" padding="16">
        <Text variant="inline_12_default" textColor="content_mono_secondary">
          {item.description}
        </Text>
      </Layout>
    </Layout>
  )
}

DropdownItem.displayName = 'DropdownItem'

export default DropdownItem
