import React from 'react'
import { Layout, Spinner, Text } from '@di-nxt/components'
import { StatusSpinnerProps } from './index.type'

/**
 *
 * A floating status spinner with message
 */
const StatusSpinner: React.FC<StatusSpinnerProps> = ({ message }) => {
  return (
    <Layout horizontal>
      <Layout paddingRight="12">
        <Spinner size="Small" />
      </Layout>
      <Layout>
        <Text variant="inline_12_default">{message}</Text>
      </Layout>
    </Layout>
  )
}

StatusSpinner.displayName = 'StatusSpinner'

export default StatusSpinner
