import { HttpStatusCode } from 'axios'
import { useEffect, useState } from 'react'
import useApi from './useApi'
import { QueryHookParams } from '../types'
import {
  AllOrganizationsSharedData,
  OrganizationSharedData,
  ReceivingOrganizationId,
  SendingOrganizationId,
} from '../../types/organization.type'

const useOrganizationSharedData = (
  organizationId: string,
  params?: QueryHookParams,
) => {
  const { fetcher } = useApi()
  const [
    allSharedData,
    setAllSharedData,
  ] = useState<AllOrganizationsSharedData>({
    sentData: [],
    receivedData: [],
  })
  const [loading, setLoading] = useState<boolean>(!params?.disabled)

  useEffect(() => {
    if (params?.disabled) {
      return
    }

    setLoading(true)
    setAllSharedData({
      sentData: [],
      receivedData: [],
    })

    fetcher
      .get<AllOrganizationsSharedData>(
        `/organization/${organizationId}/sharedData`,
        {
          validateStatus: (status) => status === HttpStatusCode.Ok,
        },
      )
      .then((response) => {
        setAllSharedData(response.data)
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  const updateSharedData = async (
    sendingOrganizationId: string,
    sentData: ReceivingOrganizationId & { sharedData: OrganizationSharedData },
  ) => {
    return fetcher
      .post(`/organization/${sendingOrganizationId}/sharedData`, sentData, {
        validateStatus: (status) => status === HttpStatusCode.Ok,
      })
      .then(() => {
        if (sendingOrganizationId === organizationId) {
          updateSentData(sentData)
        } else if (sentData.receivingOrganizationId === organizationId) {
          updateReceivedData({
            sendingOrganizationId,
            sharedData: sentData.sharedData,
          })
        }
      })
  }

  const updateSentData = (
    sentData: ReceivingOrganizationId & { sharedData: OrganizationSharedData },
  ) => {
    setAllSharedData({
      ...allSharedData,
      sentData: allSharedData.sentData.map((data) => {
        if (data.receivingOrganizationId === sentData.receivingOrganizationId) {
          return {
            receivingOrganizationId: data.receivingOrganizationId,
            receivingOrganizationName: data.receivingOrganizationName,
            sharedData: sentData.sharedData,
          }
        }
        return data
      }),
    })
  }

  const updateReceivedData = (
    receivedData: SendingOrganizationId & {
      sharedData: OrganizationSharedData
    },
  ) => {
    setAllSharedData({
      ...allSharedData,
      receivedData: allSharedData.receivedData.map((data) => {
        if (data.sendingOrganizationId === receivedData.sendingOrganizationId) {
          return {
            sendingOrganizationId: data.sendingOrganizationId,
            sendingOrganizationName: data.sendingOrganizationName,
            sharedData: receivedData.sharedData,
          }
        }
        return data
      }),
    })
  }

  return {
    allSharedData,
    loading,
    updateSharedData,
  }
}

export default useOrganizationSharedData
