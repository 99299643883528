import { Layout } from '@di-nxt/components'
import DropdownInfo from './DropdownInfo'

/**
 *  Dropdown component showing different search options
 *
 */
const Dropdown: React.FC = () => {
  return (
    <Layout
      flex
      backgroundColor="background_mono_primary"
      position="absolute"
      zIndex={1000}
      left={0}
      top={48}
      right={0}
      vertical
      paddingTop="8"
      borderRadius="medium"
      shadow
    >
      <Layout
        paddingTop="20"
        paddingLeft="16"
        paddingRight="16"
        paddingBottom="32"
        vertical
      >
        <DropdownInfo />
      </Layout>
    </Layout>
  )
}

Dropdown.displayName = 'Dropdown'

export default Dropdown
