import { Layout, Text } from '@di-nxt/components'
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import AboutIcon from '../../../../../assets/icons/about_icon.svg'
import { StyledAboutIcon } from './style'
import TierCostDialog from './TierCostDialog'

const TierCostTableTitle = () => {
  const { formatMessage: t } = useIntl()
  const [isTierCostDialogShown, setIsTierCostDialogShown] = useState(false)

  const openDialog = useCallback(() => {
    setIsTierCostDialogShown(true)
  }, [])

  return (
    <Layout justify="space-between" width={140}>
      <Text variant="paragraph_14_emphasis">{t({ id: 'tierCost' })}</Text>
      <StyledAboutIcon
        src={AboutIcon}
        alt={t({ id: 'tierCost' })}
        onClick={openDialog}
      />
      {isTierCostDialogShown && (
        <TierCostDialog setIsTierCostDialogShown={setIsTierCostDialogShown} />
      )}
    </Layout>
  )
}

export default TierCostTableTitle
