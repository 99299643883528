import React, { createContext, useState } from 'react'
import { AppStateContextType, DialogTypes } from './app.state.provider.type'

/**
 *
 * Default state for app state
 */
const defaultAppState: AppStateContextType = {
  openDialogs: {
    createWorkspaceDialog: false,
    addNewUserDialog: false,
    addWorkspaceUsers: false,
  },
  manageDialog: () => null,
}

/**
 *
 * App state provider stores:
 * 1 - Dialog states
 * 2 - Toaster states
 * 3 - Any other configurations related to functioning of application.
 */
export const AppStateContext = createContext<AppStateContextType>(
  defaultAppState,
)

/**
 * Construct the provider
 */
const AppStateProvider: React.FC = ({ children }) => {
  // Local state management
  const [openDialogs, setOpenDialogs] = useState<DialogTypes>(
    defaultAppState.openDialogs,
  )

  /**
   *
   * Open or close a dialog
   */
  const manageDialog = (dialog: DialogTypes) => {
    setOpenDialogs({ ...openDialogs, ...dialog })
  }

  return (
    <AppStateContext.Provider
      value={{
        openDialogs,
        manageDialog,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}

AppStateProvider.displayName = 'AppStateProvider'
export default AppStateProvider
