import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Dialog, Layout } from '@di-nxt/components'
import WorkspaceUserSelection from '../../WorkspaceUserSelection'
import {
  WorkspaceUserInput,
  WorkspaceUserRole,
} from '../../../types/workspace.type'
import { AddWorkspaceUsersDialogProps } from './index.type'
import { ApiContext } from '../../../providers/api.provider'
import StatusSpinner from '../../StatusSpinner'

/**
 *
 * Dialog for adding users to an existing workspace
 */
const AddWorkspaceUsersDialog: React.FC<AddWorkspaceUsersDialogProps> = ({
  handleOnClose,
  existingUsers,
}) => {
  // Api context to create workspace users
  const { workspaceUsersService, workspaceService, user } = useContext(
    ApiContext,
  )
  const { createWorkspaceUsers, addingWorkspaceUsers } = workspaceUsersService

  // Users to add to workspace
  const [usersToAdd, setUsersToAdd] = useState<WorkspaceUserInput[]>([])

  // Selected role
  const [selectedRole, setSelectedRole] = useState<WorkspaceUserRole>(
    WorkspaceUserRole.viewer,
  )

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Handle adding users to workspace
  const handleOkButton = () => {
    const { workspace, updateWorkspace } = workspaceService
    if (workspace) {
      createWorkspaceUsers(workspace.workspaceId, {
        users: usersToAdd,
        workspaceName: workspace.workspaceName,
        workspaceKey: workspace.workspaceKey ?? '',
        organizationKey: user?.organization?.organizationKey ?? '',
      })
    }

    if (workspace && workspace.workspaceUsers) {
      updateWorkspace(workspace.workspaceId, {
        workspaceUsers: [...workspace.workspaceUsers, ...usersToAdd],
      })
    }
  }

  // Handle adding users to array set of new users to be added
  const handleAddWorkspaceUser = (userToAdd: WorkspaceUserInput) => {
    setUsersToAdd([...usersToAdd, userToAdd])
  }

  // Handle filterning users list to remove selection
  const handleRemoveWorkspaceUser = (userToRemove: WorkspaceUserInput) => {
    setUsersToAdd(
      usersToAdd.filter(
        (addedUser) => addedUser.userId !== userToRemove.userId,
      ),
    )
  }

  // Handle updating of user roles and apply to all the users in the list
  const handleSetSelectedRole = (role: WorkspaceUserRole) => {
    setSelectedRole(role)
    const updatedUsersToAdd = usersToAdd.map((userToAdd) => ({
      ...userToAdd,
      workspaceRole: role,
    }))

    setUsersToAdd(updatedUsersToAdd)
  }

  return (
    <Dialog
      title={t({ id: 'addPeopleToWorkspace' })}
      onOkButton={handleOkButton}
      okButtonTitle={t({ id: 'addUsers' })}
      cancelButtonTitle={t({ id: 'cancel' })}
      onCancelButton={handleOnClose}
    >
      <WorkspaceUserSelection
        users={usersToAdd}
        excludeUsers={existingUsers}
        onAddWorkspaceUser={handleAddWorkspaceUser}
        onRemoveWorkspaceUser={handleRemoveWorkspaceUser}
        selectedRole={selectedRole}
        onSelectRole={handleSetSelectedRole}
      />
      {addingWorkspaceUsers && (
        <Layout position="absolute" bottom={-54} left={28}>
          <StatusSpinner message={t({ id: 'addingUserToWorkspace' })} />
        </Layout>
      )}
    </Dialog>
  )
}

AddWorkspaceUsersDialog.displayName = 'AddWorkspaceUsersDialog'
export default AddWorkspaceUsersDialog
