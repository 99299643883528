import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
// Other components
import SelectTemplate from './SelectTemplate'
import CreateWorkspaceDetails from './CreateWorkspaceDetails'
import AddUsersToWorksapce from './AddUsersToWorksapce'
import WorkspaceOverview from './WorkspaceOverview'
import Dialog from '../../Dialog'
import Wizard from '../../Wizard'
// Providers
import { AppStateContext } from '../../../providers/app.state.provider'
import { ApiContext } from '../../../providers/api.provider'
// Types
import {
  CreateWorkspaceInput,
  WorkspaceUserInput,
  WorkspaceUserRole,
} from '../../../types/workspace.type'
import { User } from '../../../types/user.type'

/**
 *
 * Create workspace wizard dialog
 * 1 - Provide workspace name and description
 * 2 - Add users
 * 3 - Review
 */
const CreateWorkspaceWizard: React.FC = () => {
  // App state context to manage dialog state
  const { manageDialog } = useContext(AppStateContext)

  // Api service context
  const { workspacesService, user, selectedOrganization } = useContext(
    ApiContext,
  )

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // use workspaces hook to create a new workspace
  const { handleCreateWorkspace, creatingWorkspace } = workspacesService

  // Manage wizard step
  const [wizardStep, setWizardStep] = useState<number>(1)

  // Store the template type selected for the workspace creation
  const [workspaceTemplate, setWorkspaceTemplate] = useState<
    'Blank' | undefined
  >(undefined)

  // Store name for the workspace in state
  const [workspaceName, setWorkspaceName] = useState<string>('')

  // Store description for the workspace in state
  const [workspaceDescription, setWorkspaceDescription] = useState<string>('')

  // Store workspace key
  const [workspaceKey, setWorkspaceKey] = useState<string>('')

  // Users added to a workspace
  const [workspaceUsers, setWorkspaceUsers] = useState<WorkspaceUserInput[]>([])

  // Selected Role for the users to be added to workspace
  const [selectedRole, setSelectedRole] = useState<WorkspaceUserRole>(
    WorkspaceUserRole.viewer,
  )

  // Set the state of ok button
  const [advanceButtonDisabled, setAdvanceButtonDisabled] =
    useState<boolean>(false)

  /**
   *
   * Use effect on steps to validate whether the advance button should be enabled
   */
  useEffect(() => {
    if (wizardStep === 1) {
      setAdvanceButtonDisabled(workspaceTemplate === undefined)
    } else if (
      wizardStep === 2 &&
      workspaceName.trim().length < 3 &&
      workspaceKey.length === 0
    ) {
      setAdvanceButtonDisabled(true)
    }
  }, [wizardStep, workspaceTemplate, workspaceName, workspaceKey])

  useEffect(() => {
    if (
      workspaceUsers.length === 0 &&
      user &&
      user.organization?.organizationId === selectedOrganization?.organizationId
    ) {
      setWorkspaceUsers([
        {
          userId: user.userId,
          workspaceRole: WorkspaceUserRole.owner,
          username: user.username,
          email: user.email,
        },
      ])
    }
  }, [user, workspaceUsers, selectedOrganization])

  useEffect(() => {
    if (wizardStep === 3) {
      if (
        user?.organization?.organizationId !==
        selectedOrganization?.organizationId
      ) {
        if (workspaceUsers.length === 0) {
          setAdvanceButtonDisabled(true)
        } else if (
          workspaceUsers.filter(
            (user) => user.workspaceRole === WorkspaceUserRole.owner,
          ).length === 0
        ) {
          setAdvanceButtonDisabled(true)
        } else {
          setAdvanceButtonDisabled(false)
        }
      }
    }
  }, [
    workspaceUsers,
    selectedOrganization,
    advanceButtonDisabled,
    user,
    wizardStep,
  ])

  /**
   *
   * Handle the action when ok button is pressed
   */
  const handleOkButton = () => {
    if (wizardStep + 1 <= 4) {
      const nextStep = wizardStep + 1
      setWizardStep(nextStep)
    }
    if (wizardStep + 1 === 5) {
      // Create the payload for backend service
      const workspace: CreateWorkspaceInput = {
        workspaceName: workspaceName,
        workspaceDescription: workspaceDescription,
        workspaceKey: workspaceKey,
        workspaceUsers: workspaceUsers,
      }

      // Call the method to initiate creation of workspace
      handleCreateWorkspace(workspace)
    }
  }

  /**
   *
   * Handle the action when cancel button is pressed
   */
  const handleCancelButton = () => {
    if (wizardStep - 1 >= 1) {
      const prevStep = wizardStep - 1
      setWizardStep(prevStep)
    } else {
      manageDialog({ createWorkspaceDialog: false })
    }
  }

  const closeDialog = () => {
    manageDialog({ createWorkspaceDialog: false })
  }

  /**
   *
   * Handle change of input on workspace step 1
   * @param {String} value - value of the input
   * @param {String} inputProperty
   */
  const handleInputChange = (
    value: string,
    inputProperty: 'name' | 'description' | 'key',
  ) => {
    switch (inputProperty) {
      case 'name':
        setWorkspaceName(value)
        break
      case 'description':
        setWorkspaceDescription(value)
        break
      case 'key':
        setWorkspaceKey(value)
        break
    }
  }

  /**
   *
   * Handle selecting workspace template
   */
  const handleSelectWorkspaceTemplate = (
    workspaceTemplate: 'Blank' | undefined,
  ) => setWorkspaceTemplate(workspaceTemplate)

  /**
   *
   * Adds a new user to the list of workspace users
   */
  const handleAddWorkspaceUser = (user: WorkspaceUserInput) => {
    setWorkspaceUsers([
      ...workspaceUsers,
      { ...user, workspaceRole: selectedRole },
    ])
  }

  /**
   *
   * Removes a user from the workspace users list
   */
  const handleRemoveWorkspaceUser = (user: WorkspaceUserInput) => {
    setWorkspaceUsers(
      workspaceUsers.filter(
        (workspaceUser) => workspaceUser.userId !== user.userId,
      ),
    )
  }

  /**
   *
   * select workspace user role
   */
  const handleSelectUsersRole = (
    role: WorkspaceUserRole,
    currentUser?: User,
  ) => {
    setSelectedRole(role)
    // Update the role of all users except the user creating the workspace
    const updatedUsers = workspaceUsers.map((workspaceUser) => {
      if (workspaceUser.userId === currentUser?.userId) {
        return workspaceUser
      }

      return {
        ...workspaceUser,
        workspaceRole: role,
      }
    })
    // Save the information in local state
    setWorkspaceUsers(updatedUsers)
  }

  /**
   *
   * Sets flag on next, overrides component functionality
   */
  const handleOkButtonDisabled = (nextDisabled: boolean) => {
    setAdvanceButtonDisabled(nextDisabled)
  }

  /**
   *
   * Get teh text for the create workspace button
   */
  const getOkButtonText = (): string => {
    if (wizardStep + 1 === 5) {
      return t({ id: 'createWorkspace' })
    }
    return t({ id: 'next' })
  }

  /**
   *
   * Get the text for the cancel button
   */
  const getCancelButtonText = (): string => {
    if (wizardStep === 1) {
      return t({ id: 'cancel' })
    }
    return t({ id: 'previous' })
  }

  return (
    <Dialog onPressCancelButton={closeDialog}>
      <Wizard
        okButtonTitle={getOkButtonText()}
        onPressOkButton={handleOkButton}
        okButtonDisabled={advanceButtonDisabled}
        onPressCancelButton={handleCancelButton}
        cancelButtonTitle={getCancelButtonText()}
        okButtonSpining={creatingWorkspace}
        okButtonLeftIcon={wizardStep + 1 === 5 ? 'checkmark' : undefined}
        cancelButtonLeftIcon={wizardStep > 1 ? 'arrowLeft' : undefined}
        currentStep={wizardStep}
        title={t({ id: 'createAWorkspace' })}
        steps={[
          {
            title: t({ id: 'chooseTemplate' }),
            index: 1,
            component: (
              <SelectTemplate
                onSelectWorkspaceTemplate={handleSelectWorkspaceTemplate}
                selectedTemplate={workspaceTemplate}
              />
            ),
          },
          {
            title: t({ id: 'nameAndDescription' }),
            index: 2,
            component: (
              <CreateWorkspaceDetails
                name={workspaceName}
                description={workspaceDescription}
                workspaceKey={workspaceKey}
                onInputChange={handleInputChange}
                handleOkButtonDisabled={handleOkButtonDisabled}
              />
            ),
          },
          {
            title: t({ id: 'addUsers' }),
            index: 3,
            component: (
              <AddUsersToWorksapce
                workspaceUsers={workspaceUsers}
                onAddWorkspaceUser={handleAddWorkspaceUser}
                onRemoveWorkspaceUser={handleRemoveWorkspaceUser}
                onSelectRole={handleSelectUsersRole}
                selectedRole={selectedRole}
              />
            ),
          },
          {
            title: t({ id: 'summary' }),
            index: 4,
            component: (
              <WorkspaceOverview
                workspaceKey={workspaceKey}
                workspaceTemplate={workspaceTemplate}
                workspaceName={workspaceName}
                workspaceDescription={workspaceDescription}
                workspaceUsers={workspaceUsers}
              />
            ),
          },
        ]}
      />
    </Dialog>
  )
}

CreateWorkspaceWizard.displayName = 'CreateWorkspaceWizard'

export default CreateWorkspaceWizard
