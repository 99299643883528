import { Button, Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { RoutePathes } from '../../../constants/routes'

const PrivacyPolicyPreview = () => {
  const { formatMessage: t } = useIntl()
  const navigate = useNavigate()

  const handleReadPrivacyPolicy = () => {
    navigate(`/${RoutePathes.Account}/${RoutePathes.PrivacyPolicy}`)
  }

  return (
    <Layout
      backgroundColor="background_mono_primary"
      borderColor="background_mono_tertiary"
      borderStyle="solid"
      borderWidth="hairline"
      borderRadius="medium"
      vertical
      padding="40"
    >
      <Layout>
        <Text variant="inline_16_emphasis">
          {t({ id: 'readPrivacyPolicyTitle' })}
        </Text>
      </Layout>
      <Layout paddingTop="24">
        <Text variant="paragraph_12_default">
          {t({ id: 'readPrivacyPolicyDescription' })}
        </Text>
      </Layout>
      <Layout paddingTop="24">
        <Button
          title={t({ id: 'readPrivacyPolicyTitle' })}
          kind="Inverse"
          leftIcon="globe"
          onPress={handleReadPrivacyPolicy}
        />
      </Layout>
    </Layout>
  )
}

export default PrivacyPolicyPreview
