import { Spacer, Text } from '@di-nxt/components'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { StyledCircle } from '../../styles/figures'
import { User } from '../../types/user.type'

type Props = {
  user: User
}

const UserIsActiveIndicator: React.FC<Props> = ({ user }) => {
  const { formatMessage: t } = useIntl()

  if (user.enabled) {
    return (
      <ContainerStyled>
        <StyledCircle color="#008A69" radius={8} />
        <Spacer width="6" />
        <Text variant="inline_14_default">{t({ id: 'active' })}</Text>
      </ContainerStyled>
    )
  }

  return (
    <ContainerStyled>
      <StyledCircle color="#BC012E" radius={8} />
      <Spacer width="6" />
      <Text variant="inline_14_default">{t({ id: 'deactivated' })}</Text>
    </ContainerStyled>
  )
}

const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d7;
  border-radius: 5px;
  padding: 10px;
`

export default UserIsActiveIndicator