import { KEYCLOAK_EMAIL_ACTIONS } from './keycloak.type'
import { Organization, OrganizationRole } from './organization.type'
import { UpdateRedshiftStatus } from './redshift.type'
import { WorkspaceUserRole } from './workspace.type'

export enum UserRoles {
  diAdmin = 'DiAdmin',
  admin = 'Admin',
  editor = 'Editor',
  user = 'User',
}

/**
 *
 * User specification
 */
export type User = {
  /**
   *
   * Username associated with a user
   * The attribute is saved in keycloak and dynamo db
   */
  username: string

  /**
   *
   * UUID value of a user, reflects the same value in keycloak
   */
  userId: string

  /**
   *
   * First name of the user, reflects the same value in keycloak
   */
  firstName: string

  /**
   *
   * Last name of the logged in user, reflects the same value in keycloak
   */
  lastName: string

  /**
   *
   * Email of the logged in user, reflects the same value in keycloak
   */
  email: string

  /**
   *
   * Role of the user, reflects to a group in keycloak
   */
  role: OrganizationRole

  /**
   *
   * organization user sbelongs to
   */
  organization?: Organization

  /**
   *
   * organization name user belongs to
   */
  organizationName?: string

  /**
   *
   * Id of the organization user belongs to
   */
  organizationId?: string

  /**
   *
   * Flag to determine whether user is enabled or not
   */
  enabled: boolean

  /**
   *
   * Date user was created
   */
  dateCreated?: number

  /**
   *
   * Date last time user was modified
   */
  dateModified?: number

  /**
   *
   * User can also have an attribute related to workspace role
   */
  workspaceRole?: WorkspaceUserRole

  /**
   *
   * Flag that determines whether the user is diAdmin or not
   */
  diAdmin?: boolean

  /**
   *
   * Redshift logs regarding user account creation
   */
  userAccountCreated?: any

  /**
   *
   * Last seen attribute determines whether the user has logged in before
   */
  lastSeen?: number

  /**
   *
   * User activity information is also returned
   */
  activity?: UserActivity

  /*
   *
   * Attribute which determines whether the user is marked for deletion from the workspace
   */
  isMarkedForDeletion?: boolean
}

/**
 *
 * Model when a user needs to be created
 */
export type UserInput = {
  username: string
  firstName: string
  lastName: string
  email: string
  organization?: {
    organizationName: string
    organizationId: string
  }
  role: OrganizationRole | undefined
}

/**
 *
 * Model when a user needs to be modified
 */
export type ModifyUserInput = {
  /**
   *
   * User role in an organization, Admin | Editor | User
   */
  role?: OrganizationRole

  /**
   *
   * Updates keycloak as well
   */
  enabled?: boolean

  /**
   *
   * When re-assigning users role in an organization, payload must be sent for exisitng roles
   */
  detachRole?: OrganizationRole

  /**
   *
   * First name of the user (Also updates keycloak)
   */
  firstName?: string

  /**
   *
   * Last name of the user (Also updates keycloak)
   */
  lastName?: string

  /**
   *
   * Redshift executor status
   */
  userAccountCreated?: UpdateRedshiftStatus

  /**
   *
   * Keycloak supported email actions
   */
  actions?: KEYCLOAK_EMAIL_ACTIONS[]
}

export type UserActivity = {
  /**
   *
   * Sort key from the database, probably should rename it to something else
   */
  userId: string

  /**
   *
   * Partition key from dynamodb, probably should name it something else
   */
  activityType: string

  /**
   *
   * Activity data which is an object
   */
  activityData: Record<string, Activity>
}

export type Activity = {
  LastAccessed: number
}
