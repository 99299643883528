import styled from 'styled-components'

/**
 * Custom component for a layout with word-breaks in it
 */
export const WordBreakLayout = styled.div<{
  topText?: boolean
  wrapFlex?: boolean
}>`
  word-break: break-word;
  ${(props) =>
    props.wrapFlex
      ? `display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 3px;
  `
      : ''}
  ${(props) =>
    props.topText
      ? `> span {
    vertical-align: text-top;
  }`
      : ''}
`
