import React from 'react'
import { Layout, Spacer, Text } from '@di-nxt/components'

import { TableOrganizationNameProps } from './TableOrganizationName.type'
import RedshiftOrganizationError from '../RedshiftErrors/RedshiftOrganizationError'

/**
 *
 * Component that is rendered when displaying organization name in a table
 */
const TableOrganizationName: React.FC<TableOrganizationNameProps> = ({
  organization,
  user,
}) => {
  return (
    <Layout justify="space-between" horizontal width="100%" align="center">
      <Text variant="inline_14_default">{organization.organizationName}</Text>
      <Layout align="center" justify="center">
        <RedshiftOrganizationError
          organization={organization}
          user={user}
          location="table"
        />
        <Spacer width="12" />
      </Layout>
    </Layout>
  )
}

TableOrganizationName.displayName = 'TableOrganizationName'
export default TableOrganizationName
