import {
  Button,
  Checkbox,
  Layout,
  Spacer,
  Spinner,
  Text,
} from '@di-nxt/components'
import { withHeader, withSidebar } from '../../../hooks'
import { TableauWorkbookWithSelectedViews } from '../../../types/tableau.api.type'
import InformationPanel from '../../../components/InformationPanel'
import { FormattedMessage, useIntl } from 'react-intl'
import { ReactNode, useContext, useRef, useState } from 'react'
import { ApiContext } from '../../../providers/api.provider'
import InfoIconWarning from '../../../assets/icons/info_icon_orange.svg'
import InfoIconSuccess from '../../../assets/icons/info_icon_green.svg'
import ViewsDifferenceBetweenFolders from '../../../components/Tableau/ViewsDifferenceBetweenFolders'
import useTableauOrganizationData from '../../../hooks/api/useTableauOrganizationData'
import { SnackbarContext } from '../../../providers/snackbar.provider'
import {
  InformationPanelError,
  InformationPanelSuccess,
  InformationPanelWarn,
} from '../../../constants/layoutProps'
import { IconNames } from '@di-nxt/components/dist/components/icons/types'

const TableauTemplatesContainer: React.FC = () => {
  const { formatMessage: t } = useIntl()
  const { selectedOrganization } = useContext(ApiContext)

  const {
    folder: tableauFolder,
    loading,
    provisionTableau,
  } = useTableauOrganizationData(selectedOrganization?.organizationId!, {
    disabled: !selectedOrganization,
  })

  const [selectedWorkbooks, setSelectedWorkbooks] = useState<
    TableauWorkbookWithSelectedViews[]
  >([])

  const [includeExtract, setIncludeExtract] = useState(true)

  const { handleSetContent, handleSetSnackbarType } =
    useContext(SnackbarContext)

  const infoRef = useRef<HTMLDivElement>(null)

  if (loading) {
    return (
      <Layout flex centered>
        <Spinner size="Large" />
      </Layout>
    )
  }

  const cloneWorkbooks = async () => {
    const workbooks = selectedWorkbooks
      .filter((workbook) => workbook.views.some((view) => view.selected))
      .map((workbook) => ({
        id: workbook.id,
        includeExtract,
        visibleViewNames: workbook.views
          .filter((view) => view.selected)
          .map((view) => view.name),
      }))

    await provisionTableau(workbooks)
      .then(() => {
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'valuesSaved' }))
      })
      .catch(() => {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'valuesSavedError' }))
      })
      .finally(() => infoRef.current?.scrollIntoView({ behavior: 'smooth' }))
  }

  let infoPanelProps
  if (!tableauFolder) {
    infoPanelProps = { ...InformationPanelWarn, img: InfoIconWarning }
  } else if (tableauFolder.provisionStatus === 'Error') {
    infoPanelProps = {
      ...InformationPanelError,
      icon: 'closeCircle' as IconNames,
    }
  } else if (tableauFolder.provisionStatus === 'Pending') {
    infoPanelProps = { ...InformationPanelWarn, img: InfoIconWarning }
  } else {
    infoPanelProps = { ...InformationPanelSuccess, img: InfoIconSuccess }
  }

  let infoPanelMessage: string
  if (!tableauFolder) {
    infoPanelMessage = 'tableauProvisionForOrganizationByDiAdmin'
  } else if (tableauFolder.provisionStatus === 'Error') {
    infoPanelMessage = 'tableauProvisionForOrganizationError'
  } else if (tableauFolder.provisionStatus === 'Pending') {
    infoPanelMessage = 'tableauProvisionForOrganizationPending'
  } else {
    infoPanelMessage = 'tableauProvisionForOrganizationSuccess'
  }

  return (
    <Layout flex vertical>
      <div ref={infoRef}></div>
      <InformationPanel
        panelId="tableau_provision_for_organization"
        message={() => (
          <FormattedMessage
            id={infoPanelMessage}
            values={{
              orgName: selectedOrganization?.organizationName,
              tableauSite: tableauFolder?.siteContentUrl,
              tableauProject: tableauFolder?.projectName,
              'bold-text': (...chunks: ReactNode[]) => <b>{chunks}</b>,
              br: <br />,
            }}
          />
        )}
        {...infoPanelProps}
        padding="12"
        marginBottom="24"
        disableClose={true}
      />

      <Text variant="paragraph_16_default">
        <FormattedMessage
          id="tableauChooseViewsFromMasterSite"
          values={{
            orgName: selectedOrganization?.organizationName,
            'bold-text': (...chunks: ReactNode[]) => <b>{chunks}</b>,
          }}
        />
      </Text>
      <Spacer height="24" />

      <ViewsDifferenceBetweenFolders
        sourceFolder={{
          siteContentUrl: process.env.REACT_APP_TABLEAU_MASTER_SITE!,
          projectName: 'Default',
        }}
        targetFolder={tableauFolder}
        handleSelection={(workbooks) => setSelectedWorkbooks(workbooks)}
      />
      <Spacer height="48" />

      <Layout flex align="center">
        <Button
          title={t({
            id: !tableauFolder
              ? 'tableauProvisionForOrganizationButton'
              : 'tableauUpdateForOrganizationButton',
          })}
          {...(tableauFolder?.provisionStatus === 'Pending' && {
            state: 'Disabled',
          })}
          onPress={() => cloneWorkbooks()}
        />
        <Spacer width="20" />
        <Checkbox
          checked={includeExtract}
          label={
            <Text variant="paragraph_16_emphasis">
              {t({ id: 'tableauIncludeExtract' })}
            </Text>
          }
          disabled={tableauFolder?.provisionStatus === 'Pending'}
          onChange={(checked) => setIncludeExtract(checked)}
        />
      </Layout>
    </Layout>
  )
}

TableauTemplatesContainer.displayName = 'TableauTemplatesContainer'

export default withSidebar(withHeader(TableauTemplatesContainer))
