import React from 'react'
import { Input, Layout, Spacer, Text } from '@di-nxt/components'

import { SelectorContentProps } from './index.type'

/**
 *
 * Content rendered for the popup of workspace/org selector
 */
const SelectorContent: React.FC<SelectorContentProps> = ({
  title,
  inputPlaceholder,
  inputValue,
  onSearch,
}) => {
  return (
    <Layout padding="12" vertical>
      <Text align="center" variant="inline_12_default">
        <span style={{ textTransform: 'uppercase' }}>{title}</span>
      </Text>
      <Spacer height="16" />
      <Layout horizontal flex>
        <Input
          placeholder={inputPlaceholder}
          rightIcon="search"
          value={inputValue ?? ''}
          onChange={onSearch}
          flex={1}
        />
      </Layout>
    </Layout>
  )
}

SelectorContent.displayName = 'SelectorContent'

export default SelectorContent
