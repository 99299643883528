import { useEffect, useMemo } from 'react'
import useTableauFetch from '../../../hooks/api/useTableauFetch'
import { TableauPathParams } from '../../../hooks/api/useTableauRestClient'
import {
  TableauFolder,
  TableauView,
  TableauWorkbook,
  TableauWorkbookWithSelectedViews,
  TableauWorkbookWithViews,
} from '../../../types/tableau.api.type'
import {
  getSelectedWorkbooks,
  groupWorkbooksWithViews,
} from '../../../utils/tableau'
import { Layout, Spinner } from '@di-nxt/components'
import InformationPanel from '../../InformationPanel'
import { useIntl } from 'react-intl'
import ViewsSelection from './ViewsSelection'
import { InformationPanelError } from '../../../constants/layoutProps'

const ViewsDifferenceBetweenFolders: React.FC<{
  sourceFolder: TableauFolder
  targetFolder: TableauFolder | null
  handleSelection?: (workbooks: TableauWorkbookWithSelectedViews[]) => void
}> = ({ sourceFolder, targetFolder, handleSelection }) => {
  const { formatMessage: t } = useIntl()

  const {
    data: sourceWorkbooksData,
    loading: sourceWorkbooksLoading,
    errorStatus: sourceWorkbooksErrorStatus,
  } = useTableauFetch<{ workbooks: { workbook: TableauWorkbook[] } }>(
    sourceFolder.siteContentUrl,
    `sites/${TableauPathParams.siteId}/workbooks?filter=projectName:eq:${sourceFolder.projectName}`,
    undefined,
    {
      queryOnlyOnce: true,
    },
  )

  const {
    data: sourceViewsData,
    loading: sourceViewsLoading,
    errorStatus: sourceViewsErrorStatus,
  } = useTableauFetch<{ views: { view: TableauView[] } }>(
    sourceFolder.siteContentUrl,
    `sites/${TableauPathParams.siteId}/views?filter=projectName:eq:${sourceFolder.projectName}`,
    undefined,
    {
      queryOnlyOnce: true,
    },
  )

  const {
    data: targetWorkbooksData,
    loading: targetWorkbooksLoading,
    errorStatus: targetWorkbooksErrorStatus,
  } = useTableauFetch<{ workbooks: { workbook: TableauWorkbook[] } }>(
    targetFolder?.siteContentUrl!,
    `sites/${
      TableauPathParams.siteId
    }/workbooks?filter=projectName:eq:${targetFolder?.projectName!}`,
    undefined,
    {
      disabled: !targetFolder,
      queryOnlyOnce: true,
    },
  )

  const {
    data: targetViewsData,
    loading: targetViewsLoading,
    errorStatus: targetViewsErrorStatus,
  } = useTableauFetch<{ views: { view: TableauView[] } }>(
    targetFolder?.siteContentUrl!,
    `sites/${
      TableauPathParams.siteId
    }/views?filter=projectName:eq:${targetFolder?.projectName!}`,
    undefined,
    {
      disabled: !targetFolder,
      queryOnlyOnce: true,
    },
  )

  const sourceWorkbooks: TableauWorkbookWithViews[] = useMemo(
    () =>
      sourceWorkbooksData && sourceViewsData
        ? groupWorkbooksWithViews(
            sourceWorkbooksData.workbooks.workbook ?? [],
            sourceViewsData.views.view ?? [],
          )
        : [],
    [sourceWorkbooksData, sourceViewsData],
  )

  const targetWorkbooks: TableauWorkbookWithViews[] = useMemo(
    () =>
      targetWorkbooksData && targetViewsData
        ? groupWorkbooksWithViews(
            targetWorkbooksData.workbooks.workbook ?? [],
            targetViewsData.views.view ?? [],
          )
        : [],
    [targetWorkbooksData, targetViewsData],
  )

  const workbooks: TableauWorkbookWithSelectedViews[] = useMemo(
    () => getSelectedWorkbooks(sourceWorkbooks, targetWorkbooks),
    [sourceWorkbooks, targetWorkbooks],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSelection?.(workbooks), [workbooks])

  if (
    sourceWorkbooksLoading ||
    sourceViewsLoading ||
    (targetFolder && (targetWorkbooksLoading || targetViewsLoading))
  ) {
    return (
      <Layout flex centered>
        <Spinner size="Large" />
      </Layout>
    )
  }

  if (
    sourceWorkbooksErrorStatus ||
    sourceViewsErrorStatus ||
    targetWorkbooksErrorStatus ||
    targetViewsErrorStatus
  ) {
    return (
      <InformationPanel
        panelId="tableau_master_site_loading_error"
        message={() => t({ id: 'tableauDataLoadingError' })}
        {...InformationPanelError}
        icon="closeCircle"
        padding="12"
        disableClose={true}
      />
    )
  }

  return (
    <ViewsSelection
      siteContentUrl={sourceFolder.siteContentUrl}
      workbooks={workbooks}
      handleSelection={handleSelection}
    />
  )
}

ViewsDifferenceBetweenFolders.displayName =
  'TableauViewsDifferenceBetweenFolders'

export default ViewsDifferenceBetweenFolders
