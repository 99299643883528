import React, { useRef } from 'react'
import { Layout, Popup, Text, Icon, Spinner } from '@di-nxt/components'

import DropdownItem from './DropdownItem'

import { DropdownProps } from './index.type'

import { CustomSelect } from './style'

/**
 *
 * A custom dropdown component
 */
const Dropdown: React.FC<DropdownProps> = ({
  selected,
  items,
  onSelect: handleSelect,
  label,
  paddingTop,
  spinning,
  width,
  textVariant,
}) => {
  /**
   *
   * Create a reference for the popup
   */
  const popupRef = useRef<HTMLDivElement>(null)

  return (
    <Layout vertical width="100%">
      {label && <Text variant="paragraph_12_default">{label}</Text>}
      <Popup
        trigger={
          <Layout paddingTop={paddingTop ? '12' : '1'} width="100%">
            <CustomSelect ref={popupRef} style={{ width: width ?? 'auto' }}>
              <Layout paddingLeft="12" paddingRight="12">
                <Text variant={textVariant ?? 'paragraph_12_default'}>
                  {selected}
                </Text>
              </Layout>
              <Layout paddingRight="12">
                {spinning ? (
                  <Spinner size="Small" />
                ) : (
                  <Icon name="arrowDownSmall" />
                )}
              </Layout>
            </CustomSelect>
          </Layout>
        }
      >
        {() => {
          return (
            <Layout
              width="100%"
              backgroundColor="background_mono_primary"
              borderRadius="small"
              borderWidth="hairline"
              borderStyle="solid"
              borderColor="border_mono_30"
              vertical
              minWidth="250px"
              maxWidth="280px"
            >
              {items.map((role) => (
                <DropdownItem
                  parentRef={popupRef}
                  item={role}
                  key={role.key}
                  selected={selected === role.key}
                  onSelect={handleSelect}
                />
              ))}
            </Layout>
          )
        }}
      </Popup>
    </Layout>
  )
}

Dropdown.displayName = 'Dropdown'
export default Dropdown
