import styled, { CSSProperties } from 'styled-components'

export const MainVisalizationContainerStyled = styled.div`
  background-color: #fff;
  border: 1px solid rgba(18, 18, 33, 0.1);
  border-radius: 8px;
  padding: 30px 15px;
`

export const StyledTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: '#F1F3FD';
  &::after {
    content: '';
    top: 100%;
    right: 10%;
    position: absolute;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px solid #f0f0fd;
  }
`

export const TierUpgradeDateStyled = styled.div`
  color: rgba(127, 135, 175, 1);
  font-size: 14px;
`

export const TierUpgradeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const tooltipWrapperStyles: CSSProperties = {
  visibility: 'visible',
  padding: '5px 15px',
  backgroundColor: '#F0f0fd',
  outline: 'none',
  left: '-153px',
  top: '-70px',
  borderRadius: '4px',
}
