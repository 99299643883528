import React, { useContext, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Layout, Spacer, Text, Button, Dropdown } from '@di-nxt/components'
// Internal Components
import LeaveWorkspaceConfirm from '../../../../components/Dialogs/LeaveWorkspaceConfirm'
// Providers
import { AppStateContext } from '../../../../providers/app.state.provider'
import { ApiContext } from '../../../../providers/api.provider'
// utils
import { CardProps } from '../../../../utils/commonProps'
// Types
import {
  WorkspaceUserInput,
  WorkspaceUserRole,
} from '../../../../types/workspace.type'
import { LeaveWorkspaceUserInput } from '../../../../types/workspace.users.type'

// Images and assets
import InformationIcon from '../../../../assets/icons/info_icon.svg'
import InformationPanel from '../../../../components/InformationPanel'

/**
 *
 * Card that delivers option to leave workspace for a user
 */
const LeaveWorkspace: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // state management for selecting new owner of the workspace
  const [newOwner, setNewOwner] = useState<WorkspaceUserInput | undefined>(
    undefined,
  )

  // Set the new owner index
  const [newOwnerIndex, setNewOwnerIndex] = useState<number | undefined>(
    undefined,
  )

  // Show error message when new owner is require dbut not selected
  const [showError, setShowError] = useState<boolean>(false)

  // Flag to determine whether a new owner is required before leaving the workspace
  const [newOwnerRequired, setNewOwnerRequired] = useState<boolean>(false)

  // Eligble new owners
  const [eligibleNewOwners, setEligibleNewOwners] = useState<
    WorkspaceUserInput[]
  >([])

  // App state context to manage dialog state
  const { manageDialog, openDialogs } = useContext(AppStateContext)

  // Api context to leave a workspace
  const { workspaceUsersService, workspaceService, user } =
    useContext(ApiContext)
  const { workspace, userWorkspaceRole } = workspaceService
  const { leaveWorkspace, leavingWorkspace } = workspaceUsersService

  /**
   *
   * Close the confirmation dialog
   */
  const handleCloseDialog = () => manageDialog({ leaveWorkspaceConfirm: false })

  /**
   *
   * Open the leave workspace confirmation dialog
   */
  const handleOpenDialog = () => {
    if (newOwnerRequired && !newOwner) {
      setShowError(true)
    } else {
      manageDialog({ leaveWorkspaceConfirm: true })
    }
  }

  /**
   *
   * Select a new owner
   */
  const handleSelectNewOwner = (userIndex: number | undefined) => {
    setNewOwnerIndex(userIndex)
    setNewOwner({
      ...eligibleNewOwners[userIndex ?? 0],
      workspaceRole: WorkspaceUserRole.owner,
      workspaceOldRole: eligibleNewOwners[userIndex ?? 0].workspaceRole,
    })
    setShowError(false)
  }

  /**
   *
   * Determine whether a new owner is required before leaving the workspace
   */
  useEffect(() => {
    if (!newOwner && workspace) {
      const owners = workspace.workspaceUsers.filter(
        (workspaceUser) =>
          workspaceUser.workspaceRole === WorkspaceUserRole.owner,
      )

      if (owners.length > 1) {
        setNewOwnerRequired(false)
      } else if (userWorkspaceRole === WorkspaceUserRole.owner) {
        setNewOwnerRequired(true)
      }
    }
  }, [workspace, newOwner, userWorkspaceRole])

  /**
   *
   * Determine which users can be elected as new owners
   */
  useEffect(() => {
    if (newOwnerRequired && workspace) {
      const eligbleOwners = workspace.workspaceUsers.filter(
        (workspaceUser) =>
          workspaceUser.workspaceRole !== WorkspaceUserRole.owner,
      )
      setEligibleNewOwners(eligbleOwners)
    }
  }, [workspace, newOwnerRequired])

  /**
   *
   * In case user is the sole owner, user must assign a new owner before leaving
   */
  const renderAssignNewOwner = () => {
    if (workspace) {
      if (!newOwnerRequired) {
        return null
      }

      return (
        <Layout vertical>
          <Layout horizontal paddingBottom="12">
            <Layout>
              <img src={InformationIcon} alt="Information" />
            </Layout>
            <Spacer width="10" />
            <Text variant="paragraph_14_default">
              <FormattedMessage id="chooseNewOwner" />
            </Text>
          </Layout>
          <Dropdown
            items={eligibleNewOwners.map(
              (selectableUser) => selectableUser.username,
            )}
            placeholder={t({ id: 'selectFromThisWorkspace' })}
            selectedIndex={newOwnerIndex}
            onSelectedIndexChange={handleSelectNewOwner}
          />
          {showError && (
            <>
              <Spacer height="24" />
              <InformationPanel
                panelId="worksapce_leave"
                message={() => t({ id: 'errorChooseNewOwner' })}
                borderColor="border_negative_default"
                textColor="content_negative_default"
                icon="warning"
              />
            </>
          )}
          <Spacer height="24" />
        </Layout>
      )
    }
    return null
  }

  /**
   *
   * Send the request to leave the workspace
   */
  const handleLeaveWorkspace = () => {
    if (workspace && workspace.workspaceId && user) {
      const currentWorkspaceUser = workspace.workspaceUsers.find(
        (workspaceUser) => workspaceUser.userId === user.userId,
      )

      let payload: LeaveWorkspaceUserInput = {
        organizationKey: user.organization?.organizationKey ?? '',
        workspaceKey: workspace.workspaceKey ?? '',
        email: currentWorkspaceUser?.email ?? '',
        workspaceRole: currentWorkspaceUser?.workspaceRole ?? '',
        workspaceUsers: workspace?.workspaceUsers.filter(
          (workspaceUser) => workspaceUser.userId !== user.userId,
        ),
        ...(newOwnerRequired && {
          promoteUser: newOwner,
        }),
      }

      // Also update the workspace users in the payload
      if (newOwner && payload.workspaceUsers) {
        payload.workspaceUsers = payload.workspaceUsers.map((workspaceUser) => {
          if (workspaceUser.userId === newOwner?.userId) {
            return {
              ...workspaceUser,
              workspaceRole: WorkspaceUserRole.owner,
            }
          }
          return workspaceUser
        })
      }

      manageDialog({ leaveWorkspaceConfirm: false })
      leaveWorkspace(workspace.workspaceId, user.userId, payload)
    }
  }

  return (
    <Layout vertical>
      <Layout {...CardProps}>
        <Layout
          paddingTop="32"
          paddingBottom="32"
          paddingLeft="40"
          paddingRight="40"
          vertical
        >
          <Text variant="inline_20_emphasis">
            <FormattedMessage id="leaveWorkspace" />
          </Text>
          <Spacer height="24" />
          <Text variant="paragraph_12_default">
            <FormattedMessage id="leaveWorkspaceDescription" />
          </Text>
          <Spacer height="24" />
          {renderAssignNewOwner()}
          <Layout>
            <Button
              leftIcon="remove"
              title={t({ id: 'leaveWorkspace' })}
              kind="Inverse"
              onPress={handleOpenDialog}
              state={leavingWorkspace ? 'Disabled' : 'Default'}
              spinning={leavingWorkspace}
            />
          </Layout>
        </Layout>
      </Layout>
      <Spacer height="24" />
      {openDialogs.leaveWorkspaceConfirm && (
        <LeaveWorkspaceConfirm
          onCancel={handleCloseDialog}
          onOk={handleLeaveWorkspace}
          canLeaveWorkspace
        />
      )}
    </Layout>
  )
}

LeaveWorkspace.displayName = 'LeaveWorkspace'
export default LeaveWorkspace
