import { Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { WordBreakLayout } from '../../../../../styles/wordBreakLayout'
import { TableDescriptionProps } from './TableDescription.type'

/**
 * Component showing the description of a selected table
 *
 */
const TableDescription: React.FC<TableDescriptionProps> = ({ description }) => {
  const { formatMessage: t } = useIntl()

  return (
    <Layout paddingTop="28" width="100%">
      <Layout width="15%">
        <WordBreakLayout topText>
          <Text variant="inline_14_default">
            {t({ id: 'tableDescriptionColumn' })}:
          </Text>
        </WordBreakLayout>
      </Layout>
      <Layout width="1%" />
      <Layout
        backgroundColor="background_mono_secondary"
        borderRadius="medium"
        width="84%"
      >
        <Layout padding="16">
          <Text variant="paragraph_12_default">{description}</Text>
        </Layout>
      </Layout>
    </Layout>
  )
}

TableDescription.displayName = 'TableDescription'

export default TableDescription
