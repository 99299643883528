import styled from 'styled-components'
import { Layout } from '../../styles'
import { DayColors } from '../../styles/colors'

/**
 * When popover is triggered by a hyperlink
 */
export const StyledHyperlink = styled.a`
  font-size: inherit;
  font-family: inherit;
  text-decoration: underline;
  text-transform: lowercase;
`

/**
 * Popover content
 */
export const StyledPopupContent = styled.div`
  display: flex;
  width: auto;
  max-width: 500px;
  height: auto;
  background-color: ${DayColors.background_accent_light};
  border: 1px solid #7474fa;
  border-radius: ${Layout.borderRadius};
  padding: ${Layout.padding};
  flex-direction: row;
`

/**
 * Icon
 */
export const StyledPopupIcon = styled.div`
  width: 24px;
  margin-right: ${Layout.margin};
`

export const WrapSpan = styled.div`
  & > div {
    display: inline;
  }
  display: inline;
`
