import React from 'react'
import { Layout, Text } from '@di-nxt/components'

import { TableUserEmailProps } from './TableUserEmail.type'
import RedshiftUserError from '../RedshiftErrors/RedshiftUserError'

/**
 *
 * Component that is rendered when displaying user name in a table
 * The name is interactive as clicking it opens up the specific user
 */
const TableUserEmail: React.FC<TableUserEmailProps> = ({ user }) => {
  return (
    <Layout
      justify="space-between"
      horizontal
      width="100%"
      align="center"
      overflow="hidden"
    >
      <div className="text-overflow">
        <Text variant="inline_14_default">{user.email}</Text>
      </div>
      <Layout align="center" justify="center">
        <RedshiftUserError user={user} location="table" />
      </Layout>
    </Layout>
  )
}

TableUserEmail.displayName = 'TableUserEmail'
export default TableUserEmail
