import {
  Button,
  Checkbox,
  Layout,
  Spacer,
  Spinner,
  Text,
} from '@di-nxt/components'
import { TableauWorkbookWithSelectedViews } from '../../../types/tableau.api.type'
import InformationPanel from '../../../components/InformationPanel'
import { FormattedMessage, useIntl } from 'react-intl'
import { ReactNode, useContext, useRef, useState } from 'react'
import { ApiContext } from '../../../providers/api.provider'
import InfoIconOrange from '../../../assets/icons/info_icon_orange.svg'
import InfoIconGreen from '../../../assets/icons/info_icon_green.svg'
import { WorkspaceUserRole } from '../../../types/workspace.type'
import ViewsDifferenceBetweenFolders from '../../../components/Tableau/ViewsDifferenceBetweenFolders'
import useTableauOrganizationData from '../../../hooks/api/useTableauOrganizationData'
import useTableauWorkspaceData from '../../../hooks/api/useTableauWorkspaceData'
import { SnackbarContext } from '../../../providers/snackbar.provider'
import { UserRoles } from '../../../types/user.type'
import {
  InformationPanelError,
  InformationPanelSuccess,
  InformationPanelWarn,
} from '../../../constants/layoutProps'
import { IconNames } from '@di-nxt/components/dist/components/icons/types'

const WorkspaceTableauTemplatesContainer: React.FC = () => {
  const { formatMessage: t } = useIntl()

  const { user, workspaceService, selectedOrganization } =
    useContext(ApiContext)
  const { workspace, userWorkspaceRole } = workspaceService

  const isUserOwnerOfWorkspace = userWorkspaceRole === WorkspaceUserRole.owner
  const isDiAdmin = !!user?.diAdmin
  const isOrganizationAdmin = user?.role.name === UserRoles.admin

  const { folder: orgTableauFolder, loading: orgTableauFolderLoading } =
    useTableauOrganizationData(workspace?.organizationId!, {
      disabled:
        !workspace ||
        (!isDiAdmin && !isOrganizationAdmin && !isUserOwnerOfWorkspace),
    })

  const {
    folder: workspaceTableauFolder,
    loading: workspaceTableauFolderLoading,
    provisionTableau,
  } = useTableauWorkspaceData(
    workspace?.organizationId!,
    workspace?.workspaceId!,
    {
      disabled: !workspace,
    },
  )

  const [selectedWorkbooks, setSelectedWorkbooks] = useState<
    TableauWorkbookWithSelectedViews[]
  >([])

  const [includeExtract, setIncludeExtract] = useState(true)

  const { handleSetContent, handleSetSnackbarType } =
    useContext(SnackbarContext)

  const infoRef = useRef<HTMLDivElement>(null)

  if (orgTableauFolderLoading || workspaceTableauFolderLoading) {
    return (
      <Layout flex centered>
        <Spinner size="Large" />
      </Layout>
    )
  }

  const cloneWorkbooks = async () => {
    const workbooks = selectedWorkbooks
      .filter((workbook) => workbook.views.some((view) => view.selected))
      .map((workbook) => ({
        id: workbook.id,
        includeExtract,
        visibleViewNames: workbook.views
          .filter((view) => view.selected)
          .map((view) => view.name),
      }))

    await provisionTableau(workbooks)
      .then(() => {
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'valuesSaved' }))
      })
      .catch(() => {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'valuesSavedError' }))
      })
      .finally(() => infoRef.current?.scrollIntoView({ behavior: 'smooth' }))
  }

  const getInfoPanelProps = () => {
    if (workspaceTableauFolder?.provisionStatus === 'Provisioned') {
      return { ...InformationPanelSuccess, img: InfoIconGreen }
    }
    if (isUserOwnerOfWorkspace || isOrganizationAdmin || isDiAdmin) {
      if (workspaceTableauFolder?.provisionStatus === 'Pending') {
        return { ...InformationPanelWarn, img: InfoIconOrange }
      }
      if (workspaceTableauFolder?.provisionStatus === 'Error') {
        return {
          ...InformationPanelError,
          icon: 'closeCircle' as IconNames,
        }
      }
    }
    return { ...InformationPanelWarn, img: InfoIconOrange }
  }

  const getInfoPanelMessage = () => {
    if (workspaceTableauFolder?.provisionStatus === 'Provisioned') {
      return 'tableauProvisionForWorkspaceSuccess'
    }
    if (isUserOwnerOfWorkspace || isOrganizationAdmin || isDiAdmin) {
      if (workspaceTableauFolder?.provisionStatus === 'Pending') {
        return 'tableauProvisionForWorkspacePending'
      }
      if (workspaceTableauFolder?.provisionStatus === 'Error') {
        return 'tableauProvisionForWorkspaceError'
      }
    }
    if (isDiAdmin) {
      return orgTableauFolder?.provisionStatus !== 'Provisioned'
        ? 'tableauProvisionForOrganizationByDiAdmin'
        : 'tableauProvisionForWorkspaceByDiAdmin'
    }
    if (isUserOwnerOfWorkspace) {
      return orgTableauFolder?.provisionStatus !== 'Provisioned'
        ? 'tableauAskDiAdminToProvisionForOrganization'
        : 'tableauProvisionForWorkspaceByOwner'
    }
    if (isOrganizationAdmin) {
      return orgTableauFolder?.provisionStatus !== 'Provisioned'
        ? 'tableauAskDiAdminToProvisionForOrganization'
        : 'tableauProvisionForWorkspaceByOrganizationAdmin'
    }
    return 'tableauAskOwnerToProvisionForWorkspace'
  }

  return (
    <Layout flex vertical>
      <div ref={infoRef}></div>
      <InformationPanel
        panelId="tableau_provision_for_organization"
        message={() => (
          <FormattedMessage
            id={getInfoPanelMessage()}
            values={{
              orgName: selectedOrganization?.organizationName,
              tableauSite: workspaceTableauFolder?.siteContentUrl,
              tableauProject: workspaceTableauFolder?.projectName,
              'bold-text': (...chunks: ReactNode[]) => <b>{chunks}</b>,
              br: <br />,
            }}
          />
        )}
        {...getInfoPanelProps()}
        padding="12"
        marginBottom="24"
        disableClose={true}
      />

      {orgTableauFolder?.provisionStatus === 'Provisioned' &&
        (isUserOwnerOfWorkspace || isOrganizationAdmin || isDiAdmin) && (
          <>
            <Text variant="paragraph_16_default">
              {t({ id: 'tableauChooseViewsForWorkspace' })}
            </Text>
            <Spacer height="24" />

            <ViewsDifferenceBetweenFolders
              sourceFolder={orgTableauFolder}
              targetFolder={workspaceTableauFolder}
              handleSelection={(workbooks) => setSelectedWorkbooks(workbooks)}
            />
            <Spacer height="48" />

            <Layout flex align="center">
              <Button
                title={t({
                  id: !workspaceTableauFolder
                    ? 'tableauProvisionForWorkspaceButton'
                    : 'tableauUpdateForWorkspaceButton',
                })}
                {...(workspaceTableauFolder?.provisionStatus === 'Pending' && {
                  state: 'Disabled',
                })}
                onPress={() => cloneWorkbooks()}
              />
              <Spacer width="20" />
              <Checkbox
                checked={includeExtract}
                label={
                  <Text variant="paragraph_16_emphasis">
                    {t({ id: 'tableauIncludeExtract' })}
                  </Text>
                }
                disabled={workspaceTableauFolder?.provisionStatus === 'Pending'}
                onChange={(checked) => setIncludeExtract(checked)}
              />
            </Layout>
          </>
        )}
      {workspaceTableauFolder?.provisionStatus === 'Provisioned' &&
        !isUserOwnerOfWorkspace &&
        !isOrganizationAdmin &&
        !isDiAdmin && (
          <ViewsDifferenceBetweenFolders
            sourceFolder={workspaceTableauFolder}
            targetFolder={null}
          />
        )}
    </Layout>
  )
}

WorkspaceTableauTemplatesContainer.displayName =
  'WorkspaceTableauTemplatesContainer'

export default WorkspaceTableauTemplatesContainer
