import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Layout, Spacer, Text, Button } from '@di-nxt/components'

import DeleteWorkspaceConfirm from '../../../../components/Dialogs/DeleteWorkspaceConfirm'

import { AppStateContext } from '../../../../providers/app.state.provider'

import { CardProps } from '../../../../utils/commonProps'
import { ApiContext } from '../../../../providers/api.provider'

/**
 *
 * Card that delivers option to leave workspace for a user
 */
const DeleteWorkspace: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // App state context to manage dialog states
  const { manageDialog, openDialogs } = useContext(AppStateContext)

  // Api context to process delete workspace request
  const { workspaceService } = useContext(ApiContext)
  const { deleteWorkspace, deletingWorkspace, workspace } = workspaceService

  /**
   *
   * Close the delete workspace confirmation dialog
   */
  const handleCloseDialog = () =>
    manageDialog({ deleteWorkspaceConfirm: false })

  /**
   *
   * Open delete confirmation dialog
   */
  const handleOpenConfirmDialog = () =>
    manageDialog({ deleteWorkspaceConfirm: true })

  /**
   *
   * On confirm delete workspace
   */
  const handleOk = () => {
    if (workspace?.workspaceId) {
      manageDialog({ deleteWorkspaceConfirm: false })
      deleteWorkspace(workspace.workspaceId)
    }
  }

  return (
    <Layout vertical>
      <Layout {...CardProps}>
        <Layout
          paddingTop="32"
          paddingBottom="32"
          paddingLeft="40"
          paddingRight="40"
          vertical
        >
          <Text variant="inline_20_emphasis">
            <FormattedMessage id="deleteWorkspace" />
          </Text>
          <Spacer height="24" />
          <Text variant="paragraph_12_default">
            <FormattedMessage id="deleteWorkspaceDescription" />
          </Text>
          <Spacer height="24" />
          <Layout>
            <Button
              leftIcon="remove"
              title={t({ id: 'deleteWorkspace' })}
              kind="Inverse"
              onPress={handleOpenConfirmDialog}
              state={workspace?.workspaceId ? 'Default' : 'Disabled'}
              spinning={deletingWorkspace}
            />
          </Layout>
        </Layout>
      </Layout>
      <Spacer height="24" />
      {openDialogs.deleteWorkspaceConfirm && (
        <DeleteWorkspaceConfirm onCancel={handleCloseDialog} onOk={handleOk} />
      )}
    </Layout>
  )
}

DeleteWorkspace.displayName = 'DeleteWorkspace'
export default DeleteWorkspace
