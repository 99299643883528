import React, { useEffect, useState, useContext } from 'react'
import { Text } from '@di-nxt/components'
// Hooks
import { useDropdown } from '../../hooks/definitions/useDropdown'
// Providers
import { ApiContext } from '../../providers/api.provider'
// Types
import { TableWorkspaceRoleSelectorProps } from './TableWorkspaceRoleSelector.type'
import { WorkspaceUserRole } from '../../types/workspace.type'
import { UpdateWorkspaceUserInput } from '../../types/workspace.users.type'
import { DropdownItem } from '../../types/dropdown.type'
import TableCellDropdown from '../TableCellDropdown'

/**
 *
 * Allows the user to change the role of themselves or another user
 * - Provided user changing the role has the right privilages
 */
const TableWorkspaceRoleSelector: React.FC<TableWorkspaceRoleSelectorProps> = ({
  user,
  editable,
}) => {
  // Api context and attach workspace service
  const {
    workspaceUsersService,
    workspaceService,
    user: orgUser,
  } = useContext(ApiContext)
  const { updateWorkspaceUser } = workspaceUsersService
  const { workspace } = workspaceService
  // Local state management to manage user role changes
  const [userRole, setUserRole] = useState<WorkspaceUserRole>(
    WorkspaceUserRole.viewer,
  )

  // Get the roles
  const { workspaceRoles } = useDropdown()

  /**
   *
   * Run the use effect watcher to update the role in local state
   */
  useEffect(() => {
    if (user && user.workspaceRole) {
      setUserRole(user.workspaceRole)
    }
  }, [user])

  /**
   *
   * Change the role of the user
   */
  const handleChangeRole = (newRole: DropdownItem) => {
    if (workspace) {
      setUserRole(newRole.key as WorkspaceUserRole)
      // Contruct the payload for the request
      const payload: UpdateWorkspaceUserInput = {
        workspaceRole: newRole.key as WorkspaceUserRole,
        workspaceOldRole: user.workspaceRole,
        organizationKey: orgUser?.organization?.organizationKey,
        workspaceKey: workspace.workspaceKey,
        workspaceUsers: workspace.workspaceUsers.map((workspaceUser) => {
          if (workspaceUser.userId === user.userId) {
            return {
              ...workspaceUser,
              workspaceRole: newRole.key as WorkspaceUserRole,
            }
          }
          return workspaceUser
        }),
      }
      // Run the update workspace user query
      updateWorkspaceUser(workspace.workspaceId, payload, user.userId)
    }
  }

  if (
    editable &&
    (user.workspaceRole !== WorkspaceUserRole.owner ||
      workspaceUsersService.workspaceUsers?.some(
        (workspaceUser) =>
          workspaceUser.workspaceRole === WorkspaceUserRole.owner &&
          workspaceUser.userId !== user.userId,
      ))
  ) {
    return (
      <TableCellDropdown
        selected={userRole}
        onSelect={handleChangeRole}
        items={workspaceRoles}
        maxWidth="280px"
        showCheckmark={true}
      />
    )
  }
  return <Text variant="inline_14_default">{userRole}</Text>
}

TableWorkspaceRoleSelector.displayName = 'TableWorkspaceRoleSelector'

export default TableWorkspaceRoleSelector
