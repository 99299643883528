import React, { useEffect } from 'react'
import { Layout, Button, Spacer } from '@di-nxt/components'

import { DialogProps } from './index.type'
import { Backdrop } from './style'

/**
 *
 * Since DI Components Dialog doesnt provide much room to customize
 * A custom dialog had to be created
 */
const Dialog: React.FC<DialogProps> = ({
  children,
  okButtonTitle,
  cancelButtonTitle,
  onPressCancelButton,
  onPressOkButton,
}) => {
  const closeDialog = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target !== e.currentTarget) {
      return
    }
    if (onPressCancelButton) {
      onPressCancelButton()
    }
  }

  useEffect(() => {
    const close = (e: Event) => {
      if (
        (e as unknown as React.KeyboardEvent<HTMLDivElement>).key ===
          'Escape' &&
        onPressCancelButton
      ) {
        onPressCancelButton()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onPressCancelButton])

  return (
    <Backdrop onClick={closeDialog}>
      <Layout
        backgroundColor="background_mono_primary"
        borderRadius="medium"
        position="relative"
        overflow="hidden"
        justify="space-between"
        vertical
      >
        <Layout paddingBottom="40">{children}</Layout>
        {okButtonTitle || cancelButtonTitle ? (
          <Layout horizontal justify="flex-end">
            {cancelButtonTitle && (
              <>
                <Button
                  title={cancelButtonTitle}
                  kind="Secondary"
                  onPress={onPressCancelButton}
                />
                <Spacer width="12" />
              </>
            )}
            {okButtonTitle && (
              <Button title={okButtonTitle} onPress={onPressOkButton} />
            )}
          </Layout>
        ) : null}
      </Layout>
    </Backdrop>
  )
}

Dialog.displayName = 'DIAnalyticalPlatformDialog'

export default Dialog
