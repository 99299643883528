import styled, { css } from 'styled-components'
import { Layout } from '../../../styles'

type Props = {
  selected?: boolean
}

export const NavigationButton = styled.button<Props>`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: none;
  width: calc(${Layout.sidebarWidth} - 32px);

  ${(props) => {
    const style = css`
      background-color: #4d4d61;
      span {
        text-decoration-color: #4d4d61;
      }
    `
    return props.selected
      ? css`
          ${style}
        `
      : css`
          &:hover {
            ${style}
          }
        `
  }}
`
