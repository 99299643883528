import { Checkbox, Icon, Layout, Spacer } from '@di-nxt/components'
import { useContext } from 'react'
import { ApiContext } from '../../../../../../providers/api.provider'
import { DayColors } from '../../../../../../styles/colors'
import { HoveredLayout } from '../../../../../../styles/hoveredLayout'
import ItemDetails from './ItemDetails'
import { ClickableLayout } from './styles/clickableLayout'
import { AlignedCheckbox, TopItemProps } from './TopItem.type'

/**
 * Component showing item in a tree view
 *
 */
const TopItem: React.FC<TopItemProps> = ({
  onClick,
  iconName,
  isSelected,
  isEditable,
  item,
  matchedPart,
  onSelected,
}) => {
  const changeSelectedTable =
    useContext(ApiContext).workspaceDataService.changeSelectedTable

  const handlePress = (tableName: string) => {
    changeSelectedTable(tableName)
  }

  const onIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onClick()
  }

  // Custom handler which only propagates clicks on the checkbox
  const onChange = (
    e: React.MouseEvent<HTMLDivElement | HTMLInputElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    if (e.target instanceof HTMLInputElement) {
      onSelected(item.tableName)
    }
  }

  return (
    <HoveredLayout
      border={`1px solid ${DayColors.border_mono_10}`}
      onClick={handlePress.bind(null, item.tableName)}
      cursor="pointer"
    >
      <Layout
        backgroundColor={isSelected ? 'background_mono_secondary' : undefined}
        borderStyle={'solid'}
        borderColor={isSelected ? 'border_mono_30' : 'transparent'}
        borderWidth={'hairline'}
        align="center"
        borderRadius="small"
        paddingVertical="6"
      >
        <ClickableLayout onClick={onIconClick}>
          <Icon name={iconName} />
        </ClickableLayout>
        {isEditable && (
          <>
            <ClickableLayout onClick={onChange}>
              <AlignedCheckbox>
                <Checkbox
                  checked={
                    item.selected === 'Indetermined'
                      ? undefined
                      : item.selected === 'Checked'
                  }
                  disabled={!isEditable}
                  indeterminate={
                    item.selected !== 'Indetermined' ? undefined : true
                  }
                />
              </AlignedCheckbox>
            </ClickableLayout>
            <Spacer width="12" />
          </>
        )}
        <ItemDetails
          isDisabled={item.selected === 'NotChecked'}
          tableName={item.tableName}
          matchedPart={matchedPart}
        />
      </Layout>
    </HoveredLayout>
  )
}

TopItem.displayName = 'TopItem'

export default TopItem
