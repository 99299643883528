import { escapeRegExp } from 'lodash'
import { useMemo } from 'react'

export const useTextMatch = (
  searchText: string,
  item: { name: string; columns: string[] },
) => {
  // Function which returns matches of search keyword onto table name
  // and column names
  const { tableNameMatches, columnsMatches } = useMemo(() => {
    if (searchText !== '') {
      const searchTextRegex = new RegExp(escapeRegExp(searchText), 'i')
      const tableNameMatches = searchTextRegex.exec(item.name)

      const columnsMatches = item.columns.map((column) =>
        searchTextRegex.exec(column),
      )
      return { tableNameMatches, columnsMatches }
    }
    return {
      tableNameMatches: null,
      columnsMatches: Array(item.columns.length).fill(null),
    }
  }, [searchText, item])

  const isMatched =
    tableNameMatches !== null ||
    columnsMatches.find((column) => column !== null)
  return { isMatched, tableNameMatches, columnsMatches }
}
