import { Layout, Spacer, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import InfoIcon from '../../../../../../assets/icons/info_icon.svg'

const DropdownInfo: React.FC = () => {
  const { formatMessage: t } = useIntl()
  return (
    <Layout
      borderRadius="small"
      backgroundColor="background_mono_secondary"
      paddingLeft="10"
      paddingTop="8"
      paddingRight="8"
      paddingBottom="10"
    >
      <img src={InfoIcon} alt="info" />
      <Spacer width="10" />
      <Text variant="paragraph_12_default">{t({ id: 'dropdownInfo' })}</Text>
    </Layout>
  )
}

DropdownInfo.displayName = 'DropdownInfo'

export default DropdownInfo
