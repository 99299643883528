import { isEqual } from 'lodash'
import { memo, useState } from 'react'
import { useTextMatch } from '../../../../../../hooks/helpers/useTextMatch'
import SubItems from './SubItems'
import TopItem from './TopItem'
import { TreeItemProps } from './TreeItem.type'

const TreeItem: React.FC<TreeItemProps> = ({
  isSelected,
  isEditable,
  item,
  searchText,
  onSelected,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const handleIsOpened = () => {
    setIsOpened((isOpened) => !isOpened)
  }

  const { columnsMatches, isMatched, tableNameMatches } = useTextMatch(
    searchText,
    {
      name: item.tableName,
      columns: item.columns.map((column) => column.columnName),
    },
  )

  return searchText === '' || isMatched ? (
    <>
      <TopItem
        iconName={isOpened || isMatched ? 'arrowDownSmall' : 'arrowRightSmall'}
        isSelected={isSelected}
        onClick={handleIsOpened}
        item={item}
        matchedPart={tableNameMatches !== null ? tableNameMatches[0] : null}
        onSelected={onSelected}
        isEditable={isEditable}
      />
      {isOpened || isMatched ? (
        <SubItems
          matchedParts={columnsMatches}
          subItems={item.columns}
          tableName={item.tableName}
          onSelected={onSelected}
          isEditable={isEditable}
        />
      ) : null}
    </>
  ) : null
}

TreeItem.displayName = 'TreeItem'

export default memo(TreeItem, (prevProps, nextProps) => {
  const result =
    prevProps.isSelected === nextProps.isSelected &&
    isEqual(prevProps.item, nextProps.item) &&
    prevProps.searchText === nextProps.searchText
  return result
})
