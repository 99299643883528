import React, { useContext, useEffect } from 'react'
import { sortBy } from 'lodash'
import { useIntl } from 'react-intl'
import { BaseSpinner, Button, Layout, Table, Text } from '@di-nxt/components'

import AddOrganizationDialog from '../../components/Dialogs/AddOrganizationDialog'
import DeleteOrganizationConfirm from '../../components/Dialogs/DeleteOrganizationConfirm'

import { useDefinitions, withHeader, withSidebar } from '../../hooks'

import { ApiContext } from '../../providers/api.provider'
import { AppStateContext } from '../../providers/app.state.provider'

import { Organization } from '../../types/organization.type'
import ModifyOrganizationDialog from '../../components/Dialogs/ModifyOrganizationDialog'
import { TextNoWrap } from './style'

/**
 *
 * DI Administrators can View and Modify other organizations
 * This component lists the organizations and the ability for the DI ADMIN TO:
 * Modify - Products and Sybase customer ids
 */
const Organizations: React.FC = () => {
  // Internationalization Hook
  const { formatMessage: t } = useIntl()
  // Context to show or hide dialog for adding user to workspace
  const { manageDialog, openDialogs } = useContext(AppStateContext)
  // Api context to attach workspace users
  const { organizationService, user } = useContext(ApiContext)
  const {
    getOrganizations,
    organizations,
    loadingOrganizations,
    deleteOrganization,
  } = organizationService

  // Attach hooks
  const { organizationsTableDef } = useDefinitions()

  // On load get the list of organizations
  useEffect(() => {
    if (organizations.length === 0 && !loadingOrganizations) {
      getOrganizations()
    }
  }, [organizations, getOrganizations, loadingOrganizations])

  /**
   *
   * Trigger state update to open add users to workspace dialog
   */
  const handleOpenAddOrganizationDialog = () =>
    manageDialog({ addNewOrganization: true })

  // Handle close create new organization dialog
  const handleCloseDialog = () => {
    manageDialog({ addNewOrganization: false })
  }

  /**
   *
   * Close the delete organization dialog
   */
  const handleCloseDeleteOrgDialog = () =>
    manageDialog({
      organization: undefined,
      deleteOrganizationConfirm: false,
    })

  /**
   *
   * Close the modify organization dialog
   */
  const handleCloseModifyOrgDialog = () =>
    manageDialog({
      organization: undefined,
      modifyOrganization: false,
    })

  /**
   *
   * Handle deletion of an organization
   */
  const handleDeleteOrg = (org?: Organization) => {
    if (org) {
      deleteOrganization(org.organizationId).finally(() => {
        manageDialog({
          organization: undefined,
          deleteOrganizationConfirm: false,
        })
      })
    }
  }

  /**
   *
   * Render the table if not loading the users
   * Else return the spinner
   */
  const renderTable = () => {
    if (loadingOrganizations) {
      return (
        <Layout width="100%" align="center" justify="center" minHeight="300px">
          <BaseSpinner size={32} />
        </Layout>
      )
    } else {
      return (
        <Layout width="100%" backgroundColor="background_mono_primary">
          <Table
            columns={organizationsTableDef}
            data={sortBy(organizations, 'organizationName')}
          />
        </Layout>
      )
    }
  }
  return (
    <Layout paddingTop="32" vertical>
      <Layout horizontal justify="space-between">
        <Text variant="paragraph_16_default">
          {t({ id: 'organizationsDescription' })}
        </Text>
        {user?.diAdmin && (
          <TextNoWrap>
            <Button
              title={t({ id: 'addOrganization' })}
              leftIcon="plus"
              onPress={handleOpenAddOrganizationDialog}
              state={loadingOrganizations ? 'Disabled' : 'Default'}
            />
          </TextNoWrap>
        )}
      </Layout>
      <Layout paddingTop="24" width="100%">
        {renderTable()}
      </Layout>
      {openDialogs.addNewOrganization && (
        <AddOrganizationDialog onCloseDialog={handleCloseDialog} />
      )}
      {openDialogs.modifyOrganization && openDialogs.organization && (
        <ModifyOrganizationDialog
          organization={openDialogs.organization}
          onCancel={handleCloseModifyOrgDialog}
        />
      )}
      {openDialogs.organization && openDialogs.deleteOrganizationConfirm && (
        <DeleteOrganizationConfirm
          onCancel={handleCloseDeleteOrgDialog}
          onOk={handleDeleteOrg}
          organization={openDialogs.organization}
        />
      )}
    </Layout>
  )
}

Organizations.displayName = 'Organizations'
export default withSidebar(withHeader(Organizations))
