import styled from 'styled-components'
import { Layout } from '../../styles'
import { DayColors } from '../../styles/colors'

/**
 * Container for workspaces list
 */
export const StyledWorkspaces = styled.section`
  width: 100%;
  margin-top: ${Layout.marginHuge};

  & > ul li {
    display: inline;
  }

  & > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  & .link {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: ${DayColors.content_mono_primary};
    font-size: 14px;

    &--active {
      background-color: ${DayColors.background_mono_excited};
    }

    &--arrow {
      margin: 0 ${Layout.margin};
      & > a {
        font-size: 24px;
      }
    }

    & a {
      text-decoration: none;
      font-weight: 700;
    }
  }
`

/**
 * Styled base layout
 */
export const StyledRow = styled.div`
  flex: 1 1 0%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  min-height: 200px;
  margin-top: ${Layout.margin};
`
