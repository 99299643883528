import { Link } from 'react-router-dom'
import { Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import Logo from '../../../assets/logo.svg'

const SidebarLogo = () => {
  const { formatMessage: t } = useIntl()

  return (
    <Layout paddingLeft="16" paddingTop="24" align="center">
      <Link to="/">
        <img alt="diap" src={Logo} />
      </Link>
      <Layout paddingLeft="10">
        <Text
          align="end"
          variant="inline_16_default"
          textColor="background_mono_primary"
          nowrap
        >
          {t({ id: 'analyticsPlatform' })}
        </Text>
      </Layout>
    </Layout>
  )
}

export default SidebarLogo
