import React, { useState, useEffect } from 'react'
import { Layout, Text, Icon, Spacer } from '@di-nxt/components'
import { InformationPanelProps } from './index.type'

/**
 *
 * Information panel component renders block of message with a cross rendered on top right corner
 * Pressing the corner will close the information block
 */
const InformationPanel: React.FC<InformationPanelProps> = (props) => {
  const [showPanel, setShowPanel] = useState<boolean>(false)

  /**
   *
   * Handle closing of information panel
   */
  const handleCloseInformationPanel = () => {
    localStorage.setItem(props.panelId, JSON.stringify(true))
    setShowPanel(false)
  }

  /**
   *
   * Use effect hook to determine whether to show information panel or not
   */
  useEffect(() => {
    try {
      const isPanelHidden = localStorage.getItem(props.panelId)
      if (isPanelHidden) {
        const parsedData = JSON.parse(isPanelHidden)
        setShowPanel(!parsedData)
      } else {
        setShowPanel(true)
      }
    } catch (e) {
      console.log('Unable to parse local data')
    }
  }, []) // eslint-disable-line

  if (!showPanel) {
    return null
  }

  return (
    <Layout vertical>
      {props.marginTop && <Spacer height={props.marginTop} />}
      <Layout
        {...props}
        borderRadius="small"
        borderWidth="hairline"
        borderStyle="solid"
        horizontal
        align={props.alignStart ? 'flex-start' : 'center'}
        justify="center"
      >
        {props.icon && (
          <Layout flex={0.02} padding="12">
            <Icon name={props.icon} color={props.textColor} />
          </Layout>
        )}
        {!props.icon && props.img && (
          <Layout flex={0.02} padding="12">
            <img src={props.img} alt="icon" />
          </Layout>
        )}
        <Layout flex={1} padding="12">
          <Layout flex={1}>
            <Text variant="paragraph_12_default" textColor={props.textColor}>
              {props.message && props.message()}
            </Text>
          </Layout>
          {!props.disableClose && (
            <Layout
              flex={0.1}
              align="flex-start"
              justify="flex-end"
              height="100%"
              horizontal
              onPress={handleCloseInformationPanel}
            >
              <Icon name="close" size="16" color={props.textColor} />
            </Layout>
          )}
        </Layout>
      </Layout>
      {props.marginBottom && <Spacer height={props.marginBottom} />}
    </Layout>
  )
}

InformationPanel.displayName = 'InformationPanel'
export default InformationPanel
