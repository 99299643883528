import { LayoutProps } from '@di-nxt/components'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import InformationPanel from '../../../../components/InformationPanel'

import { InformationPanelProps } from '../../../../components/InformationPanel/index.type'
import InfoIcon from '../../../../assets/icons/blue_info_icon.svg'

/**
 *
 * A simple components rendering how to steps for a workspace
 */
const WorkspaceHomeHowTo: React.FC = () => {
  /**
   *
   * Render the message
   * @returns {ReactNode}
   */
  const renderMessage = (): React.ReactNode => (
    <>
      <FormattedMessage
        id="workspaceHowTo"
        values={{
          br: <br />,
        }}
      />
      <ul>
        <li>
          <FormattedMessage id="workspaceHowToStep1" />
        </li>
        <li>
          <FormattedMessage id="workspaceHowToStep2" />
        </li>
        <li>
          <FormattedMessage id="workspaceHowToStep3" />
        </li>
        <li>
          <FormattedMessage id="workspaceHowToStep4" />
        </li>
      </ul>
      <FormattedMessage id="workspaceHowToEndNote" />
    </>
  )

  /**
   *
   * Commpon props for the information panel
   */
  const commonProps: LayoutProps & InformationPanelProps = {
    backgroundColor: 'background_accent_light',
    width: '100%',
    maxHeight: '250px',
    minWidth: 320,
    borderColor: 'border_accent_default',
    panelId: 'workspace_home_how_to',
    message: renderMessage,
    img: InfoIcon,
    alignStart: true,
    textColor: 'content_accent_default',
  }

  return (
    <>
      <InformationPanel {...commonProps} />
    </>
  )
}

WorkspaceHomeHowTo.displayName = 'WorkspaceHomeHowTo'
export default WorkspaceHomeHowTo
