import React, { useState, useEffect, useContext, ReactNode } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Layout, TabGroup } from '@di-nxt/components'
import { IconNames } from '@di-nxt/components/dist/components/icons/types'

import { ApiContext } from '../../../providers/api.provider'
import { RoutePathes } from '../../../constants/routes'
import { UserRoles } from '../../../types/user.type'

import {
  InformationPanelError,
  InformationPanelWarn,
} from '../../../constants/layoutProps'
import InformationPanel from '../../../components/InformationPanel'
import InfoIconWarning from '../../../assets/icons/info_icon_orange.svg'

import { TabContainer } from './style'

const Action = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`

/**
 *
 * Renders tabs for workspace container
 */
const WorkspaceTabs: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Managing states for keeping track of selected tab
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  // Get the user and the workspace from context api
  const { user, workspaceService, workspaceCloudApiService } = useContext(
    ApiContext,
  )
  const { workspace, loadingWorkspace } = workspaceService
  const {
    status: cloudApiStatus,
    loading: loadingCloudApiStatus,
    getStatus: getCloudApiStatus,
    resubmitResources: resubmitCloudApiResources,
  } = workspaceCloudApiService

  // Navigation to different views inside workspace
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  // Tab items
  const tabItems: string[] = ['home', 'data', 'users']

  if (process.env.REACT_APP_TABLEAU_ENABLED === 'true') {
    tabItems.splice(2, 0, 'tableau')
  }

  const isUserPartOfWorkspace = workspace?.workspaceUsers?.some(
    (workspaceUser) => workspaceUser.userId === user?.userId,
  )
  if (
    user?.diAdmin ||
    user?.role.name === UserRoles.admin ||
    isUserPartOfWorkspace
  ) {
    tabItems.push('settings')
  }

  /**
   *
   * Determine the right tab based on location
   */
  useEffect(() => {
    const path = location.pathname.split('/')
    const currentPath = path[path.length - 1]
    switch (currentPath) {
      case RoutePathes.Data:
        setSelectedTabIndex(tabItems.indexOf('data'))
        break
      case RoutePathes.Tableau:
        setSelectedTabIndex(tabItems.indexOf('tableau'))
        break
      case RoutePathes.Users:
        setSelectedTabIndex(tabItems.indexOf('users'))
        break
      case RoutePathes.Settings:
        setSelectedTabIndex(tabItems.indexOf('settings'))
        break
      default:
        setSelectedTabIndex(0)
    }
  }, [location]) //eslint-disable-line

  /**
   *
   * Handle change the selected tab
   * @param {number} index - index of newly selected tab
   */
  const handleChangeSelectedTab = (index: number) => {
    let path = ''
    const tab = tabItems[index]
    switch (tab) {
      case 'home':
        path = `/workspace/${params.workspaceId}`
        break
      case 'data':
        path = `/workspace/${params.workspaceId}/${RoutePathes.Data}`
        break
      case 'tableau':
        path = `/workspace/${params.workspaceId}/${RoutePathes.Tableau}`
        break
      case 'users':
        path = `/workspace/${params.workspaceId}/${RoutePathes.Users}`
        break
      case 'settings':
        path = `/workspace/${params.workspaceId}/${RoutePathes.Settings}`
        break
    }
    navigate(path)
  }

  if (
    loadingWorkspace ||
    loadingCloudApiStatus ||
    !workspace ||
    !cloudApiStatus
  ) {
    return null
  }

  let infoPanelProps: any = null
  let infoPanelMessage: ReactNode | null = null
  if (['SUBMITTED', 'CLOUD_API_SUBMITTED'].includes(cloudApiStatus)) {
    infoPanelProps = { ...InformationPanelWarn, img: InfoIconWarning }
    infoPanelMessage = (
      <FormattedMessage
        id="workspaceRefreshCloudApiStatus"
        values={{
          action: (...chunks: ReactNode[]) => (
            <Action
              onClick={() =>
                getCloudApiStatus(
                  workspace.organizationId,
                  workspace.workspaceId,
                )
              }
            >
              {chunks}
            </Action>
          ),
        }}
      />
    )
  }
  if (['CLOUD_API_FAILED'].includes(cloudApiStatus)) {
    infoPanelProps = {
      ...InformationPanelError,
      icon: 'closeCircle' as IconNames,
    }
    infoPanelMessage = (
      <FormattedMessage
        id="workspaceResubmitCloudApiResources"
        values={{
          action: (...chunks: ReactNode[]) => (
            <Action
              onClick={() =>
                resubmitCloudApiResources(
                  workspace.organizationId,
                  workspace.workspaceId,
                )
              }
            >
              {chunks}
            </Action>
          ),
        }}
      />
    )
  }

  return (
    <Layout vertical paddingLeft="48" paddingRight="48" paddingTop="18">
      {infoPanelProps && infoPanelMessage && (
        <InformationPanel
          panelId="workspace_cloud_api_status"
          message={() => infoPanelMessage}
          {...infoPanelProps}
          padding="12"
          marginBottom="24"
          disableClose={true}
        />
      )}
      <Layout horizontal paddingTop="18">
        <TabContainer>
          <TabGroup
            items={tabItems.map((tabItem) => t({ id: tabItem }))}
            selectedIndex={selectedTabIndex}
            onSelectedIndexChange={handleChangeSelectedTab}
          />
        </TabContainer>
      </Layout>
    </Layout>
  )
}

WorkspaceTabs.displayName = 'WorkspaceTabs'

export default WorkspaceTabs
