import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { sortBy } from 'lodash'
import { ApiContext } from '../../providers/api.provider'
import { useDefinitions, withHeader, withSidebar } from '../../hooks'
import { BaseSpinner, Layout, Spacer, Table, Text } from '@di-nxt/components'
import useOrganizationSharedData from '../../hooks/api/useOrganizationSharedData'
import { AppStateContext } from '../../providers/app.state.provider'
import UpdateOrganizationSharedDataDialog from '../../components/Dialogs/UpdateOrganizationSharedDataDialog'
import {
  OrganizationSharedData,
  ReceivingOrganizationId,
  SendingOrganizationId,
} from '../../types/organization.type'
import InformationPanel from '../../components/InformationPanel'
import InfoIcon from '../../assets/icons/info_icon_blue.svg'

const OrganizationSharedDataContainer: React.FC = () => {
  const { formatMessage: t } = useIntl()
  const { user, selectedOrganization } = useContext(ApiContext)

  const { manageDialog, openDialogs } = useContext(AppStateContext)

  const {
    organizationsSentDataTableDef,
    organizationsReceivedDataTableDef,
  } = useDefinitions()

  const {
    allSharedData,
    loading,
    updateSharedData,
  } = useOrganizationSharedData(selectedOrganization?.organizationId!, {
    disabled: !selectedOrganization,
  })

  if (loading) {
    return (
      <Layout width="100%" align="center" justify="center" minHeight="300px">
        <BaseSpinner size={32} />
      </Layout>
    )
  }

  const handleCloseUpdateSharedDataDialog = () =>
    manageDialog({
      organizationSharedData: undefined,
      updateOrganizationSharedData: false,
    })

  const handleSubmitUpdateSharedDataDialog = async (
    data: OrganizationSharedData &
      (ReceivingOrganizationId | SendingOrganizationId),
  ) => {
    const sharedData = {
      tables: data.tables ?? [],
      columns: data.columns ?? [],
      products: data.products ?? [],
      sybaseCustomers: data.sybaseCustomers ?? [],
      ownerPartyIds: data.ownerPartyIds ?? [],
    }
    if ('receivingOrganizationId' in data) {
      await updateSharedData(selectedOrganization!.organizationId, {
        receivingOrganizationId: data.receivingOrganizationId,
        sharedData,
      })
    } else if ('sendingOrganizationId' in data) {
      await updateSharedData(data.sendingOrganizationId, {
        receivingOrganizationId: selectedOrganization!.organizationId,
        sharedData,
      })
    }
    handleCloseUpdateSharedDataDialog()
  }

  let sentData = allSharedData.sentData.map((data) => ({
    receivingOrganizationId: data.receivingOrganizationId,
    receivingOrganizationName: data.receivingOrganizationName,
    ...data.sharedData,
  }))

  let receivedData = allSharedData.receivedData.map((data) => ({
    sendingOrganizationId: data.sendingOrganizationId,
    sendingOrganizationName: data.sendingOrganizationName,
    ...data.sharedData,
  }))

  if (!user?.diAdmin) {
    const notEmptyDataFilter = (data: OrganizationSharedData) =>
      data.tables?.length ||
      data.columns?.length ||
      data.products?.length ||
      data.sybaseCustomers?.length ||
      data.ownerPartyIds?.length

    sentData = sentData.filter(notEmptyDataFilter)
    receivedData = receivedData.filter(notEmptyDataFilter)
  }

  return (
    <Layout flex vertical>
      <Text variant="paragraph_16_default">
        {t({ id: 'sharedDataDescriptionForAdmin' })}
      </Text>
      <Spacer height="24" />
      <Text variant="paragraph_14_emphasis">
        {t({ id: 'sharedSentDataDescription' })}
      </Text>
      <Spacer height="8" />

      {sentData.length ? (
        <Layout width="100%" backgroundColor="background_mono_primary">
          <Table
            columns={organizationsSentDataTableDef}
            data={sortBy(sentData, 'receivingOrganizationName')}
          />
        </Layout>
      ) : (
        <InformationPanel
          backgroundColor="background_accent_light"
          width="100%"
          panelId="shared_data_nothing_sent"
          borderColor="border_accent_default"
          message={() => t({ id: 'sharedDataNothingSent' })}
          img={InfoIcon}
          alignStart
          textColor="content_accent_default"
          marginTop="32"
          marginBottom="32"
          disableClose={true}
        />
      )}

      <Spacer height="24" />
      <Text variant="paragraph_14_emphasis">
        {t({ id: 'sharedReceivedDataDescription' })}
      </Text>
      <Spacer height="8" />

      {receivedData.length ? (
        <Layout width="100%" backgroundColor="background_mono_primary">
          <Table
            columns={organizationsReceivedDataTableDef}
            data={sortBy(receivedData, 'sendingOrganizationName')}
          />
        </Layout>
      ) : (
        <InformationPanel
          backgroundColor="background_accent_light"
          width="100%"
          panelId="shared_data_nothing_received"
          borderColor="border_accent_default"
          message={() => t({ id: 'sharedDataNothingReceived' })}
          img={InfoIcon}
          alignStart
          textColor="content_accent_default"
          marginTop="32"
          marginBottom="32"
          disableClose={true}
        />
      )}

      {openDialogs.updateOrganizationSharedData &&
        openDialogs.organizationSharedData && (
          <UpdateOrganizationSharedDataDialog
            data={openDialogs.organizationSharedData}
            onOk={handleSubmitUpdateSharedDataDialog}
            onCancel={handleCloseUpdateSharedDataDialog}
          />
        )}
    </Layout>
  )
}

OrganizationSharedDataContainer.displayName = 'OrganizationSharedDataContainer'

export default withSidebar(withHeader(OrganizationSharedDataContainer))
