import { useEffect, useState } from 'react'
import { keycloak, TABLEAU_ACCESS_TOKEN_MIN_VALIDITY_SECONDS } from '../../../auth/keycloak'
import { Layout, Spinner } from '@di-nxt/components'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': EmbeddedViewProps
    }
  }
}

interface EmbeddedViewProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  src: string
  toolbar: string
  token: string
}

const EmbeddedView: React.FC<{
  siteContentUrl: string
  viewContentUrl: string
}> = ({ siteContentUrl, viewContentUrl }) => {
  const [isTokenExpired, setTokenExpired] = useState(true)

  useEffect(() => {
    if (isTokenExpired) {
      keycloak
        .updateToken(TABLEAU_ACCESS_TOKEN_MIN_VALIDITY_SECONDS)
        .then(() => setTokenExpired(false))
    }
  })

  viewContentUrl = viewContentUrl.replace('/sheets/', '/')

  return (
    <>
      {isTokenExpired ? (
        <Layout flex vertical centered spacing="32" height="100%">
          <Spinner size="Large" />
        </Layout>
      ) : (
        <tableau-viz
          id="tableau-viz"
          src={`${process.env.REACT_APP_TABLEAU_PATH}/t/${siteContentUrl}/views/${viewContentUrl}`}
          toolbar="bottom"
          token={keycloak.token!}
        ></tableau-viz>
      )}
    </>
  )
}

export default EmbeddedView
