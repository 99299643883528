import {
  BaseSpinner,
  Button,
  Layout,
  Spacer,
  Text,
  Toggle,
} from '@di-nxt/components'
import { useContext, useMemo, useState } from 'react'
import TreeItem from './WorkspaceTreeItem/TreeItem'
import SearchableDropdown from './WorkspaceSearchableDropdown/SearchableDropdown'
import { SmallToggle, Wrapper } from './style'
import { useIntl } from 'react-intl'
import { TreeViewProps } from './index.type'
import { AppStateContext } from '../../../../../providers/app.state.provider'
import SaveSelectionDialog from '../../../../../components/Dialogs/SaveSelectionDialog'
import { ApiContext } from '../../../../../providers/api.provider'
import { WorkspaceUserRole } from '../../../../../types/workspace.type'

/**
 * Component showing a tree view of all tables in a dataset
 *
 */
const TreeView: React.FC<TreeViewProps> = ({
  isLoading,
  database,
  resources,
  selectedTable,
  stateChanged,
  resourcesChanged,
  showOnlySelectedObjects,
  handleShowObjectsToggler,
  onOk,
  changeResource,
}) => {
  const { formatMessage: t } = useIntl()

  const { openDialogs, manageDialog } = useContext(AppStateContext)
  const { workspaceService, user } = useContext(ApiContext)

  const shownResources = useMemo(
    () =>
      showOnlySelectedObjects
        ? resources.filter((table) => table.selected !== 'NotChecked')
        : resources,
    [showOnlySelectedObjects, resources],
  )

  // Checks if the user has rights to change the data state
  const isEditable = useMemo(() => {
    return (
      workspaceService.workspace?.workspaceUsers.some(
        (wsUser) =>
          wsUser.userId === user?.userId &&
          wsUser.workspaceRole === WorkspaceUserRole.owner,
      ) ?? false
    )
  }, [user, workspaceService.workspace])

  const [searchText, setSearchText] = useState({
    inputText: '',
    filterText: '',
  })

  const onCancel = () => {
    manageDialog({
      saveSelection: false,
    })
  }

  const onSaveSelections = () => {
    manageDialog({
      saveSelection: true,
    })
  }

  const changeHandler = (value: string) => {
    setSearchText((searchText) => ({ ...searchText, inputText: value }))
    setTimeout(() => {
      setSearchText((searchText) => ({
        ...searchText,
        filterText: searchText.inputText,
      }))
    }, 100) ///used to avoid rerendering of the results table on every keystroke
  }

  return (
    <Layout borderRadius="round" width="100%">
      <Layout
        width="100%"
        vertical
        paddingLeft="24"
        paddingTop="24"
        paddingRight="24"
        backgroundColor="background_mono_primary"
        borderRadius="medium"
        paddingBottom="32"
        justify="space-between"
        centered={isLoading}
      >
        {isLoading ? (
          <BaseSpinner size={32} />
        ) : (
          <>
            <Wrapper>
              <Text
                variant="inline_12_emphasis"
                textColor="content_mono_secondary"
              >
                Search {database}
              </Text>
              <SearchableDropdown
                searchText={searchText.inputText}
                updateSearchText={changeHandler}
              />

              <Layout paddingTop="24" align="center" justify="flex-end">
                <Text variant="inline_12_emphasis">
                  {t({ id: 'showOnlySelectedObjects' })}
                </Text>
                <Spacer width="12" />
                <SmallToggle>
                  <Toggle
                    kind="Active"
                    on={showOnlySelectedObjects}
                    onPress={handleShowObjectsToggler}
                  />
                </SmallToggle>
              </Layout>

              <Layout paddingTop="16" paddingBottom="32" vertical>
                {shownResources.map((table) => (
                  <TreeItem
                    searchText={searchText.filterText}
                    item={table}
                    key={table.tableName}
                    isSelected={selectedTable?.tableName === table.tableName}
                    onSelected={changeResource}
                    isEditable={isEditable}
                  />
                ))}
              </Layout>
            </Wrapper>

            {isEditable && (
              <Button
                kind="Positive"
                state={stateChanged ? 'Default' : 'Disabled'}
                title={t({ id: 'saveSelections' })}
                onPress={onSaveSelections}
              />
            )}
            {openDialogs.saveSelection && (
              <SaveSelectionDialog
                onOk={onOk}
                onCancel={onCancel}
                changes={resourcesChanged}
              />
            )}
          </>
        )}
      </Layout>
    </Layout>
  )
}

TreeView.displayName = 'TreeView'

export default TreeView
