import { useState, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import useApi from './useApi'
import { debounce } from 'lodash'

import { CreateWorkspaceInput, Workspace } from '../../types/workspace.type'
import { UseWorkspaces } from './useWorkspaces.type'
import { AppStateContext } from '../../providers/app.state.provider'
import { SnackbarContext } from '../../providers/snackbar.provider'

import { ENDPOINTS } from '../../types/config.api.type'

export const defaultUseWorkspaces: UseWorkspaces = {
  debouncedSearchString: '',
  getWorkspacesByKey: () => new Promise((resolve, reject) => {}),
  handleCreateWorkspace: () => null,
  handleGetWorkspaces: () => null,
  handleSetSearchWorkspacesString: () => null,
  handleUpdateWorkspace: () => null,
  loadingWorkspaceError: false,
  loadingWorkspaces: false,
  searchWorkspacesString: '',
  workspaces: [],
}

/**
 * A simple hook that fetches workspaces from our api endpoint
 * @returns {Array<Workspace>}
 */
const useWorkspaces = (
  organizationId?: string,
  organizationKey?: string,
): UseWorkspaces => {
  const { fetcher } = useApi()
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // App state hooks to manage snackbar and dialogs state
  const { manageDialog } = useContext(AppStateContext)

  // Snackbar context to render snackbars
  const {
    handleSetContent,
    handleSetSnackbarType,
    handleSetProperties,
  } = useContext(SnackbarContext)

  // Initialize basic state variables
  const [workspaces, setWorkspaces] = useState<Workspace[] | null>(null)
  const [loadingWorkspaces, setLoadingWorkspaces] = useState<boolean>(false)
  const [creatingWorkspace, setCreatingWorkspace] = useState<boolean>(false)
  const [loadingWorkspaceError, setLoadingWorkspaceError] = useState<boolean>(
    false,
  )
  const [loadingWorkspacesByKey, setLoadingWorkspacesByKey] = useState<boolean>(
    false,
  )

  const [searchWorkspacesString, setSearchWorkspacesString] = useState<string>(
    '',
  )
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('')
  // Last evaluated key is pagination technique in dynamo db query format

  /**
   * Handle set search workspaces string
   */
  const handleSetSearchWorkspacesString = (query: string) => {
    setSearchWorkspacesString(query)
    processSearchQuery(query)
  }

  // Use effect hook to monitor changes on search query
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const processSearchQuery = useCallback(
    debounce((data: string) => {
      setDebouncedSearchString(data.toLowerCase())
    }, 1000),
    [],
  )

  /**
   *
   * Update a specific workspace in the list of workspaces
   * This is usefull for when an item has been successfully updated
   * And it costs more to refetch the entire table
   */
  const handleUpdateWorkspace = (workspace: Workspace) => {
    if (workspaces && workspaces.length > 0) {
      const updatedWorkspaces = workspaces.map((workspaceItem) => {
        if (workspaceItem.workspaceId === workspace.workspaceId) {
          return workspace
        }
        return workspaceItem
      })

      setWorkspaces(updatedWorkspaces)
    }
  }

  /**
   *
   * GET workspaces
   */
  const handleGetWorkspaces = async (orgId?: string) => {
    // Build the query
    if (organizationId) {
      const params = new URLSearchParams({
        ...(searchWorkspacesString && { search: searchWorkspacesString }),
      })
      setLoadingWorkspaces(true)
      const endpoint = ENDPOINTS.ORGANIZATION_WORKSPACES.replace(
        '$organizationId',
        orgId ?? organizationId,
      )

      try {
        const response = await fetcher.get(endpoint, { params })
        if (response.data) {
          setWorkspaces(response.data.workspaces as Workspace[])
        }
      } catch (exception) {
        setLoadingWorkspaceError(true)
        setWorkspaces([])
      } finally {
        setLoadingWorkspaces(false)
      }
    }
  }

  /**
   *
   * Get workspaces by key
   */
  const getWorkspacesByKey = async (workspaceKey: string) => {
    if (organizationId) {
      const params = new URLSearchParams({ workspaceKey })
      setLoadingWorkspacesByKey(true)
      return fetcher
        .get(
          ENDPOINTS.ORGANIZATION_WORKSPACES.replace(
            '$organizationId',
            organizationId,
          ),
          { params },
        )
        .finally(() => {
          setLoadingWorkspacesByKey(false)
        })
    }
  }

  /**
   *
   * POST creation of workspace
   * @param {CreateWorkspaceInput} payload - params required for workspace creation
   */
  const handleCreateWorkspace = async (payload: CreateWorkspaceInput) => {
    setCreatingWorkspace(true)
    // TODO replace hardcoded organization id
    if (organizationId) {
      const endpoint = ENDPOINTS.ORGANIZATION_WORKSPACES.replace(
        '$organizationId',
        organizationId,
      )

      try {
        const response = await fetcher.post(
          endpoint,
          { ...payload, organizationKey },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )

        if (response) {
          handleSetSnackbarType('Success')
          handleSetProperties({
            id: 'create-workspace',
            actionTitle: t({ id: 'view' }),
            actionType: 'NAVIGATE',
            to: `/workspace/${response.data.workspaceId}`,
          })
          handleGetWorkspaces()
          handleSetContent(
            t(
              { id: 'workspaceCreationSuccess' },
              { name: payload.workspaceName },
            ),
          )
        }
      } catch (error) {
        handleSetSnackbarType('Error')
        handleSetContent(
          t({ id: 'workspaceCreationError' }, { name: payload.workspaceName }),
        )
        console.error(error)
      } finally {
        setCreatingWorkspace(false)
        manageDialog({ createWorkspaceDialog: false })
      }
    }
  }

  // Return the variables we wish to export
  return {
    creatingWorkspace,
    debouncedSearchString,
    getWorkspacesByKey,
    handleCreateWorkspace,
    handleGetWorkspaces,
    handleSetSearchWorkspacesString,
    handleUpdateWorkspace,
    loadingWorkspacesByKey,
    loadingWorkspaceError,
    loadingWorkspaces,
    searchWorkspacesString,
    workspaces,
  }
}

export default useWorkspaces
