import { Layout, Spacer, Text } from '@di-nxt/components'
import { LinkStyled, MenuItemStyled } from '../style'
import { NavigationButton } from './style'

type Props = {
  id?: string
  icon: string | React.ReactNode
  text: string
  url?: string
  disabled?: boolean
  selected?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const NavigationLink: React.FC<Props> = ({
  id,
  icon,
  text,
  url,
  onClick,
  disabled,
  selected,
  children,
}) => {
  const renderContent = () => (
    <Layout align="center">
      {typeof icon === 'string' ? <img alt="diap" src={icon} /> : icon}
      <Spacer width="12" />
      <Text variant="inline_16_default" textColor="background_mono_primary">
        {text}
      </Text>
      {children}
    </Layout>
  )

  if (!url) {
    return (
      <NavigationButton
        id={id}
        disabled={disabled}
        selected={selected}
        onClick={onClick}
      >
        <MenuItemStyled>{renderContent()}</MenuItemStyled>
      </NavigationButton>
    )
  }

  return (
    <NavigationButton id={id} disabled={disabled} selected={selected}>
      <LinkStyled to={url} onClick={onClick}>
        {renderContent()}
      </LinkStyled>
    </NavigationButton>
  )
}

export default NavigationLink
