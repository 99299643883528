import styled from 'styled-components'

/**
 * Custom span which highlights the specified children in the props
 */
export const Marked = styled.span<{ states: Array<{ marked: boolean }> }>`
  ${(props) =>
    props.states
      .map((child, i) =>
        child.marked
          ? `& span:nth-child(${i + 1}) {
                  color: white;
                  background-color: #4f4fff;
                  word-break: break-all;
             }`
          : ` > span {
            word-break: break-all;
          }`,
      )
      .join('\n')}
`
