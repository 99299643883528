import { Table } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import CustomDialog from '../../../../../components/Dialogs/AddUserDialog/CustomDialog'
import { tierCostDataMock } from './__mocks__'
import useTierCostDialogDefinitions from './useTierCostDialogDefinitions'

type Props = {
  setIsTierCostDialogShown: (isOpen: boolean) => void
}

// TODO: Make it with real data when DI admin cost tracker api will be ready ( https://jira-di.atlassian.net/browse/DIAP-1405 )
const TierCostDialog: React.FC<Props> = ({ setIsTierCostDialogShown }) => {
  const { formatMessage: t } = useIntl()
  const { tierCostDialogDef } = useTierCostDialogDefinitions()

  const handleCloseDialog = () => setIsTierCostDialogShown(false)

  return (
    <CustomDialog
      hasCloseButton
      onOkButton={handleCloseDialog}
      okButtonTitle=""
      data-cy="tier-cost-dialog"
      title={t({ id: 'tierCost' })}
      cancelButtonTitle={t({ id: 'close' })}
      onCancelButton={handleCloseDialog}
    >
      <Table columns={tierCostDialogDef as any} data={tierCostDataMock} />
    </CustomDialog>
  )
}

export default TierCostDialog
