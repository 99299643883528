import React, { useContext, useEffect } from 'react'
import { BaseLayout, Spinner } from '@di-nxt/components'

import ToolCard from '../../../../components/ToolCard'
import { ApiContext } from '../../../../providers/api.provider'
import { WorkspaceToolsProps } from './index.type'

import { StyledGrid } from './style'

/**
 *
 * List of tools belonging to a workspace
 */
const WorkspaceTools: React.FC<WorkspaceToolsProps> = ({ workspaceId }) => {
  // Use the context api to get the list of tools
  const {
    workspaceToolsService,
    workspaceService,
    organizationService,
  } = useContext(ApiContext)
  const { tools, getWorkspaceTools, loadingTools } = workspaceToolsService
  const {
    organization,
    loadingOrganization,
    getOrganization,
  } = organizationService
  useEffect(() => {
    if (workspaceId) {
      getWorkspaceTools(workspaceId)
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if (
      workspaceService.workspace?.organizationId &&
      (!organization ||
        organization.organizationId !==
          workspaceService.workspace?.organizationId)
    ) {
      getOrganization(workspaceService.workspace?.organizationId)
    }
  }, []) //eslint-disable-line

  if (loadingTools || loadingOrganization) {
    return (
      <BaseLayout width="100%" align="center" justify="center">
        <Spinner size="Default" />
      </BaseLayout>
    )
  }

  return (
    <StyledGrid>
      {tools
        ?.filter(
          ({ toolName }) =>
            ['Amazon Redshift'].includes(toolName) ||
            (process.env.REACT_APP_TABLEAU_ENABLED === 'true' &&
              toolName === 'Tableau'),
        )
        .map((tool) => (
          <ToolCard
            tool={tool}
            key={tool.toolId}
            organizationKey={organization?.organizationKey ?? ''}
          />
        ))}
    </StyledGrid>
  )
}

WorkspaceTools.displayName = 'WorkspaceTools'
export default WorkspaceTools
