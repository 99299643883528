import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Avatar,
  Spacer,
  TableColumn,
  Layout,
  Text,
  Badge,
} from '@di-nxt/components'
import { format } from 'date-fns'

import { User, ModifyUserInput, UserRoles } from '../../types/user.type'
import { Workspace } from '../../types/workspace.type'
import { UpdateDetails, UseDefinitions } from './useDefinitions.type'

import UserRoleSelector from '../../components/TableComponents/UserRoleSelector'
import TableWorkspaceRoleSelector from '../../components/TableComponents/TableWorkspaceRoleSelector'
import WorkspaceName from '../../components/TableComponents/WorkspaceName'
import TableOrganizationName from '../../components/TableComponents/TableOrganizationName'
import TableUserActions from '../../components/TableComponents/TableUserActions'
import DeleteUserToggle from '../../components/TableComponents/DeleteUserToggle'
import TableOrganizationActions from '../../components/TableComponents/TableOrganizationActions'
import TableUserOrganization from '../../components/TableComponents/TableUserOrganization'
import TableWorkspaceActions from '../../components/TableComponents/TableWorkspaceActions'
import WorkspaceLastAccessed from '../../components/TableComponents/WorkspaceLastAccessed'
import TableUserEmail from '../../components/TableComponents/TableUserEmail'

import {
  Organization,
  OrganizationSharedData,
  ReceivingOrganizationId,
  ReceivingOrganizationName,
  SendingOrganizationId,
  SendingOrganizationName,
} from '../../types/organization.type'
import { TableProperties } from '../../types/table.properties'

import { ApiContext } from '../../providers/api.provider'
import EditableTableProperty from '../../components/TableComponents/EditableTableProperty'
import { WorkspaceDataColumn } from '../api/useWorkspaceData.type'
import ColumnReferences from '../../components/TableComponents/ColumnReferences'
import { WordBreakLayout } from '../../styles/wordBreakLayout'
import UserLastSeen from '../../components/TableComponents/UserLastSeen'
import { Row } from '../api/useQuery.type'
import { StyledBadge } from '../../styles/styledBadge'
import UserIsActiveIndicator from '../../components/TableComponents/UserIsActiveIndicator'
import UserTableauRoleSelector from '../../components/TableComponents/UserTableauRoleSelector'
import { SnackbarContext } from '../../providers/snackbar.provider'
import { TableauUser, TableauUserSiteRole } from '../../types/tableau.api.type'
import TableOrganizationSharedDataActions from '../../components/TableComponents/TableOrganizationSharedDataActions'

/**
 * A hook used for definitions
 * For instance table definitons
 * It is a hook due to internationalization and other elements
 */
const useDefinitions = (): UseDefinitions => {
  /** Id of the user being updated */
  const [updateDetails, setUpdateDetails] = useState<UpdateDetails>(undefined)

  /** User is fetched at initialization */
  const { user } = useContext(ApiContext)

  // Context api to display snackbar messages
  const { handleSetContent, handleSetSnackbarType } = useContext(
    SnackbarContext,
  )

  /** Internationalization hook */
  const { formatMessage: t } = useIntl()

  const numbersComparator = useCallback((a, b) => a - b, [])

  /** Handler to update organization user */
  const handleUpdateUser = (
    updateOrganizationUserCallback: (
      data: ModifyUserInput,
      updateUserId: string,
    ) => Promise<void>,
  ) => async (
    userId: string,
    updateInput: ModifyUserInput,
    updatingComponent: string,
  ) => {
    setUpdateDetails({
      updatingUserId: userId,
      updatingComponent,
    })
    return await updateOrganizationUserCallback(updateInput, userId)
      .then((response) => response)
      .finally(() => setUpdateDetails(undefined))
  }

  const handleUpdateUserTableauRole = (
    assignTableauUserRoleCallback: (
      user: User,
      siteRole: TableauUserSiteRole,
    ) => Promise<void>,
  ) => async (user: User, siteRole: TableauUserSiteRole) => {
    return assignTableauUserRoleCallback(user, siteRole)
      .then(() => {
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'valuesSaved' }))
      })
      .catch(() => {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'valuesSavedError' }))
      })
  }

  const getOrganizationUsersTableDef = (
    data: {
      isTableauEnabled: boolean
      tableauUsers: TableauUser[]
    },
    callbacks: {
      updateOrganizationUser: (
        data: ModifyUserInput,
        updateUserId: string,
      ) => Promise<void>
      assignTableauUserRole: (
        user: User,
        siteRole: TableauUserSiteRole,
      ) => Promise<void>
    },
  ): TableColumn<User>[] => {
    const config: TableColumn<User>[] = [
      {
        key: 'email',
        title: t({ id: 'email' }),
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        sortable: true,
        selector: (user) => {
          return <TableUserEmail user={user} />
        },
      },
      {
        key: 'firstName',
        title: t({ id: 'firstName' }),
        sortable: true,
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        selector: (row) => (
          <EditableTableProperty
            property={row.firstName}
            userId={row.userId}
            propertyKey="firstName"
            onEditUser={handleUpdateUser(callbacks.updateOrganizationUser)}
            updateDetails={updateDetails}
            editable={user?.diAdmin || user?.role.name === UserRoles.admin}
          />
        ),
      },
      {
        key: 'lastName',
        title: t({ id: 'lastName' }),
        sortable: true,
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        selector: (row) => (
          <EditableTableProperty
            property={row.lastName}
            userId={row.userId}
            propertyKey="lastName"
            onEditUser={handleUpdateUser(callbacks.updateOrganizationUser)}
            updateDetails={updateDetails}
            editable={user?.diAdmin || user?.role.name === UserRoles.admin}
          />
        ),
      },
      {
        key: 'role',
        title: t({ id: 'role' }),
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        selector: (row) => {
          return (
            <UserRoleSelector
              user={row}
              onEditUser={handleUpdateUser(callbacks.updateOrganizationUser)}
              propertyKey="role"
              updateDetails={updateDetails}
              editable={user?.diAdmin || user?.role.name === UserRoles.admin}
            />
          )
        },
      },
      {
        key: 'status',
        title: t({ id: 'status' }),
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        selector: (row) => {
          return <UserIsActiveIndicator user={row} />
        },
      },
      {
        key: 'lastSeen',
        title: t({ id: 'lastLogin' }),
        cellStyle: {
          flex: 0.4,
          align: 'center',
        },
        selector: (row) => {
          return <UserLastSeen user={row} />
        },
      },
      ...[
        (user?.diAdmin || user?.role.name === UserRoles.admin) &&
          ({
            key: '',
            title: '',
            cellStyle: {
              flex: 0.2,
              align: 'center',
            },
            selector: (row: User) => {
              return <TableUserActions user={row} />
            },
          } as any),
      ].filter(Boolean),
    ]

    if (user?.diAdmin) {
      const index = config.findIndex((col) => col.key === 'role')
      config.splice(index + 1, 0, {
        key: 'organizationId',
        title: t({ id: 'organizationName' }),
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        selector: (row) => {
          return <TableUserOrganization organizationId={row.organizationId} />
        },
      })
    }

    if (
      process.env.REACT_APP_TABLEAU_ENABLED === 'true' &&
      data.isTableauEnabled
    ) {
      const index = config.findIndex((col) => col.key === 'role')
      config.splice(index + 1, 0, {
        key: 'tableauSiteRole',
        title: t({ id: 'tableauSiteRole' }),
        cellStyle: {
          flex: 0.8,
          align: 'center',
        },
        selector: (row) => {
          return (
            <UserTableauRoleSelector
              user={row}
              onEditUser={handleUpdateUserTableauRole(
                callbacks.assignTableauUserRole,
              )}
              currentRole={
                data.tableauUsers.find((user) => user.name === row.userId)
                  ?.siteRole ?? TableauUserSiteRole.Unassigned
              }
              editable={!!user?.diAdmin}
            />
          )
        },
      })
    }

    return config
  }

  /**
   * Workspaces table def
   */
  const workspacesTableDef: TableColumn<Workspace>[] = [
    {
      key: 'workspaceName',
      title: t({ id: 'workspaceName' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
      selector: (row) => {
        return <WorkspaceName workspace={row} user={user} />
      },
    },
    {
      key: 'workspaceOwners',
      title: t({ id: 'owners' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          row.workspaceUsers &&
          row.workspaceUsers
            .filter(
              (user) => user.workspaceRole && user.workspaceRole === 'Owner',
            )
            .map((owner) => (
              <Layout key={owner.userId}>
                <Avatar name={owner.username} size="30" />
                <Spacer width="12" />
              </Layout>
            ))
        )
      },
    },
    {
      key: 'dateModified',
      title: t({ id: 'updated' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
      selector: (row) => {
        return (
          <div>
            {row.dateModified ? (
              <Text variant="inline_14_default">
                {format(new Date(row.dateModified), 'd.M.yyyy')}
              </Text>
            ) : (
              'N/A'
            )}
          </div>
        )
      },
    },
    {
      key: 'lastAccessed',
      title: t({ id: 'lastAccessed' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => <WorkspaceLastAccessed workspace={row} />,
    },
    {
      key: '',
      title: '',
      cellStyle: {
        flex: 0.3,
        align: 'center',
      },
      selector: (row) => <TableWorkspaceActions workspace={row} />,
    },
  ]

  /**
   * Organizations table def
   */
  const organizationsTableDef: TableColumn<Organization & TableProperties>[] = [
    {
      key: 'organizationName',
      title: t({ id: 'organizationName' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
      selector: (organization) => {
        return <TableOrganizationName organization={organization} user={user} />
      },
    },
    {
      key: 'organizationKey',
      title: t({ id: 'organizationKey' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
    },
    {
      key: 'sybaseCustomers',
      title: t({ id: 'sybaseCustomers' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.sybaseCustomers ? (
              <Text variant="inline_14_default">
                {row.sybaseCustomers
                  .map(Number)
                  .sort(numbersComparator)
                  .join(', ')}
              </Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'products',
      title: t({ id: 'products' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.products ? (
              <Text variant="inline_14_default">
                {row.products.map(Number).sort(numbersComparator).join(', ')}
              </Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'ownerPartyIDs',
      title: t({ id: 'ownerPartyIDsTitle' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.ownerPartyIDs ? (
              <Text variant="inline_14_default">
                {row.ownerPartyIDs
                  .map(Number)
                  .sort(numbersComparator)
                  .join(', ')}
              </Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'fromDate',
      title: t({ id: 'fromDate' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
    },
    {
      key: 'toDate',
      title: t({ id: 'toDate' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
    },
    {
      key: '',
      title: '',
      cellStyle: {
        flex: 0.3,
        align: 'center',
      },
      selector: (row) => {
        return <TableOrganizationActions organization={row} />
      },
    },
  ]

  /**
   * Organizations shared data table def
   */
  const organizationsSharedDataTableDef: TableColumn<
    OrganizationSharedData & TableProperties
  >[] = [
    {
      key: 'tables',
      title: t({ id: 'sharedTables' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.tables ? (
              <Text variant="inline_14_default">{row.tables.join(', ')}</Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'columns',
      title: t({ id: 'sharedColumns' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.columns ? (
              <Text variant="inline_14_default">{row.columns.join(', ')}</Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'products',
      title: t({ id: 'sharedProducts' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.products ? (
              <Text variant="inline_14_default">
                {row.products.map(Number).sort(numbersComparator).join(', ')}
              </Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'sybaseCustomers',
      title: t({ id: 'sharedSybaseCustomers' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.sybaseCustomers ? (
              <Text variant="inline_14_default">
                {row.sybaseCustomers
                  .map(Number)
                  .sort(numbersComparator)
                  .join(', ')}
              </Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    {
      key: 'ownerPartyIds',
      title: t({ id: 'sharedOwnerPartyIds' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <div>
            {row.ownerPartyIds ? (
              <Text variant="inline_14_default">
                {row.ownerPartyIds
                  .map(Number)
                  .sort(numbersComparator)
                  .join(', ')}
              </Text>
            ) : (
              ''
            )}
          </div>
        )
      },
    },
  ]

  /**
   * Organizations sent shared data table def
   */
  const organizationsSentDataTableDef: TableColumn<
    ReceivingOrganizationName &
      ReceivingOrganizationId &
      OrganizationSharedData &
      TableProperties
  >[] = [
    {
      key: 'receivingOrganizationName',
      title: t({ id: 'sharedDataReceivingOrganizationName' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
    },
    ...organizationsSharedDataTableDef,
    ...[
      user?.diAdmin &&
        ({
          key: '',
          title: '',
          cellStyle: {
            flex: 0.3,
            align: 'center',
          },
          selector: (row: any) => {
            return <TableOrganizationSharedDataActions data={row} />
          },
        } as any),
    ].filter(Boolean),
  ]

  /**
   * Organizations received shared data table def
   */
  const organizationsReceivedDataTableDef: TableColumn<
    SendingOrganizationName &
      SendingOrganizationId &
      OrganizationSharedData &
      TableProperties
  >[] = [
    {
      key: 'sendingOrganizationName',
      title: t({ id: 'sharedDataSendingOrganizationName' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
    },
    ...organizationsSharedDataTableDef,
    ...[
      user?.diAdmin &&
        ({
          key: '',
          title: '',
          cellStyle: {
            flex: 0.3,
            align: 'center',
          },
          selector: (row: any) => {
            return <TableOrganizationSharedDataActions data={row} />
          },
        } as any),
    ].filter(Boolean),
  ]

  const workspaceUsersTableDef = (editable: boolean) => {
    const tableColumns: TableColumn<User>[] = [
      {
        key: 'email',
        title: t({ id: 'email' }),
        cellStyle: {
          flex: 2,
          align: 'center',
        },
        sortable: true,
      },
      {
        key: 'firstName',
        title: t({ id: 'firstName' }),
        sortable: true,
        cellStyle: {
          flex: 1,
          align: 'center',
        },
      },
      {
        key: 'lastName',
        title: t({ id: 'lastName' }),
        sortable: true,
        cellStyle: {
          flex: 1,
          align: 'center',
        },
      },
      {
        key: 'workspaceRole',
        title: t({ id: 'role' }),
        sortable: true,
        selector: (row: User) => {
          return <TableWorkspaceRoleSelector user={row} editable={editable} />
        },
        cellStyle: {
          flex: 1,
          align: 'center',
        },
      },
    ]
    return editable
      ? [
          {
            key: '',
            title: '',
            cellStyle: {
              flex: 0.5,
              centered: true,
              maxWidth: '5em',
            },
            sortable: true,
            selector: (row: User) => {
              return <DeleteUserToggle workspaceUser={row} />
            },
          } as TableColumn<User>,
          ...tableColumns,
        ]
      : tableColumns
  }

  const columnsTableDef: TableColumn<WorkspaceDataColumn>[] = [
    {
      key: 'columnName',
      title: t({ id: 'columnName' }),
      cellStyle: {
        width: '26%',
      },
      selector: (row) => (
        <WordBreakLayout wrapFlex>
          <Layout horizontal justify="space-between">
            <Text variant="inline_14_default">{row.columnName}</Text>
          </Layout>
          {row.isPii && (
            <Layout>
              <StyledBadge textColor="#ffffff" backgroundColor="#EF9104">
                <Badge
                  content={'PII'}
                  kind={'Positive'}
                  round={false}
                  size="Small"
                />
              </StyledBadge>
            </Layout>
          )}
        </WordBreakLayout>
      ),
    },
    {
      key: 'type',
      title: t({ id: 'columnType' }),
      cellStyle: {
        width: '15%',
      },
      selector: ({ type }) => (
        <WordBreakLayout>
          <Text variant="inline_14_default">{type}</Text>
        </WordBreakLayout>
      ),
    },
    {
      key: 'references',
      title: t({ id: 'columnReferences' }),
      cellStyle: {
        width: '30%',
      },
      selector: (row) => <ColumnReferences references={row.references} />,
    },
    {
      key: 'description',
      title: t({ id: 'columnDescription' }),
      cellStyle: {
        width: '29%',
      },
      selector: ({ description }) => (
        <WordBreakLayout>
          <Text variant="inline_14_default">{description}</Text>
        </WordBreakLayout>
      ),
    },
  ]
  const getQueryResultsTableDef = (
    columnNames: string[],
  ): TableColumn<Row>[] => {
    const config: TableColumn<Row>[] = columnNames.map((columnName) => ({
      key: columnName,
      title: columnName,
      cellStyle: {},
      sortable: true,
    }))
    return config
  }

  return {
    columnsTableDef,
    getOrganizationUsersTableDef,
    getQueryResultsTableDef,
    workspacesTableDef,
    organizationsTableDef,
    organizationsSentDataTableDef,
    organizationsReceivedDataTableDef,
    workspaceUsersTableDef,
  }
}

export default useDefinitions
