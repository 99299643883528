import { useEffect } from 'react'

const CustomReferenceDot = ({ cx, cy, setTierUpgradeTooltipPosition }: any) => {
  useEffect(() => {
    setTierUpgradeTooltipPosition({ cx, cy })
  }, [cx, cy, setTierUpgradeTooltipPosition])
  return (
    <circle
      r="7"
      cx={cx}
      cy={cy}
      fill="#F0f0fd"
      stroke="#A2a5f1"
      strokeWidth="2"
    ></circle>
  )
}

export default CustomReferenceDot
