import { Badge, Checkbox, Layout, Spacer } from '@di-nxt/components'
import { StyledBadge } from '../../../../../../styles/styledBadge'
import { SubItemProps } from './SubItem.type'
import { getMarkedText } from './util/markedText'

const SubItem: React.FC<SubItemProps> = ({
  item,
  isEditable,
  matchedPart,
  tableName,
  onSelected,
}) => {
  const onChange = () => {
    onSelected(tableName, item.columnName)
  }

  return (
    <Layout paddingLeft="24" paddingVertical="8">
      {isEditable && (
        <>
          <Checkbox
            onChange={onChange}
            checked={item.selected === 'Checked'}
            disabled={!isEditable}
          />
          <Spacer width="12" />
        </>
      )}
      {getMarkedText(
        item.columnName,
        matchedPart,
        item.selected === 'NotChecked',
      )}
      {item.isPii && (
        <>
          <Spacer width="16" />
          <StyledBadge
            height="18px"
            textColor="#FFFFFF"
            backgroundColor="#EF9104"
            center
          >
            <Badge content="PII" kind="Positive" round={false} size="Default" />
          </StyledBadge>
        </>
      )}
    </Layout>
  )
}

SubItem.displayName = 'SubItem'

export default SubItem
