export type TableauAuthRequest = {
  credentials: {
    jwt: string
    site: {
      contentUrl: string
    }
  }
}

export type TableauAuthResponse = {
  credentials: {
    site: {
      id: string
      contentUrl: string
    }
    user: Pick<TableauUser, 'id'>
    token: string
  }
}

export type TableauWorkbook = {
  id: string
  name: string
  description: string
  contentUrl: string
}

export type TableauView = {
  id: string
  contentUrl: string
  name: string
  workbook: Pick<TableauWorkbook, 'id'>
}

export type TableauWorkbookWithViews = TableauWorkbook & {
  views: TableauView[]
}

export type TableauSelectedView = TableauView & { selected: boolean }

export type TableauWorkbookWithSelectedViews = TableauWorkbook & {
  views: TableauSelectedView[]
}

export enum TableauUserSiteRole {
  Unassigned = 'Unassigned',
  Viewer = 'Viewer',
  Creator = 'Creator',
  SiteAdministratorCreator = 'SiteAdministratorCreator',
  Explorer = 'Explorer',
  ExplorerCanPublish = 'ExplorerCanPublish',
  SiteAdministratorExplorer = 'SiteAdministratorExplorer',
  Unlicensed = 'Unlicensed',
}

export type TableauUser = {
  id: string
  name: string
  siteRole: TableauUserSiteRole
}

export type TableauFolder = {
  siteContentUrl: string
  projectName: string
  provisionStatus?: 'Provisioned' | 'Pending' | 'Error'
}

export type TableauWorkbooksProvisionRequest = {
  id: string
  visibleViewNames: string[]
}[]
