import React, { useContext, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input, Layout, Spacer, Text } from '@di-nxt/components'

import { AppStateContext } from '../../../providers/app.state.provider'
import { ApiContext } from '../../../providers/api.provider'

import { UserInput } from '../../../types/user.type'
import { OrganizationRole } from '../../../types/organization.type'
import StatusSpinner from '../../StatusSpinner'
import RoleAndOrganization from './RoleAndOrganization'
import { EMAIL_REGEX } from '../../../constants/regex'
import CustomDialog from './CustomDialog'
import { InputProps } from '@di-nxt/components/dist/components/input/types'

/**
 *
 * Dialog for creating a new user in an organization
 */
const AddUserDialog: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Attach app state context
  const { manageDialog } = useContext(AppStateContext)
  const { selectedOrganization, organizationUserService, user } =
    useContext(ApiContext)
  // State management for invite user button
  const [enabled, setEnabled] = useState<boolean>(false)
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  const [emailTaken, setEmailTaken] = useState<boolean>(false)
  const [isEmailChecked, setIsEmailChecked] = useState<boolean>(false)

  const { getUserByEmail, loadingUserByEmail } = organizationUserService

  // State management for user roles
  const [userDetails, setUserDetails] = useState<UserInput>({
    username: '',
    firstName: '',
    lastName: '',
    role: undefined,
    organization: undefined,
    email: '',
  })

  /**
   *
   * Use effect hook to monitor email validity
   */
  useEffect(() => {
    if (
      emailVerified &&
      !emailTaken &&
      isEmailChecked &&
      userDetails.email.length > 0 &&
      userDetails.firstName.trim().length > 0 &&
      userDetails.lastName.trim().length > 0
    ) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }

    if (!emailVerified && EMAIL_REGEX.test(userDetails.email)) {
      setEmailVerified(true)
    }
  }, [userDetails, emailVerified, emailTaken, isEmailChecked])

  /**
   *
   * Create the user
   */
  const handleCreateUser = () => {
    organizationUserService
      ?.createOrganizationUser({
        ...userDetails,
        username: `${userDetails.firstName} ${userDetails.lastName}`,
        organization: {
          organizationName: selectedOrganization!.organizationName,
          organizationId: selectedOrganization!.organizationId,
        },
      })
      .then(() => {
        manageDialog({ addNewUserDialog: false })
      })
  }

  /**
   *
   * Close the dialog
   */
  const handleCloseDialog = () => manageDialog({ addNewUserDialog: false })

  /**
   *
   * Handle input change on input fields
   */
  const handleChangeSelectedRole = (role: OrganizationRole) => {
    setUserDetails({ ...userDetails, role })
  }

  /**
   *
   * Set the email of the user
   */
  const handleEmailChange = (data: string) => {
    setIsEmailChecked(false)
    setUserDetails({ ...userDetails, email: data })
    if (emailVerified) {
      setEmailVerified(false)
    }
  }

  /**
   *
   * Set first name of the user
   */
  const handleFirstNameChange = (data: string) =>
    setUserDetails({ ...userDetails, firstName: data })

  /**
   *
   * Set last name of the user
   */
  const handleLastNameChange = (data: string) =>
    setUserDetails({ ...userDetails, lastName: data })

  /**
   *
   * Validate whether the email has been used before
   */
  const handleValidateEmail = () => {
    if (user?.userId) {
      getUserByEmail(user?.userId, userDetails.email)
        .then((response) => {
          setEmailTaken(false)
        })
        .catch((error) => {
          setEmailTaken(true)
        })
        .finally(() => {
          setIsEmailChecked(true)
        })
    } else {
      setIsEmailChecked(true)
    }
  }

  /**
   *
   * If the email is invalid
   */
  const renderEmailInvalid = () =>
    emailTaken &&
    isEmailChecked && (
      <>
        <Spacer height="6" />
        <Text
          variant="paragraph_12_default"
          textColor={'content_negative_default'}
        >
          <FormattedMessage id={'errorUserEmailTaken'} />
        </Text>
      </>
    )

  /**
   *
   * A custom input wrapper with data test attr
   * @param {ReactNode} child - the item to render
   * @param {string} dataTest - test id tag for component
   * @return {React.ReactNode}
   */
  const inputWrapper = (
    value: string,
    changeHandler: (value: string) => void,
    dataTest: string,
    blurHandler?: () => void,
    rightSpinner?: boolean,
  ): React.ReactNode => {
    const inputProps: InputProps = {
      onChange: changeHandler,
      value: value,
      ...(blurHandler && { onBlur: blurHandler }),
      ...(rightSpinner && { rightSpinner }),
      flex: 1,
    }
    return (
      <div style={{ display: 'flex' }} data-cy={dataTest}>
        <Input {...inputProps} />
      </div>
    )
  }

  return (
    <CustomDialog
      hasCloseButton
      okButtonTitle={t({ id: 'inviteUser' })}
      onOkButton={handleCreateUser}
      data-cy="create-user-dialog"
      title={t(
        { id: 'invitePeople' },
        {
          orgName:
            selectedOrganization?.organizationName.toLocaleLowerCase() ??
            t({ id: 'org' }),
        },
      )}
      cancelButtonTitle={t({ id: 'cancel' })}
      onCancelButton={handleCloseDialog}
      okButtonDisabled={!enabled}
    >
      <Layout vertical>
        <Layout paddingBottom="16">
          <Text variant="paragraph_14_default">
            {t({ id: 'invitePeopleDescription' })}
          </Text>
        </Layout>

        <Layout vertical width="100%" paddingTop="16">
          <label>{t({ id: 'userEmail' })}</label>
          {inputWrapper(
            userDetails.email,
            handleEmailChange,
            'create-user-email',
            handleValidateEmail,
            loadingUserByEmail,
          )}
          {renderEmailInvalid()}
          <Spacer height="12" />
          <label>{t({ id: 'firstName' })}</label>
          {inputWrapper(
            userDetails.firstName,
            handleFirstNameChange,
            'create-user-firstname',
          )}
          <Spacer height="12" />
          <label>{t({ id: 'lastName' })}</label>
          {inputWrapper(
            userDetails.lastName,
            handleLastNameChange,
            'create-user-lastname',
          )}
          <Spacer height="12" />
          <RoleAndOrganization
            user={user}
            selectedOrganization={selectedOrganization!}
            onSelectRole={handleChangeSelectedRole}
          />
          <Spacer height="12" />
        </Layout>
      </Layout>
      {organizationUserService.creatingUser && (
        <Layout position="absolute" bottom={-54} left={28}>
          <StatusSpinner message={t({ id: 'addingUser' })} />
        </Layout>
      )}
    </CustomDialog>
  )
}

AddUserDialog.displayName = 'AddUserDialog'
export default AddUserDialog
