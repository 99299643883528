import React, { useEffect, useState } from 'react'
import { Text } from '@di-nxt/components'
import { ColumnReferencesProps, MAX_REFERENCES } from './ColumnReferences.type'
import { WordBreakLayout } from '../../styles/wordBreakLayout'

/**
 * Component which renders the references of a column
 *
 */
const ColumnReferences: React.FC<ColumnReferencesProps> = ({ references }) => {
  const [showAll, setShowAll] = useState(references.length < MAX_REFERENCES)

  const handleShowAll = () => {
    setShowAll(true)
  }
  const refs =
    references.length < MAX_REFERENCES || showAll
      ? references.join(', ')
      : [...references.slice(0, MAX_REFERENCES - 1), '...'].join(', ')

  useEffect(() => {
    setShowAll(references.length < MAX_REFERENCES)
  }, [references])

  return (
    <WordBreakLayout>
      {references.length < MAX_REFERENCES || showAll ? (
        <Text variant="inline_14_default">{refs}</Text>
      ) : (
        <Text variant="inline_14_default">
          {refs}{' '}
          <Text variant="inline_14_default" onPress={handleShowAll}>
            {references.length - MAX_REFERENCES + 1} more
          </Text>
        </Text>
      )}
    </WordBreakLayout>
  )
}

ColumnReferences.displayName = 'ColumnReferences'

export default ColumnReferences
