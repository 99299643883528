import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { configureAssert } from 'assert-ts'

// Translations
import { en } from './i18n'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

configureAssert({
  // TODO:
  warningReporter: (type, message, props) =>
    console.warn('Assertion failed: ', message || '', JSON.stringify(props)),
})

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <IntlProvider messages={en} locale="en">
        <App />
      </IntlProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )

renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
