import React, { useRef, useContext, useState, useEffect, useMemo } from 'react'
import { Layout, LayoutProps, Popup, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import { TableUserActionsProps } from './TableUserActions.type'
import { ApiContext } from '../../providers/api.provider'
import { AppStateContext } from '../../providers/app.state.provider'

import MoreInActive from '../../assets/icons/more_inactive_icon.svg'

/**
 *
 * Displays the icon that renders a menu
 * The menu has two options:
 * 1 - Enable/disable user
 * 2 - Delete user if the user hasn't logged in yet
 */
const TableUserActions: React.FC<TableUserActionsProps> = ({ user }) => {
  // Create a reference for the image button
  const moreRef = useRef(null)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Get the api context to toggle enabled property of the user
  const { user: loggedUser, organizationUserService } = useContext(ApiContext)

  // App state context for dialog state management
  const { manageDialog } = useContext(AppStateContext)

  const { updateOrganizationUser, updatingUser } = organizationUserService
  // State for the toggle button
  const [userEnabled, setUserEnabled] = useState<boolean>(user.enabled)

  const currentUser = useMemo(
    () =>
      organizationUserService.users?.find(
        (orgUser) => orgUser.userId === user.userId,
      ),
    [organizationUserService.users, user.userId],
  )

  /**
   *
   * Use effect hook to monitor changes in user
   */
  useEffect(() => {
    if (currentUser && currentUser.enabled !== userEnabled) {
      setUserEnabled(currentUser.enabled)
    }
  }, [organizationUserService.users, userEnabled, user.userId, currentUser])

  /**
   *
   * Toggle popup
   */
  const togglePopup = () => (moreRef?.current as any).click()

  /**
   *
   * Handle on press enabled toggle
   */
  const handleToggleUserActivation = () => {
    if (!updatingUser) {
      updateOrganizationUser({ enabled: !userEnabled }, user.userId)
    }
    togglePopup()
  }

  /**
   *
   * Open the user deletion dialog
   */
  const handleOpenUserDeleteDialog = () => {
    manageDialog({ deleteUserConfirm: true, deleteUser: user })
    togglePopup()
  }

  /**
   *
   * Custom props for the button
   */
  const buttonProps: LayoutProps = {
    backgroundColor: 'background_mono_primary',
    hover: true,
    width: '100%',
    padding: '16',
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      data-cy={`create-user-popup-${user.firstName}-${user.lastName}`}
    >
      <Popup
        trigger={
          <div
            style={{ width: '100%', height: '100%' }}
            data-cy={`create-user-context-${user.firstName}-${user.lastName}`}
          >
            <img src={MoreInActive} alt="more" ref={moreRef} />
          </div>
        }
      >
        {() => (
          <Layout
            shadow
            minWidth="150px"
            borderRadius="medium"
            backgroundColor="background_mono_primary"
            vertical
            overflow="hidden"
            paddingTop="10"
            paddingBottom="10"
          >
            <div data-cy="toggle-user-activated">
              <Layout onPress={handleToggleUserActivation} {...buttonProps}>
                <Text variant="inline_14_default">
                  {userEnabled
                    ? t({ id: 'disableUser' })
                    : t({ id: 'enableUser' })}
                </Text>
              </Layout>
            </div>
            {currentUser?.userId !== loggedUser?.userId && (
              <div
                data-cy={`delete-user-account-${user.firstName}-${user.lastName}`}
              >
                <Layout {...buttonProps} onPress={handleOpenUserDeleteDialog}>
                  <Text variant="inline_14_default">
                    {t({ id: 'deleteUser' })}
                  </Text>
                </Layout>
              </div>
            )}
          </Layout>
        )}
      </Popup>
    </div>
  )
}

TableUserActions.displayName = 'TableUserActions'

export default TableUserActions
