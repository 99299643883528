import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { DatePicker, Dialog, Input, Layout, Spacer } from '@di-nxt/components'
// Providers
import { ApiContext } from '../../../providers/api.provider'
// Other components
import { ArrayNumberInput } from '../../ArrayInput'
// Type Definitions
import { ModifyOrganizationDialogProps } from './index.type'
import { UpdateOrganizationInput } from '../../../types/organization.type'
import { format, isAfter, isBefore, parse } from 'date-fns'
import { DatePickerContainer, TrashIconStyled } from '../style'
import TrashIcon from '../../../assets/icons/trash_icon.svg'

/**
 *
 * Dialog that presents a form to create a new organization
 */
const ModifyOrganizationDialog: React.FC<ModifyOrganizationDialogProps> = ({
  organization,
  onCancel: handleCloseDialog,
}) => {
  // State to store organization name and sybase customer ids
  const [organizationDetails, setOrganizationDetails] =
    useState<UpdateOrganizationInput>({
      organizationName: organization.organizationName,
      sybaseCustomers: organization.sybaseCustomers,
      products: organization.products,
      ownerPartyIDs: organization.ownerPartyIDs,
      fromDate: organization.fromDate,
      toDate: organization.toDate,
    })

  // State that store the values that have been updated
  const [updatedValues, setUpdatedValues] = useState<
    UpdateOrganizationInput | undefined | null
  >(undefined)

  // Context api to get the services
  const { organizationService } = useContext(ApiContext)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Handler to update organization name in the state
  const handleUpdateName = (value: string) => {
    setOrganizationDetails({ ...organizationDetails, organizationName: value })
    if (value !== organization.organizationName) {
      setUpdatedValues({ ...updatedValues, organizationName: value })
    }
  }

  // Handler to send the update organization request
  const handleUpdateOrganization = () => {
    const { organizationName, ...rest } = organization
    if (updatedValues) {
      if (
        (updatedValues.fromDate !== undefined &&
          updatedValues.toDate === undefined &&
          (organizationDetails.toDate === undefined ||
            organizationDetails.toDate === '')) ||
        (updatedValues.fromDate === undefined &&
          updatedValues.toDate !== undefined &&
          (organizationDetails.fromDate === undefined ||
            organizationDetails.fromDate === ''))
      ) {
        return
      }
      organizationService
        .modifyOrganization(organization.organizationId, {
          ...rest,
          ...updatedValues,
          organizationKey: organization.organizationKey,
        })
        .finally(() => {
          handleCloseDialog()
        })
    }
  }

  // Update sybase customer ids
  const handleUpdateCustomerIds = (value: number[]) => {
    setUpdatedValues({ ...updatedValues, sybaseCustomers: value })
  }

  // Handle updating products value
  const handleUpdateProductsValue = (value: number[]) => {
    setUpdatedValues({ ...updatedValues, products: value })
  }

  // Handler to update from date in the state
  const handleUpdateFromDate = (value?: Date | null) => {
    if (
      organizationDetails.toDate &&
      value &&
      isAfter(value, parse(organizationDetails.toDate, 'd.M.yyyy', new Date()))
    ) {
      return
    }

    const formattedDate = value && format(value, 'd.M.yyyy')
    setOrganizationDetails({ ...organizationDetails, fromDate: formattedDate })
    if (formattedDate !== organization.fromDate) {
      setUpdatedValues({ ...updatedValues, fromDate: formattedDate })
    }
  }

  // Handler to update to date in the state
  const handleUpdateToDate = (value?: Date | null) => {
    if (
      organizationDetails.fromDate &&
      value &&
      isBefore(
        value,
        parse(organizationDetails.fromDate, 'd.M.yyyy', new Date()),
      )
    ) {
      return
    }
    const formattedDate = value && format(value, 'd.M.yyyy')
    setOrganizationDetails({ ...organizationDetails, toDate: formattedDate })
    if (formattedDate !== organization.toDate) {
      setUpdatedValues({ ...updatedValues, toDate: formattedDate })
    }
  }

  // Handle update owner party ids
  const handleUpdateOwnerPartyIds = (value: number[]) => {
    setUpdatedValues({ ...updatedValues, ownerPartyIDs: value })
  }

  return (
    <Dialog
      title={t({ id: 'updateOrganization' })}
      okButtonTitle={t({ id: 'save' })}
      onOkButton={handleUpdateOrganization}
      onCancelButton={handleCloseDialog}
      cancelButtonTitle={t({ id: 'cancel' })}
      okButtonDisabled={
        !updatedValues || organizationService.modifyingOrganization
      }
    >
      <Layout vertical width="100%">
        <Layout vertical width="100%">
          <label>{t({ id: 'organizationName' })}</label>
          <Input
            value={organizationDetails.organizationName}
            onChange={handleUpdateName}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'sybaseCustomersInput' })}</label>
          <ArrayNumberInput
            value={organizationDetails.sybaseCustomers}
            onChangeValue={handleUpdateCustomerIds}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'productsInput' })}</label>
          <ArrayNumberInput
            value={organizationDetails.products}
            onChangeValue={handleUpdateProductsValue}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{t({ id: 'ownerPartyIDs' })}</label>
          <ArrayNumberInput
            value={organizationDetails.ownerPartyIDs}
            onChangeValue={handleUpdateOwnerPartyIds}
          />
        </Layout>
        <Spacer height="16" />
        <Layout horizontal>
          <DatePickerContainer>
            <label>{t({ id: 'fromDate' })}</label>
            <DatePicker
              onDateChanged={handleUpdateFromDate}
              selectedDate={
                organizationDetails.fromDate
                  ? parse(organizationDetails.fromDate, 'd.M.yyyy', new Date())
                  : undefined
              }
            />
            {organizationDetails.fromDate && (
              <TrashIconStyled
                onClick={() => handleUpdateFromDate(null)}
                src={TrashIcon}
              />
            )}
          </DatePickerContainer>
          <Spacer width="24" />
          <DatePickerContainer>
            <label>{t({ id: 'toDate' })}</label>
            <DatePicker
              onDateChanged={handleUpdateToDate}
              selectedDate={
                organizationDetails.toDate
                  ? parse(organizationDetails.toDate, 'd.M.yyyy', new Date())
                  : undefined
              }
            />
            {organizationDetails.toDate && (
              <TrashIconStyled
                onClick={() => handleUpdateToDate(null)}
                src={TrashIcon}
              />
            )}
          </DatePickerContainer>
        </Layout>
      </Layout>
    </Dialog>
  )
}

ModifyOrganizationDialog.displayName = 'ModifyOrganizationDialog'
export default ModifyOrganizationDialog
