import React, { useContext, useEffect, useState } from 'react'
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import {
  useThemeProvider,
  DefaultThemeConfig,
  ThemeContext,
} from '@di-nxt/components'
import { useIntl } from 'react-intl'

import { GlobalStyle } from './styles'
import './App.css'

import HomepageContainer from './containers/Homepage'
import AccountManagementContainer from './containers/AccountManagement'
import CostTrackerContainer from './containers/CostTracker'
import TableauTemplatesContainer from './containers/Tableau/TableauTemplates'
import TableauEmbeddedViewContainer from './containers/Tableau/EmbeddedView'
import OrganizationSharedDataContainer from './containers/OrganizationSharedData'
import WorkspaceContainer from './containers/Workspace'
import WorkspaceHomeContainer from './containers/Workspace/WorkspaceHome'
import WorkspaceUsersContainer from './containers/Workspace/WorkspaceUsers'
import WorkspaceSettingsContainer from './containers/Workspace/WorkspaceSettings'
import WorkspaceDataContainer from './containers/Workspace/WorkspaceData'
import CreateWorkspaceWizard from './components/Dialogs/CreateWorkspaceWizard'
import Organizations from './containers/Organizations'
import OrganizationUsers from './containers/OrganizationUsers'
import { AdminModifyUserContainer } from './containers/Admin'
import PrivacyPolicy from './containers/PrivacyPolicy'

import ProtectedRoute from './components/ProtectedRoute'

import { UserRoles } from './types/user.type'

import SnackbarProvider from './providers/snackbar.provider'
import ApiProvider from './providers/api.provider'
import AppStateProvider, {
  AppStateContext,
} from './providers/app.state.provider'

import { RoutePathes } from './constants/routes'
import { Modules } from './constants/modules'
import WorkspaceTableauTemplatesContainer from './containers/Workspace/WorkspaceTableau'
import PageHeaderProvider from './providers/pageHeader.provider'
import { initAuth } from './auth/keycloak'

const App: React.FC = () => {
  // Initialize theme
  const theme = useThemeProvider('day', DefaultThemeConfig)
  // Keycloak hook
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const init = async  () => {
      await initAuth()
      setIsInitialized(true)
    }

    init()
  }, [])

  /**
   * For the time being while the keycloak is not initialized
   * 1 - TODO ask for UI design
   */
  if (!isInitialized) {
    return <>Loading</>
  }

  const Root = () => {
    // Context api to get the state of the dialogs
    const { openDialogs } = useContext(AppStateContext)

    return (
      <SnackbarProvider>
        <PageHeaderProvider>
          <ApiProvider>
            <GlobalStyle />
            <Outlet />
            {openDialogs.createWorkspaceDialog && <CreateWorkspaceWizard />}
          </ApiProvider>
        </PageHeaderProvider>
      </SnackbarProvider>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Root />}>
        <Route path={RoutePathes.Index}>
          <Route
            index
            element={
              <ProtectedRoute>
                <HomepageContainer />
              </ProtectedRoute>
            }
            handle={{
              title: () => t({ id: 'title.workspaces' }),
              module: () => Modules.Workspaces,
            }}
          />

          <Route
            element={
              <ProtectedRoute isAccessAllowed={(isDiAdmin) => isDiAdmin} />
            }
          >
            <Route
              path={RoutePathes.Organizations}
              element={<Organizations />}
              handle={{
                title: () => t({ id: 'title.organizations' }),
                crumb: () => t({ id: 'breadcrumbs.organizations' }),
                module: () => Modules.Organizations,
              }}
            />
            {process.env.REACT_APP_TABLEAU_ENABLED === 'true' && (
              <Route
                path={RoutePathes.Tableau}
                element={<TableauTemplatesContainer />}
                handle={{
                  title: () => t({ id: 'title.organization.tableau' }),
                  crumb: () => t({ id: 'breadcrumbs.organization.tableau' }),
                  module: () => Modules.Tableau,
                }}
              />
            )}
          </Route>

          <Route
            element={
              <ProtectedRoute
                isAccessAllowed={(isDiAdmin, role) =>
                  isDiAdmin || role === UserRoles.admin
                }
              />
            }
          >
            <Route
              path={RoutePathes.OrganizationCostTracker}
              element={<CostTrackerContainer />}
              handle={{
                title: () => t({ id: 'title.organization.costTracker' }),
                crumb: () => t({ id: 'breadcrumbs.organization.costTracker' }),
                module: () => Modules.CostTracker,
              }}
            />
          </Route>

          <Route
            path={RoutePathes.OrganizationUsers}
            element={
              <ProtectedRoute>
                <OrganizationUsers />
              </ProtectedRoute>
            }
            handle={{
              title: () => t({ id: 'title.organization.users' }),
              crumb: () => t({ id: 'breadcrumbs.organization.users' }),
              module: () => Modules.Users,
            }}
          />
          <Route
            path={RoutePathes.OrganizationUsersOld}
            element={
              <Navigate to={`/${RoutePathes.OrganizationUsers}`} replace />
            }
          />

          <Route
            path={RoutePathes.OrganizationSharedData}
            element={
              <ProtectedRoute>
                <OrganizationSharedDataContainer />
              </ProtectedRoute>
            }
            handle={{
              title: () => t({ id: 'title.organization.sharedData' }),
              crumb: () => t({ id: 'breadcrumbs.organization.sharedData' }),
              module: () => Modules.SharedData,
            }}
          />

          <Route
            path={`${RoutePathes.Workspace}/:workspaceId`}
            element={
              <ProtectedRoute>
                <WorkspaceContainer />
              </ProtectedRoute>
            }
            handle={{
              title: () => '{workspaceName}',
              crumb: () => '{workspaceName}',
              module: () => Modules.Workspaces,
            }}
          >
            <Route index element={<WorkspaceHomeContainer />} />
            <Route
              path={RoutePathes.Data}
              element={<WorkspaceDataContainer />}
              handle={{ crumb: () => t({ id: 'breadcrumbs.workspace.data' }) }}
            />
            {process.env.REACT_APP_TABLEAU_ENABLED === 'true' && (
              <Route
                path={RoutePathes.Tableau}
                element={<WorkspaceTableauTemplatesContainer />}
                handle={{
                  crumb: () => t({ id: 'breadcrumbs.workspace.tableau' }),
                }}
              />
            )}
            <Route
              path={RoutePathes.Users}
              element={<WorkspaceUsersContainer />}
              handle={{ crumb: () => t({ id: 'breadcrumbs.workspace.users' }) }}
            />
            <Route
              path={RoutePathes.Settings}
              element={<WorkspaceSettingsContainer />}
              handle={{
                crumb: () => t({ id: 'breadcrumbs.workspace.settings' }),
              }}
            />
          </Route>

          <Route
            path={RoutePathes.Account}
            element={<ProtectedRoute />}
            handle={{
              title: () => t({ id: 'title.account' }),
              crumb: () => t({ id: 'breadcrumbs.account' }),
              module: () => Modules.Account,
            }}
          >
            <Route index element={<AccountManagementContainer />} />
            <Route
              path={RoutePathes.PrivacyPolicy}
              element={<PrivacyPolicy />}
              handle={{
                crumb: () => t({ id: 'breadcrumbs.account.privacyPolicy' }),
              }}
            />
          </Route>

          <Route
            path={`${RoutePathes.ModifyUser}/:userId`}
            element={
              <ProtectedRoute
                isAccessAllowed={(isDiAdmin, role) =>
                  isDiAdmin || role === UserRoles.admin
                }
              >
                <AdminModifyUserContainer />
              </ProtectedRoute>
            }
            handle={{
              title: () => t({ id: 'title.modifyUser' }),
              crumb: () => t({ id: 'breadcrumbs.modifyUser' }),
            }}
          />

          <Route
            path={RoutePathes.TableauView}
            element={
              <ProtectedRoute>
                <TableauEmbeddedViewContainer />
              </ProtectedRoute>
            }
            handle={{
              title: () => '{tableauViewName}',
              crumb: () => '{tableauViewName}',
            }}
          />
        </Route>

        <Route path="*" element={<Navigate to={RoutePathes.Index} replace />} />
      </Route>,
    ),
  )

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>
        <AppStateProvider>
          <RouterProvider router={router} />
        </AppStateProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

App.displayName = 'App'

export default App
