import { LayoutProps } from '@di-nxt/components'

/**
 *
 * Common props for components
 */
export const CardProps: LayoutProps = {
  backgroundColor: 'background_mono_primary',
  borderRadius: 'medium',
  borderWidth: 'hairline',
  borderStyle: 'solid',
  borderColor: 'border_mono_10',
}
