import React, { useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Layout, Text, Button, Table, BaseSpinner } from '@di-nxt/components'

import AddUserDialog from '../../components/Dialogs/AddUserDialog'
import TableControls from './TableControls'
import DeleteUserConfirm from '../../components/Dialogs/DeleteUserConfirm'

import { AppStateContext } from '../../providers/app.state.provider'
import { ApiContext } from '../../providers/api.provider'

import { useDefinitions, withHeader, withSidebar } from '../../hooks'
import { User, UserRoles } from '../../types/user.type'
import { TextNoWrap, StyledTableContainer } from './style'
import useTableauOrganizationData from '../../hooks/api/useTableauOrganizationData'
import useTableauUsers from '../../hooks/api/useTableauUsers'

/**
 *
 * The component renders list of users:
 * - If logged in user is DI Admin, we display all the users
 * - Otherwise we list users for the organization the admin belongs to
 */
const OrganizationUsers: React.FC = () => {
  // Internationalization Hook
  const { formatMessage: t } = useIntl()
  // Context to show or hide dialog for adding user to workspace
  const { manageDialog, openDialogs } = useContext(AppStateContext)
  // Api context to attach workspace users
  const {
    organizationService,
    organizationUserService,
    user,
    selectedOrganization,
  } = useContext(ApiContext)
  // Organization service hook
  const { getOrganizations } = organizationService
  const {
    getOrganizationUsers,
    loadingUsers,
    users,
    deleteOrganizationUser,
    deletingUser,
    updateOrganizationUser,
    filters,
  } = organizationUserService

  const {
    folder: tableauData,
    loading: loadingTableauData,
  } = useTableauOrganizationData(selectedOrganization?.organizationId!, {
    disabled:
      process.env.REACT_APP_TABLEAU_ENABLED !== 'true' || !selectedOrganization,
  })
  const {
    users: tableauUsers,
    loading: loadingTableauUsers,
    assignUserRole: assignTableauUserRole,
  } = useTableauUsers(tableauData?.siteContentUrl!, {
    disabled: process.env.REACT_APP_TABLEAU_ENABLED !== 'true' || !tableauData,
  })

  // Attach hooks
  const { getOrganizationUsersTableDef } = useDefinitions()

  // Use effect hook on filters
  useEffect(() => {
    if (filters) {
      getOrganizationUsers(filters)
    }
  }, [filters]) // eslint-disable-line

  /**
   *
   * On load, get the workspace users
   */
  useEffect(() => {
    if (user?.userId) {
      getOrganizationUsers()
    }
  }, []) //eslint-disable-line

  /**
   *
   * if the user is di admin also load organizations
   */
  useEffect(() => {
    if (user?.diAdmin) {
      getOrganizations()
    }
  }, []) //eslint-disable-line

  /**
   *
   * Trigger state update to open add users to workspace dialog
   */
  const handleOpenAddUserDialog = () => manageDialog({ addNewUserDialog: true })

  /**
   *
   * Trigger to close the delete user dialog
   */
  const handleCloseDeleteUserDialog = () =>
    manageDialog({ deleteUserConfirm: false, deleteUser: undefined })

  /**
   *
   * Delete the account of a user
   */
  const handleDeleteUserAccount = (userToDelete?: User) => {
    if (userToDelete && !deletingUser) {
      deleteOrganizationUser(userToDelete.userId).then(() => {
        manageDialog({ deleteUserConfirm: false, deleteUser: undefined })
      })
    }
  }

  const sortedUsers = useMemo(() => {
    return users?.sort((a, b) => a.email?.localeCompare(b.email)) ?? []
  }, [users])

  /**
   *
   * Render the table if not loading the users
   * Else return the spinner
   */
  const renderTable = () => {
    if (loadingUsers || loadingTableauData || loadingTableauUsers) {
      return (
        <Layout width="100%" align="center" justify="center" minHeight="300px">
          <BaseSpinner size={32} />
        </Layout>
      )
    } else {
      return (
        <Layout width="100%" backgroundColor="background_mono_primary">
          <Table
            columns={getOrganizationUsersTableDef(
              {
                isTableauEnabled:
                  process.env.REACT_APP_TABLEAU_ENABLED === 'true' &&
                  !!tableauData,
                tableauUsers,
              },
              {
                updateOrganizationUser,
                assignTableauUserRole,
              },
            )}
            data={sortedUsers}
          />
        </Layout>
      )
    }
  }

  return (
    <Layout paddingTop="32" vertical>
      <Layout horizontal justify="space-between">
        <Layout maxWidth={1000}>
          <Text variant="paragraph_16_default">
            {t({ id: 'organizationUsersDescription' })}
          </Text>
        </Layout>

        {(user?.diAdmin || user?.role.name === UserRoles.admin) && (
          <Layout paddingLeft="96">
            <TextNoWrap>
              <div data-cy="invite-users-button">
                <Button
                  title={t({ id: 'inviteUsers' })}
                  leftIcon="plus"
                  onPress={handleOpenAddUserDialog}
                  state={loadingUsers ? 'Disabled' : 'Default'}
                />
              </div>
            </TextNoWrap>
          </Layout>
        )}
      </Layout>
      <Layout width="100%">
        <TableControls />
      </Layout>
      <Layout paddingTop="24" width="100%">
        <StyledTableContainer>{renderTable()}</StyledTableContainer>
      </Layout>
      {openDialogs.addNewUserDialog && <AddUserDialog />}
      {openDialogs.deleteUserConfirm && openDialogs.deleteUser && (
        <DeleteUserConfirm
          onOk={handleDeleteUserAccount}
          onCancel={handleCloseDeleteUserDialog}
          user={openDialogs.deleteUser}
        />
      )}
    </Layout>
  )
}

OrganizationUsers.displayName = 'OrganizationUsers'
export default withSidebar(withHeader(OrganizationUsers))
