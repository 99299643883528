import React, { useContext, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Layout,
  Icon,
  Text,
  Popup,
  LayoutProps,
  Button,
  Spacer,
} from '@di-nxt/components'

import { RedshiftUserErrorProps } from './RedshiftUserError.type'
import { ApiContext } from '../../providers/api.provider'
import { ModifyUserInput } from '../../types/user.type'

/**
 *
 * Component that renders the workspace redshift error
 */
const RedshiftUserError: React.FC<RedshiftUserErrorProps> = ({
  user,
  location,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Use ref for popup
  const popupRef = useRef(null)

  // API context
  const { organizationUserService, user: loggedInUser } = useContext(ApiContext)
  const {
    updateOrganizationUser,
    getOrganizationUsers,
  } = organizationUserService

  // Flag to determine whether creating user failed
  const creationFailed =
    user.userAccountCreated?.Status === 'FAILED' ||
    typeof user.userAccountCreated === 'string'
  const creating = !('userAccountCreated' in user)

  // Common props
  const popupProps: LayoutProps = {
    backgroundColor: 'background_mono_primary',
    padding: '16',
    borderRadius: 'small',
    shadow: true,
    vertical: true,
  }

  /**
   *
   * Resolve the error manually
   */
  const handleResolveError = () => {
    const payload: ModifyUserInput = {
      userAccountCreated: {
        Status: 'FINISHED',
        TriggeredManually: true,
        TriggeredByUser: loggedInUser?.userId ?? '',
      },
    }
    updateOrganizationUser(payload, user.userId).then(() => {
      getOrganizationUsers()
    })
  }

  /**
   *
   * Render and return the resolution button
   */
  const renderResolveIssue = () => (
    <Button
      title={t({ id: 'markAsResolved' })}
      size="Small"
      onPress={handleResolveError}
    />
  )

  // If it failed and location is table
  if (creationFailed && location === 'table') {
    return (
      <Popup
        trigger={
          <div ref={popupRef}>
            <Layout align="center" justify="center">
              <Icon
                size="16"
                name="warning"
                color="background_warning_default"
              />
            </Layout>
          </div>
        }
      >
        {() => (
          <Layout {...popupProps} maxWidth="250px">
            <Text>
              <FormattedMessage
                id="userRedshiftError"
                values={{
                  br: <br />,
                  adminMessage: loggedInUser?.diAdmin ? (
                    <FormattedMessage id="redshiftErrorAdmin" />
                  ) : (
                    <FormattedMessage id="organizationRedshiftErrorUser" />
                  ),
                }}
              />
            </Text>
            {loggedInUser?.diAdmin && (
              <>
                <Spacer height="8" />
                {renderResolveIssue()}
              </>
            )}
          </Layout>
        )}
      </Popup>
    )
  }

  // If the workspace is being created
  if (creating && location === 'table') {
    return (
      <Popup trigger={<Icon size="16" name="warning" />}>
        {() => (
          <Layout {...popupProps} maxWidth="250px">
            <Text>
              <FormattedMessage id="userRedshiftCreating" />
            </Text>
          </Layout>
        )}
      </Popup>
    )
  }

  return null
}

RedshiftUserError.displayName = 'RedshiftUserError'
export default RedshiftUserError
