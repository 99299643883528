import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { Button, Text, Layout } from '@di-nxt/components'
import { ApiContext } from '../../../providers/api.provider'

/**
 *
 * A child component that renders password reset text and button
 */
const PasswordReset: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Api context to send password reset link to the user
  const { organizationUserService, user } = useContext(ApiContext)
  // Modify user
  const { updateOrganizationUser, updatingUser } = organizationUserService

  /**
   *
   * Navigate to keycloak to handle password reset
   */
  const handlePasswordReset = () => {
    if (user) {
      updateOrganizationUser(
        { actions: ['UPDATE_PASSWORD'] },
        user.userId,
        t({ id: 'changePasswordSent' }),
        t({ id: 'errorChangePassword' }),
      )
    }
  }

  return (
    <Layout
      backgroundColor="background_mono_primary"
      borderColor="background_mono_tertiary"
      borderStyle="solid"
      borderWidth="hairline"
      borderRadius="medium"
      vertical
      padding="40"
    >
      <Layout>
        <Text variant="inline_16_emphasis">{t({ id: 'changePassword' })}</Text>
      </Layout>
      <Layout paddingTop="24">
        <Text variant="paragraph_12_default">
          {t({ id: 'changePasswordDescription' })}
        </Text>
      </Layout>
      <Layout paddingTop="24">
        <Button
          title={t({ id: 'goToPasswordReset' })}
          kind="Inverse"
          leftIcon="globe"
          onPress={handlePasswordReset}
          spinning={updatingUser}
        />
      </Layout>
    </Layout>
  )
}

PasswordReset.displayName = 'PasswordReset'
export default PasswordReset
