import React, { useContext } from 'react'
import { Dialog, Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'

import { DeleteUserConfirmProps } from './index.type'
import { ApiContext } from '../../../providers/api.provider'
import StatusSpinner from '../../StatusSpinner'

/**
 *
 * Render the dialog to confirm delete action on workspace
 */
const DeleteUserConfirm: React.FC<DeleteUserConfirmProps> = ({
  onOk: handleOk,
  user,
  onCancel: handleCancel,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Context API
  const { organizationUserService } = useContext(ApiContext)
  const { deletingUser } = organizationUserService

  /**
   *
   * Get the title for deleting the user account
   * If the user cannot be deleted then a different message is presented
   */
  const getTitle = (): string => {
    if (user && user.lastSeen) {
      return t({ id: 'cannotDeleteAccount' })
    }
    return t({ id: 'deleteAccount' })
  }

  /**
   *
   * Get the description for deleting the user account dialog
   * @returns {String}
   */
  const getDescription = (): string => {
    if (user && user.lastSeen) {
      return t(
        { id: 'cannotDeleteAccountDescription' },
        {
          userName: `${user.firstName} ${user.lastName}`,
          lastSeen: format(new Date(user.lastSeen), 'd.M.yyyy'),
        },
      )
    }
    return t(
      { id: 'deleteUserAccountDescription' },
      { userName: `${user?.firstName} ${user?.lastName}` },
    )
  }

  /**
   *
   * Call the HOC and delete the user account
   */
  const handleDeleteUserAccount = () => handleOk(user)

  return (
    <Dialog
      title={getTitle()}
      onOkButton={
        user && !user.lastSeen ? handleDeleteUserAccount : handleCancel
      }
      okButtonDisabled={deletingUser}
      okButtonTitle={
        user && !user.lastSeen ? t({ id: 'deleteAccount' }) : t({ id: 'close' })
      }
      {...(user &&
        !user.lastSeen && {
          cancelButtonTitle: t({ id: 'cancel' }),
          onCancelButton: handleCancel,
        })}
    >
      <Text variant="paragraph_16_default">{getDescription()}</Text>
      {deletingUser && (
        <Layout position="absolute" bottom={-54} left={28}>
          <StatusSpinner message={t({ id: 'deletingUser' })} />
        </Layout>
      )}
    </Dialog>
  )
}

DeleteUserConfirm.displayName = 'DeleteUserConfirm'
export default DeleteUserConfirm
