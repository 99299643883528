import React from 'react'
import { BaseSpinner, Layout, Spacer } from '@di-nxt/components'

import WorkspaceUsersTableControls from './WorkspaceUsersTableControls'
import WorkspaceUsersTable from './WorkspaceUsersTable'
import { WorkspaceUsersProps } from './index.type'

/**
 *
 * Renders the table for workspace users
 */
const WorkspaceUsers: React.FC<WorkspaceUsersProps> = ({
  workspaceUsers,
  loadingWorkspaceUsers,
  canEditUsers,
}) => {
  return (
    <Layout vertical paddingTop="64">
      <WorkspaceUsersTableControls canEditUsers={canEditUsers} />
      {loadingWorkspaceUsers && (
        <Layout width="100%" align="center" justify="center" minHeight="300px">
          <BaseSpinner size={32} />
        </Layout>
      )}
      {workspaceUsers && !loadingWorkspaceUsers && (
        <>
          <Spacer height="24" />
          <WorkspaceUsersTable
            workspaceUsers={workspaceUsers}
            canEditUsers={canEditUsers}
          />
        </>
      )}
    </Layout>
  )
}

WorkspaceUsers.displayName = 'WorkspaceUsers'
export default WorkspaceUsers
