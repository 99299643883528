import { Icon, Layout, Spacer } from '@di-nxt/components'
import NavigationLink from '../NavigationLink'
import YourAccountSettingsIcon from '../../../assets/icons/your_account_settings_icon.svg'
import LogoutIcon from '../../../assets/icons/logout_icon.svg'
import { useIntl } from 'react-intl'
import { useUser } from '../../../hooks'
import NewsPopup from './NewsPopup'
import { useState } from 'react'
import { RoutePathes } from '../../../constants/routes'
import { Modules } from '../../../constants/modules'
import useModule from '../useModule'

const BottomNavigation = () => {
  const { formatMessage: t } = useIntl()
  const { logout } = useUser()
  const module = useModule()
  const [isNewsWidgetOpened, setIsNewsWidgetOpened] = useState(false)

  return (
    <Layout vertical position="relative">
      <Layout align="center" justify="flex-start">
        <NavigationLink
          id="whats-new-link"
          icon={
            <Icon name="favourite" size="24" color="background_mono_primary" />
          }
          onClick={() => setIsNewsWidgetOpened(true)}
          text={t({ id: 'news' })}
        ></NavigationLink>
      </Layout>
      <Spacer height="6" />
      <NavigationLink
        icon={YourAccountSettingsIcon}
        text={t({ id: 'yourAccountSettings' })}
        url={`/${RoutePathes.Account}`}
        selected={module === Modules.Account}
      />
      <Spacer height="6" />
      <NavigationLink
        icon={LogoutIcon}
        onClick={logout}
        text={t({ id: 'logout' })}
      />
      <NewsPopup
        isOpen={isNewsWidgetOpened}
        setIsOpen={setIsNewsWidgetOpened}
      />
    </Layout>
  )
}

export default BottomNavigation
