import React from 'react'
import { Layout, Text } from '@di-nxt/components'
import { InputCounterProps } from './index.type'

/**
 *
 * Renders the label and text length counter in a row
 */
const InputCounter: React.FC<InputCounterProps> = ({
  label,
  maxLength,
  value,
}) => (
  <Layout horizontal justify="space-between" paddingBottom="6">
    <label>{label}</label>
    <Text
      textColor="content_mono_disabled"
      variant="inline_12_default"
    >{`${value.length}/${maxLength}`}</Text>
  </Layout>
)

InputCounter.displayName = 'InputCounter'
export default InputCounter
