import React from 'react'
import { Layout, Text, Button, Spacer } from '@di-nxt/components'

import { WizardProps } from './index.type'
import Step from './Step'

/**
 *
 * Wizard is currently displayed for workspace creation
 * Later on may be used somewhere else
 */
const Wizard: React.FC<WizardProps> = ({
  steps,
  title,
  currentStep,
  okButtonTitle,
  okButtonDisabled,
  okButtonLeftIcon,
  okButtonSpining,
  onPressOkButton,
  cancelButtonTitle,
  cancelButtonLeftIcon,
  onPressCancelButton,
}) => {
  /**
   *
   * Render the steps
   */
  const renderSteps = () => {
    return steps.map((step, index) => (
      <Step
        key={`step-${step.title}-${index}`}
        step={step}
        index={index}
        totalSteps={steps.length}
        currentStep={currentStep}
      />
    ))
  }

  return (
    <Layout horizontal>
      <Layout
        width="256px"
        vertical
        paddingTop="32"
        paddingBottom="32"
        paddingRight="28"
        paddingLeft="28"
        height="100%"
        left={0}
        top={0}
        backgroundColor="background_mono_secondary"
      >
        <Text variant="inline_20_emphasis">{title}</Text>
        <Layout
          width="200px"
          vertical
          paddingTop="56"
          justify="center"
          alignSelf="center"
        >
          {renderSteps()}
        </Layout>
      </Layout>
      <Layout
        minWidth="400px"
        maxWidth="450px"
        paddingLeft="28"
        paddingRight="28"
        paddingTop="32"
        justify="space-between"
        vertical
      >
        {steps[currentStep - 1].component}
        {okButtonTitle || cancelButtonTitle ? (
          <Layout horizontal justify="flex-end" paddingTop="40">
            {cancelButtonTitle && (
              <>
                <Button
                  title={cancelButtonTitle}
                  kind="Secondary"
                  onPress={onPressCancelButton}
                  {...(cancelButtonLeftIcon && {
                    leftIcon: cancelButtonLeftIcon,
                  })}
                />
                <Spacer width="12" />
              </>
            )}
            {okButtonTitle && (
              <Button
                title={okButtonTitle}
                onPress={onPressOkButton}
                {...(okButtonLeftIcon && { leftIcon: okButtonLeftIcon })}
                state={okButtonDisabled ? 'Disabled' : 'Default'}
                spinning={okButtonSpining}
              />
            )}
          </Layout>
        ) : null}
      </Layout>
    </Layout>
  )
}

Wizard.displayName = 'Wizard'

export default Wizard
