import React, { useContext } from 'react'
import { Layout } from '@di-nxt/components'
import { FormattedMessage } from 'react-intl'
// Other components
import InformationPanel from '../../../InformationPanel'
import WorkspaceUserSelection from '../../../WorkspaceUserSelection'

import { AddUsersToWorksapceProps } from './index.type'
import {
  WorkspaceUserInput,
  WorkspaceUserRole,
} from '../../../../types/workspace.type'
import { User } from '../../../../types/user.type'
import { ApiContext } from '../../../../providers/api.provider'
import {
  InformationPanelAccent,
  InformationPanelWarn,
} from '../../../../constants/layoutProps'

/**
 *
 * Create workspace wizard step 1
 * Allows entry of name and description for a new workspace entry
 */
const AddUsersToWorksapce: React.FC<AddUsersToWorksapceProps> = ({
  workspaceUsers,
  selectedRole,
  onAddWorkspaceUser,
  onRemoveWorkspaceUser,
  onSelectRole,
}) => {
  /**
   *
   * Context api, get the user information and the selected organisation information
   */
  const { user, selectedOrganization } = useContext(ApiContext)

  /**
   *
   * Handler to open user selection list
   */
  const handleAddUserToWorkspace = (user: WorkspaceUserInput) => {
    onAddWorkspaceUser({
      username: user.username,
      userId: user.userId,
      workspaceRole: WorkspaceUserRole.viewer,
      email: user.email,
    })
  }

  /**
   *
   * Get the user role and user information
   */
  const handleSelectRole = (role: WorkspaceUserRole, user?: User) => {
    onSelectRole(role, user)
  }

  /**
   *
   * Renders the add users description
   */
  const renderAddUsersDescription = () => {
    /** If the user is DI ADMIN and they are creating ws for another organisation */
    const mustAddOwner =
      user?.diAdmin &&
      user.organizationId !== selectedOrganization?.organizationId

    const panelProps = mustAddOwner
      ? {
          ...InformationPanelWarn,
          disableClose: true,
          panelId: 'add_workspace_users_warning',
        }
      : {
          ...InformationPanelAccent,
          panelId: 'add_workspace_users',
        }

    const panelMessage = mustAddOwner ? (
      <FormattedMessage
        id="messageAddUsersToWorkspaceOverride"
        values={{ orgName: selectedOrganization?.organizationName }}
      />
    ) : (
      <FormattedMessage id="messageAddUsersToWorkspace" />
    )

    return (
      <InformationPanel
        {...panelProps}
        icon="warning"
        marginBottom="24"
        message={() => panelMessage}
      />
    )
  }

  return (
    <Layout width="100%" height="auto">
      <WorkspaceUserSelection
        users={workspaceUsers}
        excludeUsers={user ? [user] : []}
        onAddWorkspaceUser={handleAddUserToWorkspace}
        onRemoveWorkspaceUser={onRemoveWorkspaceUser}
        selectedRole={selectedRole}
        onSelectRole={handleSelectRole}
        renderBefore={renderAddUsersDescription}
        hideDescription
      />
    </Layout>
  )
}

AddUsersToWorksapce.displayName = 'AddUsersToWorksapce'

export default AddUsersToWorksapce
