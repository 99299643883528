import React, { useContext, useState, useEffect } from 'react'
import { Input, Layout, Spacer, Text, Editor, Button } from '@di-nxt/components'
import { FormattedMessage, useIntl } from 'react-intl'
import { CardProps } from '../../../../utils/commonProps'
import { ApiContext } from '../../../../providers/api.provider'

/**
 *
 * Component that renders editable workspace details
 * The workspace details can only be edited by the owner(s)
 */
const WorkspaceDetails: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Attach workspace context
  const { workspaceService } = useContext(ApiContext)
  const { workspace, updateWorkspace, updatingWorkspace } = workspaceService

  // State to update description of the workspace
  const [workspaceDescription, setWorkspaceDescription] = useState<
    string | undefined
  >(undefined)

  // Workspace name may also be updated
  const [workspaceName, setWorkspaceName] = useState<string | undefined>(
    undefined,
  )

  // Use effect hook to set workspace description as it becomes available
  useEffect(() => {
    if (!workspaceDescription && workspace?.workspaceDescription) {
      setWorkspaceDescription(workspace.workspaceDescription)
    }

    if (!workspaceName && workspace?.workspaceName) {
      setWorkspaceName(workspace.workspaceName)
    }
  }, [workspace, workspaceDescription, workspaceName])

  // Handle update description locall
  const handleUpdateDescription = (value: string) => {
    setWorkspaceDescription(value)
  }

  /**
   *
   * Call the service to update workspace
   */
  const handleUpdateWorkspace = () => {
    if (workspace?.workspaceId) {
      updateWorkspace(
        workspace.workspaceId,
        { workspaceDescription, workspaceName },
        true,
      )
    }
  }

  /**
   *
   * Update the locally stored workspace name
   */
  const handleUpdateWorkspaceName = (value: string) => {
    setWorkspaceName(value)
  }

  return (
    <Layout vertical>
      <Layout {...CardProps} vertical>
        <Layout
          paddingTop="32"
          paddingBottom="32"
          paddingLeft="40"
          paddingRight="40"
          vertical
        >
          <Text variant="inline_20_emphasis">
            <FormattedMessage id="workspaceDetails" />
          </Text>
          <Spacer height="32" />
          <Layout horizontal justify="space-between">
            <label>
              <FormattedMessage id="workspaceName" />
            </label>
            <Text variant="inline_12_default" textColor="content_mono_disabled">
              {workspaceName?.length ?? 0} / 15
            </Text>
          </Layout>
          <Input value={workspaceName} onChange={handleUpdateWorkspaceName} />
          <Spacer height="24" />
          <Layout horizontal justify="space-between">
            <label>
              <FormattedMessage id="workspaceKey" />
            </label>
            <Text variant="inline_12_default" textColor="content_mono_disabled">
              {workspace?.workspaceKey?.length ?? 0} / 20
            </Text>
          </Layout>
          <Input state="Disabled" value={workspace?.workspaceKey} />
          <Spacer height="24" />
          <Layout horizontal justify="space-between">
            <label>
              <FormattedMessage id="description" />
            </label>
            <Text variant="inline_12_default" textColor="content_mono_disabled">
              {workspaceDescription?.length ?? 0} / 400
            </Text>
          </Layout>

          <Editor
            rows={5}
            value={workspaceDescription}
            onChange={handleUpdateDescription}
          />
          <Spacer height="24" />
          <Layout horizontal justify="flex-end">
            <Button
              kind="Inverse"
              title={t({ id: 'save' })}
              onPress={handleUpdateWorkspace}
              spinning={updatingWorkspace}
              state={
                workspaceDescription === workspace?.workspaceDescription &&
                workspaceName === workspace?.workspaceName
                  ? 'Disabled'
                  : 'Default'
              }
            />
          </Layout>
        </Layout>
      </Layout>
      <Spacer height="24" />
    </Layout>
  )
}

WorkspaceDetails.displayName = 'WorkspaceDetails'
export default WorkspaceDetails
