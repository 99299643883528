import React from 'react'
import { FormattedMessage } from 'react-intl'
import InformationPanel from '../../../../components/InformationPanel'

/**
 *
 * Workspace settings page instructions and information
 */
const WorkspaceSettingsIntroduction: React.FC = () => {
  return (
    <InformationPanel
      panelId="workspace_settings_introduction"
      message={() => (
        <FormattedMessage
          id="workspaceSettingsIntroduction"
          values={{ br: <br /> }}
        />
      )}
      backgroundColor="background_warning_light"
      borderColor="border_warning_default"
      textColor="content_warning_default"
      icon="warning"
    />
  )
}

WorkspaceSettingsIntroduction.displayName = 'WorkspaceSettingsIntroduction'
export default WorkspaceSettingsIntroduction
