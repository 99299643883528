import { Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { CaptionText } from '../style'
import { Container } from './style'
import ArrowDownIcon from '../../../assets/icons/arrow_down_icon.svg'

type Props = {
  title: string
  isDropdown?: boolean
}

const OrganizationTrigger: React.FC<Props> = ({ title }) => {
  const { formatMessage: t } = useIntl()

  return (
    <Container>
      <Layout vertical>
        <CaptionText>{t({ id: 'org' }).toUpperCase()}</CaptionText>
        <Layout justify="space-between" align="flex-start" paddingTop="12">
          <Text
            variant="inline_16_emphasis"
            textColor="background_mono_primary"
          >
            {title}
          </Text>
          <img alt="diap" src={ArrowDownIcon} />
        </Layout>
      </Layout>
    </Container>
  )
}

export default OrganizationTrigger
