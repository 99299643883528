import { Icon, Spacer, Text, ThemeColorNames } from '@di-nxt/components'

type Props = {
  popupTriggerRef: React.RefObject<HTMLDivElement>
  titleColor: keyof ThemeColorNames | undefined
  title: string
}

const DefaultPopupTrigger: React.FC<Props> = ({
  popupTriggerRef,
  title,
  titleColor,
}) => (
  <div
    ref={popupTriggerRef}
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Text variant="inline_14_emphasis" textColor={titleColor}>
      {title}
    </Text>
    <Spacer width="8" />
    <Icon name="arrowDownSmall" color={titleColor} />
  </div>
)

export default DefaultPopupTrigger
