import React from 'react'
import { Avatar, Layout } from '@di-nxt/components'
import { SelectedUserProps } from './index.type'

/**
 *
 * Show an avatar for each user selected
 * The avatar will have a cross button on top right corner to remove the user
 */
const SelectedUser: React.FC<SelectedUserProps> = ({ user }) => {
  return (
    <Layout>
      <Avatar name={user.username.toUpperCase()} size="30" />
    </Layout>
  )
}

SelectedUser.displayName = 'SelectedUser'

export default SelectedUser
