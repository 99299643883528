import React, { ReactNode, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Text } from '@di-nxt/components'

import HyperlinkPopup from '../../../components/HyperlinkPopup'
import { ApiContext } from '../../../providers/api.provider'

import { StyledWelcomeContaienr } from './style'

/**
 * Welcome message and banner information
 */
const Welcome: React.FC = () => {
  /** Internationalization */
  const { formatMessage: t } = useIntl()

  /** Api context t oget the user */
  const { user, selectedOrganization } = useContext(ApiContext)

  /**
   * Return the rendering component
   */
  return (
    <StyledWelcomeContaienr>
      <h1>
        <FormattedMessage
          id="welcomeMessage"
          values={{ name: `${user?.firstName} ${user?.lastName}` }}
        />
      </h1>
      <Text variant="inline_16_default">
        <FormattedMessage
          id="workspaceIntroduction"
          values={{
            organizationName: selectedOrganization?.organizationName,
            'link-to-organization': (...chunks: ReactNode[]) => (
              <HyperlinkPopup
                hyperlinkText={t({ id: 'organization' })}
                message={t({ id: 'organizationDescription' })}
                showIcon
                icon="calendar"
              >
                {chunks}
              </HyperlinkPopup>
            ),
            'link-to-workspaces': (...chunks: ReactNode[]) => (
              <HyperlinkPopup
                hyperlinkText={t({ id: 'workspaces' })}
                message={t({ id: 'workspacesDescription' })}
                showIcon
                icon="favourite"
              >
                {chunks}
              </HyperlinkPopup>
            ),
            'link-to-tools': (...chunks: ReactNode[]) => (
              <HyperlinkPopup
                hyperlinkText={t({ id: 'tools' })}
                message={t({ id: 'toolsDescription' })}
                showIcon
                icon="settings"
              >
                {chunks}
              </HyperlinkPopup>
            ),
          }}
        />
      </Text>
    </StyledWelcomeContaienr>
  )
}

Welcome.displayName = 'Welcome'
export default Welcome
