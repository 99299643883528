import { formatMoneyAmount } from '../../../utils'
import { TierCostItem } from './types'

export const tierCostDataMock: TierCostItem[] = [
  ...[10, 25, 50, 100, 175, 275, 400].map((amount, index, array) => ({
    tier: `Tier ${index + 1}`,
    monthlyDataRows: `${index !== 0 ? array[index - 1] : 0}-${array[index]}M`,
    monthlyCostBeforeYear2024: formatMoneyAmount(5375 * (index + 2), 'NOK'),
    monthlyCostFromYear2024: formatMoneyAmount(5633 * (index + 2), 'NOK'),
  })),
  {
    tier: '> Tier 7',
    monthlyDataRows: '+150M',
    monthlyCostBeforeYear2024: `+${formatMoneyAmount(5375, 'NOK')}`,
    monthlyCostFromYear2024: `+${formatMoneyAmount(5633, 'NOK')}`,
  },
]
