import { HttpStatusCode } from 'axios'
import { useEffect, useState } from 'react'
import {
  TableauFolder,
  TableauWorkbooksProvisionRequest,
} from '../../types/tableau.api.type'
import useApi from './useApi'
import { QueryHookParams } from '../types'

const useTableauOrganizationData = (
  organizationId: string,
  params?: QueryHookParams,
) => {
  const { fetcher } = useApi()
  const [folder, setFolder] = useState<TableauFolder | null>(null)
  const [loading, setLoading] = useState<boolean>(!params?.disabled)

  useEffect(() => {
    if (params?.disabled) {
      return
    }

    setLoading(true)
    setFolder(null)

    fetcher
      .get<TableauFolder>(`/organization/${organizationId}/tableau`, {
        validateStatus: (status) =>
          status === HttpStatusCode.Ok || status === HttpStatusCode.NotFound,
      })
      .then((response) => {
        if (response.status === HttpStatusCode.NotFound) {
          setFolder(null)
        } else {
          setFolder(response.data)
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  const provisionTableau = async (
    workbooks: TableauWorkbooksProvisionRequest,
  ) => {
    return fetcher
      .post<TableauFolder>(
        `/organization/${organizationId}/tableau`,
        workbooks,
        {
          validateStatus: (status) =>
            status === HttpStatusCode.Ok ||
            status === HttpStatusCode.GatewayTimeout,
        },
      )
      .then((response) => {
        if (response.status === HttpStatusCode.GatewayTimeout) {
          setFolder({
            siteContentUrl: '',
            projectName: '',
            provisionStatus: 'Pending',
          })
        } else {
          setFolder(response.data)
        }
      })
  }

  return {
    folder,
    loading,
    provisionTableau,
  }
}

export default useTableauOrganizationData
