import React from 'react'
import { Dialog, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { DeleteWorkspaceUsersConfirmProps } from './index.type'

/**
 *
 * Render the dialog to confirm deleting users from a workspace
 */
const DeleteWorkspaceUsersConfirm: React.FC<
  DeleteWorkspaceUsersConfirmProps
> = ({ onOk: handleOk, onCancel: handleCancel }) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  return (
    <Dialog
      title={`${t({ id: 'deleteWorkspaceUsers' })}?`}
      onOkButton={handleOk}
      onCancelButton={handleCancel}
      okButtonTitle={t({ id: 'deleteWorkspaceUsers' })}
      cancelButtonTitle={t({ id: 'cancel' })}
    >
      <Text variant="paragraph_16_default">
        {t({ id: 'deleteWorkspaceUsersConfirm' })}
      </Text>
    </Dialog>
  )
}

DeleteWorkspaceUsersConfirm.displayName = 'DeleteWorkspaceUsersConfirm'
export default DeleteWorkspaceUsersConfirm
