import React, { useState, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { debounce, indexOf } from 'lodash'
import { Layout, Input, Dropdown } from '@di-nxt/components'
import { ApiContext } from '../../../providers/api.provider'

enum UserState {
  AllStates = 'All States',
  Active = 'Active',
  Deactivated = 'Deactivated',
}

const UserStateFiltersMap = {
  [UserState.AllStates]: undefined,
  [UserState.Active]: true,
  [UserState.Deactivated]: false,
}

/**
 *
 * Actions on table:
 * 1 - Search users (currently only email)
 * 2 - Filter users by state (Enabled or disabled)
 * 3 - Filter users by role (User, Admin, Editor)
 */
const TableControls: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Context to store filter states
  const { organizationUserService } = useContext(ApiContext)
  const { handleSetFilters, filters } = organizationUserService

  // Filter options for state
  const userStates = Object.values(UserState)

  const [selectedState, setSelectedState] = useState<UserState>(
    UserState.AllStates,
  )

  // Search Input
  const [searchValue, setSearchValue] = useState<string>('')

  //eslint-disable-next-line
  const processSearchQuery = useCallback(
    debounce((data: string) => {
      handleSetFilters({ ...filters, search: data })
    }, 1000),
    [filters],
  )

  // Set the search value
  const handleSetSearchValue = (value: string) => {
    setSearchValue(value)
    processSearchQuery(value)
  }

  // Selecting the state from drop down
  const handleSelectState = (index?: number) => {
    if (typeof index === 'number') {
      setSelectedState(userStates[index])
      handleSetFilters({
        ...filters,
        filterState: UserStateFiltersMap[userStates[index]],
      })
    }
  }

  return (
    <Layout horizontal width="100%" justify="space-between" paddingTop="64">
      <Layout vertical flex={0.2}>
        <label>{t({ id: 'search' })}</label>
        <Input
          rightIcon="search"
          placeholder={t({ id: 'searchOrganizationUsersPlaceholder' })}
          width={350}
          value={searchValue}
          onChange={handleSetSearchValue}
        />
      </Layout>

      <Layout horizontal justify="flex-end" flex={0.5}>
        <Layout vertical minWidth="158px">
          <label>{t({ id: 'filterByState' })}</label>
          <Layout backgroundColor="background_mono_tertiary">
            <Dropdown
              items={userStates}
              selectedIndex={indexOf(userStates, selectedState)}
              onSelectedIndexChange={handleSelectState}
            />
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

TableControls.displayName = 'OrganizationUsersTableControl'

export default TableControls
