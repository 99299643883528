import React from 'react'
import { Icon, Layout, Spacer, Text } from '@di-nxt/components'
import { DropdownItemProps } from './index.type'

/**
 *
 * User role selector item
 */
const DropdownItem: React.FC<DropdownItemProps> = ({
  item,
  selected,
  onSelect,
  showCheckmark,
}) => {
  return (
    <Layout
      borderRadius="small"
      paddingLeft="12"
      paddingTop="16"
      paddingBottom="12"
      paddingRight="16"
      cursor="pointer"
      backgroundColor={
        selected ? 'background_mono_secondary' : 'background_mono_primary'
      }
      align="center"
      hover
      key={item.key}
      onPress={onSelect.bind(null, item)}
    >
      <Layout vertical>
        <Layout>
          {item.icon && (
            <>
              {item.icon}
              <Spacer width="6" />
            </>
          )}
          <Text variant="inline_14_emphasis">{item.title}</Text>
        </Layout>
        <Layout paddingTop="6">
          <Text variant="inline_12_default" textColor="content_mono_secondary">
            {item.description}
          </Text>
        </Layout>
      </Layout>
      <Layout paddingLeft="16" centered height="100%">
        {selected && showCheckmark && <Icon name="checkmarkThick" />}
      </Layout>
    </Layout>
  )
}

DropdownItem.displayName = 'DropdownItem'

export default DropdownItem
