import React, { useContext } from 'react'
import { Layout, Text, Spacer, Icon } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { ApiContext } from '../../providers/api.provider'
import { ToolCardProps } from './index.type'
import { getLink } from '../../utils/workspaceToolsLinks'
import { Clickable } from '../../styles/ClickableLayout'
import { HoveredLayout } from '../../styles/hoveredLayout'
import NewTabIcon from '../../assets/icons/new_tab_icon.svg'

/**
 *
 * Tools are associated with a workspace
 * The component renders each individual tool for a workspace
 */
const ToolCard: React.FC<ToolCardProps> = ({ tool, organizationKey }) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  const { workspaceService, user } = useContext(ApiContext)
  /**
   *
   * Copy the text to clipboard
   */
  const handleCopyText = (text: string) => navigator.clipboard.writeText(text)

  const openLink = (toolLink: string) => {
    if (toolLink !== '') {
      window.open(toolLink, '_blank', 'noopener,noreferrer')
    }
  }

  let toolProperties: Record<string, string> = {}
  if (tool.toolName === 'Tableau') {
    toolProperties = {
      ...tool.toolProperties,
      username: user?.email ?? '',
    }
  } else if (tool.toolName === 'Amazon Redshift') {
    toolProperties = {
      ...tool.toolProperties,
      schema: `${organizationKey}_${workspaceService.workspace?.workspaceKey}`,
      username: user?.email ?? '',
    }
  }

  const toolLink =
    tool.toolName === 'Tableau'
      ? getLink(tool.toolName).replace(
          /{ORGANIZATION_KEY}_{WORKSPACE_KEY}/,
          `${organizationKey}_${workspaceService.workspace?.workspaceKey}`,
        )
      : getLink(tool.toolName)

  return (
    <Layout
      maxWidth="274px"
      width="100%"
      borderStyle="solid"
      borderRadius="medium"
      borderColor="background_mono_tertiary"
    >
      <HoveredLayout boxShadow="rgb(0 0 0 / 20%) 0px 2px 8px;">
        <Layout
          backgroundColor="background_mono_tertiary"
          vertical
          flex={0.5}
          maxWidth={250}
          minWidth={250}
          height="100%"
          borderRadius="small"
        >
          <Clickable
            cursor={toolLink === '' ? 'auto' : 'pointer'}
            onClick={openLink.bind(null, toolLink)}
          >
            <Layout
              borderRadius="medium"
              vertical
              backgroundColor="background_mono_primary"
              padding="16"
              height="80px"
            >
              <Layout height="28px" width="100%">
                <img src={tool.toolImage} alt="tool_image" height="100%" />
              </Layout>
              <Spacer height="16" />
              <Layout align="center">
                <Text variant="inline_16_emphasis">{tool.toolName}</Text>
                <Layout paddingLeft="14">
                  <img src={NewTabIcon} alt="new tab icon" />
                </Layout>
              </Layout>
              <Text variant="inline_12_default">{tool.toolCategory}</Text>
            </Layout>
          </Clickable>
          <Layout vertical padding="16">
            {Object.entries(toolProperties).map(([key, value]) => (
              <Layout vertical key={`tool-property-${key}`} paddingBottom="14">
                <Text variant="inline_12_emphasis">{t({ id: key })}</Text>
                <Layout horizontal justify="space-between">
                  <Layout overflow="hidden">
                    <Text variant="inline_12_default">{value}</Text>
                  </Layout>
                  <Layout onPress={() => handleCopyText(value)}>
                    <Icon name="copy" size="16" color="content_mono_primary" />
                  </Layout>
                </Layout>
              </Layout>
            ))}
          </Layout>
        </Layout>
      </HoveredLayout>
    </Layout>
  )
}

ToolCard.displayName = 'ToolCard'

export default ToolCard
