const LINKS: Record<string, any> = {
  Tableau: {
    production:
      'http://tableau.prod.data.aws.di.no/#/site/{ORGANIZATION_KEY}_{WORKSPACE_KEY}/home',
  },
  'Amazon Redshift': {
    development:
      'https://auth2.foundations-eun1-dev-1.eks.schibsted.io/auth/realms/dap/protocol/saml/clients/amazon-aws?RelayState=https%3A%2F%2Feu-north-1.console.aws.amazon.com%2Fsqlworkbench%2Fhome%3Fregion%3Deu-north-1%23%2Fclient',
    staging:
      'https://auth2.foundations-eun1-stg-1.eks.schibsted.io/auth/realms/dap/protocol/saml/clients/amazon-aws?RelayState=https%3A%2F%2Feu-north-1.console.aws.amazon.com%2Fsqlworkbench%2Fhome%3Fregion%3Deu-north-1%23%2Fclient',
    production:
      'https://auth2.foundations-eun1-prod-1.eks.schibsted.io/auth/realms/dap/protocol/saml/clients/amazon-aws?RelayState=https%3A%2F%2Feu-north-1.console.aws.amazon.com%2Fsqlworkbench%2Fhome%3Fregion%3Deu-north-1%23%2Fclient',
  },
}

export type Environment = 'development' | 'staging' | 'production'
export type Tools = keyof typeof LINKS

export const getLink = (toolName: string): string => {
  try {
    return (
      LINKS[toolName as Tools][
        process.env.REACT_APP_ENVIRONMENT as Environment
      ] ?? ''
    )
  } catch (error) {
    console.warn('Tool link not implemented')
    return ''
  }
}
