import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Dialog, Layout } from '@di-nxt/components'
// Other components
import { ArrayNumberInput, ArrayStringInput } from '../../ArrayInput'
// Type Definitions
import { UpdateOrganizationSharedDataDialogProps } from './index.type'
import {
  OrganizationSharedData,
  ReceivingOrganizationId,
  SendingOrganizationId,
} from '../../../types/organization.type'

/**
 *
 * Dialog for updating organization shared data
 */
const UpdateOrganizationSharedDataDialog: React.FC<
  UpdateOrganizationSharedDataDialogProps
> = ({ data, onOk: handleSubmitDialog, onCancel: handleCloseDialog }) => {
  // State to store organization shared data
  const [sharedData, setSharedData] = useState<
    OrganizationSharedData & (ReceivingOrganizationId | SendingOrganizationId)
  >(data)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Handle update property value
  const handleUpdateValue = (property: string, value: number[] | string[]) => {
    setSharedData({ ...sharedData, [property]: value })
  }

  return (
    <Dialog
      title={t({ id: 'sharedDataUpdate' })}
      okButtonTitle={t({ id: 'save' })}
      onOkButton={() => handleSubmitDialog(sharedData)}
      onCancelButton={() => handleCloseDialog()}
      cancelButtonTitle={t({ id: 'cancel' })}
    >
      <Layout vertical width="100%">
        <Layout vertical width="100%" paddingTop="18">
          <label>{`${t({ id: 'sharedTables' })} (${t({
            id: 'sharedDataInputInstruction',
          })})`}</label>
          <ArrayStringInput
            value={sharedData.tables}
            onChangeValue={(value) => handleUpdateValue('tables', value)}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{`${t({ id: 'sharedColumns' })} (${t({
            id: 'sharedDataInputInstruction',
          })})`}</label>
          <ArrayStringInput
            value={sharedData.columns}
            onChangeValue={(value) => handleUpdateValue('columns', value)}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{`${t({ id: 'sharedProducts' })} (${t({
            id: 'sharedDataInputInstruction',
          })}, ${t({ id: 'sharedDataOnlyNumbers' })})`}</label>
          <ArrayNumberInput
            value={sharedData.products}
            onChangeValue={(value) => handleUpdateValue('products', value)}
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{`${t({ id: 'sharedSybaseCustomers' })} (${t({
            id: 'sharedDataInputInstruction',
          })}, ${t({ id: 'sharedDataOnlyNumbers' })})`}</label>
          <ArrayNumberInput
            value={sharedData.sybaseCustomers}
            onChangeValue={(value) =>
              handleUpdateValue('sybaseCustomers', value)
            }
          />
        </Layout>
        <Layout vertical width="100%" paddingTop="18">
          <label>{`${t({ id: 'sharedOwnerPartyIds' })} (${t({
            id: 'sharedDataInputInstruction',
          })}, ${t({ id: 'sharedDataOnlyNumbers' })})`}</label>
          <ArrayNumberInput
            value={sharedData.ownerPartyIds}
            onChangeValue={(value) => handleUpdateValue('ownerPartyIds', value)}
          />
        </Layout>
      </Layout>
    </Dialog>
  )
}

UpdateOrganizationSharedDataDialog.displayName =
  'UpdateOrganizationSharedDataDialog'
export default UpdateOrganizationSharedDataDialog
