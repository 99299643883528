import React from 'react'
import HeaderV2 from '../../components/HeaderV2'
import { Main } from '../../styles/layout'

/**
 * React HOC which appends header to the desired component
 */
const withHeader = <P extends object>(
  Component: React.ComponentType<P>,
  BeforeMainContent?: React.ComponentType,
) =>
  class WithHeader extends React.Component {
    render() {
      const { ...props } = this.props
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <HeaderV2 />
          {BeforeMainContent && <BeforeMainContent />}
          <Main>
            <Component {...(props as P)} />
          </Main>
        </div>
      )
    }
  }

export default withHeader
