import { useIntl } from 'react-intl'
import NoAccess from '../../NoAccess'

const NoWorkspaceAccess = () => {
  const { formatMessage: t } = useIntl()

  return (
    <NoAccess
      title={t({ id: 'noAccessToWorkspace' })}
      description={t({ id: 'noAccessDescription' })}
    />
  )
}

export default NoWorkspaceAccess
