import styled from 'styled-components'
import { DayColors } from '../../../../styles/colors'

export const Backdrop = styled.div`
  background-color: ${DayColors.background_overlay_dark};
  position: fixed;
  inset: 0px;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding: 14px;
  display: flex;
`
