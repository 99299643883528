import { Layout, Spacer, Text } from '@di-nxt/components'
import { useContext } from 'react'
import { PageHeaderContext } from '../../providers/pageHeader.provider'
import Breadcrumbs from '../Breadcrumbs'

const HeaderV2 = () => {
  const { breadcrumbs, title } = useContext(PageHeaderContext)

  return (
    <Layout
      flex
      vertical
      backgroundColor="background_mono_primary"
      minHeight={120}
    >
      <Layout
        flex
        align="center"
        paddingLeft="48"
        paddingRight="48"
        borderBottomColor="border_mono_10"
        borderBottomStyle="solid"
        borderBottomWidth="hairline"
      >
        <Text variant="inline_20_emphasis">{title}</Text>
      </Layout>

      <Layout paddingTop="12" paddingBottom="12">
        <Spacer width="48" />
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Layout>
    </Layout>
  )
}

export default HeaderV2
