import { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import useApi from './useApi'
import { UseWorkspaceUsers } from './useWorkspaceUsers.type'

import { User } from '../../types/user.type'
import {
  CreateWorkspaceUserInput,
  WorkspaceUserInput,
} from '../../types/workspace.type'
import { AppStateContext } from '../../providers/app.state.provider'
import { SnackbarContext } from '../../providers/snackbar.provider'
import {
  LeaveWorkspaceUserInput,
  UpdateWorkspaceUserInput,
} from '../../types/workspace.users.type'
import { ENDPOINTS } from '../../types/config.api.type'

export const defaultUseWorkspaceUsers: UseWorkspaceUsers = {
  loadingWorkspaceUsers: false,
  errorLoadingWorkspaceUsers: false,
  workspaceUsers: [],
  getWorkspaceUsers: () => null,
  clearUsers: () => null,
  createWorkspaceUsers: () => null,
  updateWorkspaceUser: () => null,
  leaveWorkspace: () => null,
  markUserForDeletion: (userId: string, marked: boolean) => null,
  deleteWorkspaceUsers: () => null,
  leavingWorkspace: false,
}

/**
 *
 * Hook that deals with workspace users
 * - Getting workspace users
 * - Adding user to workspace
 * - Modifying users
 * - Removing users
 */
const useWorkspaceUsers = (organizationId?: string): UseWorkspaceUsers => {
  // Local state management to store various states of information
  const [loadingWorkspaceUsers, setLoadingWorkspaceUsers] =
    useState<boolean>(false)
  const [errorLoadingWorkspaceUsers, setErrorLoadingWorkspaceUsers] =
    useState<boolean>(false)
  const [workspaceUsers, setWorkspaceUsers] = useState<User[]>()

  const [addingWorkspaceUsers, setAddingWorkspaceUsers] =
    useState<boolean>(false)

  const [updatingWorkspaceUser, setUpdatingWorkspaceUser] =
    useState<boolean>(false)

  const [leavingWorkspace, setLeavingWorkspace] = useState<boolean>(false)

  const [deletingWorkspaceUsers, setDeletingWorkspaceUsers] =
    useState<boolean>(false)

  // Internationalization hook
  const { formatMessage: t } = useIntl()
  // Navigation hook
  const navigate = useNavigate()

  // Snack bar context and dialogs context
  const { manageDialog } = useContext(AppStateContext)
  const { handleSetContent, handleSetSnackbarType } =
    useContext(SnackbarContext)

  // Fetcher api
  const { fetcher } = useApi()

  /**
   *
   * Function to clear users from local state
   */
  const clearUsers = () => setWorkspaceUsers(undefined)

  /**
   * Function to mark users for deletion from a workspace
   * @param userId - id of the user marked for deletion
   * @param marked - whether marked on unmarked for deletion
   */
  const markUserForDeletion = (userId: string, marked: boolean) => {
    setWorkspaceUsers((users) =>
      users?.map((user) => {
        if (user.userId === userId) {
          return {
            ...user,
            isMarkedForDeletion: marked,
          }
        }
        return user
      }),
    )
  }

  /**
   *
   * Method to fetch workspace users
   * @param {String} workspaceId - id of the workspace for which the users are requested
   */
  const getWorkspaceUsers = async (workspaceId: string) => {
    if (organizationId) {
      try {
        setLoadingWorkspaceUsers(true)
        const uri: string = ENDPOINTS.WORKSPACE_USERS.replace(
          '$organizationId',
          organizationId,
        ).replace('$workspaceId', workspaceId)
        // Run the query
        const response = await fetcher.get(uri)
        if (response.data) {
          setWorkspaceUsers(response.data as User[])
        }
      } catch (error) {
        setErrorLoadingWorkspaceUsers(true)
      } finally {
        setLoadingWorkspaceUsers(false)
      }
    }
  }

  /**
   *
   * Method to create workspace users
   * @param {String} workspaceId - id of the workpsace for which users are added
   * @param {String} workspaceName - helps in searching workspaces
   */
  const createWorkspaceUsers = async (
    workspaceId: string,
    data: CreateWorkspaceUserInput,
  ) => {
    if (organizationId) {
      try {
        setAddingWorkspaceUsers(true)
        const uri: string = ENDPOINTS.WORKSPACE_USERS.replace(
          '$organizationId',
          organizationId,
        ).replace('$workspaceId', workspaceId)

        await fetcher.post(uri, data)
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'addedWorkspaceUser' }))
      } catch (error) {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'addingWorkspaceUsersFailed' }))
      } finally {
        setAddingWorkspaceUsers(false)
        manageDialog({ addWorkspaceUsers: false })
        await getWorkspaceUsers(workspaceId)
      }
    }
  }

  /**
   *
   * Method to update workspace users
   * @param {String} workspaceId - id of the workspace for which a user is being edited
   * @param {UpdateWorkspaceUserInpt} data - payload to send
   * @param {String} userId - id of the user to update
   */
  const updateWorkspaceUser = async (
    workspaceId: string,
    data: UpdateWorkspaceUserInput,
    userId: string,
  ) => {
    if (organizationId) {
      try {
        setUpdatingWorkspaceUser(true)
        const uri: string = ENDPOINTS.WORKSPACE_USER.replace(
          '$organizationId',
          organizationId,
        )
          .replace('$workspaceId', workspaceId)
          .replace('$userId', userId)

        await fetcher.put(uri, data)
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'userUpdated' }))
      } catch {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'errorUpdatingUser' }))
      } finally {
        setUpdatingWorkspaceUser(false)
        await getWorkspaceUsers(workspaceId)
      }
    }
  }

  /**
   *
   * Method to delete an individual in a workspace
   * @param {string} workspaceId - workspace from which user is removed
   * @param {string} userId - id of the user to remove
   */
  const leaveWorkspace = async (
    workspaceId: string,
    userId: string,
    payload?: LeaveWorkspaceUserInput,
    onSuccess?: Function,
  ) => {
    if (organizationId && userId) {
      setLeavingWorkspace(true)
      try {
        const uri: string = ENDPOINTS.WORKSPACE_USER.replace(
          '$organizationId',
          organizationId,
        )
          .replace('$workspaceId', workspaceId)
          .replace('$userId', userId)

        // Run the request
        await fetcher.delete(uri, { ...(payload && { data: payload }) })
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'userRemovedFromWorkspace' }))
        navigate('/')
      } catch {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'errorRemovingUserFromWorkspace' }))
      } finally {
        manageDialog({
          leaveWorkspaceConfirm: false,
          selectedWorkspace: undefined,
        })
        if (typeof onSuccess === 'function') {
          onSuccess()
        }
        setLeavingWorkspace(false)
      }
    }
  }

  /**
   *
   * @param workspaceId - id of the workspace from which users will be deleted
   * @param userIds - ids of the users to be deleted
   * @param workspaceUsersLeft - users left after deletion
   * @param onSuccess - callback called if users are delleted successfully
   */
  const deleteWorkspaceUsers = async (
    workspaceId: string,
    users: User[],
    workspaceUsersLeft: WorkspaceUserInput[],
    onSuccess: () => void,
    workspaceKey: string,
    organizationKey: string,
  ) => {
    if (organizationId && workspaceId && users.length !== 0) {
      setDeletingWorkspaceUsers(true)
      try {
        const uri: string = ENDPOINTS.DELETE_WORKSPACE_USERS.replace(
          '$organizationId',
          organizationId,
        ).replace('$workspaceId', workspaceId)

        // Run the request
        await fetcher.delete(uri, {
          data: {
            users,
            workspaceUsers: workspaceUsersLeft,
            workspaceKey,
            organizationKey,
          },
        })

        onSuccess()
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'usersRemovedFromWorkspace' }))
      } catch {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'errorRemovingUsersFromWorkspace' }))
      } finally {
        setDeletingWorkspaceUsers(false)
        getWorkspaceUsers(workspaceId)
      }
    }
  }

  return {
    loadingWorkspaceUsers,
    errorLoadingWorkspaceUsers,
    workspaceUsers,
    getWorkspaceUsers,
    clearUsers,
    createWorkspaceUsers,
    addingWorkspaceUsers,
    updateWorkspaceUser,
    updatingWorkspaceUser,
    leaveWorkspace,
    leavingWorkspace,
    markUserForDeletion,
    deleteWorkspaceUsers,
    deletingWorkspaceUsers,
  }
}

export default useWorkspaceUsers
