import styled from 'styled-components'

/**
 *
 * Table component wrapper for overriding cell style
 */
export const StyledTableContainer = styled.div`
  width: 100%;
  div[role='cell'] {
    min-width: 0px;
  }
`

export const TextNoWrap = styled.div`
  white-space: nowrap;
`
