import { Link } from 'react-router-dom'
import styled from 'styled-components'

/**
 * Custom component for showing link with black underline
 */
export const BlackUnderlineLink = styled(Link)`
  text-decoration-color: black;
  &:hover {
    text-decoration-color: black;
  }
`
