import React, { createContext, useState, useMemo, useCallback } from 'react'
import { useMatches } from 'react-router-dom'
import {
  Breadcrumb,
  PageHeaderState,
  RouteType,
} from './pageHeader.provider.type'

export const PageHeaderContext = createContext<PageHeaderState>({
  breadcrumbs: [],
  title: '',
  setDynamicBreadcrumb: () => {},
  removeDynamicBreadcrumb: () => {},
  setDynamicTitle: () => {},
  removeDynamicTitle: () => {},
})

const PageHeaderProvider: React.FC = ({ children }) => {
  const matches = useMatches() as RouteType[]

  const [dynamicCrumbs, setDynamicCrumbs] = useState<{
    [key: string]: string | undefined
  }>({})

  const [dynamicPageTitle, setDynamicPageTitle] = useState<string>('')

  const crumbs: Breadcrumb[] = useMemo(
    () =>
      matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => {
          let text = match.handle.crumb(match.data)
          if (text.startsWith('{') && text.endsWith('}')) {
            text = dynamicCrumbs[text] ?? ''
          }
          return {
            text,
            path: match.pathname,
          }
        }),
    [matches, dynamicCrumbs],
  )

  const breadcrumbs = crumbs.every((crumb) => Boolean(crumb.text)) ? crumbs : []

  const setDynamicBreadcrumb = useCallback(
    (key: string, value: string) =>
      setDynamicCrumbs((dynamicCrumbs) => ({
        ...dynamicCrumbs,
        [`{${key}}`]: value,
      })),
    [],
  )

  const removeDynamicBreadcrumb = useCallback(
    (key: string) =>
      setDynamicCrumbs((dynamicCrumbs) => ({
        ...dynamicCrumbs,
        [`{${key}}`]: undefined,
      })),
    [],
  )

  const title: string = useMemo(() => {
    const filteredMatches = matches.filter((match) =>
      Boolean(match.handle?.title),
    )
    const lastMatch =
      filteredMatches.length > 0
        ? filteredMatches[filteredMatches.length - 1]
        : null
    let pageTitle = lastMatch?.handle.title(lastMatch.data) ?? ''
    if (pageTitle.startsWith('{') && pageTitle.endsWith('}')) {
      pageTitle = dynamicPageTitle ?? ''
    }
    return pageTitle
  }, [matches, dynamicPageTitle])

  const setDynamicTitle = useCallback(
    (title: string) => setDynamicPageTitle(title),
    [],
  )
  const removeDynamicTitle = useCallback(() => setDynamicPageTitle(''), [])

  return (
    <PageHeaderContext.Provider
      value={{
        breadcrumbs,
        title,
        setDynamicBreadcrumb,
        removeDynamicBreadcrumb,
        setDynamicTitle,
        removeDynamicTitle,
      }}
    >
      {children}
    </PageHeaderContext.Provider>
  )
}

PageHeaderProvider.displayName = 'PageHeaderProvider'
export default PageHeaderProvider
