import React, { useCallback, useContext, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input, Editor, Layout, Spacer, Text } from '@di-nxt/components'

import { CreateWorkspaceDetailsProps } from './index.type'
import InputCounter from '../../../InputCounter'
import { AppConstants } from '../../../../config/appConstants'
import { debounce } from 'lodash'
import { ApiContext } from '../../../../providers/api.provider'
import { replaceDiacritics } from '../../../../utils/replaceDiacritics'
/**
 *
 * Create workspace wizard step 1
 * Allows entry of name and description for a new workspace entry
 */
const CreateWorkspaceDetails: React.FC<CreateWorkspaceDetailsProps> = ({
  name,
  description,
  workspaceKey,
  onInputChange,
  handleOkButtonDisabled,
}) => {
  /**
   *
   * Internationalization hook
   */
  const { formatMessage: t } = useIntl()

  /**
   *
   * API Context to fetch workspaces by key
   */
  const { workspacesService } = useContext(ApiContext)
  const { getWorkspacesByKey, loadingWorkspacesByKey } = workspacesService

  /**
   *
   * State management to manage errors on workspace key
   */
  const [workspaceKeyValid, setWorkspaceKeyValid] = useState<boolean>(false)

  // Flag used to see if key has been sent for validation
  const [workspaceKeyVerified, setWorkspaceKeyVerified] = useState<boolean>(
    false,
  )

  /**
   *
   * Use effect to check the state of fields
   */
  useEffect(() => {
    if (
      name.trim().length > 3 &&
      workspaceKey.length > 1 &&
      workspaceKeyValid &&
      workspaceKeyVerified
    ) {
      handleOkButtonDisabled(false)
    } else {
      handleOkButtonDisabled(true)
    }
  }, [
    name,
    workspaceKey,
    workspaceKeyValid,
    workspaceKeyVerified,
    handleOkButtonDisabled,
  ])

  /**
   *
   * Handles change of name of the workspace
   */
  const handleNameChange = (value: string) => {
    if (value.length <= AppConstants.WORKSPACE_NAME_MAX_LENGTH) {
      onInputChange(value, 'name')
      const key = replaceDiacritics(value).toLowerCase().replaceAll(' ', '_')
      if (
        key.length <= AppConstants.WORKSPACE_KEY_MAX_LENGTH &&
        /^[a-z0-9_]*$/.test(key)
      ) {
        setWorkspaceKeyVerified(false)
        onInputChange(key, 'key')
        processWorkspaceKey(key)
      }
    }
  }

  /**
   *
   * handles changing of workspace key
   */
  const handleChangeWorkspaceKey = (value: string) => {
    const key = value.toLocaleLowerCase().replaceAll(' ', '_')
    if (
      key.length <= AppConstants.WORKSPACE_KEY_MAX_LENGTH &&
      /^[a-z0-9_]*$/.test(key)
    ) {
      setWorkspaceKeyVerified(false)
      onInputChange(key, 'key')
    }
  }

  /**
   *
   * Call valudate workspace key
   */
  // eslint-disable-next-line
  const processWorkspaceKey = useCallback(
    debounce((data: string) => {
      handleValidateKey(data)
    }, 1000),
    [],
  )

  /**
   *
   * Validate workspace key and see if it is used elsewhere
   */
  const handleValidateKey = (key?: string) => {
    getWorkspacesByKey(key ?? workspaceKey)
      .then(() => setWorkspaceKeyValid(true))
      .catch(() => setWorkspaceKeyValid(false))
      .finally(() => setWorkspaceKeyVerified(true))
  }

  /**
   *
   * Handles change of description of the workspace
   */
  const handleDescriptionChange = (value: string) => {
    if (value.length <= AppConstants.WORKSPACE_DESCRIPTION_MAX_LENGTH) {
      onInputChange(value, 'description')
    }
  }

  /**
   *
   * If the email is invalid
   */
  const renderKeyInvalid = () =>
    !workspaceKeyValid &&
    workspaceKeyVerified && (
      <>
        <Spacer height="6" />
        <Text
          variant="paragraph_12_default"
          textColor={'content_negative_default'}
        >
          <FormattedMessage
            id={'errorWorkspaceKeyUsed'}
            values={{ key: workspaceKey }}
          />
        </Text>
      </>
    )

  return (
    <Layout>
      <Layout vertical width="100%">
        <Layout vertical paddingTop="12">
          <InputCounter
            value={name}
            maxLength={AppConstants.WORKSPACE_NAME_MAX_LENGTH}
            label={t({ id: 'workspaceName' })}
          />
          <Input value={name} onChange={handleNameChange} />
        </Layout>
        <Layout vertical paddingTop="12">
          <InputCounter
            value={workspaceKey}
            maxLength={AppConstants.WORKSPACE_KEY_MAX_LENGTH}
            label={t({ id: 'workspaceKey' })}
          />
          <Input
            value={workspaceKey}
            onChange={handleChangeWorkspaceKey}
            onBlur={handleValidateKey}
            rightSpinner={loadingWorkspacesByKey}
          />
          {renderKeyInvalid()}
        </Layout>
        <Layout vertical paddingTop="12">
          <InputCounter
            value={description}
            maxLength={AppConstants.WORKSPACE_DESCRIPTION_MAX_LENGTH}
            label={t({ id: 'description' })}
          />
          <Editor
            rows={5}
            value={description}
            onChange={handleDescriptionChange}
          />
        </Layout>
      </Layout>
    </Layout>
  )
}

CreateWorkspaceDetails.displayName = 'CreateWorkspaceDetails'

export default CreateWorkspaceDetails
