import { useState, useContext } from 'react'
import { useIntl } from 'react-intl'

import { SnackbarContext } from '../../providers/snackbar.provider'
import { ModifyUserInput, User, UserInput } from '../../types/user.type'
import {
  OrganizationFilters,
  UseOrganizationUsers,
} from './useOrganizationUsers.type'

import { ENDPOINTS } from '../../types/config.api.type'

import useApi from './useApi'

export const defaultUserOrganizationUser: UseOrganizationUsers = {
  createOrganizationUser: () => Promise.resolve(),
  creatingUser: false,
  deleteOrganizationUser: () => new Promise((resolve, reject) => {}),
  getOrganizationUsers: () => Promise.resolve(),
  getOrganizationUser: () => new Promise((resolve, reject) => {}),
  loadingUsers: false,
  loadingUsersError: false,
  updateOrganizationUser: () => Promise.resolve(),
  updatingUser: false,
  users: [],
  handleSetFilters: () => null,
  filters: undefined,
  getUserByEmail: () => new Promise((resolve, reject) => {}),
}

/**
 *
 * Hook that serves as a service to query users within an organization
 */
const useOrganizationUsers = (
  organizationId?: string,
  userId?: string,
): UseOrganizationUsers => {
  // Local state management for setting states of users, loading state and errors
  const [users, setUsers] = useState<User[] | null>(null)
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [loadingUsersError, setLoadingUsersError] = useState<boolean>(false)
  const [creatingUser, setCreatingUser] = useState<boolean>(false)
  const [updatingUser, setUpdatingUser] = useState<boolean>(false)
  const [deletingUser, setDeletingUser] = useState<boolean>(false)
  const [loadingUserByEmail, setLoadingUserByEmail] = useState<boolean>(false)

  const [filters, setFilters] = useState<OrganizationFilters | undefined>(
    undefined,
  )

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Hook for api
  const { fetcher } = useApi()

  // Context api to display snackbar messages
  const { handleSetContent, handleSetSnackbarType } =
    useContext(SnackbarContext)

  // Set the filters in local state
  const handleSetFilters = (filters: OrganizationFilters) => {
    setFilters(filters)
  }

  /**
   *
   * Method to get users in an organization
   */
  const getOrganizationUsers = async (filters?: OrganizationFilters) => {
    // Construct the params for the organization users
    const params = new URLSearchParams({
      scope: 'org',
      ...(filters?.filterRole && { role: filters.filterRole }),
      ...(typeof filters?.filterState === 'boolean' && {
        filterState: filters.filterState ? 'True' : 'False',
      }),
      ...(filters?.search && { search: filters.search }),
    })

    if (organizationId) {
      setLoadingUsers(true)
      try {
        // TODO replace hardcoded organization id with dynamically fetched id
        const response = await fetcher.get(
          ENDPOINTS.ORGANIZATION_USERS.replace(
            '$organizationId',
            organizationId,
          ),
          { params },
        )
        if (response.data) {
          setUsers(response.data.users as User[])
        }
      } catch (e) {
        setLoadingUsersError(true)
      } finally {
        setLoadingUsers(false)
      }
    }
  }

  /**
   *
   * @param {string} userId - id of the user for which data is being requested
   */
  const getOrganizationUser = async (userId: string) => {
    if (organizationId) {
      try {
        return await fetcher.get(
          ENDPOINTS.ORGANIZATION_USER.replace(
            '$organizationId',
            organizationId,
          ).replace('$userId', userId),
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  /**
   *
   * Method to create a new organization user
   */
  const createOrganizationUser = async (data: UserInput) => {
    if (organizationId) {
      setCreatingUser(true)
      try {
        // TODO replace hardcoded organization id with dynamically fetched id
        const response = await fetcher.post(
          ENDPOINTS.ORGANIZATION_USERS.replace(
            '$organizationId',
            organizationId,
          ),
          data,
        )

        if (response.data) {
          handleSetSnackbarType('Success')
          handleSetContent(
            t({ id: 'userCreationSuccess' }, { name: data.username }),
          )
        }
      } catch (e) {
        handleSetSnackbarType('Error')
        handleSetContent(
          t({ id: 'userCreationError' }, { name: data.username }),
        )
      } finally {
        setCreatingUser(false)
        await getOrganizationUsers()
      }
    }
  }

  /**
   *
   * Method to modify an organization user
   */
  const updateOrganizationUser = async (
    data: ModifyUserInput,
    updateUserId: string,
    successMessage?: string,
    failureMessage?: string,
  ) => {
    if (updateUserId && organizationId) {
      setUpdatingUser(true)
      try {
        const response = await fetcher.put(
          ENDPOINTS.ORGANIZATION_USER.replace(
            '$organizationId',
            organizationId,
          ).replace('$userId', updateUserId),
          data,
        )

        /**
         *
         * Update the local state of users
         */
        if (response.data) {
          if (users) {
            const updatedUsers = users?.map((user: User) => {
              if (user.userId === response.data.userId) {
                const { detachRole, ...rest } = response.data
                return { ...user, ...(rest as User) }
              }
              return user
            })

            handleSetSnackbarType('Success')
            if (successMessage) {
              handleSetContent(successMessage)
            } else {
              handleSetContent(t({ id: 'valuesSaved' }))
            }
            setUsers(updatedUsers)
          }
        }
      } catch (e) {
        handleSetSnackbarType('Error')
        if (failureMessage) {
          handleSetContent(failureMessage)
        } else {
          handleSetContent('Failed to update user')
        }
      } finally {
        setUpdatingUser(false)
      }
    }
  }

  /**
   *
   * Method to delete a user from the organization
   * @param {string} userId
   */
  const deleteOrganizationUser = async (userId: string) => {
    if (userId && organizationId) {
      setDeletingUser(true)
      try {
        const deleteUser = await fetcher.delete(
          ENDPOINTS.ORGANIZATION_USER.replace(
            '$organizationId',
            organizationId,
          ).replace('$userId', userId),
        )
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'userDeleted' }))
        getOrganizationUsers()
        return deleteUser
      } catch (error) {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'errorDeletingUser' }))
        return error
      } finally {
        setDeletingUser(false)
      }
    }
  }

  /**
   *
   * Get an organization user by email
   */
  const getUserByEmail = async (userId: string, email: string) => {
    if (userId && organizationId) {
      const params = new URLSearchParams({ email })
      setLoadingUserByEmail(true)
      return fetcher
        .get(
          ENDPOINTS.ORGANIZATION_USERS.replace(
            '$organizationId',
            organizationId,
          ),
          { params },
        )
        .finally(() => {
          setLoadingUserByEmail(false)
        })
    }
  }

  return {
    createOrganizationUser,
    creatingUser,
    deleteOrganizationUser,
    deletingUser,
    filters,
    getOrganizationUser,
    getOrganizationUsers,
    getUserByEmail,
    handleSetFilters,
    loadingUserByEmail,
    loadingUsers,
    loadingUsersError,
    updateOrganizationUser,
    updatingUser,
    users,
  }
}

export default useOrganizationUsers
