import React from 'react'
import { Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { SaveSelectionDialogProps } from './index.type'
import CustomDialog from '../AddUserDialog/CustomDialog'

/**
 *
 * Dialog for saving selected tables and columns in workspace data
 */
const SaveSelectionDialog: React.FC<SaveSelectionDialogProps> = ({
  changes,
  onOk,
  onCancel,
}) => {
  const { formatMessage: t } = useIntl()
  return (
    <CustomDialog
      title={`${t({ id: 'saveSelection' })}?`}
      onOkButton={onOk}
      okButtonTitle={t({ id: 'saveSelection' })}
      cancelButtonTitle={t({ id: 'cancel' })}
      onCancelButton={onCancel}
    >
      <Layout width="100%" vertical>
        <Text variant="paragraph_16_default">
          {t({ id: 'confirmSelection' })}
        </Text>
        <ul style={{ maxHeight: '275px', overflowY: 'auto' }}>
          {changes.map((change) => (
            <li key={change}>{change}</li>
          ))}
        </ul>
        <Text variant="paragraph_16_default">
          {t({ id: 'confirmContinue' })}
        </Text>
      </Layout>
    </CustomDialog>
  )
}

SaveSelectionDialog.displayName = 'SaveSelectionDialog'
export default SaveSelectionDialog
