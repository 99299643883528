import {
  TableauView,
  TableauWorkbook,
  TableauWorkbookWithSelectedViews,
  TableauWorkbookWithViews,
} from '../types/tableau.api.type'

export const getSelectedWorkbooks = (
  sourceWorkbooks: TableauWorkbookWithViews[],
  targetWorkbooks: TableauWorkbookWithViews[],
): TableauWorkbookWithSelectedViews[] => {
  return sourceWorkbooks.map((swb) => {
    const targetWorkbook = targetWorkbooks.find((twb) => twb.name === swb.name)
    const selectedViews = swb.views.map((sv) => ({
      ...sv,
      selected: !!targetWorkbook?.views.some((tv) => tv.name === sv.name),
    }))
    return { ...swb, views: selectedViews }
  })
}

export const groupWorkbooksWithViews = (
  workbooks: TableauWorkbook[],
  views: TableauView[],
): TableauWorkbookWithViews[] => {
  return workbooks.map((wb) => ({
    ...wb,
    views: views.filter((v) => v.workbook.id === wb.id),
  }))
}
