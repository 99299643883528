import { AxiosInstance } from 'axios'
import { ENDPOINTS } from '../types/config.api.type'

/**
 *
 * Runs async query
 */
export const runQuery = async (
  fetcher: AxiosInstance,
  query: string,
  mode: 'export' | 'preview',
) => {
  return fetcher.post(
    ENDPOINTS.START_QUERY,
    {
      query,
      mode,
    },
    {
      baseURL: process.env.REACT_APP_ASYNC_QUERY_API_PATH || '',
    },
  )
}

/**
 *
 * Checks query status
 */
export const checkQuery = async (fetcher: AxiosInstance, queryId: string) => {
  return fetcher.post(
    ENDPOINTS.CHECK_QUERY,
    {
      QueryId: queryId,
    },
    {
      baseURL: process.env.REACT_APP_ASYNC_QUERY_API_PATH || '',
    },
  )
}
/**
 *
 * Returns query results
 */
export const getQueryResults = async (
  fetcher: AxiosInstance,
  queryId: string,
) => {
  return fetcher.post(
    ENDPOINTS.GET_QUERY_RESULTS,
    {
      QueryId: queryId,
    },
    {
      baseURL: process.env.REACT_APP_ASYNC_QUERY_API_PATH || '',
    },
  )
}
/**
 *
 * Stops running query
 */
export const stopQuery = async (fetcher: AxiosInstance, queryId: string) => {
  return fetcher.post(
    ENDPOINTS.STOP_QUERY,
    {
      QueryId: queryId,
    },
    {
      baseURL: process.env.REACT_APP_ASYNC_QUERY_API_PATH || '',
    },
  )
}
