import { Spacer, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { StyledTooltipContent, TierUpgradeDateStyled } from './style'

type Props = {
  tierName: string
  tierUpgradeDate?: string
} & any

const TierUpgradeTooltip: React.FC<Props> = ({ tierName, tierUpgradeDate }) => {
  const { formatMessage: t } = useIntl()

  return (
    <StyledTooltipContent>
      <Text textColor="content_accent_default" variant="paragraph_12_emphasis">
        {t(
          { id: 'estimatedTierUpgrade' },
          { tierName: `${t({ id: 'tier' })} ${tierName}` },
        )}
      </Text>
      <Spacer height="4" />
      <TierUpgradeDateStyled>{tierUpgradeDate}</TierUpgradeDateStyled>
    </StyledTooltipContent>
  )
}

export default TierUpgradeTooltip
