import React from 'react'
import { Dialog, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { LeaveWorkspaceConfirmProps } from './index.type'

/**
 *
 * Render the dialog to confirm leaving action from a workspace
 */
const LeaveWorkspaceConfirm: React.FC<LeaveWorkspaceConfirmProps> = ({
  onOk: handleOk,
  onCancel: handleCancel,
  canLeaveWorkspace,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Flag that determines whether user can leave workspace
  const getOkButtonTitle = (): string => {
    if (canLeaveWorkspace) {
      return t({ id: 'leaveWorkspace' })
    }
    return t({ id: 'manageWorkspaceRoles' })
  }

  /**
   *
   * When ok button is pressed
   */
  const handleOnOk = () => handleOk(canLeaveWorkspace)

  return (
    <Dialog
      title={`${t({ id: 'leaveWorkspace' })}`}
      onOkButton={handleOnOk}
      onCancelButton={handleCancel}
      okButtonTitle={getOkButtonTitle()}
      cancelButtonTitle={t({ id: 'cancel' })}
    >
      <Text variant="paragraph_16_default">
        {canLeaveWorkspace
          ? t({ id: 'leaveWorkspaceConfirm' })
          : t({ id: 'cannotLeaveWorkspace' })}
      </Text>
    </Dialog>
  )
}

LeaveWorkspaceConfirm.displayName = 'LeaveWorkspaceConfirm'
export default LeaveWorkspaceConfirm
