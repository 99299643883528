import React, { useContext, useEffect, useState } from 'react'
// Providers
import { ApiContext } from '../../providers/api.provider'
// Type Definitions
import { UserRoleSelectorProps } from './UserRoleSelector.type'
import { OrganizationRole } from '../../types/organization.type'
import { DropdownItem } from '../../types/dropdown.type'
// Hooks
import { useDropdown } from '../../hooks/definitions/useDropdown'
import TableCellDropdown from '../TableCellDropdown'
import { Text } from '@di-nxt/components'

/**
 *
 * Allows the user to change the role of themselves or another user
 * - Provided user changing the role has the right privilages
 */
const UserRoleSelector: React.FC<UserRoleSelectorProps> = ({
  user,
  onEditUser,
  propertyKey,
  updateDetails,
  editable,
}) => {
  // State to manage selection of role
  const [currentRole, setCurrentRole] = useState<OrganizationRole>(user.role)

  // Attach the api service to modify roles
  const { organizationUserService, selectedOrganization } = useContext(
    ApiContext,
  )

  // Get users from organization user service
  const { users, updatingUser } = organizationUserService

  // Roles applicable to the users
  const { organizationRoles } = useDropdown()

  /**
   *
   * Use effect hook to monitor changes in user
   */
  useEffect(() => {
    if (users) {
      const currentUser = users.find(
        (orgUser) => orgUser.userId === user.userId,
      )
      if (
        currentUser &&
        currentRole &&
        currentUser.role.name !== currentRole.name
      ) {
        setCurrentRole(currentUser.role)
      }
    }
  }, [users, currentRole, user.userId])

  /**
   *
   * Change the role of the user
   */
  const handleChangeRole = (item: DropdownItem) => {
    if (selectedOrganization && !organizationUserService.updatingUser) {
      // Determine the new role of the user
      const newOrgRole = selectedOrganization.organizationRoles.find(
        (orgRole) => orgRole.name === item.key,
      )
      // Update the user with the new role
      onEditUser(
        user.userId,
        { role: newOrgRole, detachRole: currentRole },
        propertyKey,
      )
    }
  }

  return editable ? (
    <TableCellDropdown
      items={organizationRoles}
      onSelect={handleChangeRole}
      selected={currentRole?.name}
      spinning={
        updatingUser &&
        user.userId === updateDetails?.updatingUserId &&
        propertyKey === updateDetails?.updatingComponent
      }
      maxWidth="280px"
      showCheckmark={true}
    />
  ) : (
    <Text variant="inline_14_default">{currentRole.name}</Text>
  )
}

UserRoleSelector.displayName = 'UserRoleSelector'

export default UserRoleSelector
