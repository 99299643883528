import Keycloak from 'keycloak-js'
import { keycloak, TABLEAU_ACCESS_TOKEN_MIN_VALIDITY_SECONDS } from './keycloak'
import axios, { HttpStatusCode } from 'axios'
import {
  TableauAuthRequest,
  TableauAuthResponse,
} from '../types/tableau.api.type'

export class TableauAuthenticator {
  private static readonly axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_TABLEAU_API_PATH,
  })

  private isRequestInProgress = false
  private requestPromise: Promise<void> | null = null

  private _token = ''
  private _siteId = ''
  private _userId = ''

  constructor(
    private readonly keycloak: Keycloak,
    private readonly _siteContentUrl: string,
  ) {}

  async authenticate(): Promise<void> {
    if (!this.isRequestInProgress) {
      this.requestPromise = this.getAuthenticationPromise()
    }
    return this.requestPromise!
  }

  async signout(): Promise<void> {
    if (!this.isAuthenticated) {
      return
    }

    const signoutPromise = TableauAuthenticator.axiosInstance.post(
      '/auth/signout',
      {},
      {
        baseURL: process.env.REACT_APP_TABLEAU_API_PATH,
        headers: {
          'X-Tableau-Auth': this._token,
        },
        validateStatus: (status) => status === HttpStatusCode.NoContent,
      },
    )

    this._token = ''
    this._siteId = ''
    this._userId = ''

    await signoutPromise
  }

  get token(): string {
    return this._token
  }

  get isAuthenticated(): boolean {
    return !!this._token
  }

  get siteContentUrl(): string {
    return this._siteContentUrl
  }

  get siteId(): string {
    return this._siteId
  }

  get userId(): string {
    return this._userId
  }

  private async getAuthenticationPromise(): Promise<void> {
    if (!this.keycloak.authenticated) {
      throw new Error('Keycloak is not authenticated')
    }

    this._token = ''
    this._siteId = ''
    this._userId = ''

    this.isRequestInProgress = true

    try {
      await this.keycloak.updateToken(TABLEAU_ACCESS_TOKEN_MIN_VALIDITY_SECONDS)

      const authRequest: TableauAuthRequest = {
        credentials: {
          jwt: this.keycloak.token!,
          site: {
            contentUrl: this._siteContentUrl,
          },
        },
      }

      const authResponse = await TableauAuthenticator.axiosInstance.post(
        '/auth/signin',
        authRequest,
        {
          baseURL: process.env.REACT_APP_TABLEAU_API_PATH,
          validateStatus: (status) => status === HttpStatusCode.Ok,
        },
      )
      const authResponseData = authResponse.data as TableauAuthResponse

      this._token = authResponseData.credentials.token
      this._siteId = authResponseData.credentials.site.id
      this._userId = authResponseData.credentials.user.id
    } finally {
      this.isRequestInProgress = false
    }
  }
}

let currentAuthenticator: TableauAuthenticator | null = null

export const useTableauAuthenticator = (siteContentUrl: string) => {

  if (
    currentAuthenticator === null ||
    currentAuthenticator.siteContentUrl !== siteContentUrl
  ) {
    currentAuthenticator = new TableauAuthenticator(keycloak, siteContentUrl)
  }

  return currentAuthenticator
}
