/**
 * Props for column references component
 */
export type ColumnReferencesProps = {
  references: string[]
}

/**
 * Max amount of references to be shown on initial render
 */
export const MAX_REFERENCES = 3
