import React, { useContext } from 'react'
import { Layout, Text, BaseSpinner, BaseLayout } from '@di-nxt/components'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { ApiContext } from '../../../providers/api.provider'

import { WorkspaceHomeIntroduction } from './WorkspaceHomeMessages'
import WorkspaceTools from './WorkspaceTools'

/**
 *
 * Renders the Workspace home component
 */
const WorkspaceHomeContainer: React.FC = () => {
  // React router dom hook
  const routeParams = useParams()

  // Attach the api context
  const { workspaceService } = useContext(ApiContext)
  const { workspace, loadingWorkspace } = workspaceService

  if (loadingWorkspace) {
    return (
      <Layout vertical justify="center" align="center" minHeight="100%" flex>
        <BaseSpinner size={32} />
      </Layout>
    )
  }

  return (
    <Layout paddingTop="32" vertical>
      <Text variant="inline_20_emphasis">
        <FormattedMessage id="about" />
      </Text>
      <Layout paddingTop="24">
        <Text variant="paragraph_16_default">
          {workspace?.workspaceDescription}
        </Text>
      </Layout>
      <WorkspaceHomeIntroduction />
      <Layout paddingTop="32" paddingBottom="32">
        <Text variant="inline_20_emphasis">
          <FormattedMessage id="toolsTitle" />
        </Text>
      </Layout>
      <BaseLayout horizontal flex wrap="wrap">
        <BaseLayout horizontal flex={1}>
          <WorkspaceTools workspaceId={routeParams.workspaceId} />
        </BaseLayout>
      </BaseLayout>
    </Layout>
  )
}

WorkspaceHomeContainer.displayName = 'WorkspaceHomeContainer'

export default WorkspaceHomeContainer
