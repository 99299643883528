import { useMemo } from 'react'
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { ACCESS_TOKEN_MIN_VALIDITY_SECONDS, keycloak } from '../../auth/keycloak'

/**
 * Use API Hook to make calls to the api gateway
 */
const useApi = (): { fetcher: AxiosInstance } => {

  const fetcher: AxiosInstance = useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_PATH,
    })

    axiosInstance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig<any>) => {
        if (config && config.headers && keycloak.authenticated) {
          await keycloak.updateToken(ACCESS_TOKEN_MIN_VALIDITY_SECONDS)
          config.headers['Authorization'] = `Bearer ${keycloak.token}`
        }
        return config
      },
    )

    return axiosInstance
  }, [])

  return {
    fetcher,
  }
}

export default useApi
