import styled from 'styled-components'
import { AppLayout } from '../types/styles.type'
import { NightColors } from './colors'

export const sidebarWidth = 250

export const Layout: AppLayout = {
  margin: '1em',
  marginSmall: '0.4em',
  marginLarge: '3em',
  marginHuge: '4em',
  padding: '1em',
  paddingLarge: '3em',
  borderRadius: '8px',
  sidebarWidth: `${sidebarWidth}px`,
  headerHeight: '82px',
}

export const Main = styled.main`
  width: 100%;
  box-sizing: border-box;
  padding: ${Layout.padding} ${Layout.paddingLarge};
  display: flex;
  flex-direction: column;
  background-color: ${NightColors.background_mono_inverse_secondary};
  min-height: calc(100% - ${Layout.headerHeight});
`

/**
 * Grid for tree view and selected table information components
 */
export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 341px minmax(0, 1fr);
  column-gap: 12px;
  min-height: 800px;
  padding-top: 12px;
`
