import styled from 'styled-components'
import { Layout } from '../../../styles'

/**
 * Workspaces header
 */
export const StyledWorkspacesHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > button {
    margin-left: auto;
    cursor: pointer;
  }

  & > span {
    margin-left: ${Layout.margin};
    margin-top: ${Layout.marginSmall};
  }
`

export const StyledWorkspacesHeaderDetails = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & > button {
    margin-left: auto;
    cursor: pointer;
  }

  & > span {
    margin-left: ${Layout.margin};
    margin-top: ${Layout.marginSmall};
  }
`

export const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`
