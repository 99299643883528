import { TableColumn } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { TierCostItem } from './types'

const useTierCostDialogDefinitions = () => {
  const { formatMessage: t } = useIntl()

  const tierCostDialogDef: TableColumn<TierCostItem>[] = [
    {
      key: 'tier',
      title: t({ id: 'tier' }),
      cellStyle: {
        minWidth: '144px',
      },
    },
    {
      key: 'monthlyDataRows',
      title: t({ id: 'monthlyDataRows' }),
      cellStyle: {
        minWidth: '191px',
      },
    },
    {
      key: 'monthlyCostBeforeYear2024',
      title: t({ id: 'monthlyCostBeforeYear2024' }),
      cellStyle: {
        minWidth: '163px',
      },
    },
    {
      key: 'monthlyCostFromYear2024',
      title: t({ id: 'monthlyCostFromYear2024' }),
      cellStyle: {
        minWidth: '163px',
      },
    },
  ]

  return {
    tierCostDialogDef,
  }
}

export default useTierCostDialogDefinitions
