import React, { useContext, useEffect, useState } from 'react'
import { Dialog, Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import StatusSpinner from '../../StatusSpinner'

import { DeleteOrganizationConfirmProps } from './index.type'

import { ApiContext } from '../../../providers/api.provider'

/**
 *
 * Render a dialog when deleting an organization
 * The component will check following:
 * 1 - If there are any workspaces associated with the organization
 * 2 - If there are any users in the organization
 * 3 - DI org cannot be deleted
 * if above conditions pass, we can delete the organization
 */
const DeleteOrganizationConfirm: React.FC<DeleteOrganizationConfirmProps> = ({
  onCancel: handleCancel,
  onOk: handleOk,
  organization,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()
  // Api context to fetch workspaces and users of an organization
  const { organizationService } = useContext(ApiContext)
  // Get the methods and data about the workspaces and organizations
  const { getOrganization, loadingOrganization } = organizationService
  // Store the state of organization deletion status
  const [canDelete, setCanDelete] = useState<boolean>(false)

  /**
   *
   * Run use effect and get the organization we want to delete
   * The response should contain information on number of workspaces and users combined
   */
  useEffect(() => {
    if (organization) {
      getOrganization(organization?.organizationId).then((response) => {
        if (response as any) {
          setCanDelete((response as any).usersAndWorkspacesCount === 0)
        }
      })
    }
  }, []) // eslint-disable-line

  /**
   *
   * Delete the organization
   */
  const handleDeleteOrg = () => {
    handleOk(organization)
  }

  /**
   *
   * Depending on the state of organization, return the error
   */
  const getCheckingMessage = () => {
    if (loadingOrganization) {
      return t({ id: 'organizationDeleteConfirming' })
    } else if (canDelete) {
      return t({ id: 'organizationDeleteConfirm' })
    } else {
      return t({ id: 'organizationDeleteUnable' })
    }
  }

  return (
    <Dialog
      title={t({ id: 'deleteOrganization' })}
      onOkButton={handleDeleteOrg}
      cancelButtonTitle={t({ id: 'cancel' })}
      onCancelButton={handleCancel}
      okButtonTitle={'Delete organization'}
      okButtonDisabled={loadingOrganization || !canDelete}
    >
      <Text variant="paragraph_16_default">
        {t(
          {
            id: 'deleteOrganizationWarning',
          },
          {
            organizationName: organization?.organizationName,
            br: <br />,
            checkingMessage: getCheckingMessage(),
          },
        )}
      </Text>
      {loadingOrganization && (
        <Layout position="absolute" bottom={-54} left={28}>
          <StatusSpinner message={t({ id: 'loadingOrganization' })} />
        </Layout>
      )}
    </Dialog>
  )
}

DeleteOrganizationConfirm.displayName = 'DeleteOrganizationConfirm'
export default DeleteOrganizationConfirm
