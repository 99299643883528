import React, { useState } from 'react'
import { Layout, Icon, Text, Spacer } from '@di-nxt/components'
import { ArrayItemProps } from './index.type'

/**
 *
 * An individual item in array
 */
const ArrayItem: React.FC<ArrayItemProps> = ({
  value,
  index,
  onRemoveItem: removeItem,
}) => {
  // Determine if mouse is hovering over the group
  const [showDeleteOption, setShowDeleteOption] = useState<boolean>(false)

  // When mouse enters the group
  const handleSetMouseEnter = () => setShowDeleteOption(true)

  // When mouse leaves the group
  const handleSetMouseLeave = () => setShowDeleteOption(false)

  // Remove item from array
  const handleRemoveItem = () => {
    removeItem(index)
  }

  return (
    <div onMouseEnter={handleSetMouseEnter} onMouseLeave={handleSetMouseLeave}>
      <Layout horizontal>
        <Layout
          backgroundColor="background_mono_tertiary"
          paddingTop="6"
          paddingBottom="6"
          paddingLeft="20"
          paddingRight="20"
          cursor="pointer"
          borderRadius="small"
          borderColor="border_mono_inverse_10"
          borderWidth="hairline"
          overflow="hidden"
          borderStyle="solid"
        >
          <Text>{value}</Text>
          <Layout
            width="100%"
            height="100%"
            position="absolute"
            backgroundColor="background_mono_primary"
            left={0}
            top={0}
            align="center"
            justify="center"
            visibility={showDeleteOption ? 'visible' : 'hidden'}
            onPress={handleRemoveItem}
          >
            <Icon name="remove" size="16" />
          </Layout>
        </Layout>
        <Spacer width="8" />
      </Layout>
      <Spacer height="8" />
    </div>
  )
}

ArrayItem.displayName = 'ArrayItem'
export default ArrayItem
