import React, { useRef, useContext } from 'react'
import { Layout, Menu, Popup } from '@di-nxt/components'

import { useIntl } from 'react-intl'

import { AppStateContext } from '../../providers/app.state.provider'

import { TableWorkspaceActionsProps } from './TableWorkspaceActions.type'

import { ApiContext } from '../../providers/api.provider'

import MoreInActive from '../../assets/icons/more_inactive_icon.svg'
import MoreActive from '../../assets/icons/more_active_icon.svg'

/**
 *
 * Action buttons for the table component
 * Displays leave workspace button
 */
const TableWorkspaceActions: React.FC<TableWorkspaceActionsProps> = ({
  workspace,
}) => {
  const { formatMessage: t } = useIntl()
  const { manageDialog } = useContext(AppStateContext)
  const { user } = useContext(ApiContext)
  const userInWorkspace = workspace.workspaceUsers.find(
    (workspaceUser) => workspaceUser.userId === user?.userId,
  )
  // console.log(userInWorkspace)
  // Reference to close the popup
  const popupRef = useRef(null)

  // Options to render in workspace actions
  const options: string[] = [t({ id: 'leaveWorkspace' })]

  // When an option is selected from the popup
  const handleSelectOption = (index: number) => {
    switch (index) {
      case 0:
        manageDialog({
          leaveWorkspaceConfirm: true,
          selectedWorkspace: workspace,
        })
        break
    }

    if (popupRef.current) {
      ;(popupRef as any).current.click()
    }
  }

  return (
    <Layout align="center" justify="center" width="100%" cursor="pointer">
      {userInWorkspace ? (
        <Popup trigger={<img src={MoreActive} alt="more" ref={popupRef} />}>
          {() => <Menu items={options} onChange={handleSelectOption} />}
        </Popup>
      ) : (
        <img src={MoreInActive} alt="moreActions" />
      )}
    </Layout>
  )
}

TableWorkspaceActions.displayName = 'TableWorkspaceActions'
export default TableWorkspaceActions
