import styled from 'styled-components'
import { DayColors } from '../../../styles/colors'

export const NewsBackdrop = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: ${DayColors.background_overlay_dark};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`
