import { QueryMetric } from '../../types'

export const getCurrentMetricIndex = (queryMetrics: QueryMetric[]): number => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  return queryMetrics.findIndex(
    (metric) => metric.Month === currentMonth && metric.Year === currentYear,
  )
}

export const getNextTierUpgradeIndex = (
  queryMetrics: QueryMetric[],
): number => {
  const currentIndex = getCurrentMetricIndex(queryMetrics)
  if (currentIndex < 0) {
    return -1
  }
  const currentTier = queryMetrics[currentIndex].Tier
  return queryMetrics.findIndex(
    (metric, index) => index > currentIndex && metric.Tier > currentTier,
  )
}
