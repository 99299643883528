import { Layout } from '@di-nxt/components'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import CostItem from './CostItem'
import { formatHours, getNumberOfMillions } from '../../utils'
import { QueryMetric } from '../../types'

type Props = {
  queryMetrics: QueryMetric[]
  isQueryMetricsLoading: boolean
}

const CostInfo: React.FC<Props> = ({ queryMetrics, isQueryMetricsLoading }) => {
  const { formatMessage: t } = useIntl()

  const numberOfRows = useMemo(
    () => queryMetrics.reduce((sum, metric) => sum + metric.DataRows, 0),
    [queryMetrics],
  )

  return (
    <Layout
      backgroundColor="background_mono_primary"
      justify="space-around"
      align="center"
      paddingTop="32"
      paddingBottom="28"
      borderRadius="medium"
      borderColor="border_mono_10"
      borderWidth="hairline"
      borderStyle="solid"
    >
      <CostItem
        isLoading={isQueryMetricsLoading}
        title={t({ id: 'monthlyAwsConsumption' })}
        units={formatHours(queryMetrics[0]?.AWSConsumption)}
        unitsName={t({ id: 'hours' })}
      />
      <CostItem
        isLoading={isQueryMetricsLoading}
        title={t({ id: 'numberOfRows' })}
        units={`${getNumberOfMillions(numberOfRows)} ${t({ id: 'million' })}`}
        unitsName={t({ id: 'rows' })}
      />
      <CostItem
        isLoading={isQueryMetricsLoading}
        title={t({ id: 'estimatedCostThisMonth' })}
        units={0}
        unitsName={t({ id: 'nok' })}
      />
    </Layout>
  )
}

export default CostInfo
