import React, { useContext } from 'react'
import { Layout, Text } from '@di-nxt/components'
import { ApiContext } from '../../providers/api.provider'
import { TableUserOrganizationProps } from './TableUserOrganization.type'
/**
 *
 * Organization ids are returned as a part of user meta data
 * Map them to organization to get a name
 */
const TableUserOrganization: React.FC<TableUserOrganizationProps> = ({
  organizationId,
}) => {
  // Attach the organization context
  const { organizationService } = useContext(ApiContext)
  const { organizations } = organizationService
  return (
    <Layout width="100%" align="center" justify="flex-start">
      <Text variant="inline_14_default">
        {organizations.find((org) => org.organizationId === organizationId)
          ?.organizationName ?? ''}
      </Text>
    </Layout>
  )
}

TableUserOrganization.displayName = 'TableUserOrganization'
export default TableUserOrganization
