import React from 'react'
import { Layout, Spinner } from '@di-nxt/components'

import { UserListProps } from './index.type'
import UserListItem from './UserListItem'

/**
 *
 * Render list of users to be selected for the workspace
 */
const UserList: React.FC<UserListProps> = ({
  users,
  loadingUsers,
  onAddWorkspaceUser,
  workspaceUsers,
  onRemoveWorkspaceUser,
  selectedRole,
  excludeUsers,
}) => {
  /**
   *
   * Conditionally render spinner if users are still being loaded
   */
  const renderSpinner = () => {
    if (loadingUsers) {
      return (
        <Layout align="center" justify="center" width="100%" height="100%">
          <Spinner size="Default" />
        </Layout>
      )
    }

    return null
  }
  return (
    <Layout width="100%" vertical height="200px" overflow="auto">
      {renderSpinner()}
      {!loadingUsers &&
        users &&
        users.map((user) => (
          <UserListItem
            isAddedToWorkspace={
              workspaceUsers.find(
                (workspaceUser) => workspaceUser.userId === user.userId,
              ) !== undefined
            }
            user={user}
            key={user.userId}
            onAddWorkspaceUser={onAddWorkspaceUser}
            onRemoveWorkspaceUser={onRemoveWorkspaceUser}
            selectedRole={selectedRole}
            excludeUser={
              excludeUsers?.find(
                (excludedUser) => excludedUser.userId === user.userId,
              ) !== undefined
            }
          />
        ))}
    </Layout>
  )
}

UserList.displayName = 'UserList'

export default UserList
