import {
  Button,
  DialogProps,
  DialogSize,
  Layout,
  LayoutScrollable,
  Spacer,
  Text,
} from '@di-nxt/components'
import assert from 'assert-ts'
import React, { useEffect } from 'react'
import { Backdrop } from './style'
import { useAsyncCallback } from './useAsyncCallback'

type DataProps = {
  width?: number
  'data-cy'?: string
}

/**
 *
 * Temporary dialog supporting clicks outside the dialog
 * and escape key presses
 */
export const CustomDialog: React.FC<DialogProps & DataProps> = ({
  title,
  subtitle,
  okButtonDisabled = false,
  size = 'Small',
  okButtonTitle,
  okButtonKind = 'Primary',
  cancelButtonTitle,
  hasCloseButton,
  hideButtons = false,
  onOkButton,
  onCancelButton,
  children,
  width,
  ...rest
}) => {
  const [isSubmitting, handleOkButton] = useAsyncCallback(onOkButton)
  if (hasCloseButton) {
    assert(
      onCancelButton,
      'onCancelButton should be set when using hasCloseButton',
    )
  }

  const closeDialog = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target !== e.currentTarget) {
      return
    }
    if (onCancelButton) {
      onCancelButton()
    }
  }

  useEffect(() => {
    const close = (e: Event) => {
      if (
        (e as unknown as React.KeyboardEvent<HTMLDivElement>).key ===
          'Escape' &&
        onCancelButton
      ) {
        onCancelButton()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onCancelButton])

  return (
    <Backdrop onClick={closeDialog} data-cy={rest['data-cy']}>
      <Layout
        width={width || SizeMap[size].width}
        minHeight={SizeMap[size].height}
      >
        <Layout
          shadow
          flex
          vertical
          borderRadius="medium"
          backgroundColor="background_mono_primary"
        >
          <Layout
            horizontal
            justify="space-between"
            align="center"
            borderColor="background_mono_primary"
            borderBottomColor="border_mono_10"
            borderBottomWidth="hairline"
            borderBottomStyle={size === 'Small' ? 'none' : 'solid'}
            shadow={size !== 'Small'}
            paddingHorizontal="28"
            paddingVertical={size === 'Large' ? '28' : undefined}
            paddingTop={size === 'Small' ? '28' : undefined}
          >
            <Layout vertical>
              <Text variant="paragraph_20_emphasis">{title}</Text>
              {subtitle && (
                <Text
                  textColor="content_mono_secondary"
                  variant="paragraph_14_default"
                >
                  {subtitle}
                </Text>
              )}
            </Layout>
            {hasCloseButton && onCancelButton && (
              <Button
                leftIcon="close"
                state="Default"
                kind="Clear"
                onPress={onCancelButton}
              />
            )}
          </Layout>
          <Layout flex>
            {size === 'Small' && (
              <Layout
                flex
                horizontal
                paddingVertical="12"
                paddingHorizontal="28"
              >
                {children}
              </Layout>
            )}
            {size === 'Large' && (
              <LayoutScrollable
                vertical
                absolute
                paddingVertical="12"
                paddingHorizontal="28"
              >
                {children}
              </LayoutScrollable>
            )}
          </Layout>
          {!hideButtons && (
            <Layout
              justify="flex-end"
              paddingVertical="24"
              paddingHorizontal="28"
              borderColor="background_mono_primary"
              borderTopColor="border_mono_10"
              borderTopStyle={size === 'Small' ? 'none' : 'solid'}
              borderTopWidth="hairline"
            >
              {onCancelButton && (
                <>
                  <Button
                    kind="Secondary"
                    state={isSubmitting ? 'Disabled' : 'Default'}
                    title={cancelButtonTitle}
                    onPress={onCancelButton}
                  />
                  <Spacer width="8" />
                </>
              )}
              <div data-cy={'create-user-button'}>
                <Button
                  kind={okButtonKind}
                  state={
                    isSubmitting || okButtonDisabled ? 'Disabled' : 'Default'
                  }
                  spinning={isSubmitting}
                  title={okButtonTitle}
                  onPress={handleOkButton}
                />
              </div>
            </Layout>
          )}
        </Layout>
      </Layout>
    </Backdrop>
  )
}

CustomDialog.displayName = 'CustomDialog'
export default CustomDialog

const SizeMap: { [key in DialogSize]: { width?: number; height?: number } } = {
  FullScreen: {
    width: undefined,
    height: undefined,
  },
  Large: {
    width: 1004,
    height: 688,
  },
  Small: {
    width: 480,
    height: undefined,
  },
}
