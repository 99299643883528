import React, { useContext } from 'react'
import { Button, Text, Input, Layout } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import {
  StyledSearchContainer,
  StyledWorkspacesHeader,
  StyledWorkspacesHeaderDetails,
} from './style'
import { WorkspaceHeaderProps } from './index.type'
import { AppStateContext } from '../../../providers/app.state.provider'

/**
 * Displays the title the workspaces count and a button to add workspace
 */
const WorkspaceHeader: React.FC<WorkspaceHeaderProps> = ({
  joinedWorkspaces,
  searchWorkspaceString,
  handleSetSearchWorkspacesString,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // App state context
  const { manageDialog } = useContext(AppStateContext)

  /**
   *
   * Handle opening of create workspace wizard dialog
   */
  const handleOpenCreateWorkspaceWizrd = () =>
    manageDialog({ createWorkspaceDialog: true })

  // Rturn the rendered component
  return (
    <StyledWorkspacesHeader>
      <StyledWorkspacesHeaderDetails>
        <h1>{t({ id: 'yourWorkspaces' })}</h1>
        <Text variant="inline_14_default">{`(${joinedWorkspaces ?? 0})`}</Text>
        <button onClick={handleOpenCreateWorkspaceWizrd}>
          <Button title={t({ id: 'createWorkspace' })} leftIcon="plus"></Button>
        </button>
      </StyledWorkspacesHeaderDetails>
      <Layout justify="space-between" flex width="100%">
        <StyledSearchContainer>
          <label>{t({ id: 'search' })}</label>
          <Input
            rightIcon="search"
            placeholder={t({ id: 'searchWorkspacePlaceholder' })}
            value={searchWorkspaceString}
            onChange={handleSetSearchWorkspacesString}
          />
        </StyledSearchContainer>
      </Layout>
    </StyledWorkspacesHeader>
  )
}

WorkspaceHeader.displayName = 'WorkspaceHeader'

export default WorkspaceHeader
