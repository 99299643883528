import { Layout, Spinner, Text } from '@di-nxt/components'
import React from 'react'

type Props = {
  title: string
  units?: string | number
  unitsName: string
  isLoading: boolean
}

const CostItem: React.FC<Props> = ({ title, units, unitsName, isLoading }) => {
  return (
    <Layout vertical>
      <Text variant="inline_12_emphasis">{title}</Text>
      <Layout align="flex-end" justify="center" paddingTop="10">
        {isLoading ? (
          <Spinner size="Small" />
        ) : (
          <>
            <Text
              variant="inline_40_emphasis"
              textColor="border_accent_default"
            >
              {units}
            </Text>
            <Layout paddingBottom="6" paddingLeft="10">
              <Text
                variant="inline_12_default"
                textColor="content_mono_primary"
              >
                {unitsName}
              </Text>
            </Layout>
          </>
        )}
      </Layout>
    </Layout>
  )
}

export default CostItem
