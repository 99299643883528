import { useState } from 'react'
import useApi from './useApi'
import { ENDPOINTS } from '../../types/config.api.type'
import { UseWorkspaceCloudApi } from './useWorkspaceCloudApi.type'

export const defaultUseWorkspaceCloudApi: UseWorkspaceCloudApi = {
  getStatus: () => null,
  resubmitResources: () => null,
  status: null,
  loading: false,
  error: null,
  organizationId: null,
  workspaceId: null,
}

const useWorkspaceCloudApi = (): UseWorkspaceCloudApi => {
  const { fetcher } = useApi()

  const [status, setStatus] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const [workspaceId, setWorkspaceId] = useState<string | null>(null)
  const [organizationId, setOrganizationId] = useState<string | null>(null)

  const getStatus = (organizationId: string, workspaceId: string) => {
    setStatus(null)
    setLoading(true)
    setError(null)
    setWorkspaceId(null)
    setOrganizationId(null)
    fetcher
      .get<{ cloudApiStatus?: string }>(
        ENDPOINTS.ORGANIZATION_WORKSPACE_CLOUD_API_STATUS.replace(
          '$organizationId',
          organizationId!,
        ).replace('$workspaceId', workspaceId!),
      )
      .then((res) => setStatus(res.data.cloudApiStatus || 'UNKNOWN'))
      .catch((e) => setError(e))
      .finally(() => {
        setLoading(false)
        setWorkspaceId(workspaceId)
        setOrganizationId(organizationId)
      })
  }

  const resubmitResources = (organizationId: string, workspaceId: string) => {
    setStatus(null)
    setLoading(true)
    setError(null)
    setWorkspaceId(null)
    setOrganizationId(null)
    fetcher
      .put(
        ENDPOINTS.ORGANIZATION_WORKSPACE_CLOUD_API_RESOURCES.replace(
          '$organizationId',
          organizationId!,
        ).replace('$workspaceId', workspaceId!),
      )
      .catch((e) => setError(e))
      .finally(() => {
        getStatus(organizationId, workspaceId)
      })
  }

  return {
    status,
    loading,
    error,
    workspaceId,
    organizationId,
    getStatus,
    resubmitResources,
  }
}

export default useWorkspaceCloudApi
