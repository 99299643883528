import { Icon, Input, Layout, LeftEnhancer, Spacer } from '@di-nxt/components'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import Dropdown from './Dropdown'
import { SearchableDropdownProps } from './SearchableDropdown.type'

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  searchText,
  updateSearchText,
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)

  const { formatMessage: t } = useIntl()

  const handleDropdownChange = () => {
    setIsDropdownOpened((isDropdownOpened) => !isDropdownOpened)
  }

  return (
    <Layout paddingTop="6" flex height={44} maxHeight={44}>
      <Input
        flex
        onChange={updateSearchText}
        rightIcon="arrowDownSmall"
        rightOnPress={handleDropdownChange}
        placeholder={t({ id: 'searchPlaceholder' })}
        value={searchText}
      >
        <LeftEnhancer>
          <Icon name="search" size="24" />
          <Spacer width="10" />
        </LeftEnhancer>
      </Input>
      {isDropdownOpened && <Dropdown />}
    </Layout>
  )
}

SearchableDropdown.displayName = 'SearchableDropdown'

export default SearchableDropdown
