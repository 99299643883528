import { Spacer, Text } from '@di-nxt/components'
import { throttle } from 'lodash'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { sidebarWidth } from '../../../../styles/layout'
import { QueryMetric } from '../../types'
import { debounceTimeout, defaultGraphWidth, graphPadding } from './constants'
import { TierUpgradeContainer } from './style'
import TierUpgradeVisualization from './TierUpgradeVisualization'

type Props = {
  queryMetrics: QueryMetric[]
  isQueryMetricsLoading: boolean
}

const EstimatedTierUpgrade: React.FC<Props> = ({
  queryMetrics,
  isQueryMetricsLoading,
}) => {
  const { formatMessage: t } = useIntl()
  const [graphWidth, setGraphWidth] = useState(defaultGraphWidth)

  const handleResize = throttle(
    () => setGraphWidth(window.innerWidth - sidebarWidth - graphPadding),
    debounceTimeout,
  )

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return (
    <TierUpgradeContainer>
      <Text variant="paragraph_20_emphasis">
        {t(
          { id: 'estimatedTierUpgrade' },
          {
            tierName: 'tier',
          },
        )}
      </Text>
      <Spacer height="32" />
      <TierUpgradeVisualization
        graphWidth={graphWidth}
        queryMetrics={queryMetrics}
        isQueryMetricsLoading={isQueryMetricsLoading}
      />
    </TierUpgradeContainer>
  )
}

export default EstimatedTierUpgrade
