import styled from 'styled-components'

export const DatePickerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  paddingtop: 18px;
`

export const TrashIconStyled = styled.img`
  position: absolute;
  right: 12px;
  top: 27px;
  cursor: pointer;
  margin-top: auto;
`
