import { useEffect, useMemo, useRef, useState } from 'react'

export const useAsyncCallback = <
  T extends (() => void) | (() => Promise<void>) | undefined,
>(
  callback?: T,
): [boolean, T] => {
  const [isRunning, setIsRunning] = useState<boolean>(false)
  const isUnmountedRef = useRef<boolean>(false)
  const handleCallback = useMemo(() => {
    if (callback) {
      return () => {
        if (isRunning) return
        const retVal = callback()
        if (retVal !== undefined && Promise.resolve(retVal) === retVal) {
          // We got a promise (according to:
          // http://www.ecma-international.org/ecma-262/6.0/#sec-promise.resolve)
          setIsRunning(true)
          retVal
            .then(() => {
              !isUnmountedRef.current && setIsRunning(false)
            })
            .catch(() => {
              !isUnmountedRef.current && setIsRunning(false)
            })
        }
      }
    }
    return undefined
  }, [callback, isRunning])

  useEffect(
    () => () => {
      isUnmountedRef.current = true
    },
    [],
  )

  return [isRunning, handleCallback as T]
}
