import { QueryResponseData, Resource } from '../hooks/api/useQuery.type'

export const parseResources = ({ Records }: QueryResponseData) =>
  Records.reduce((resources, row) => {
    const tableName = Object.values(row[0])[0]
    const columnName = Object.values(row[1])[0]

    if (
      resources.length > 0 &&
      resources[resources.length - 1].name === tableName
    ) {
      resources[resources.length - 1].columns.push(columnName as string)
      return resources
    }
    return [
      ...resources,
      { name: tableName, columns: [columnName] },
    ] as Resource[]
  }, [] as Resource[])
