export const parseModel = (model: Record<string, any>) => {
  const database = model.database[1].name
  const tables = getTables(Object.values(model.tables), model)
  return {
    database,
    tables,
  }
}

const getTables = (tables: Table[], model: Record<string, any>) => {
  return tables.map((table) => ({
    tableName: table.name,
    columns: getColumns(table.fieldIds, model),
    schemaName: getSchemaName(table.schemaId, model),
    description: table.note ?? '',
  }))
}
const getSchemaName = (schemaId: number, model: Record<string, any>) =>
  model.schemas[schemaId].name

const getColumns = (ids: number[], model: Record<string, any>) =>
  ids.map((id) => ({
    columnName: model.fields[id].name,
    type: model.fields[id].type.type_name,
    description: (model.fields[id] as { note?: string }).note ?? '',
    isPii: ((model.fields[id] as { note?: string }).note ?? '').includes(
      '[PII]',
    ),
    references: getReferences(model.fields[id].endpointIds, model),
  }))

const getReferences = (endPointIds: number[], model: Record<string, any>) => {
  let references: string[] = []
  for (const endPointId of endPointIds) {
    const endpoint = model.endpoints[endPointId]
    const ref = model.refs[endpoint.refId]
    const otherEndpoints = (ref.endpointIds as number[]).filter(
      (id) => id !== endPointId,
    )
    references = [
      ...references,
      ...otherEndpoints.flatMap((epId) => {
        const ep = model.endpoints[epId]
        return ep.fieldNames.map((name: string) => `${ep.tableName}.${name}`)
      }),
    ]
  }
  return references
}

type Table = {
  id: number
  name: string
  alias: string | null
  fieldIds: number[]
  indexIds: number[]
  schemaId: number
  groupId: string | null
  note?: string | undefined
}
