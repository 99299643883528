import React from 'react'
import { FormattedMessage } from 'react-intl'
import InformationPanel from '../../../../components/InformationPanel'
import InfoIcon from '../../../../assets/icons/blue_info_icon.svg'

/**
 *
 * Message introducing the workspace to the user
 */
const WorkspaceHomeIntroduction: React.FC = () => {
  return (
    <InformationPanel
      backgroundColor="background_accent_light"
      width="100%"
      panelId="workspace_home_introduction"
      borderColor="border_accent_default"
      message={() => (
        <FormattedMessage id="workspaceInstruction" values={{ br: <br /> }} />
      )}
      img={InfoIcon}
      alignStart
      textColor="content_accent_default"
      marginTop="32"
      marginBottom="32"
    />
  )
}

WorkspaceHomeIntroduction.displayName = 'WorkspaceHomeIntroduction'
export default WorkspaceHomeIntroduction
