import React from 'react'
import { Avatar, Layout, Spacer, Text } from '@di-nxt/components'

import { SelectorItemProps } from './index.type'

/**
 *
 * Individual item in the list of workspaces selector
 */
const SelectorItem: React.FC<SelectorItemProps> = ({
  selected,
  onSelectItem,
  data,
  title,
  subtitle,
}) => {
  /** Handle navigate to another workspace */
  const handleNavigate = () => onSelectItem(data)

  return (
    <Layout vertical flex>
      <Layout
        cursor="pointer"
        backgroundColor={'background_mono_primary'}
        hover
        horizontal
        flex={1}
        width={350}
        {...(!selected && { onPress: handleNavigate })}
        {...(selected && {
          borderLeftWidth: 'thick',
          borderLeftStyle: 'solid',
          borderLeftColor: 'background_accent_default',
        })}
      >
        <Layout padding="12" flex={1}>
          <Avatar name={title?.toLocaleUpperCase()} shape="square" />
          <Spacer width="12" />
          <Layout vertical align="flex-start" justify="center">
            <Text variant="inline_12_emphasis">{title}</Text>
            {subtitle && (
              <Text
                variant="inline_12_default"
                textColor="content_mono_secondary"
              >
                {subtitle}
              </Text>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

SelectorItem.displayName = 'WorkspaceSelectorItem'
export default SelectorItem
