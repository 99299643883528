import { HttpStatusCode } from 'axios'
import { useEffect, useState } from 'react'
import useTableauFetch from './useTableauFetch'
import useTableauRestClient from './useTableauRestClient'
import { TableauUser, TableauUserSiteRole } from '../../types/tableau.api.type'
import { QueryHookParams } from '../types'
import { User } from '../../types/user.type'

const useTableauUsers = (siteContentUrl: string, params?: QueryHookParams) => {
  const { fetcher } = useTableauRestClient(siteContentUrl)

  const { data: fetchedUsers, loading } = useTableauFetch<{
    users: { user: TableauUser[] }
  }>(siteContentUrl, 'sites/:siteId/users', undefined, {
    disabled: params?.disabled,
  })

  const [users, setUsers] = useState<TableauUser[]>([])

  useEffect(() => {
    setUsers(fetchedUsers?.users.user ?? [])
  }, [fetchedUsers])

  const createUser = async (
    dapUser: User,
    siteRole: TableauUserSiteRole,
  ): Promise<void> => {
    const {
      data: { user: createdUser },
    } = await fetcher.post<{ user: TableauUser }>(
      'sites/:siteId/users',
      {
        user: {
          name: dapUser.userId,
          siteRole,
        },
      },
      {
        validateStatus: (status) => status === HttpStatusCode.Created,
      },
    )

    await fetcher.put(
      `sites/:siteId/users/${createdUser.id}`,
      {
        user: {
          fullName: `${dapUser.firstName} ${dapUser.lastName}`,
          //email: dapUser.email, //doesn't work when authenticating using JWT
          siteRole: createdUser.siteRole,
        },
      },
      {
        validateStatus: (status) => status === HttpStatusCode.Ok,
      },
    )

    setUsers((users) => [...users, createdUser])
  }

  const updateUser = async (
    id: string,
    siteRole: TableauUserSiteRole,
  ): Promise<void> => {
    return fetcher
      .put(
        `sites/:siteId/users/${id}`,
        {
          user: {
            siteRole,
          },
        },
        {
          validateStatus: (status) => status === HttpStatusCode.Ok,
        },
      )
      .then((response) => {
        const { siteRole } = response.data.user
        setUsers((users) =>
          users.map((user) => {
            if (user.id === id) {
              user.siteRole = siteRole
            }
            return user
          }),
        )
      })
  }

  const deleteUser = async (id: string): Promise<void> => {
    return fetcher
      .delete(`sites/:siteId/users/${id}`, {
        validateStatus: (status) => status === HttpStatusCode.NoContent,
      })
      .then((response) => {
        setUsers((users) => users.filter((user) => user.id !== id))
      })
  }

  const assignUserRole = async (
    dapUser: User,
    siteRole: TableauUserSiteRole,
  ): Promise<void> => {
    const user = users.find((user) => user.name === dapUser.userId)
    if (siteRole !== TableauUserSiteRole.Unassigned) {
      return user
        ? updateUser(user.id, siteRole)
        : createUser(dapUser, siteRole)
    }
    return user ? deleteUser(user.id) : Promise.resolve()
  }

  return {
    users,
    loading,
    assignUserRole,
  }
}

export default useTableauUsers
