import { useMemo } from 'react'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { useTableauAuthenticator } from '../../auth/tableauAuthenticator'

export enum TableauPathParams {
  siteId = ':siteId',
  siteContentUrl = ':siteContentUrl',
  userId = ':userId',
}

/**
 * Use Tableau API Hook to make calls to the Tableau REST API
 */
const useTableauRestClient = (siteContentUrl: string) => {
  const tableauAuthenticator = useTableauAuthenticator(siteContentUrl)

  const fetcher = useMemo(() => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_TABLEAU_API_PATH,
    })

    axiosInstance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig<any>) => {
        if (!tableauAuthenticator.isAuthenticated) {
          await tableauAuthenticator.authenticate()
        }
        config.headers['X-Tableau-Auth'] = tableauAuthenticator.token
        config.url = config.url
          ?.replace(TableauPathParams.siteId, tableauAuthenticator.siteId)
          .replace(
            TableauPathParams.siteContentUrl,
            tableauAuthenticator.siteContentUrl,
          )
          .replace(TableauPathParams.userId, tableauAuthenticator.userId)
        return config
      },
    )

    return axiosInstance
  }, [tableauAuthenticator])

  return {
    fetcher,
  }
}

export default useTableauRestClient
