import React from 'react'

import Workspaces from '../../components/Workspaces'
import Welcome from './Welcome'
import { withHeader, withSidebar } from '../../hooks'

/**
 * Homepage container component
 */
const HomepageContainer: React.FC = () => {
  return (
    <>
      <Welcome />
      <Workspaces />
    </>
  )
}

HomepageContainer.displayName = 'HomepageContainer'

export default withSidebar(withHeader(HomepageContainer))
