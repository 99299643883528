import React, { useState } from 'react'
import { Layout, Text, Icon, Spinner, BaseLayout } from '@di-nxt/components'

import DropdownItem from './DropdownItem'

import { TableCellDropdownProps } from './index.type'

import { DropdownItem as ItemType } from '../../types/dropdown.type'

/**
 *
 * A custom dropdown component for table cells
 */
const TableCellDropdown: React.FC<TableCellDropdownProps> = ({
  selected,
  items,
  onSelect: handleSelect,
  spinning,
  maxWidth,
  showCheckmark,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const handleDropdown = () => {
    setIsOpened((opened) => !opened)
  }

  const selectHandler = (item: ItemType) => {
    setIsOpened(false)
    handleSelect(item)
  }

  return (
    <Layout width="100%">
      <Layout
        padding="10"
        borderColor="border_mono_30"
        borderWidth="hairline"
        borderStyle="solid"
        borderRadius="small"
        width="100%"
        justify="space-between"
        align="center"
        onPress={handleDropdown}
      >
        <BaseLayout flex ellipsis>
          <Text variant="paragraph_14_default">{selected}</Text>
        </BaseLayout>
        {spinning ? <Spinner size="Small" /> : <Icon name="arrowDownSmall" />}
      </Layout>
      {isOpened && (
        <Layout
          shadow="raised"
          flex
          backgroundColor="background_mono_primary"
          position="absolute"
          zIndex={1000}
          top={48}
          right={0}
          vertical
          borderRadius="small"
          borderColor="border_mono_30"
          borderWidth="hairline"
          borderStyle="solid"
          minWidth="250px"
          maxWidth={maxWidth ?? undefined}
        >
          {items.map((item) => (
            <DropdownItem
              onSelect={selectHandler}
              item={item}
              key={item.key}
              selected={selected === item.key}
              showCheckmark={showCheckmark}
            />
          ))}
        </Layout>
      )}
    </Layout>
  )
}

TableCellDropdown.displayName = 'TableCellDropdown'
export default TableCellDropdown
