import React from 'react'
import { useIntl } from 'react-intl'

import TemplateCard from './TemplateCard'

import BlankTemplate from '../../../../assets/icons/blank_template_icon.svg'
import TemplatesComingSoon from '../../../../assets/icons/templates_coming_soon_icon.svg'
import { SelectTemplateProps } from './index.type'
import { StyledGrid } from './style'

/**
 *
 * Render the template choosing step
 */
const SelectTemplate: React.FC<SelectTemplateProps> = ({
  onSelectWorkspaceTemplate,
  selectedTemplate,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  return (
    <StyledGrid>
      <TemplateCard
        description={t({ id: 'blankWorkspace' })}
        icon={BlankTemplate}
        onSelectTemplate={onSelectWorkspaceTemplate}
        selectedTemplate={selectedTemplate}
      />
      <TemplateCard
        description={() => t({ id: 'templatesPlaceholder' }, { br: <br /> })}
        icon={TemplatesComingSoon}
        state="DISABLED"
        onSelectTemplate={onSelectWorkspaceTemplate}
        selectedTemplate={selectedTemplate}
      />
    </StyledGrid>
  )
}

SelectTemplate.displayName = 'SelectTemplate'

export default SelectTemplate
