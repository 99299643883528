import React from 'react'
import { Dialog, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { DeleteWorkspaceConfirmProps } from './index.type'

/**
 *
 * Render the dialog to confirm delete action on workspace
 */
const DeleteWorkspaceConfirm: React.FC<DeleteWorkspaceConfirmProps> = ({
  onOk: handleOk,
  onCancel: handleCancel,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  return (
    <Dialog
      title={`${t({ id: 'deleteThisWorkspace' })}`}
      onOkButton={handleOk}
      onCancelButton={handleCancel}
      okButtonTitle={t({ id: 'deleteWorkspace' })}
      cancelButtonTitle={t({ id: 'cancel' })}
    >
      <Text variant="paragraph_16_default">
        {t({ id: 'deleteWorkspaceConfirm' }, { br: <br /> })}
      </Text>
    </Dialog>
  )
}

DeleteWorkspaceConfirm.displayName = 'DeleteWorkspaceConfirm'
export default DeleteWorkspaceConfirm
