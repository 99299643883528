import { Layout, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { withHeader, withSidebar } from '../../hooks'

const PrivacyPolicy = () => {
  const { formatMessage: t } = useIntl()

  return (
    <Layout vertical paddingTop="24" paddingBottom="56" maxWidth="570px">
      <Text variant="inline_20_emphasis">
        {t({ id: 'privacyPolicyTitle' })}
      </Text>
      <Layout paddingTop="24">
        <Text variant="paragraph_16_default">
          <span
            dangerouslySetInnerHTML={{
              __html: t({ id: 'privacyPolicyContent' }),
            }}
          ></span>
        </Text>
      </Layout>
    </Layout>
  )
}

export default withSidebar(withHeader(PrivacyPolicy))
