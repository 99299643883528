import { Layout, Spacer, Text } from '@di-nxt/components'
import AccessRestrictedIcon from '../../assets/icons/access_restricted_icon.svg'

const NoAccess: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Layout flex vertical centered>
      <img src={AccessRestrictedIcon} alt={title} />
      <Spacer height="20" />
      <Text align="center" variant="paragraph_20_emphasis">
        {title}
      </Text>
      <Spacer height="20" />
      <Layout maxWidth={600}>
        <Text align="center">{description}</Text>
      </Layout>
    </Layout>
  )
}

export default NoAccess
