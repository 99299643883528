import styled from 'styled-components'

export const StyledCircle = styled.div<StyledCircleProps>`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  width: ${(props) => props.radius}px;
  height: ${(props) => props.radius}px;
`

export type StyledCircleProps = {
  color: string
  radius: number
}
