import { AppColors } from '../types/styles.type'

export const Colors: AppColors = {
  primaryColor: '#29293D',
  inverseColor: '#4D4D61',
  backgroundMonoPrimary: '#FFFFFF',
}

export const DayColors = {
  background_mono_primary: 'rgba(255,255,255,1)',
  background_mono_secondary: 'rgba(245,245,247,1)',
  background_mono_tertiary: 'rgba(232,232,238,1)',
  background_mono_excited: 'rgba(25,25,77,0.05)',
  background_mono_inverse_primary: '#29293D',
  background_mono_inverse_secondary: '#3B3B4E',
  background_mono_inverse_tertiary: '#4D4D61',
  background_mono_inverse_excited: 'rgba(224, 224, 235, 0.1)',
  background_mono_inverse_disabled: 'rgba(36,36,66,0.3)',
  background_overlay_dark: 'rgba(41,41,61,0.4)',
  background_accent_default: 'rgba(116,116,250,1)',
  background_accent_excited: 'rgba(93,93,249,1)',
  background_accent_disabled: 'rgba(116,116,250,0.3)',
  background_accent_light: 'rgba(214,214,254,1)',
  background_positive_default: 'rgba(20,190,149,1)',
  background_positive_excited: 'rgba(18,166,130,1)',
  background_positive_disabled: 'rgba(20,190,149,0.3)',
  background_positive_light: 'rgba(185,236,224,1)',
  background_warning_default: 'rgba(239,145,4,1)',
  background_warning_excited: 'rgba(211,128,3,1)',
  background_warning_disabled: 'rgba(239,145,4,0.3)',
  background_warning_light: 'rgba(251,223,180,1)',
  background_negative_default: 'rgba(245,80,120,1)',
  background_negative_excited: 'rgba(242,33,84,1)',
  background_negative_disabled: 'rgba(245,80,120,0.3)',
  background_negative_light: 'rgba(252,203,215,1)',
  background_location_001: 'rgba(246,76,66,1)',
  background_location_002: 'rgba(234,120,7,1)',
  background_location_003: 'rgba(222,174,19,1)',
  background_location_004: 'rgba(131,201,0,1)',
  background_location_005: 'rgba(10,192,10,1)',
  background_location_006: 'rgba(11,208,139)',
  background_location_007: 'rgba(7,196,210,1)',
  background_location_008: 'rgba(75,161,248,1)',
  background_location_009: 'rgba(128,128,248,1)',
  background_location_010: 'rgba(177,109,246,1)',
  background_location_011: 'rgba(228,89,228,1)',
  background_location_012: 'rgba(235,92,175,1)',
  border_mono_10: 'rgba(18,18,33,0.1)',
  border_mono_15: 'rgba(20,20,31,0.15)',
  border_mono_30: 'rgba(20,20,31,0.3)',
  border_mono_inverse_10: 'rgba(255,255,255,0.1)',
  border_mono_inverse_15: 'rgba(255,255,255,0.15)',
  border_mono_inverse_30: 'rgba(255,255,255,0.3)',
  border_accent_default: 'rgba(116,116,250,1)',
  border_accent_excited: 'rgba(43,43,247,1)',
  border_accent_disabled: 'rgba(116,116,250,0.3)',
  border_positive_default: 'rgba(20,190,149,1)',
  border_positive_excited: 'rgba(13,125,98,1)',
  border_positive_disabled: 'rgba(20,190,149,0.3)',
  border_warning_default: 'rgba(239,145,4,1)',
  border_warning_excited: 'rgba(211,128,4,1)',
  border_warning_disabled: 'rgba(239,145,4,0.3)',
  border_negative_default: 'rgba(245,80,120,1)',
  border_negative_excited: 'rgba(243,43,91,1)',
  border_negative_disabled: 'rgba(245,80,120,0.3)',
  content_mono_primary: 'rgba(41,41,61,1)',
  content_mono_secondary: 'rgba(41,41,61,0.65)',
  content_mono_disabled: 'rgba(41,41,61,0.3)',
  content_mono_inverse_primary: 'rgba(255,255,255,1)',
  content_mono_inverse_secondary: 'rgba(255,255,255,0.65)',
  content_mono_inverse_disabled: 'rgba(255,255,255,0.4)',
  content_accent_default: 'rgba(79,79,255,1)',
  content_accent_excited: 'rgba(26,26,255,1)',
  content_accent_disabled: 'rgba(79,79,255,0.3)',
  content_positive_default: 'rgba(0,172,131,1)',
  content_positive_excited: 'rgba(0,138,105,1)',
  content_positive_disabled: 'rgba(0,172,131,0.3)',
  content_warning_default: 'rgba(202,121,0,1)',
  content_warning_excited: 'rgba(163,98,0,1)',
  content_warning_disabled: 'rgba(202,121,0,0.3)',
  content_negative_default: 'rgba(237,1,58,1)',
  content_negative_excited: 'rgba(188,1,46,1)',
  content_negative_disabled: 'rgba(237,1,58,0.3)',
  transparent: 'rgba(0,0,0,0)',
}

export const NightColors = {
  background_mono_primary: 'rgba(28,28,34,1)',
  background_mono_secondary: 'rgba(41,41,50,1)',
  background_mono_tertiary: 'rgba(57,57,70,1)',
  background_mono_excited: 'rgba(224,224,235,0.1)',
  background_mono_inverse_primary: '#FFFFFF',
  background_mono_inverse_secondary: '#F5F5F7',
  background_mono_inverse_tertiary: '#E8E8EE',
  background_mono_inverse_excited: 'rgba(25,25,77,0.05)',
  background_mono_inverse_disabled: 'rgba(224,224,235,0.3)',
  background_overlay_dark: 'rgba(20,20,31,0.6)',
  background_accent_default: 'rgba(116,116,250,1)',
  background_accent_excited: 'rgba(93,93,249,1)',
  background_accent_disabled: 'rgba(116,116,250,0.4)',
  background_accent_light: 'rgba(58,58,141,1)',
  background_positive_default: 'rgba(20,190,149,1)',
  background_positive_excited: 'rgba(18,166,130,1)',
  background_positive_disabled: 'rgba(20,190,149,0.4)',
  background_positive_light: 'rgba(25,93,80,1)',
  background_warning_default: 'rgba(239,145,4,1)',
  background_warning_excited: 'rgba(211,128,3,1)',
  background_warning_disabled: 'rgba(239,145,4,0.4)',
  background_warning_light: 'rgba(112,75,21,1)',
  background_negative_default: 'rgba(245,80,120,1)',
  background_negative_excited: 'rgba(242,33,84,1)',
  background_negative_disabled: 'rgba(245,80,120,0.4)',
  background_negative_light: 'rgba(115,49,68,1)',
  background_location_001: 'rgba(218,54,45,1)',
  background_location_002: 'rgba(232,124,17,1)',
  background_location_003: 'rgba(207,167,38,1)',
  background_location_004: 'rgba(152,203,58,1)',
  background_location_005: 'rgba(53,206,53,1)',
  background_location_006: 'rgba(42,215,155,1)',
  background_location_007: 'rgba(36,185,195,1)',
  background_location_008: 'rgba(59,137,215,1)',
  background_location_009: 'rgba(100,100,230,1)',
  background_location_010: 'rgba(149,73,224,1)',
  background_location_011: 'rgba(183,55,183,1)',
  background_location_012: 'rgba(214,92,163,1)',
  border_mono_10: 'rgba(255,255,255,0.1)',
  border_mono_15: 'rgba(255,255,255,0.15)',
  border_mono_30: 'rgba(255,255,255,0.3)',
  border_mono_inverse_10: 'rgba(18,18,33,0.1)',
  border_mono_inverse_15: 'rgba(20,20,31,0.15)',
  border_mono_inverse_30: 'rgba(20,20,31,0.3)',
  border_accent_default: 'rgba(116,116,250,1)',
  border_accent_excited: 'rgba(73,73,241,1)',
  border_accent_disabled: 'rgba(116,116,250,0.4)',
  border_positive_default: 'rgba(20,190,149,1)',
  border_positive_excited: 'rgba(13,125,98,1)',
  border_positive_disabled: 'rgba(20,190,149,0.4)',
  border_warning_default: 'rgba(239,145,4,1)',
  border_warning_excited: 'rgba(161,97,3,1)',
  border_warning_disabled: 'rgba(239,145,4,0.4)',
  border_negative_default: 'rgba(245,80,120,1)',
  border_negative_excited: 'rgba(196,42,79,1)',
  border_negative_disabled: 'rgba(245,80,120,0.4)',
  content_mono_primary: 'rgba(255,255,255,1)',
  content_mono_secondary: 'rgba(221,221,232,0.65)',
  content_mono_disabled: 'rgba(221,221,232,0.4)',
  content_mono_inverse_primary: 'rgba(41,41,61,1)',
  content_mono_inverse_secondary: 'rgba(41,41,61,0.65)',
  content_mono_inverse_disabled: 'rgba(41,41,61,0.3)',
  content_accent_default: 'rgba(116,116,250,1)',
  content_accent_excited: 'rgba(93,93,249,1)',
  content_accent_disabled: 'rgba(116,116,250,0.4)',
  content_positive_default: 'rgba(20,190,149,1)',
  content_positive_excited: 'rgba(15,143,112,1)',
  content_positive_disabled: 'rgba(20,190,149,0.4)',
  content_warning_default: 'rgba(239,145,4,1)',
  content_warning_excited: 'rgba(191,116,3,1)',
  content_warning_disabled: 'rgba(239,145,4,0.4)',
  content_negative_default: 'rgba(245,80,120,1)',
  content_negative_excited: 'rgba(230,45,90,1)',
  content_negative_disabled: 'rgba(245,80,120,0.4)',
  transparent: 'rgba(0,0,0,0)',
}
