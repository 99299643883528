import { IconNames } from '@di-nxt/components/dist/components/icons/types'
import styled from 'styled-components'
import { TableState } from '../../index.type'

/**
 *
 * Prop definition for top item in tree view
 */
export type TopItemProps = {
  onClick: () => void
  iconName: IconNames
  isSelected: boolean
  isEditable: boolean
  item: TableState
  onSelected: (tableName: string, columnName?: string) => void
  /**
   * matched part of table name from search keyword
   */
  matchedPart: string | null
}

export const AlignedCheckbox = styled.div`
  > label {
    margin-bottom: 0px;
  }
`
