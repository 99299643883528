import { DayColors } from './../../styles/colors'
import styled from 'styled-components'

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: ${DayColors.background_overlay_dark};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`
