import { Layout, Ruler, Text } from '@di-nxt/components'
import { useContext } from 'react'
import { ApiContext } from '../../../../../providers/api.provider'

/**
 * Component showing infromation about the total number of tables and columns in the dataset
 */
const DataTitle: React.FC = () => {
  const { workspaceDataService } = useContext(ApiContext)
  const { workspaceData } = workspaceDataService

  const tablesCount = workspaceData.tables.length
  const columnsCount = workspaceData.tables.reduce(
    (sum, currentTable) => sum + currentTable.columns.length,
    0,
  )

  return (
    <Layout align="center">
      <Text variant="inline_20_emphasis">{workspaceData.database}</Text>
      <Ruler vertical padding="16" width="thin" color="content_mono_primary" />
      <Text variant="inline_14_default">
        {tablesCount} {tablesCount === 1 ? 'table' : 'tables'}
      </Text>
      <Ruler vertical padding="16" width="thin" color="content_mono_primary" />
      <Text variant="inline_14_default">
        {columnsCount} {columnsCount === 1 ? 'column' : 'columns'}
      </Text>
    </Layout>
  )
}

DataTitle.displayName = 'DataTitle'

export default DataTitle
