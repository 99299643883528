import { useState } from 'react'

import { Tool } from '../../types/tool.type'
import { UseWorkspaceTools } from './useWorkspaceTools.type'

import useApi from './useApi'
import { ENDPOINTS } from '../../types/config.api.type'

/**
 *
 * Export deault properties of the hook
 */
export const defaultWorkspaceTools: UseWorkspaceTools = {
  tools: [],
  loadingTools: false,
  errorLoadingTools: false,
  getWorkspaceTools: () => null,
}

/**
 *
 * Create the hook
 */
const useWorkspaceTools = (organizationId?: string): UseWorkspaceTools => {
  // Local state management
  const [tools, setTools] = useState<Tool[] | undefined>(undefined)
  const [loadingTools, setLoadingTools] = useState<boolean>(false)
  const [errorLoadingTools, setErrorLoadingTools] = useState<boolean>(false)

  const { fetcher } = useApi()

  /**
   *
   * Method to get the tools
   */
  const getWorkspaceTools = async (workspaceId: string) => {
    if (organizationId) {
      const uri = ENDPOINTS.ORGANIZATION_TOOLS.replace(
        '$organizationId',
        organizationId,
      ).replace('$workspaceId', workspaceId)

      // run the async request
      try {
        setLoadingTools(true)
        const tools = await fetcher.get(uri)
        if (tools && tools.data) {
          setTools(tools.data)
        }
      } catch {
        setErrorLoadingTools(true)
        setTools(undefined)
      } finally {
        setLoadingTools(false)
      }
    }
  }

  return {
    tools,
    loadingTools,
    errorLoadingTools,
    getWorkspaceTools,
  }
}

export default useWorkspaceTools
