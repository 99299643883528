import styled from 'styled-components'
import { Layout } from '../../../styles'

/**
 *
 * Override certain styled
 */
export const TabContainer = styled.div`
  width: 100%;
  height: 100%;

  & a {
    text-decoration: none;
    padding-bottom: ${Layout.padding};
  }
`
