import { ThemeType } from '@di-nxt/components'
import styled, { ThemeProps, css } from 'styled-components'

export const Separator = styled.hr`
  border-top: 2px solid rgba(41, 41, 61, 0.3);
  width: 100%;
`

export const ReversedCheckbox = styled.div`
  ${(props: ThemeProps<ThemeType>) => {
    return css`
      & > label > div:first-child {
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;

        & > div:nth-child(1) {
          margin-left: ${props.theme.spacing[10]}px;
        }

        & > div:nth-child(2) {
          padding-left: 0;
        }
      }
    `
  }}
`

export const ViewsList = styled.div`
  ${(props: ThemeProps<ThemeType>) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      gap: ${props.theme.spacing[10]}px;
    `
  }}
`
