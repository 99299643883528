import { HttpStatusCode, ResponseType } from 'axios'
import { useEffect, useState } from 'react'
import useTableauRestClient from './useTableauRestClient'
import { QueryHookParams } from '../types'

type RequestParams = {
  responseType?: ResponseType
}

const useTableauFetch = <T>(
  siteContentUrl: string,
  resourcePath: string,
  requestParams?: RequestParams,
  params?: QueryHookParams,
) => {
  const { fetcher } = useTableauRestClient(siteContentUrl)

  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(!params?.disabled)
  const [errorStatus, setErrorStatus] = useState<number | null>(null)

  useEffect(
    () => {
      if (params?.disabled) {
        return
      }

      setData(null)
      setErrorStatus(null)
      setLoading(true)

      fetcher
        .get(resourcePath, {
          validateStatus: (status) => status === HttpStatusCode.Ok,
          responseType: requestParams?.responseType,
        })
        .then((response) => {
          setData(response.data)
        })
        .catch((e) => setErrorStatus(e.response?.status || -1))
        .finally(() => setLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    params?.queryOnlyOnce
      ? []
      : [siteContentUrl, resourcePath, requestParams?.responseType],
  )

  return {
    data,
    loading,
    errorStatus,
  }
}

export default useTableauFetch
