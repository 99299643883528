import { UserRoles } from '../../types/user.type'
import { UseUserType } from './useUser.type'

import { useAmplitude } from '../../amplitude/useAmplitude'
import { keycloak } from '../../auth/keycloak'

/**
 * Hook to manage keycloak related functions
 */
const useUser = (): UseUserType => {

  const amplitudeService = useAmplitude()

  const { roles } =
    (keycloak.tokenParsed as any)?.resource_access[
      process.env.REACT_APP_KEYCLOAK_CLIENT_ID || ''
    ] ?? ''

  /**
   *
   * Id of the user logged in
   * TODO replace with user api
   */
  const userId = (keycloak.tokenParsed as any)?.sub

  /**
   * Determine if the user is of di admin type
   */
  const isDiAdmin = () => roles?.includes(UserRoles.diAdmin)

  /**
   * Determine if the user is of admin type
   */
  const isAdmin = () => roles?.includes(UserRoles.admin)

  /**
   * Determine if the user is of editor type
   */
  const isEditor = () => roles?.includes(UserRoles.editor)

  /**
   * Get the organization of the user
   * TODO: Endpoint to get user information
   */
  const getOrganization = () => {
    const organizations = (keycloak.tokenParsed as any)?.groups.filter(
      (group: string) =>
        !group.includes('Keycloak') && !group.includes('Di Admin'),
    )
    return organizations[0].split('/')[1]
  }

  const login = keycloak.login

  const logout = () => {
    keycloak.logout()
    amplitudeService.logoutUser()
  }

  return {
    authenticated: keycloak.authenticated,
    getOrganization,
    initialized: true,
    isDiAdmin,
    isAdmin,
    isEditor,
    login,
    logout,
    name: keycloak.tokenParsed ? (keycloak.tokenParsed as any).name : '',
    roles,
    userId,
  }
}

export default useUser
