import React, { useEffect, useContext, ReactNode, Suspense } from 'react'
import { Layout, Spinner } from '@di-nxt/components'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import WorkspaceHeader from './WorkspaceHeader'
import NoContent from '../NoContent'
import LeaveWorkspaceConfirm from '../Dialogs/LeaveWorkspaceConfirm'

import { ApiContext } from '../../providers/api.provider'
import { AppStateContext } from '../../providers/app.state.provider'

import { StyledRow, StyledWorkspaces } from './style'
import { WorkspaceUserRole } from '../../types/workspace.type'
import { LeaveWorkspaceUserInput } from '../../types/workspace.users.type'
// Testing out lazy loading components
const WorkspacesTable = React.lazy(() => import('./WorkspacesTable'))

/**
 * List of workspaces to display on homepage and other sections
 */
const Workspaces: React.FC = () => {
  // Attach the workspaces context with the component
  const {
    workspacesService,
    user,
    workspaceUsersService,
    selectedOrganization,
  } = useContext(ApiContext)
  const { manageDialog, openDialogs } = useContext(AppStateContext)
  const navigate = useNavigate()

  /** Get the hook that fetches the list of workspaces */
  const {
    debouncedSearchString,
    loadingWorkspaces,
    workspaces,
    searchWorkspacesString,
    handleSetSearchWorkspacesString,
    handleGetWorkspaces,
  } = workspacesService

  // Method to leavea a workspace
  const { leaveWorkspace } = workspaceUsersService

  /**
   *
   * Fetch workspaces on initialization
   */
  useEffect(() => {
    handleGetWorkspaces()
  }, [debouncedSearchString]) //eslint-disable-line

  /**
   *
   * Fetch workspaces on organisation change
   */
  useEffect(() => {
    handleGetWorkspaces(selectedOrganization?.organizationId)
  }, [selectedOrganization]) //eslint-disable-line

  /** Render the loading spinner */
  const renderSpinner = () => (
    <Layout width="100%" height="200px" align="center" justify="center">
      <Spinner kind="Primary" size="Large" />
    </Layout>
  )

  /**
   *
   * When leaving workspace
   */
  const handleLeaveWorkspace = (canLeaveWorkspace?: boolean) => {
    const workspaceId = openDialogs.selectedWorkspace?.workspaceId.replace(
      'Workspace#',
      '',
    )

    if (!canLeaveWorkspace) {
      manageDialog({ leaveWorkspaceConfirm: false })
      navigate(`/workspace/${workspaceId}/users`)
    } else if (canLeaveWorkspace && user && workspaceId) {
      const currentWorkspaceUser = openDialogs.selectedWorkspace?.workspaceUsers.find(
        (workspaceUser) => workspaceUser.userId === user.userId,
      )

      const payload: LeaveWorkspaceUserInput = {
        workspaceKey: openDialogs.selectedWorkspace?.workspaceKey,
        organizationKey: user.organization?.organizationKey,
        workspaceRole: currentWorkspaceUser?.workspaceRole ?? '',
        email: currentWorkspaceUser?.email ?? '',
        workspaceUsers: openDialogs.selectedWorkspace?.workspaceUsers.filter(
          (workspaceUser) => workspaceUser.userId !== user.userId,
        ),
      }

      leaveWorkspace(workspaceId, user.userId, payload, () => {
        handleGetWorkspaces()
      })
    }
  }

  /**
   *
   * When user decides to abandon leave workspace process
   */
  const handleCloseDialog = () => manageDialog({ leaveWorkspaceConfirm: false })

  /**
   *
   * If there are no other owners besides the user, user may not leave the workspace
   */
  const getCanLeaveWorkspace = () => {
    if (openDialogs.selectedWorkspace) {
      const { selectedWorkspace } = openDialogs
      // Get the users role in the workspace
      const userWorkspaceRole = selectedWorkspace.workspaceUsers.find(
        (workspaceUser) => workspaceUser.userId === user?.userId,
      )

      if (
        userWorkspaceRole &&
        userWorkspaceRole.workspaceRole ===
          (WorkspaceUserRole.editor || WorkspaceUserRole.viewer)
      ) {
        return true
      }

      if (
        userWorkspaceRole?.workspaceRole === WorkspaceUserRole.owner &&
        selectedWorkspace.workspaceUsers.filter(
          (u) => u.workspaceRole === WorkspaceUserRole.owner,
        ).length === 1
      ) {
        return false
      }
    }
    return true
  }

  /**
   *
   * Open create workspace dialog
   */
  const openCreateWorkspaceDialog = () =>
    manageDialog({ createWorkspaceDialog: true })

  /**
   *
   * Create a message to display when there are no workspaces
   */
  const getNoContentMessage = () => {
    return (
      <FormattedMessage
        id="noWorkspaces"
        values={{
          hyperlink: (chunks: ReactNode) => (
            <button
              className="hyperlink-button"
              onClick={openCreateWorkspaceDialog}
            >
              {chunks}
            </button>
          ),
        }}
      />
    )
  }

  return (
    <StyledWorkspaces>
      <WorkspaceHeader
        joinedWorkspaces={(!loadingWorkspaces && workspaces?.length) || 0}
        searchWorkspaceString={searchWorkspacesString}
        handleSetSearchWorkspacesString={handleSetSearchWorkspacesString}
      />
      <StyledRow>
        {loadingWorkspaces && renderSpinner()}
        <Suspense fallback={renderSpinner()}>
          {!loadingWorkspaces && (
            <WorkspacesTable workspaces={workspaces ?? []} />
          )}
        </Suspense>

        {!loadingWorkspaces && (!workspaces || workspaces.length === 0) && (
          <NoContent layout={{ top: -42 }} message={getNoContentMessage()} />
        )}
      </StyledRow>
      {openDialogs.leaveWorkspaceConfirm && openDialogs.selectedWorkspace && (
        <LeaveWorkspaceConfirm
          onOk={handleLeaveWorkspace}
          onCancel={handleCloseDialog}
          canLeaveWorkspace={getCanLeaveWorkspace()}
        />
      )}
    </StyledWorkspaces>
  )
}

Workspaces.displayName = 'Workspaces'
export default Workspaces
