import React from 'react'
import { useIntl } from 'react-intl'

import { BaseLayout, Layout, Text, Icon, Button } from '@di-nxt/components'
import { ContentOverlayProps } from './index.type'

/**
 *
 * Content overlay is displayed in scenario when application content is hidden
 * Overlayed by a message, This is a temporary fix as there is no UX for it
 */
const ContentOverlay: React.FC<ContentOverlayProps> = ({
  statusCode,
  errorEntity,
  actionButton,
  actionButtonText,
  onAction,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Get the content based on errorEntity and statusCode
  const getContent = (): React.ReactNode => {
    if (statusCode === 204 && errorEntity === 'User') {
      return (
        <Layout vertical justify="center" align="center">
          <Layout paddingBottom="32">
            <Icon name="absence" size="36" />
          </Layout>
          <Text variant="inline_16_default">{t({ id: 'userNotFound' })}</Text>
          {actionButton && actionButtonText && typeof onAction === 'function' && (
            <Layout paddingTop="32">
              <Button title={actionButtonText} onPress={onAction} />
            </Layout>
          )}
        </Layout>
      )
    }
    return null
  }
  return (
    <BaseLayout
      position="absolute"
      width="100%"
      height="100%"
      align="center"
      justify="center"
    >
      {getContent()}
    </BaseLayout>
  )
}

ContentOverlay.displayName = 'ContentOverlay'

export default ContentOverlay
