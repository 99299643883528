import { Layout, Spacer } from '@di-nxt/components'
import SidebarLogo from './SidebarLogo'
import OrganizationMarker from './OrganizationMarker'
import BottomNavigation from './BottomNavigation'
import { LoweredText, MainContainer } from './style'
import { sideMenuPadding } from './constants'
import { ApiContext } from '../../providers/api.provider'
import { useContext } from 'react'
import MainNavigation from './MainNavigation'
import { useIntl } from 'react-intl'

const SidebarV2 = () => {
  const { formatMessage: t } = useIntl()
  const { user } = useContext(ApiContext)

  const isDiAdmin = !!user?.diAdmin

  return (
    <MainContainer>
      <Layout
        vertical
        padding={`${sideMenuPadding}`}
        width={`calc(100% - ${sideMenuPadding * 2}px)`}
      >
        <SidebarLogo />
        <Spacer height="28" />
        {isDiAdmin && <LoweredText>{t({ id: 'diAdmin' })}</LoweredText>}
        <OrganizationMarker />
        <Spacer height="32" />
        <MainNavigation />
      </Layout>
      <Layout padding={`${sideMenuPadding}`}>
        <BottomNavigation />
      </Layout>
    </MainContainer>
  )
}

export default SidebarV2
