import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ApiContext } from '../../../../providers/api.provider'
import TableInformation from './TableInformation'
import WorkspaceTreeView from './WorkspaceTreeView'
import { getChangesSummary, getResourcePayload } from './util'
import { ResourcesState, Resources } from './index.type'
import { updateResourcesState } from './updateResourcesState'
import { StyledGrid } from '../../../../styles/layout'

const WorkspaceDataContent: React.FC = () => {
  const {
    workspaceDataService,
    queryService,
    workspaceService,
    organizationService,
  } = useContext(ApiContext)
  const {
    workspaceData,
    selectedTable,
    loadingWorkspaceData,
  } = workspaceDataService

  const [showOnlySelectedObjects, setShowOnlySelectedObjects] = useState(false)

  const handleShowObjectsToggler = () => {
    setShowOnlySelectedObjects(
      (showOnlySelectedObjects) => !showOnlySelectedObjects,
    )
  }

  // Resets the state when the model or the resources are updated
  const getInitialResources = useCallback<() => ResourcesState>(() => {
    const resourcesState = workspaceData.tables.map((table) => {
      const resource = workspaceService.workspace?.selectedResources?.find(
        (r) => r.tableName.toLowerCase() === table.tableName.toLowerCase(),
      )
      const resourceColumns = new Set(
        resource?.columns.map(({ columnName }) => columnName.toLowerCase()),
      )

      let includesAll = true
      const columns = table.columns.map((column) => {
        const selected = resourceColumns.has(column.columnName.toLowerCase())
        if (!selected) {
          includesAll = false
        }
        return {
          ...column,
          selected: selected ? 'Checked' : 'NotChecked',
        }
      })

      return {
        ...table,
        selected: includesAll
          ? 'Checked'
          : !resource
          ? 'NotChecked'
          : 'Indetermined',
        columns,
      }
    })
    return {
      resources: resourcesState as Resources,
      stateChange: new Set(),
    }
  }, [workspaceData.tables, workspaceService.workspace?.selectedResources])
  const [resourcesState, setResourcesState] = useState<ResourcesState>(
    getInitialResources(),
  )

  const changeResource = (tableName: string, columnName?: string) => {
    setResourcesState((resourcesState) => {
      const updatedResourcesState = updateResourcesState(
        resourcesState,
        tableName,
        columnName,
      )
      return updatedResourcesState
    })
  }

  const resetSelections = () =>
    setResourcesState((previous) => ({
      resources: previous.resources,
      stateChange: new Set(),
    }))

  // Saves the changes to the tables
  const saveSelection = async () => {
    const resourcePayload = getResourcePayload(resourcesState.resources)

    return queryService.saveSelection(
      {
        organizationKey:
          organizationService.organization?.organizationKey ?? '',
        workspaceKey: workspaceService.workspace?.workspaceKey ?? '',
        workspaceId: workspaceService.workspace?.workspaceId ?? '',
        tables: resourcePayload,
      },
      resetSelections,
    )
  }

  useEffect(() => {
    setResourcesState(getInitialResources())
  }, [getInitialResources])

  // Gets the changes made to the resources
  const resourcesChanged = useMemo(() => {
    return getChangesSummary(resourcesState)
  }, [resourcesState])

  return (
    <StyledGrid>
      <WorkspaceTreeView
        isLoading={loadingWorkspaceData}
        database={workspaceData.database}
        resources={resourcesState.resources}
        selectedTable={selectedTable}
        changeResource={changeResource}
        onOk={saveSelection}
        stateChanged={resourcesState.stateChange.size > 0}
        resourcesChanged={resourcesChanged}
        showOnlySelectedObjects={showOnlySelectedObjects}
        handleShowObjectsToggler={handleShowObjectsToggler}
      />
      <TableInformation
        isLoading={loadingWorkspaceData}
        selectedTable={selectedTable}
      />
    </StyledGrid>
  )
}

WorkspaceDataContent.displayName = 'WorkspaceDataContent'

export default WorkspaceDataContent
