import styled from 'styled-components'
import { Layout } from '../../styles'

/**
 *
 * For sidebar component, since sidebar is position fixed, add offset to content
 */
export const StyledContentWithSidebar = styled.div`
  margin-left: ${Layout.sidebarWidth};
  width: calc(100% - ${Layout.sidebarWidth});
  min-height: 100%;
`
