import styled from 'styled-components'

export const StyledLayout = styled.div`
  padding-top: 8px;
  flex-wrap: wrap;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
  display: flex;
`
