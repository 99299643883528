import React from 'react'
import { useIntl } from 'react-intl'
import { Text } from '@di-nxt/components'
import { format, isYesterday, isToday } from 'date-fns'

import { UserLastSeenProps } from './UserLastSeen.type'

/**
 *
 * Render the last accessed column for a user
 */
const UserLastSeen: React.FC<UserLastSeenProps> = ({ user }) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  /**
   *
   * Get the last accessed data
   */
  const getLastAccessed = () => {
    if (user?.lastSeen) {
      if (isYesterday(user.lastSeen)) {
        return t({ id: 'yesterday' })
      } else if (isToday(user.lastSeen)) {
        return format(new Date(user.lastSeen), 'HH:mm')
      } else {
        return format(new Date(user.lastSeen), 'd.M.yyyy')
      }
    }
    return t({ id: 'never' })
  }
  return <Text variant="inline_14_default">{getLastAccessed()}</Text>
}

UserLastSeen.displayName = 'UserLastSeen'
export default UserLastSeen
