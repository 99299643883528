import React, { useRef } from 'react'
import { Layout, Popup, Spinner } from '@di-nxt/components'
import { sortBy } from 'lodash'
// Other components
import SelectorItem from './SelectorItem'
import SelectorContent from '../WorkspaceSelector/SelectorContent'
// Constants
import { SelectorLayoutProps } from '../../constants/layoutProps'
// Type definitions
import { PopupListProps } from './index.type'
// Styles
import { StyledListContainer } from '../WorkspaceSelector/style'
import DefaultPopupTrigger from './DefaultPopupTrigger'

/**
 *
 * Searchable popup list list
 */
const PopupList: React.FC<PopupListProps> = ({
  title,
  searchValue,
  searchPlaceholder,
  data,
  property,
  onSelectItem,
  onSearchChange,
  heading,
  titleColor,
  loadingData,
  renderSubtitle,
  idKey,
  selectedId,
  renderNoContent,
  PopupTrigger = DefaultPopupTrigger,
}) => {
  /** Create a reference and assign it to the popup trigger */
  const popupTriggerRef = useRef<HTMLDivElement>(null)

  /** When item is selected, close the popup */
  const handleSelectItem = (data: any) => {
    popupTriggerRef.current?.click()
    onSelectItem(data)
  }

  /** Render the content for the popup */
  const renderContent = (): React.ReactNode => {
    if (loadingData) {
      return (
        <Layout align="center" justify="center" height="100%">
          <Spinner />
        </Layout>
      )
    }

    /** Otherwise return list content */
    return (
      <>
        <SelectorContent
          title={heading}
          inputValue={searchValue ?? ''}
          onSearch={onSearchChange}
          inputPlaceholder={searchPlaceholder}
        />
        {!loadingData &&
        (!data || data.length === 0) &&
        typeof renderNoContent === 'function' ? (
          renderNoContent()
        ) : (
          <StyledListContainer>
            {data &&
              sortBy(
                data.filter((datum) => {
                  if (searchValue && searchValue.length > 0) {
                    return (datum as any)[property]
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  }
                  return true
                }),
                (o) => {
                  return (o as any)[property].toLowerCase()
                },
              ).map((datum) => {
                return (
                  <SelectorItem
                    title={(datum as any)[property]}
                    subtitle={
                      typeof renderSubtitle === 'function'
                        ? renderSubtitle(datum)
                        : undefined
                    }
                    data={datum}
                    key={(datum as any)[idKey]}
                    onSelectItem={handleSelectItem}
                    selected={
                      selectedId ? selectedId === (datum as any)[idKey] : false
                    }
                  />
                )
              })}
          </StyledListContainer>
        )}
      </>
    )
  }

  return (
    <Layout>
      <Popup
        trigger={
          <PopupTrigger
            popupTriggerRef={popupTriggerRef}
            title={title}
            titleColor={titleColor}
          />
        }
      >
        {() => {
          return <Layout {...SelectorLayoutProps}>{renderContent()}</Layout>
        }}
      </Popup>
    </Layout>
  )
}

PopupList.displayName = 'PopupList'

export default PopupList
