import { BaseSpinner, Layout } from '@di-nxt/components'
import { memo } from 'react'
import DataDescription from './DataDescription'
import DataTitle from './DataTitle'
import { TableInformationProps } from './index.type'

/**
 * Component rendering information about a selected table
 *
 */
const TableInformation: React.FC<TableInformationProps> = ({
  isLoading,
  selectedTable,
}) => {
  return (
    <Layout borderRadius="round">
      <Layout
        paddingTop="32"
        backgroundColor="background_mono_primary"
        borderRadius="medium"
        paddingLeft="40"
        paddingRight="40"
        vertical
        width="100%"
        paddingBottom="32"
        centered={isLoading}
      >
        {isLoading ? (
          <BaseSpinner size={32} />
        ) : (
          <>
            <DataTitle />
            <DataDescription selectedTable={selectedTable} />
          </>
        )}
      </Layout>
    </Layout>
  )
}

TableInformation.displayName = 'TableInformation'

export default memo(TableInformation)
