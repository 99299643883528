import { BaseLayout } from '@di-nxt/components'
import React from 'react'
import SidebarV2 from '../../components/SidebarV2'
import { StyledContentWithSidebar } from './style'

/**
 * React HOC which appends sidebar to the desired component
 */
const withSidebar = <P extends object>(Component: React.ComponentType<P>) =>
  class WithSidebar extends React.Component {
    render() {
      const { ...props } = this.props
      return (
        <BaseLayout width="100%" minHeight="100%">
          <SidebarV2 />
          <StyledContentWithSidebar>
            <Component {...(props as P)} />
          </StyledContentWithSidebar>
        </BaseLayout>
      )
    }
  }

export default withSidebar
