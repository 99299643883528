import styled from 'styled-components'

/**
 * Layout with custom background color on hover
 */
export const HoveredLayout = styled.div<{
  hoverColor?: string
  boxShadow?: string
  border?: string
  cursor?: string
  width?: string
  button?: boolean
}>`
  > ${(props) => (props.button ? 'button' : 'div')}:hover {
    ${(props) =>
      props.hoverColor ? `background-color: ${props.hoverColor};` : ''}
    ${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow};` : '')}
    ${(props) => (props.border ? `border: ${props.border};` : '')}
    ${(props) => (props.cursor ? `cursor: ${props.cursor};` : '')}
  }
  width: ${(props) => (props.width ? props.width : '')};
`
// > ${(props) => (props.button ? 'button' : 'div')}:hover {
