import { Checkbox, Layout, Spacer, Text } from '@di-nxt/components'
import {
  TableauSelectedView,
  TableauWorkbookWithSelectedViews,
} from '../../../../types/tableau.api.type'
import { ReversedCheckbox, Separator, ViewsList } from './style'
import ViewThumbnail from './ViewThumbnail'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

const ViewsSelection: React.FC<{
  siteContentUrl: string
  workbooks: TableauWorkbookWithSelectedViews[]
  handleSelection?: (workbooks: TableauWorkbookWithSelectedViews[]) => void
}> = ({ siteContentUrl, workbooks, handleSelection }) => {
  const { formatMessage: t } = useIntl()

  const [selectedWorkbooks, setSelectedWorkbooks] = useState(workbooks)

  useEffect(() => setSelectedWorkbooks(workbooks), [workbooks])

  const handleViewSelection =
    handleSelection &&
    ((updatedView: TableauSelectedView) => {
      const updatedWorkbooks = selectedWorkbooks.map((workbook) =>
        updatedView.workbook.id !== workbook.id
          ? workbook
          : {
              ...workbook,
              views: workbook.views.map((view) =>
                updatedView.id !== view.id ? view : updatedView,
              ),
            },
      )
      handleSelection(updatedWorkbooks)
      setSelectedWorkbooks(updatedWorkbooks)
    })

  const handleWorkbookSelection =
    handleSelection &&
    ((updatedWorkbook: TableauWorkbookWithSelectedViews, checked: boolean) => {
      const updatedWorkbooks = selectedWorkbooks.map((workbook) =>
        updatedWorkbook.id !== workbook.id
          ? workbook
          : {
              ...workbook,
              views: workbook.views.map((view) => ({
                ...view,
                selected: checked,
              })),
            },
      )
      handleSelection(updatedWorkbooks)
      setSelectedWorkbooks(updatedWorkbooks)
    })

  const handleAllWorkbooksSelection =
    handleSelection &&
    ((checked: boolean) => {
      const updatedWorkbooks = selectedWorkbooks.map((workbook) => ({
        ...workbook,
        views: workbook.views.map((view) => ({ ...view, selected: checked })),
      }))
      handleSelection(updatedWorkbooks)
      setSelectedWorkbooks(updatedWorkbooks)
    })

  const allViewsSelected = selectedWorkbooks.every((workbook) =>
    workbook.views.every((view) => view.selected),
  )
  const someViewsSelected =
    allViewsSelected ||
    selectedWorkbooks.some((workbook) =>
      workbook.views.some((view) => view.selected),
    )

  return (
    <Layout flex={0} vertical>
      {handleAllWorkbooksSelection && (
        <ReversedCheckbox>
          <Checkbox
            checked={someViewsSelected}
            indeterminate={someViewsSelected && !allViewsSelected}
            label={
              <Text variant="paragraph_20_emphasis">
                {t({ id: 'tableauSelectAllViews' })}
              </Text>
            }
            onChange={(checked) => handleAllWorkbooksSelection(checked)}
          />
        </ReversedCheckbox>
      )}
      {selectedWorkbooks.map((workbook) => {
        const allViewsInWorkbookSelected = workbook.views.every(
          (view) => view.selected,
        )
        const someViewsInWorkbookSelected =
          allViewsInWorkbookSelected ||
          workbook.views.some((view) => view.selected)
        return (
          <div key={workbook.id}>
            <Spacer height="24" />
            <Separator />
            <Spacer height="24" />
            <Layout flex vertical>
              {handleWorkbookSelection ? (
                <ReversedCheckbox>
                  <Checkbox
                    checked={someViewsInWorkbookSelected}
                    indeterminate={
                      someViewsInWorkbookSelected && !allViewsInWorkbookSelected
                    }
                    label={
                      <Text variant="paragraph_20_emphasis">
                        {workbook.name}
                      </Text>
                    }
                    onChange={(checked) =>
                      handleWorkbookSelection(workbook, checked)
                    }
                  />
                </ReversedCheckbox>
              ) : (
                <Text variant="paragraph_20_emphasis">{workbook.name}</Text>
              )}
              <Spacer height="24" />
              <ViewsList>
                {workbook.views.map((view) => (
                  <ViewThumbnail
                    key={view.id}
                    siteContentUrl={siteContentUrl}
                    view={view}
                    handleSelection={handleViewSelection}
                  />
                ))}
              </ViewsList>
            </Layout>
          </div>
        )
      })}
    </Layout>
  )
}

ViewsSelection.displayName = 'TableauViewsSelection'

export default ViewsSelection
