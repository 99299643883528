import { createGlobalStyle } from 'styled-components'
import { DayColors } from './colors'
import { Layout } from './layout'

export { Layout } from './layout'
export { Colors } from './colors'

export const GlobalStyle = createGlobalStyle`

  // H1 definition
  h1 {
    color: rgb(41, 41, 61);
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }

  .inline-icon-clickable {
    cursor: pointer;
    & > img {
      width: 14px;
      height: 14px;
    }
  }

  .snackbar-container {
    width: 400px;
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .hyperlink-button {
    background: transparent;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }

  .text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 12px;
  }

  .transparent-button {
    background: transparent;
    cursor: pointer;
  }

  .natural-hyperlink {
    color: black;
    font-size: 16px;
  }

  label {
    font-size: 12px;
    color: ${DayColors.content_mono_secondary};
    font-weight: 700;
    margin-bottom: ${Layout.marginSmall};
  }

  .breadcrumb_hyperlink {
    font-size: 12px;
    font-weight: 400;

    &:visited {
      color: rgb(41, 41, 61);
    }
  }
`
