import styled from "styled-components";
import { Layout } from '../../../styles'
import { sideMenuPadding } from "../constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(${Layout.sidebarWidth} - ${Layout.marginHuge});
  padding: ${sideMenuPadding}px;
  background: rgba(59, 59, 78, 0.8);
  border-radius: 10px;
`