import { format } from 'date-fns'

export const getNumberOfMillions = (num: number): number =>
  Math.floor(num / 1_000_000)

export const formatYearMonth = (year: number, month: number): string =>
  format(new Date(year, month, 1), 'MMMM, 	yyyy')

export const formatYearMonthShort = (year: number, month: number): string =>
  format(new Date(year, month, 1), 'MMM, 	yy')

export const formatYearMonthFirstDate = (year: number, month: number): string =>
  format(new Date(year, month, 1), 'dd.MM.yyyy')

export const formatMoneyAmount = (
  amount: number,
  currencyCode: string,
): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code',
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace(/^([A-Z]{3})\s*(.+)$/, '$2 $1')

export const formatHours = (hours?: number): number | undefined =>
  hours && parseFloat(hours.toFixed(4))
