import React from 'react'
import { Layout, Popup, Spacer, Text, LayoutProps } from '@di-nxt/components'
import { useNavigate } from 'react-router-dom'

import infoIcon from '../../assets/icons/info_icon.svg'
import { WorkspaceNameProps } from './WorkspaceName.type'
import RedshiftWorkspaceError from '../RedshiftErrors/RedshiftWorkspaceError'

/**
 *
 * Component that is rendered when displaying workspace name in a table
 * The name is interactive as clicking it opens up the specific workspace
 */
const WorkspaceName: React.FC<WorkspaceNameProps> = ({ workspace, user }) => {
  const navigate = useNavigate()
  /**
   *
   * Open the details page of the selected workspace
   */
  const handleOpenWorkspace = () => {
    navigate(`/workspace/${workspace.workspaceId.replace('Workspace#', '')}`)
  }

  const popupProps: LayoutProps = {
    backgroundColor: 'background_mono_primary',
    padding: '16',
    borderRadius: 'small',
    shadow: true,
    vertical: true,
  }

  return (
    <Layout justify="space-between" horizontal width="100%" align="center">
      <button onClick={handleOpenWorkspace} className="hyperlink-button">
        {workspace.workspaceName}
      </button>
      <Layout align="center" justify="center">
        <RedshiftWorkspaceError
          workspace={workspace}
          user={user}
          location="table"
        />
        <Spacer width="12" />
        <Popup
          trigger={
            <div className="inline-icon-clickable">
              <img src={infoIcon} alt="info" />
            </div>
          }
        >
          {() => (
            <Layout {...popupProps}>
              <Text variant="inline_12_emphasis">
                {workspace.workspaceName}
              </Text>
              <Spacer height="8" />
              <Text>{workspace.workspaceDescription}</Text>
            </Layout>
          )}
        </Popup>
      </Layout>
    </Layout>
  )
}

WorkspaceName.displayName = 'WorkspaceName'
export default WorkspaceName
