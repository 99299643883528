import { useCallback, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { ApiContext } from '../../../providers/api.provider'
import { NewsBackdrop } from './styles'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const NewsPopup: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { user } = useContext(ApiContext)
  const { formatMessage: t } = useIntl()

  const closePopup = useCallback(() => setIsOpen(false), [setIsOpen])

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !document.querySelector('.launchnotes-embed')?.contains(event.target) &&
        !document.querySelector('content-modal')
      ) {
        closePopup()
      }
    },
    [closePopup],
  )

  useEffect(() => {
    const closeButton = document.querySelector(
      '.launchnotes-embed .close-button',
    )
    const poweredBy = document.querySelector(
      '.powered-by-launchnotes > p:nth-child(2)',
    )
    poweredBy?.remove()

    document.addEventListener('mousedown', handleClickOutside)
    closeButton?.addEventListener('mousedown', closePopup)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      closeButton?.removeEventListener('mousedown', closePopup)
    }
  }, [isOpen, closePopup, handleClickOutside])

  return (
    <NewsBackdrop
      isOpen={isOpen}
      dangerouslySetInnerHTML={{
        __html: `<launchnotes-embed
                  token="public_lbG7cWXdA9z9vmM5TYq5XFXn"
                  project="pro_Fd3hCYI4mvyyk"
                  categories='["dap"]'
                  toggle-selector="#whats-new-link"
                  heading="${t({ id: 'productNews' })}"
                  heading-color="#FFF"
                  subheading="${t({ id: 'seeOurProductNews' })}"
                  subheading-color="rgba(255, 255, 255, 0.8)"
                  primary-color="#29293D"
                  widget-placement="top-start"
                  widget-offset-skidding="250"
                  widget-offset-distance="-50"
                  show-unread="true"
                  anonymous-user-id="${user?.userId}"
                  unread-placement="left-start"
                  unread-offset-skidding="18"
                  unread-offset-distance="-122"
                  unread-background-color="#5D5DF9"
                  unread-text-color="#ffffff"
                 ></launchnotes-embed>`,
      }}
    />
  )
}

export default NewsPopup
