import React, { ReactNode } from 'react'
import { Dialog, Text } from '@di-nxt/components'
import { useIntl, FormattedMessage } from 'react-intl'
import { DeleteAccountConfirmProps } from './index.type'

/**
 *
 * Render the dialog to confirm delete action on workspace
 */
const DeleteAccountConfirm: React.FC<DeleteAccountConfirmProps> = ({
  onOk: handleOk,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  return (
    <Dialog
      title={`${t({ id: 'deleteAccount' })}`}
      onOkButton={handleOk}
      okButtonTitle={t({ id: 'close' })}
    >
      <Text variant="paragraph_16_default">
        <FormattedMessage
          id="deleteAccountMessage"
          values={{
            'hyper-link': (chunks: ReactNode[]) => (
              <a
                href={`mailto: dap-support@di.no}`}
                className="natural-hyperlink"
              >
                {chunks}
              </a>
            ),
            supportEmail: 'dap-support@di.no',
          }}
        />
      </Text>
    </Dialog>
  )
}

DeleteAccountConfirm.displayName = 'DeleteAccountConfirm'
export default DeleteAccountConfirm
