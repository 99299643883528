import { UpdateRedshiftStatus } from './redshift.type'

/**
 *
 * Type definition for organization
 * - A user belongs to an organization
 * - A user can belong to only one organization
 * - A organization can have many users
 * - A organization can have many workspaces
 */
export type Organization = {
  /**
   *
   * Name of the organization
   */
  organizationName: string

  /**
   *
   * Roles that are associated with an organization.
   * Since keycloak creates these groups (which act as an organization) these id's should be known for:
   * 1 - When a user is added to an organization with a certain role (Admin | Editor | User)
   * 2 - When a user is removed from a group
   */
  organizationRoles: OrganizationRole[]

  /**
   *
   * Id of the organization the user is associated with
   */
  organizationId: string

  /**
   *
   * Organization key is a unique identifier for the organization to help with redshift instance
   */
  organizationKey: string

  /**
   *
   * List of sybase customers
   */
  sybaseCustomers?: number[]

  /**
   *
   * List of slaes organisations
   */
  ownerPartyIDs?: number[]

  /**
   *
   * List of sybase customers
   */
  products?: number[]

  /**
   *
   * Date/time stamp when the organization was created
   */
  dateCreated?: number

  /**
   *
   * Date/time stamp when the organization was modified
   */
  dateModified?: number

  /**
   *
   * Redshift executor status
   */
  organizationCreated?: any
  /**
   *
   * From date
   */
  fromDate?: string
  /**
   *
   * To date
   */
  toDate?: string
}

/**
 *
 * Roles associated with an organization
 */
export type OrganizationRole = {
  /**
   *
   * Name of the group in keycloak
   */
  name: string

  /**
   *
   * Id of the group in keycloak
   */
  id: string
}

/**
 *
 * Input for creating anew organizations
 */
export type OrganizationInput = {
  /**
   *
   * Name of the organization
   */
  organizationName: string

  /**
   *
   * List of sybase customer ids
   */
  sybaseCustomers?: number[]

  /**
   *
   * Organization key is a unique identifier for the organization to help with redshift instance
   */
  organizationKey?: string

  /**
   *
   * List of product ids
   */
  products?: number[]

  /**
   *
   * From date
   */
  fromDate?: string

  /**
   *
   * To date
   */
  toDate?: string

  /**
   *
   * Owner party Ids
   */
  ownerPartyIDs?: number[]
}

/**
 *
 * Type definition for organization input
 */
export type OrganizationInputState = Omit<
  OrganizationInput,
  'toDate' | 'fromDate'
> & {
  /**
   * From date for the organization as a date
   */
  fromDate?: Date
  /**
   * To date for the organization as a date
   */
  toDate?: Date
}

/**
 *
 * Update organization input
 */
export type UpdateOrganizationInput = {
  /**
   *
   * Name of the organization
   */
  organizationName?: string

  /**
   *
   * List of sybase customer ids
   */
  sybaseCustomers?: number[]

  /**
   *
   * List of product ids
   */
  products?: number[]

  /**
   *
   * Owner party Ids
   */
  ownerPartyIDs?: number[]

  /**
   *
   * Redshift status payload
   */
  organizationCreated?: UpdateRedshiftStatus

  /**
   *
   * Organization key is used to update organization in redshift, the value is never updated in dynamo
   */
  organizationKey?: string

  /**
   *
   * From date
   */
  fromDate?: string | null

  /**
   *
   * To date
   */
  toDate?: string | null
}

/**
 *
 * Roles in an organization
 */
export enum OrganizationRoles {
  Admin = 'Admin',
  Editor = 'Editor',
  User = 'User',
}

export type OrganizationSharedData = {
  tables?: string[]
  columns?: string[]
  products?: number[]
  sybaseCustomers?: number[]
  ownerPartyIds?: number[]
}

export type ReceivingOrganizationId = {
  receivingOrganizationId: string
}

export type SendingOrganizationId = {
  sendingOrganizationId: string
}

export type ReceivingOrganizationName = {
  receivingOrganizationName: string
}

export type SendingOrganizationName = {
  sendingOrganizationName: string
}

export type AllOrganizationsSharedData = {
  sentData: (ReceivingOrganizationId &
    ReceivingOrganizationName & {
      sharedData: OrganizationSharedData
    })[]
  receivedData: (SendingOrganizationId &
    SendingOrganizationName & {
      sharedData: OrganizationSharedData
    })[]
}
