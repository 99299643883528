import React, { useRef, useContext } from 'react'
import { Layout, Menu, Popup } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import { TableOrganizationActionsProps } from './TableOrganizationActions.type'
import { AppStateContext } from '../../providers/app.state.provider'

import MoreInActive from '../../assets/icons/more_inactive_icon.svg'

/**
 *
 * Displays the icon that renders a menu
 * The menu has two options:
 * 1 - Enable/disable user
 * 2 - Delete user if the user hasn't logged in yet
 */
const TableOrganizationActions: React.FC<TableOrganizationActionsProps> = ({
  organization,
}) => {
  // Create a reference for the image button
  const moreRef = useRef(null)

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // App state context for dialog state management
  const { manageDialog } = useContext(AppStateContext)

  // Menu items to render in the popup
  const menuItems = [
    t({ id: 'updateOrganization' }),
    t({ id: 'deleteOrganization' }),
  ]

  /**
   *
   * Open a dailog to display infromation about deleting an organization
   */
  const handleDeleteOrganization = () => {
    manageDialog({
      organization: organization,
      deleteOrganizationConfirm: true,
    })
  }

  /**
   *
   * Open a dialog to modify the organization
   */
  const handleModifyOrganization = () =>
    manageDialog({ organization, modifyOrganization: true })

  /**
   *
   * Handler to select an option from the menu
   */
  const handleSelectOption = (index: number) => {
    switch (index) {
      case 0:
        handleModifyOrganization()
        break
      case 1:
        handleDeleteOrganization()
        break
    }

    if (moreRef.current) {
      ;(moreRef.current as any).click()
    }
  }

  return (
    <Layout align="center" justify="center" width="100%" cursor="pointer">
      <Popup trigger={<img src={MoreInActive} alt="more" ref={moreRef} />}>
        {() => <Menu items={menuItems} onChange={handleSelectOption} />}
      </Popup>
    </Layout>
  )
}

TableOrganizationActions.displayName = 'TableOrganizationActions'

export default TableOrganizationActions
