import { Layout, Spinner } from '@di-nxt/components'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Area, AreaChart, ReferenceDot, Tooltip, XAxis, YAxis } from 'recharts'
import { QueryMetric } from '../../types'
import {
  formatYearMonthShort,
  formatYearMonthFirstDate,
  getNumberOfMillions,
} from '../../utils'
import CustomReferenceDot from './CustomReferenceDot'
import CustomTooltip from './CustomTooltip'
import { MainVisalizationContainerStyled, tooltipWrapperStyles } from './style'
import TierUpgradeTooltip from './TierUpgradeTooltip'
import { getCurrentMetricIndex, getNextTierUpgradeIndex } from './utils'

type Props = {
  graphWidth: number
  queryMetrics: QueryMetric[]
  isQueryMetricsLoading: boolean
}

const TierUpgradeVisualization: React.FC<Props> = ({
  graphWidth,
  queryMetrics,
  isQueryMetricsLoading,
}) => {
  const { formatMessage: t } = useIntl()

  const [isMouseOver, setMouseOver] = useState(false)

  const [tierUpgradeTooltipPosition, setTierUpgradeTooltipPosition] = useState({
    cx: 0,
    cy: 0,
  })

  if (isQueryMetricsLoading) {
    return (
      <Layout align="center" justify="center">
        <Spinner size="Large" />
      </Layout>
    )
  }

  if (!queryMetrics.length) {
    return null
  }

  const indexedQueryMetrics = queryMetrics.map((queryMetric, index) => ({
    index,
    ...queryMetric,
  }))

  const currentMetricIndex = getCurrentMetricIndex(queryMetrics)

  const nextTierUpgradeIndex = getNextTierUpgradeIndex(queryMetrics)
  const nextTierUpgradeMetric =
    nextTierUpgradeIndex >= 0 ? queryMetrics[nextTierUpgradeIndex] : null

  return (
    <MainVisalizationContainerStyled>
      <AreaChart
        width={graphWidth}
        height={370}
        data={indexedQueryMetrics}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        onMouseMove={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#F0f0fd" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#F0f0fd" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#A2a5f1" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#A2a5f1" stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="index"
          tickFormatter={(index: number) =>
            formatYearMonthShort(
              indexedQueryMetrics[index].Year,
              indexedQueryMetrics[index].Month,
            )
          }
        />
        <YAxis
          dataKey="DataRows"
          tickFormatter={(num: number) => `${getNumberOfMillions(num)}M`}
        />

        <Area
          type="monotone"
          dataKey={(queryMetric) =>
            queryMetric.index <= currentMetricIndex
              ? queryMetric.DataRows
              : null
          }
          stroke="#A2a5f1"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey={(queryMetric) =>
            queryMetric.index >= currentMetricIndex
              ? queryMetric.DataRows
              : null
          }
          stroke="#A2a5f1"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorPv)"
        />

        {isMouseOver && (
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={tooltipWrapperStyles}
            cursor={false}
          />
        )}

        {!isMouseOver && nextTierUpgradeMetric && (
          <>
            <Tooltip
              content={(props) => (
                <TierUpgradeTooltip
                  {...props}
                  tierName={nextTierUpgradeMetric.Tier}
                  tierUpgradeDate={formatYearMonthFirstDate(
                    nextTierUpgradeMetric.Year,
                    nextTierUpgradeMetric.Month,
                  )}
                />
              )}
              active
              wrapperStyle={tooltipWrapperStyles}
              position={{
                x: tierUpgradeTooltipPosition.cx,
                y: tierUpgradeTooltipPosition.cy,
              }}
            />
            <ReferenceDot
              x={nextTierUpgradeIndex}
              y={nextTierUpgradeMetric.DataRows}
              fill="#A2a5f1"
              ifOverflow="visible"
              fillOpacity={1}
              r={8}
              isFront
              label={() => (
                <h1>
                  {`${t(
                    { id: 'estimatedTierUpgrade' },
                    { tierName: nextTierUpgradeMetric.Tier },
                  )} ${formatYearMonthFirstDate(
                    nextTierUpgradeMetric.Year,
                    nextTierUpgradeMetric.Month,
                  )}`}
                </h1>
              )}
              shape={(props) => (
                <CustomReferenceDot
                  {...props}
                  setTierUpgradeTooltipPosition={setTierUpgradeTooltipPosition}
                />
              )}
            />
          </>
        )}
      </AreaChart>
    </MainVisalizationContainerStyled>
  )
}

export default TierUpgradeVisualization
