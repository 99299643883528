import React, { useContext, useEffect } from 'react'
import { Layout } from '@di-nxt/components'

import { withHeader, withSidebar } from '../../hooks'
import Overview from './Overview'
import useQueryMetrics from '../../hooks/api/useQueryMetrics'
import { ApiContext } from '../../providers/api.provider'

const CostTrackerContainer: React.FC = () => {
  const { selectedOrganization } = useContext(ApiContext)

  const {
    getQueryMetrics,
    queryMetrics,
    isQueryMetricsLoading,
  } = useQueryMetrics(selectedOrganization?.organizationId)

  // Exluded getQueryMetrics from deps to not run few queryMetrics requests
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getQueryMetrics(), [selectedOrganization?.organizationId])

  return (
    <Layout
      paddingLeft="48"
      paddingRight="48"
      backgroundColor="background_mono_secondary"
    >
      <Overview
        queryMetrics={queryMetrics}
        isQueryMetricsLoading={isQueryMetricsLoading}
      />
    </Layout>
  )
}

CostTrackerContainer.displayName = 'CostTrackerContainer'

export default withSidebar(withHeader(() => null, CostTrackerContainer))
