import { Layout, Text } from '@di-nxt/components'
import { formatYearMonth, getNumberOfMillions } from '../../utils'

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Layout padding="12" borderColor="transparent">
        <Text variant="paragraph_14_default">
          {`${formatYearMonth(
            payload[0].payload.Year,
            payload[0].payload.Month,
          )}: ${getNumberOfMillions(payload[0].value)}M`}
        </Text>
      </Layout>
    )
  }

  return null
}

export default CustomTooltip
