import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Input, Layout, Spacer, Text } from '@di-nxt/components'

import SelectedUsers from './SelectedUsers'
import UserList from './UserList'

import { ApiContext } from '../../providers/api.provider'
import { WorkspaceUserRole } from '../../types/workspace.type'
import { DropdownItem } from '../../types/dropdown.type'
import { WorkspaceUserSelectionProps } from './index.type'
import Dropdown from '../Dropdown'
import { useDropdown } from '../../hooks/definitions/useDropdown'

/**
 *
 * A component that lists out users and provides methods for selecting users
 * List of selected users are displayed as avatar items
 * See: https://www.figma.com/file/YNOKCgP7GroorzIJ2wM5oJ/DI-analytics-platform-UI?node-id=303%3A11974
 */
const WorkspaceUserSelection: React.FC<WorkspaceUserSelectionProps> = ({
  users,
  onAddWorkspaceUser,
  onRemoveWorkspaceUser,
  selectedRole,
  onSelectRole,
  excludeUsers,
  hideDescription,
  renderBefore,
}) => {
  // Search filter string
  const [filterString, setFilterString] = useState<string | undefined>(
    undefined,
  )

  // Attach the organization users context here to fetch the list of workspace users
  const { organizationUserService, user } = useContext(ApiContext)

  // Get the roles
  const { workspaceRoles } = useDropdown()

  const {
    getOrganizationUsers,
    loadingUsers,
    users: organizationUsers,
  } = organizationUserService

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // On loading of the component get the list of organization users
  useEffect(() => {
    getOrganizationUsers()
  }, []) //eslint-disable-line

  /**
   *
   * when selecting a role, pass in logged in user information as well
   * So that the changes arent applied to current user
   */
  const handleSelectRole = (role: DropdownItem) => {
    onSelectRole(role.key as WorkspaceUserRole, user)
  }

  /**
   *
   * Set the filter string
   */
  const handleSetFilter = (search: string) => setFilterString(search)

  return (
    <Layout vertical>
      {typeof renderBefore === 'function' && renderBefore()}
      {!hideDescription && (
        <Text variant="paragraph_14_default">
          {t({ id: 'addPeopleToWorkspaceDescription' })}
        </Text>
      )}
      <Layout
        vertical
        {...(!hideDescription && { paddingTop: '32' })}
        width="390px"
      >
        <label>{t({ id: 'filterOrganizationUsers' })}</label>
        <Input
          rightIcon="search"
          onChange={handleSetFilter}
          placeholder={t({ id: 'nameOrEmail' })}
        />
      </Layout>
      <Layout width="100%" paddingTop="24">
        <SelectedUsers users={users} onRemoveUser={onRemoveWorkspaceUser} />
      </Layout>
      <Spacer height="24" />
      <Layout>
        <UserList
          workspaceUsers={users}
          users={
            filterString
              ? organizationUsers?.filter(
                  (orgUser) =>
                    orgUser.firstName
                      .toLowerCase()
                      .includes(filterString.toLowerCase()) ||
                    orgUser.lastName
                      .toLowerCase()
                      .includes(filterString.toLowerCase()) ||
                    orgUser.email.includes(filterString),
                )
              : organizationUsers
          }
          loadingUsers={loadingUsers}
          onAddWorkspaceUser={onAddWorkspaceUser}
          onRemoveWorkspaceUser={onRemoveWorkspaceUser}
          onSelectRole={onSelectRole}
          selectedRole={selectedRole}
          excludeUsers={excludeUsers}
        />
      </Layout>
      <Layout paddingTop="24">
        <Dropdown
          paddingTop
          label={t({ id: 'userRoleSelectionDescription' })}
          onSelect={handleSelectRole}
          items={workspaceRoles}
          selected={selectedRole}
        />
      </Layout>
    </Layout>
  )
}

WorkspaceUserSelection.displayName = 'WorkspaceUserSelection'
export default WorkspaceUserSelection
