import styled from 'styled-components'

export const CustomSelect = styled.div`
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  border-color: rgba(20, 20, 31, 0.3);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  height: 44px;
  min-width: 200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
`
