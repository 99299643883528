import React from 'react'
import { Layout, Spacer } from '@di-nxt/components'

import { withHeader, withSidebar } from '../../hooks'

import UserDetails from './UserDetails'
import PasswordReset from './PasswordReset'
import DeleteAccount from './DeleteAccount'
import PrivacyPolicyPreview from './PrivacyPolicyPreview'

/**
 *
 * A logical page for managing user account details
 */
const AccountManagementContainer: React.FC = () => {
  return (
    <Layout
      borderRadius="medium"
      height="100%"
      width="50%"
      maxWidth="500px"
      minHeight="500px"
      paddingTop="40"
      vertical
    >
      <UserDetails />
      <Spacer height="24" />
      <PasswordReset />
      <Spacer height="24" />
      <DeleteAccount />
      <Spacer height="24" />
      <PrivacyPolicyPreview />
    </Layout>
  )
}

AccountManagementContainer.displayName = 'AccountManagementContainer'

export default withSidebar(withHeader(AccountManagementContainer))
