import { Resources, ResourcesState } from './index.type'

/**
 *
 * Returns the payload required by the redshift api for the tables
 */
export const getResourcePayload = (resources: Resources) => {
  return resources
    .filter((resource) => resource.selected !== 'NotChecked')
    .map((resource) => {
      const columns = resource.columns
        .filter((column) => column.selected === 'Checked')
        .map((column) => column.columnName)

      return {
        table: resource.tableName,
        columns,
        allColumns: columns.length === resource.columns.length,
      }
    })
}

/**
 *
 * Returns the changes shown in the save selection dialog
 */
export const getChangesSummary = (resourcesState: ResourcesState) => {
  const changesSummary: string[] = []
  const tableMap = new Map<string, string[]>()
  resourcesState.stateChange.forEach((key) => {
    const [table, column] = key.split('|')
    if (tableMap.has(table)) {
      tableMap.get(table)!.push(column)
    } else {
      tableMap.set(table, [column])
    }
  })
  for (const resource of resourcesState.resources) {
    if (tableMap.has(resource.tableName)) {
      const columns = tableMap.get(resource.tableName)!
      if (
        columns.length === resource.columns.length &&
        (resource.selected === 'Checked' || resource.selected === 'NotChecked')
      ) {
        changesSummary.push(
          `${resource.selected === 'Checked' ? 'add' : 'remove'} an object ${
            resource.tableName
          }`,
        )
      } else {
        let added = 0
        let removed = 0
        columns.forEach((c) => {
          const column = resource.columns.find((col) => c === col.columnName)
          if (column) {
            if (column.selected === 'Checked') {
              added++
            } else {
              removed++
            }
          }
        })
        if (added !== 0) {
          changesSummary.push(
            `add ${added} column${added === 1 ? '' : 's'} from ${
              resource.tableName
            }`,
          )
        }
        if (removed !== 0) {
          changesSummary.push(
            `remove ${removed} column${removed === 1 ? '' : 's'} from ${
              resource.tableName
            }`,
          )
        }
      }
    }
  }
  return changesSummary
}
