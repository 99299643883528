import { Layout, Spacer, Text } from '@di-nxt/components'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ApiContext } from '../../../providers/api.provider'
import CostInfo from './CostInfo'
import { QueryMetric } from '../types'
import EstimatedTierUpgrade from './EstimatedTierUpgrade'
import MonthlyCost from './MonthlyCost'

type Props = {
  queryMetrics: QueryMetric[]
  isQueryMetricsLoading: boolean
}

const Overview: React.FC<Props> = ({ queryMetrics, isQueryMetricsLoading }) => {
  const { selectedOrganization } = useContext(ApiContext)
  const { formatMessage: t } = useIntl()

  return (
    <Layout paddingTop="40" direction="Vertical">
      <Text variant="paragraph_16_default">
        {t(
          { id: 'costTrackerDetails' },
          { organisationName: selectedOrganization?.organizationName },
        )}
      </Text>
      <Spacer height="40" />
      <CostInfo
        queryMetrics={queryMetrics}
        isQueryMetricsLoading={isQueryMetricsLoading}
      />
      <Spacer height="56" />
      <MonthlyCost
        queryMetrics={queryMetrics}
        isQueryMetricsLoading={isQueryMetricsLoading}
      />
      <Spacer height="56" />
      <EstimatedTierUpgrade
        queryMetrics={queryMetrics}
        isQueryMetricsLoading={isQueryMetricsLoading}
      />
    </Layout>
  )
}

export default Overview
