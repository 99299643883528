import React from 'react'
import {
  Icon,
  Layout,
  LayoutProps,
  Spacer,
  Text,
  ThemeColorName,
} from '@di-nxt/components'

import { StepProps } from './index.type'

/**
 *
 * Render each step
 */
const Step: React.FC<StepProps> = ({
  step,
  index,
  totalSteps,
  currentStep,
}) => {
  /**
   *
   * Get the border color for the step circle
   * - Black if current step is the step
   * - Green if user has completed the step
   * - No border if step is "disabled"
   */
  const getBorderProps = (): LayoutProps => {
    const borderStyle: LayoutProps = {
      borderWidth: 'hairline',
      borderStyle: 'solid',
    }

    if (index + 1 < currentStep) {
      return {
        ...borderStyle,
        borderColor: 'content_positive_default',
      }
    }
    if (index + 1 === currentStep) {
      return {
        ...borderStyle,
        borderColor: 'content_mono_primary',
      }
    }

    return {
      borderWidth: 'hairline',
    }
  }

  /**
   *
   * Get the text color for the step
   */
  const getTextColor = (): ThemeColorName => {
    if (index + 1 < currentStep) {
      return 'content_positive_default'
    } else if (index + 1 === currentStep) {
      return 'content_mono_primary'
    }
    return 'content_mono_disabled'
  }

  /**
   *
   * Get the content for the step circle
   */
  const getStepCircleContent = (): React.ReactNode => {
    if (index + 1 < currentStep) {
      return <Icon name="checkmark" color="content_positive_default" />
    }
    return (
      <Text variant="inline_14_emphasis" textColor={getTextColor()}>
        {String(index + 1)}
      </Text>
    )
  }

  return (
    <Layout horizontal>
      <Layout vertical align="center">
        <Layout
          borderRadius="round"
          width="32px"
          minHeight="32px"
          minWidth="32px"
          align="center"
          justify="center"
          height="32px"
          {...getBorderProps()}
          backgroundColor={
            index + 1 > currentStep
              ? 'background_mono_tertiary'
              : 'background_mono_primary'
          }
        >
          {getStepCircleContent()}
        </Layout>
        {index + 1 < totalSteps && (
          <Layout
            width="1px"
            height="56px"
            backgroundColor={
              index + 1 < currentStep
                ? 'content_positive_default'
                : 'background_mono_tertiary'
            }
          ></Layout>
        )}
      </Layout>

      <Spacer width="12" />
      <Layout paddingTop="8">
        <Text variant="inline_12_emphasis" textColor={getTextColor()}>
          {step.title}
        </Text>
      </Layout>
    </Layout>
  )
}

Step.displayName = 'Step'
export default Step
