import React, { useContext, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Layout,
  Icon,
  Text,
  Popup,
  LayoutProps,
  Button,
  Spacer,
} from '@di-nxt/components'

import { RedshiftWorkspaceErrorProps } from './RedshiftWorkspaceError.type'
import { UpdateWorkspaceInput } from '../../types/workspace.type'
import { ApiContext } from '../../providers/api.provider'

/**
 *
 * Component that renders the workspace redshift error
 */
const RedshiftWorkspaceError: React.FC<RedshiftWorkspaceErrorProps> = ({
  workspace,
  user,
  location,
}) => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Use ref for popup
  const popupRef = useRef(null)

  // API context
  const { workspacesService, workspaceService } = useContext(ApiContext)
  const { updateWorkspace } = workspaceService
  const { handleGetWorkspaces } = workspacesService

  // Flag to determine whether creating workspace failed
  const creationFailed = workspace.workspaceCreated?.Status === 'FAILED'
  const creating = !('workspaceCreated' in workspace)

  // Common props
  const popupProps: LayoutProps = {
    backgroundColor: 'background_mono_primary',
    padding: '16',
    borderRadius: 'small',
    shadow: true,
    vertical: true,
  }

  /**
   *
   * Resolve the error manually
   */
  const handleResolveError = () => {
    const payload: UpdateWorkspaceInput = {
      workspaceCreated: {
        Status: 'FINISHED',
        TriggeredManually: true,
        TriggeredByUser: user?.userId ?? '',
      },
    }

    updateWorkspace(
      workspace.workspaceId.replace('Workspace#', ''),
      payload,
    ).then((response) => {
      if (response.data) {
        if (popupRef && popupRef.current) {
          ;(popupRef.current as any).click()
        }
        handleGetWorkspaces()
      }
    })
  }

  /**
   *
   * Render and return the resolution button
   */
  const renderResolveIssue = () => (
    <Button
      title={t({ id: 'markAsResolved' })}
      size="Small"
      onPress={handleResolveError}
    />
  )

  // If it failed and location is table
  if (creationFailed && location === 'table') {
    return (
      <Popup
        trigger={
          <div ref={popupRef}>
            <Layout align="center" justify="center">
              <Icon
                size="16"
                name="warning"
                color="background_warning_default"
              />
            </Layout>
          </div>
        }
      >
        {() => (
          <Layout {...popupProps} maxWidth="250px">
            <Text>
              <FormattedMessage
                id="workspaceRedshiftError"
                values={{
                  br: <br />,
                  adminMessage: user?.diAdmin ? (
                    <FormattedMessage id="redshiftErrorAdmin" />
                  ) : (
                    <FormattedMessage id="organizationRedshiftErrorUser" />
                  ),
                }}
              />
            </Text>
            {user?.diAdmin && (
              <>
                <Spacer height="8" />
                {renderResolveIssue()}
              </>
            )}
          </Layout>
        )}
      </Popup>
    )
  }

  // If the workspace is being created
  if (creating && location === 'table') {
    return (
      <Popup trigger={<Icon size="16" name="warning" />}>
        {() => (
          <Layout {...popupProps} maxWidth="250px">
            <Text>
              <FormattedMessage id="workspaceRedshiftCreating" />
            </Text>
          </Layout>
        )}
      </Popup>
    )
  }

  return null
}

RedshiftWorkspaceError.displayName = 'RedshiftWorkspaceError'
export default RedshiftWorkspaceError
