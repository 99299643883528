import React, { ReactNode, useContext } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Button, Text, Layout } from '@di-nxt/components'
import DeleteAccountConfirm from '../../../components/Dialogs/DeleteAccountConfirm'
import { AppStateContext } from '../../../providers/app.state.provider'

/**
 *
 * Delete user account link button
 */
const DeleteAccount: React.FC = () => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()
  // App state context to manage state of dialog
  const { openDialogs, manageDialog } = useContext(AppStateContext)

  // Close the information dialog
  const handleCloseDialog = () => manageDialog({ deleteAccountConfirm: false })

  // Deleting account is not yet implemented this is a stub
  const handleDeleteAccount = () => manageDialog({ deleteAccountConfirm: true })

  return (
    <Layout
      backgroundColor="background_mono_primary"
      borderColor="background_mono_tertiary"
      borderStyle="solid"
      borderWidth="hairline"
      borderRadius="medium"
      vertical
      padding="40"
    >
      <Layout>
        <Text variant="inline_16_emphasis">{t({ id: 'deleteAccount' })}</Text>
      </Layout>
      <Layout paddingTop="24">
        <Text variant="paragraph_12_default">
          <FormattedMessage
            id="deleteAccountDescription"
            values={{
              'bold-text': (...chunks: ReactNode[]) => <b>{chunks}</b>,
              br: <br />,
            }}
          />
        </Text>
      </Layout>
      <Layout paddingTop="32">
        <Button
          title={t({ id: 'sendDeletionLink' })}
          kind="Inverse"
          leftIcon="remove"
          onPress={handleDeleteAccount}
        />
      </Layout>
      {openDialogs.deleteAccountConfirm && (
        <DeleteAccountConfirm onOk={handleCloseDialog} />
      )}
    </Layout>
  )
}

DeleteAccount.displayName = 'DeleteAccount'
export default DeleteAccount
