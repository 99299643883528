import { AwsCostItem } from './types'

export const awsCostData: AwsCostItem[] = [
  {
    organization: 'Redshift',
    type: 'c2.large',
    awsCost: '$0.285/hr',
    markup: '30%',
    customerPays: '3.70 NOK / hr',
  },
]
