import { useContext } from 'react'
import { Layout, Spacer } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import NavigationLink from '../NavigationLink'
import { ApiContext } from '../../../providers/api.provider'
import { UserRoles } from '../../../types/user.type'
import { RoutePathes } from '../../../constants/routes'
import { Modules } from '../../../constants/modules'
import useModule from '../useModule'
import WorkspacesIcon from '../../../assets/icons/workspaces_icon.svg'
import OrganizationsIcon from '../../../assets/icons/organizations_icon.svg'
import CostTrackerIcon from '../../../assets/icons/cost_tracker_icon.svg'
import AdminUsersIcon from '../../../assets/icons/admin_users_icon.svg'
import DataIcon from '../../../assets/icons/data_icon.svg'
import TableauTemplatesIcon from '../../../assets/icons/tableau_templates_icon.svg'

const MainNavigation = () => {
  const { formatMessage: t } = useIntl()
  const { user } = useContext(ApiContext)
  const module = useModule()

  const isDiAdmin = !!user?.diAdmin
  const isOrganizationAdmin = user?.role.name === UserRoles.admin

  return (
    <Layout vertical width="100%">
      <NavigationLink
        icon={WorkspacesIcon}
        text={t({ id: 'workspaces' })}
        url={`/`}
        selected={module === Modules.Workspaces}
      />
      {isDiAdmin && (
        <>
          <Spacer height="6" />
          <NavigationLink
            icon={OrganizationsIcon}
            text={t({ id: 'organizations' })}
            url={`/${RoutePathes.Organizations}`}
            selected={module === Modules.Organizations}
          />
        </>
      )}
      <Spacer height="6" />
      <NavigationLink
        icon={AdminUsersIcon}
        text={t({ id: 'adminUsers' })}
        url={`/${RoutePathes.OrganizationUsers}`}
        selected={module === Modules.Users}
      />
      {(isDiAdmin || isOrganizationAdmin) && (
        <>
          <Spacer height="6" />
          <NavigationLink
            icon={CostTrackerIcon}
            text={t({ id: 'costTracker' })}
            url={`/${RoutePathes.OrganizationCostTracker}`}
            selected={module === Modules.CostTracker}
          />
        </>
      )}
      <Spacer height="6" />
      <NavigationLink
        icon={DataIcon}
        text={t({ id: 'sharedData' })}
        url={`/${RoutePathes.OrganizationSharedData}`}
        selected={module === Modules.SharedData}
      />
      {process.env.REACT_APP_TABLEAU_ENABLED === 'true' && isDiAdmin && (
        <>
          <Spacer height="6" />
          <NavigationLink
            icon={TableauTemplatesIcon}
            text={t({ id: 'tableauTemplates' })}
            url={`/${RoutePathes.Tableau}`}
            selected={module === Modules.Tableau}
          />
        </>
      )}
    </Layout>
  )
}

export default MainNavigation
