import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { Input, Layout, Button, Text } from '@di-nxt/components'
import { ApiContext } from '../../../providers/api.provider'

/**
 *
 * A child component that renders users information and a form to update:
 * - First Name
 * - Last Name
 */
const UserDetails: React.FC = () => {
  // App context contains user information
  const { user, organizationUserService } = useContext(ApiContext)
  // Internationalization hook
  const { formatMessage: t } = useIntl()
  // Local state management to store editable user properties
  const [firstName, setFirstName] = useState<string>(user?.firstName ?? '')
  const [lastName, setLastName] = useState<string>(user?.lastName ?? '')

  /**
   *
   * Handler to update last name property of the user
   * @param {String} value - updated last name of the user
   */
  const handleChangeLastName = (value: string) => setLastName(value)

  /**
   *
   * Handler to update first name property of the user
   * @param {String} value - updated first name of the user
   */
  const handleChangeFirstName = (value: string) => setFirstName(value)

  /**
   *
   * Save the user details
   */
  const handleSaveUser = () => {
    if (user?.userId) {
      organizationUserService.updateOrganizationUser(
        { firstName, lastName },
        user?.userId,
      )
    }
  }

  return (
    <Layout
      backgroundColor="background_mono_primary"
      borderColor="background_mono_tertiary"
      borderStyle="solid"
      borderWidth="hairline"
      borderRadius="medium"
      vertical
      padding="40"
    >
      <Layout paddingBottom="32">
        <Text variant="inline_16_emphasis">{t({ id: 'personalDetails' })}</Text>
      </Layout>
      <Layout vertical paddingBottom="24">
        <label>{t({ id: 'email' })}</label>
        <Input state="Disabled" value={user?.email} />
      </Layout>
      <Layout vertical paddingBottom="24">
        <label>{t({ id: 'firstName' })}</label>
        <Input value={firstName} onChange={handleChangeFirstName} />
      </Layout>
      <Layout vertical paddingBottom="24">
        <label>{t({ id: 'lastName' })}</label>
        <Input value={lastName} onChange={handleChangeLastName} />
      </Layout>
      <Layout horizontal paddingBottom="24" justify="flex-end" width="100%">
        <Button
          title={t({ id: 'save' })}
          kind="Inverse"
          spinning={organizationUserService.updatingUser}
          state={organizationUserService.updatingUser ? 'Disabled' : 'Default'}
          onPress={handleSaveUser}
        />
      </Layout>
    </Layout>
  )
}

UserDetails.displayName = 'UserDetails'

export default UserDetails
