import { Layout } from '@di-nxt/components'
import { ItemDetailsProps } from './ItemDetails.type'
import { getMarkedText } from './util/markedText'

/**
 * Renders table's name and labels
 */
const ItemDetails: React.FC<ItemDetailsProps> = ({
  tableName,
  isDisabled,
  matchedPart,
}) => {
  return (
    <Layout align="center">
      {getMarkedText(tableName, matchedPart, isDisabled)}
    </Layout>
  )
}

ItemDetails.displayName = 'ItemDetails'

export default ItemDetails
