import { Layout, Spacer, Text } from '@di-nxt/components'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ApiContext } from '../../../providers/api.provider'
import { Organization } from '../../../types/organization.type'
import PopupList from '../../PopupList'
import { CaptionText } from '../style'
import { Container } from './style'
import OrganizationTrigger from './OrganizationTrigger'

const OrganizationMarker: React.FC = () => {
  const { formatMessage: t } = useIntl()
  const {
    user,
    selectedOrganization,
    organizationService,
    handleSelectOrganization,
  } = useContext(ApiContext)
  const [searchOrgString, setSearchOrgString] = useState<string | undefined>(
    undefined,
  )
  const navigate = useNavigate()

  const {
    loadingOrganizations,
    organizations,
    getOrganizations,
  } = organizationService

  const { workspaceService } = useContext(ApiContext)

  const { resetWorkspace } = workspaceService

  useEffect(() => {
    if (!loadingOrganizations && organizations.length === 0 && user?.diAdmin) {
      getOrganizations()
    }
  }, [loadingOrganizations, organizations, getOrganizations, user])

  const handleSelectOrg = (value: unknown) => {
    handleSelectOrganization(value as Organization)
    resetWorkspace()
    navigate(`/`)
  }

  return (
    <Layout paddingTop="12">
      {user?.diAdmin ? (
        <PopupList
          heading={t({ id: 'selectOrganization' })}
          title={selectedOrganization?.organizationName ?? ''}
          searchValue={searchOrgString ?? ''}
          searchPlaceholder={t({ id: 'searchForOrganizations' })}
          onSearchChange={setSearchOrgString}
          data={organizations}
          loadingData={loadingOrganizations}
          idKey={'organizationId'}
          selectedId={selectedOrganization?.organizationId ?? undefined}
          onSelectItem={handleSelectOrg}
          titleColor="background_mono_primary"
          property="organizationName"
          PopupTrigger={OrganizationTrigger}
        />
      ) : (
        <Container>
          <CaptionText>{t({ id: 'org' }).toUpperCase()}</CaptionText>
          <Spacer height="10" />
          <Text
            variant="inline_16_emphasis"
            textColor="background_mono_primary"
          >
            {user?.organization?.organizationName}
          </Text>
        </Container>
      )}
    </Layout>
  )
}

export default OrganizationMarker
