import { useState, useContext } from 'react'
import { useIntl } from 'react-intl'

import useApi from './useApi'

import { SnackbarContext } from '../../providers/snackbar.provider'
import {
  QueryResponseData,
  Resource,
  SelectionPayload,
  UseQuery,
} from './useQuery.type'
import { parseResources } from '../../utils/parseResources'
import { ENDPOINTS } from '../../types/config.api.type'
import { AppStateContext } from '../../providers/app.state.provider'
import { poll } from '../../utils/poll'

export const defaultQuery: UseQuery = {
  resources: [],
  isRefreshingResources: false,
  isLoadingResources: false,
  loadResources: (schemaName: string) => null,
  refreshResources: (schemaName: string) => null,

  saveSelection: () => null,

  isSchemaAvailable: false,
  updateSchemaAvailable: (available: boolean) => null,
}

/**
 *
 * Use query api hook
 */
const useQuery = (): UseQuery => {
  // Fetcher api that append api token
  const { fetcher } = useApi()

  const { formatMessage: t } = useIntl()

  const [resources, setResources] = useState<Resource[]>([])

  // state to store loading state of the query execution
  const [isLoadingResources, setIsLoadingResources] = useState<boolean>(false)

  const [isSchemaAvailable, setIsSchemaAvailable] = useState<boolean>(false)

  // state to store loading state of the refreshing of the resources
  const [isRefreshingResources, setIsRefreshingResources] = useState<boolean>(
    false,
  )

  // Attach context to display snackbar messages
  const {
    handleSetContent,
    handleSetSnackbarType,
    handleSetProperties,
  } = useContext(SnackbarContext)

  const { manageDialog } = useContext(AppStateContext)

  /**
   *
   * Saves the changes to the workspace tables
   */
  const saveSelection = async (
    selectionPayload: SelectionPayload,
    cb: () => void,
  ) => {
    try {
      const result = await fetcher.put(
        ENDPOINTS.WORKSPACE_DATA_UPDATE,
        selectionPayload,
      )
      handleSetSnackbarType('Success')
      handleSetProperties({
        id: 'save-selection',
      })
      handleSetContent(t({ id: 'saveSelectionSuccess' }))
      cb()
      return result.data.status
    } catch (e) {
      console.log(e)
      handleSetSnackbarType('Error')
      handleSetContent(t({ id: 'saveSelectionError' }))
    } finally {
      manageDialog({ saveSelection: false })
    }
  }

  const refreshResources = async (schemaName: string) => {
    setIsRefreshingResources(true)
    const query = `SELECT table_name, column_name
    FROM svv_all_columns where schema_name='${schemaName}'`
    try {
      const result = await poll(fetcher, query, 'preview', true)
      const resources = parseResources(result.data as QueryResponseData)
      setResources(resources)
      handleSetSnackbarType('Success')
      handleSetProperties({
        id: 'refresh-resources',
      })
      handleSetContent(t({ id: 'resourcesRefresh' }))
    } catch (e) {
      handleSetSnackbarType('Error')
      handleSetContent(t({ id: 'errorRefreshingResources' }))
      console.log(e)
    } finally {
      setIsRefreshingResources(false)
    }
  }

  const loadResources = async (schemaName: string) => {
    setIsSchemaAvailable(true)
    setIsLoadingResources(true)
    const query = `SELECT table_name, column_name
    FROM svv_all_columns where schema_name='${schemaName}'`
    poll(fetcher, query, 'preview', true)
      .then((result) =>
        setResources(parseResources(result.data as QueryResponseData)),
      )
      .catch((e) => {
        setResources([])
        console.log(e)
      })
      .finally(() => {
        setIsLoadingResources(false)
        setIsSchemaAvailable(true)
      })
  }

  const updateSchemaAvailable = (available: boolean) => {
    setIsSchemaAvailable(available)
  }

  return {
    resources,
    isLoadingResources,
    isRefreshingResources,
    loadResources,
    refreshResources,
    saveSelection,
    isSchemaAvailable,
    updateSchemaAvailable,
  }
}

export default useQuery
