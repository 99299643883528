import React, { createContext, useEffect, useState } from 'react'
import { Layout, Spinner } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import {
  useOrganizationUsers,
  useUser,
  useWorkspaces,
  useOrganization,
  useWorkspaceTools,
} from '../hooks'
import useApi from '../hooks/api/useApi'
import { defaultUserOrganizationUser } from '../hooks/api/useOrganizationUsers'
import { defaultUseWorkspaces } from '../hooks/api/useWorkspaces'
import { defaultUseOrganization } from '../hooks/api/useOrganization'
import { defaultWorkspaceTools } from '../hooks/api/useWorkspaceTools'
import useWorkspaceUsers, {
  defaultUseWorkspaceUsers,
} from '../hooks/api/useWorkspaceUsers'

import useWorkspaceData, {
  defaultUseWorkspaceData,
} from '../hooks/api/useWorkspaceData'

import useWorkspace, { defaultUseWorkspace } from '../hooks/api/useWorkspace'

import { User } from '../types/user.type'

import { ApiContextType } from './api.provider.type'
import ContentOverlay from '../components/ContentOverlay'
import { ENDPOINTS } from '../types/config.api.type'
import useQuery, { defaultQuery } from '../hooks/api/useQuery'
import { Organization } from '../types/organization.type'
import useWorkspaceCloudApi, { defaultUseWorkspaceCloudApi } from '../hooks/api/useWorkspaceCloudApi'
import { useAmplitude } from '../amplitude/useAmplitude'

/**
 *
 * Api provider utilizes context api to provide an interface and storage to api end points
 * Loading states, retreived data amongst other attributes are handled by the provider
 */
export const ApiContext = createContext<ApiContextType>({
  organizationUserService: defaultUserOrganizationUser,
  workspacesService: defaultUseWorkspaces,
  organizationService: defaultUseOrganization,
  workspaceService: defaultUseWorkspace,
  workspaceToolsService: defaultWorkspaceTools,
  workspaceUsersService: defaultUseWorkspaceUsers,
  workspaceDataService: defaultUseWorkspaceData,
  workspaceCloudApiService: defaultUseWorkspaceCloudApi,
  queryService: defaultQuery,
  handleSelectOrganization: () => null,
})

/**
 *
 * Api provider
 */
const ApiProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [selectedOrganization, setSelectedOrganization] = useState<
    Organization | undefined
  >(undefined)

  const [loadingUser, setLoadingUser] = useState<boolean>(false)
  const [loadingUserError, setLoadingUserError] = useState<any>()

  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Attach the hook to the provider
  const organizationUserService = useOrganizationUsers(
    selectedOrganization?.organizationId,
    user?.userId,
  )

  // Attach the hook to the provider
  const organizationService = useOrganization()

  // Attach the workspaces hook
  const workspacesService = useWorkspaces(
    selectedOrganization?.organizationId,
    selectedOrganization?.organizationKey,
  )

  // Attach the workspace hook
  const workspaceService = useWorkspace(
    selectedOrganization?.organizationId,
    user?.userId,
  )

  // Attach the workspace tools hook
  const workspaceToolsService = useWorkspaceTools(
    selectedOrganization?.organizationId,
  )

  // Attach the workspace users hook
  const workspaceUsersService = useWorkspaceUsers(
    selectedOrganization?.organizationId,
  )

  // Attach the workspace data hook
  const workspaceDataService = useWorkspaceData()

  // Attach the workspace cloud api hook
  const workspaceCloudApiService = useWorkspaceCloudApi()

  // Attach the query hook
  const queryService = useQuery()

  // Attach the user hook, which is keycloak information
  const { userId, authenticated, logout } = useUser()

  // Get the fetcher api hook
  const { fetcher } = useApi()

  const amplitudeService = useAmplitude()

  // If we have the user id, fetch the user
  useEffect(() => {
    if (!user && userId && !loadingUser && !loadingUserError) {
      setLoadingUser(true)

      fetcher
        .get(ENDPOINTS.USER.replace('$userId', userId))
        .then((response) => {
          if (response.status === 204) {
            // This suggests that the request was successful but no content was found
            setLoadingUserError(true)
          } else if (response.data) {
            const user = response.data as User
            const organization = response.data.organization as Organization
            setUser(user)
            setSelectedOrganization(organization)
            amplitudeService.setUser(user)
            amplitudeService.setOrganization(organization)
          }
        })
        .catch((error) => {
          setLoadingUser(false)
          setLoadingUserError(error)
        })
        .finally(() => {
          setLoadingUser(false)
        })
    }
  }, [user, setUser, userId, loadingUser, fetcher, loadingUserError, amplitudeService])

  /**
   *
   * Handler to set the selected organization
   * @param {Organization} organization - organization selected by diAdmin
   */
  const handleSelectOrganization = (organization: Organization) => {
    if (user?.diAdmin) {
      setSelectedOrganization(organization)
      amplitudeService.setOrganization(organization)
    }
  }

  /**
   *
   * Render the children inside the api provider
   * If fetching user failed, we return content overlay
   * Id user is being loaded, return spinner
   * Otherwise, render application
   */
  const renderChildren = (children: React.ReactNode) => {
    if ((loadingUser || (!user && !loadingUserError)) && authenticated) {
      return (
        <Layout
          width="100%"
          height="100%"
          align="center"
          direction="Vertical"
          justify="center"
        >
          <Spinner size="Large" />
        </Layout>
      )
    } else if (!loadingUser && loadingUserError) {
      return (
        <ContentOverlay
          statusCode={204}
          errorEntity="User"
          actionButton
          actionButtonText={t({ id: 'signOut' })}
          onAction={logout}
        />
      )
    }
    return children
  }
  return (
    <ApiContext.Provider
      value={{
        organizationUserService,
        workspacesService,
        workspaceService,
        workspaceToolsService,
        workspaceUsersService,
        workspaceDataService,
        workspaceCloudApiService,
        user,
        loadingUser,
        organizationService,
        queryService,
        handleSelectOrganization,
        selectedOrganization,
      }}
    >
      {renderChildren(children)}
    </ApiContext.Provider>
  )
}

ApiProvider.displayName = 'ApiProvider'

export default ApiProvider
