import {
  CheckboxState,
  ColumnState,
  Resources,
  ResourcesState,
} from './index.type'

/**
 * Updates the resources state
 * @param resourcesState - state of the resources
 * @param tableName - name of the table changed
 * @param columnName - name of the column changed
 * @returns the updated state
 */
export const updateResourcesState = (
  resourcesState: ResourcesState,
  tableName: string,
  columnName?: string,
): ResourcesState => {
  const { resources, stateChange } = resourcesState
  if (columnName === undefined) {
    // A table has been clicked
    return getTableChanged(resources, stateChange, tableName)
  }
  // Specific column in a table has been clicked
  return getColumnChanged(resourcesState, tableName, columnName)
}

const getTableChanged = (
  oldResources: Resources,
  state: Set<string>,
  tableName: string,
) => {
  const newState = new Set(state)
  const resources = []
  for (const resource of oldResources) {
    const newResource = {
      ...resource,
    }
    newResource.selected =
      resource.tableName === tableName
        ? resource.selected === 'NotChecked'
          ? 'Checked'
          : 'NotChecked'
        : resource.selected
    const columns = []
    for (const column of resource.columns) {
      const newColumn = {
        ...column,
      }
      newColumn.selected =
        resource.tableName !== tableName
          ? column.selected
          : resource.selected === 'NotChecked'
          ? 'Checked'
          : 'NotChecked'
      if (newColumn.selected !== column.selected) {
        const setKey = `${tableName}|${newColumn.columnName}`
        if (newState.has(setKey)) {
          //Column has been changed before, so now it's back to it's old
          //state, so it is removed from the set of changes
          newState.delete(setKey)
        } else {
          // Column is changed
          newState.add(setKey)
        }
      }
      columns.push(newColumn)
    }
    newResource.columns = columns
    resources.push(newResource)
  }
  return {
    resources,
    stateChange: newState,
  }
}

const getColumnChanged = (
  resourcesState: ResourcesState,
  tableName: string,
  columnName: string,
) => {
  const { resources, stateChange } = resourcesState
  const table = resources.find((table) => table.tableName === tableName)
  const columnIndex =
    table !== undefined
      ? table.columns.findIndex((column) => column.columnName === columnName)
      : -1

  if (columnIndex !== -1) {
    const column = table!.columns[columnIndex]
    const newState = new Set(stateChange)

    const setKey = `${tableName}|${column.columnName}`
    if (newState.has(setKey)) {
      //Column has been changed before, so now it's back to it's old
      //state, so it is removed from the set of changes
      newState.delete(setKey)
    } else {
      // Column is changed
      newState.add(setKey)
    }
    const otherColumns = [
      ...table!.columns.slice(0, columnIndex),
      ...table!.columns.slice(columnIndex + 1),
    ]
    return {
      resources: resources.map((resource) => ({
        ...resource,
        selected:
          resource.tableName === tableName
            ? getTableState(column, otherColumns)
            : resource.selected,
        columns: resource.columns.map((column, j) => ({
          ...column,
          selected:
            resource.tableName !== tableName
              ? column.selected
              : j === columnIndex
              ? column.selected === 'NotChecked'
                ? 'Checked'
                : 'NotChecked'
              : column.selected,
        })),
      })),
      stateChange: newState,
    }
  }
  return resourcesState
}

const getTableState = (
  column: ColumnState,
  otherColumns: ColumnState[],
): CheckboxState => {
  if (
    column.selected === 'Checked' &&
    otherColumns.every((column) => column.selected === 'NotChecked')
  ) {
    return 'NotChecked'
  }
  if (
    column.selected === 'NotChecked' &&
    otherColumns.every((column) => column.selected === 'Checked')
  ) {
    return 'Checked'
  }
  return 'Indetermined'
}
