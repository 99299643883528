import React, { useContext } from 'react'
import { Button, Layout, Spacer, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'

import WorkspaceUsers from '../../../components/WorkspaceUsers'
import AddWorkspaceUsersDialog from '../../../components/Dialogs/AddWorkspaceUsersDialog'

import { AppStateContext } from '../../../providers/app.state.provider'
import { ApiContext } from '../../../providers/api.provider'
import { WorkspaceUserRole } from '../../../types/workspace.type'
import { UserRoles } from '../../../types/user.type'

/**
 *
 * Workspace has one to many users
 * There is atleast one user always associated with a workspace
 * Displays the list of users that participate in a workspace
 */
const WorkspaceUsersContainer: React.FC = () => {
  // Internationalization Hook
  const { formatMessage: t } = useIntl()
  // Context to show or hide dialog for adding user to workspace
  const { manageDialog, openDialogs } = useContext(AppStateContext)
  // Api context to attach workspace users
  const { workspaceUsersService, user } = useContext(ApiContext)
  const { loadingWorkspaceUsers, workspaceUsers } = workspaceUsersService

  const isDiAdmin = !!user?.diAdmin
  const isOrganizationAdmin = user?.role.name === UserRoles.admin
  const isUserOwnerOfWorkspace = !!workspaceUsers?.some(
    (workspaceUser) =>
      workspaceUser.userId === user?.userId &&
      workspaceUser.workspaceRole === WorkspaceUserRole.owner,
  )
  const canEditUsers =
    isDiAdmin || isOrganizationAdmin || isUserOwnerOfWorkspace

  /**
   *
   * Trigger state update to open add users to workspace dialog
   */
  const handleOpenAddUserDialog = () =>
    manageDialog({ addWorkspaceUsers: true })

  /**
   *
   * Handle closing dialog
   */
  const handleCloseDialog = () => manageDialog({ addWorkspaceUsers: false })
  return (
    <Layout paddingTop="32" vertical>
      <Layout horizontal justify="space-between">
        <Layout vertical flex={8}>
          <Layout horizontal align="center">
            <Text variant="inline_24_emphasis">
              {t({ id: 'currentUsers' })}
            </Text>
            <Spacer width="16" />
            <Text variant="inline_14_default">
              {`(${workspaceUsers?.length ?? 0})`}
            </Text>
          </Layout>

          <Spacer height="16" />
          <Text variant="paragraph_16_default">
            {t({ id: 'workspaceUsersDescription' })}
          </Text>
        </Layout>
        {canEditUsers && (
          <Layout flex={2} justify="flex-end">
            <Button
              title={t({ id: 'addUsers' })}
              leftIcon="plus"
              onPress={handleOpenAddUserDialog}
              state={loadingWorkspaceUsers ? 'Disabled' : 'Default'}
            />
          </Layout>
        )}
      </Layout>
      <WorkspaceUsers
        workspaceUsers={workspaceUsers}
        loadingWorkspaceUsers={loadingWorkspaceUsers}
        canEditUsers={canEditUsers}
      />
      {openDialogs.addWorkspaceUsers && (
        <AddWorkspaceUsersDialog
          handleOnClose={handleCloseDialog}
          existingUsers={workspaceUsers}
        />
      )}
    </Layout>
  )
}

WorkspaceUsersContainer.displayName = 'WorkspaceUsersContainer'

export default WorkspaceUsersContainer
