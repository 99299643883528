import React from 'react'
import { Layout, Spacer, Text, Icon } from '@di-nxt/components'
import { useNavigate } from 'react-router-dom'

import { BreadcrumbProps } from './index.type'

/**
 *
 * Breadcrumb component
 */
const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb, isLast }) => {
  /**
   *
   * Navigation hook
   */
  const navigate = useNavigate()

  /**
   *
   * If path is provided then navigate on click
   */
  const handleNavigate = () => {
    if (breadcrumb.path) {
      navigate(breadcrumb.path)
    }
  }

  return (
    <Layout horizontal centered onPress={handleNavigate}>
      {breadcrumb.icon && <img alt={breadcrumb.text} src={breadcrumb.icon} />}
      <Text variant={isLast ? 'inline_12_default' : 'inline_12_emphasis'}>
        {breadcrumb.text}
      </Text>
      <Spacer width="2" />
      {!isLast && (
        <>
          <Icon name="arrowRightSmall" />
          <Spacer width="2" />
        </>
      )}
    </Layout>
  )
}

Breadcrumb.displayName = 'Breadcrumb'
export default Breadcrumb
