import React, { useState, useEffect, useContext } from 'react'
import { Icon, Input, Layout, Spacer, Text, Spinner } from '@di-nxt/components'
import { EditableTablePropertyProps } from './EditableTableProperty.type'
import { ApiContext } from '../../providers/api.provider'

/**
 *
 * The user managment table has editable fields on first name and last name
 * It shows edit icon on right hand side, clicking it renders the input
 */
const EditableTableProperty: React.FC<EditableTablePropertyProps> = ({
  property,
  onEditUser: handleConfirmEdit,
  updateDetails,
  propertyKey,
  userId,
  editable,
}) => {
  // Use the context
  const { organizationUserService } = useContext(ApiContext)
  const { users } = organizationUserService

  // State management for editable table property component
  const [editing, setEditing] = useState<boolean>(false)
  const [value, setValue] = useState<string>(property)

  // Sets the editing mode for this component as on
  const handleSetEditing = () => {
    if (editable) {
      setEditing(true)
    }
  }

  // Sets the default property and closes the edit dialog
  const handleCancelEditing = () => {
    setValue(property)
    setEditing(false)
  }

  useEffect(() => {
    if (value !== property && !editing && users) {
      if (users) {
        const currentUser = users.find((user) => user.userId === userId)

        if (currentUser) {
          setValue(currentUser[propertyKey])
        } else {
          setValue(property)
        }
      }
    }
  }, [value, property, editing, users, userId, propertyKey])

  // Change the value
  const handleChangeValue = (value: string) => setValue(value)

  // Submit the updated value
  const handleSubmitValue = () => {
    if (userId && typeof handleConfirmEdit === 'function') {
      setEditing(false)
      handleConfirmEdit(userId, { [propertyKey]: value }, propertyKey).then(
        (response) => {
          setValue(value)
        },
      )
    }
  }

  /**
   *
   * Get the value to render
   */
  const getValue = () => {
    if (organizationUserService.users) {
      const currentUser = organizationUserService.users.find(
        (user) => user.userId === userId,
      )

      if (currentUser) {
        return currentUser[propertyKey]
      }
    }

    return value
  }

  return (
    <Layout width="100%" justify="space-between">
      <Layout flex={6}>
        {!editing && <Text variant="inline_14_default">{getValue()}</Text>}
        {editing && (
          <Input value={value} size="Small" onChange={handleChangeValue} />
        )}
      </Layout>
      {!editing && editable && (
        <Layout
          flex={4}
          justify="flex-end"
          align="center"
          onPress={handleSetEditing}
        >
          {(updateDetails?.updatingUserId !== userId ||
            updateDetails?.updatingComponent !== propertyKey) && (
            <Icon name="edit" size="20" />
          )}
          {updateDetails?.updatingUserId === userId &&
            updateDetails?.updatingComponent === propertyKey && (
              <Spinner size="Small" />
            )}
        </Layout>
      )}
      {editing && (
        <Layout align="center">
          <Layout onPress={handleCancelEditing}>
            <Icon name="close" size="20" color="border_negative_default" />
          </Layout>
          <Spacer width="12" />
          <Layout onPress={handleSubmitValue}>
            <Icon name="checkmark" size="20" color="border_positive_default" />
          </Layout>
        </Layout>
      )}
    </Layout>
  )
}

EditableTableProperty.displayName = 'EditableTableProperty'
export default EditableTableProperty
