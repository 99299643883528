import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Spacer, Text, Layout } from '@di-nxt/components'
import TableCellDropdown from '../../../TableCellDropdown'
import { useDropdown } from '../../../../hooks/definitions/useDropdown'
import { RoleAndOrganizationProps } from './index.type'
import { OrganizationRoles } from '../../../../types/organization.type'
import { DropdownItem } from '../../../../types/dropdown.type'

/**
 *
 * Component renders select Role dropdown
 */
const RoleAndOrganization: React.FC<RoleAndOrganizationProps> = ({
  user,
  selectedOrganization,
  onSelectRole: handleSelectRole,
}) => {
  // Selected role
  const [selectedRole, setSelectedRole] = useState<
    OrganizationRoles | undefined
  >(undefined)

  // Get the organisation roles
  const { organizationRoles } = useDropdown()

  /**
   *
   * Handle changing role
   */
  const handleRoleChange = (item: DropdownItem) => {
    let selectedRole = selectedOrganization.organizationRoles.find(
      (orgRole) => orgRole.name === item.key,
    )

    if (selectedRole) {
      handleSelectRole(selectedRole)
      setSelectedRole(item.key as OrganizationRoles)
    }
  }

  /**
   *
   * Prefill role with initial value
   */
  useEffect(() => {
    if (!selectedRole) {
      handleRoleChange({
        key: OrganizationRoles.User,
        title: OrganizationRoles.User,
      })
    }
  })

  return (
    <>
      <label>
        <FormattedMessage id="selectRole" />
      </label>
      <Layout backgroundColor="background_mono_tertiary">
        <TableCellDropdown
          items={organizationRoles}
          selected={selectedRole ?? ''}
          onSelect={handleRoleChange}
          showCheckmark={false}
        />
      </Layout>

      <Spacer height="6" />
      <Text variant="paragraph_12_default" textColor="content_mono_secondary">
        <FormattedMessage id="organizationRoleDescription" />
      </Text>
    </>
  )
}

RoleAndOrganization.displayName = 'RoleAndOrganization'

export default RoleAndOrganization
