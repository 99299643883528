import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Layout } from '../../styles'

export const MainContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: ${Layout.sidebarWidth};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
  top: 0;
  overflow-y: scroll;
  background-color: rgb(41, 41, 61);
`

export const CaptionText = styled.span`
  font-size: 8px;
  color: rgba(244, 248, 252, 1);
`

export const LoweredText = styled.span`
  font-size: 12px;
  color: rgba(244, 248, 252, 1);
`

const menuItemStyled = `
  cursor: inherit;
  padding: 14px 18px;
  span {
    text-decoration: underline;
    text-decoration-color: rgb(41, 41, 61);
  }
`

export const LinkStyled = styled(Link)`
  ${menuItemStyled}
`

export const MenuItemStyled = styled.div`
  ${menuItemStyled}
`
