import React, { createContext, useState, useEffect } from 'react'
import { Snackbar } from '@di-nxt/components'
import { useNavigate } from 'react-router-dom'
import { SnackbarState } from './snackbar.provider.type'
import { SnackbarKind } from '@di-nxt/components/dist/components/snackbar/types'

/**
 *
 * Context to manage snackbar notifications on the application.
 * If the content is provided, the snackbar will be rendered
 */
export const SnackbarContext = createContext<SnackbarState>({
  handleSetSnackbarType: () => null,
  handleSetContent: () => null,
  handleSetProperties: () => null,
})

/**
 *
 * Provider to render the snackbar
 */
const SnackbarProvider: React.FC = ({ children }) => {
  // Local state to manage content in a snackbar
  const [snackbarContent, setSnackbarContent] = useState<string>('')

  // Manage the type of snackbar to display
  const [snackbarType, setSnackbarType] = useState<SnackbarKind>('Default')

  // Navigate
  const navigate = useNavigate()

  // Setting id of the snackbar
  const [properties, setProperties] = useState<{
    id: string | number | undefined
    actionType?: 'NAVIGATE'
    actionTitle?: string
    to?: string
  }>({ id: undefined })

  // Snackbar title action

  /**
   *
   * Use effect hook to clear out content
   */
  useEffect(() => {
    let timeout: any

    if (snackbarContent.trim().length > 0) {
      timeout = setTimeout(() => setSnackbarContent(''), 5000)
    }

    return () => {
      clearTimeout(timeout)
    }
  })

  /**
   *
   * Handler to set the content for snackbar
   */
  const handleSetContent = (content: string) => {
    setSnackbarContent(content)
  }

  /**
   *
   * Handler to set the type of snackbar to render
   */
  const handleSetSnackbarType = (snackbarKind: SnackbarKind) => {
    setSnackbarType(snackbarKind)
  }

  /**
   *
   * Handle action on snackbar title
   */
  const handleAction = () => {
    if (properties.actionTitle) {
      switch (properties.actionType) {
        case 'NAVIGATE':
          if (properties.to) {
            setSnackbarContent('')
            navigate(properties.to, { replace: true })
          }
          break
      }
    }
  }

  /**
   *
   * Handle setting id of the snackbar
   */
  const handleSetProperties = (snackbarProperties: {
    id: string | number | undefined
    actionType?: 'NAVIGATE'
    actionTitle?: string
    to?: string
  }) => {
    setProperties(snackbarProperties)
  }

  return (
    <SnackbarContext.Provider
      value={{
        handleSetContent,
        handleSetSnackbarType,
        handleSetProperties,
      }}
    >
      {children}
      {snackbarContent.trim().length > 0 && (
        <div className="snackbar-container">
          <Snackbar
            id={properties.id}
            solo="Yes"
            content={snackbarContent}
            kind={snackbarType}
            duration={5000}
            onDismiss={() => {}}
            {...(properties.actionTitle && {
              actionTitle: properties.actionTitle,
            })}
            onAction={handleAction}
          />
        </div>
      )}
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.displayName = 'SnackbarProvider'
export default SnackbarProvider
