import Keycloak, { KeycloakConfig } from 'keycloak-js'

/**
 * Keycloak configuration to connect to authentication server
 * The server authenticates and returns to the application with a token
 * Use the token to communicate with the user service to validate and getting permissions
 */
export const KEYCLOAK_CONFIG: KeycloakConfig = {
  url: process.env.REACT_APP_AUTHENTICATION_URI,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
}

export const keycloak: Keycloak = new Keycloak(KEYCLOAK_CONFIG)

export const ACCESS_TOKEN_MIN_VALIDITY_SECONDS = 10
export const TABLEAU_ACCESS_TOKEN_MIN_VALIDITY_SECONDS = -1

export const initAuth = async () =>  keycloak.init({
  onLoad: "check-sso",
  pkceMethod: 'S256'
}).then((auth) => {
  if (!auth) {
    window.location.reload();
  }
}, () => {
  console.error("Authenticated Failed");
});