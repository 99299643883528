import { TableColumn, Text } from '@di-nxt/components'
import { useIntl } from 'react-intl'
import { formatHours, formatMoneyAmount, formatYearMonth } from '../../utils'
import AwsCostTableTitle from './AwsCostTableTitle'
import TierCostTableTitle from './TIerCostTableTitle'
import { MonthlyCostItem } from './types'

const useMonthlyCostDefinitions = () => {
  const { formatMessage: t } = useIntl()

  const monthlyCostTableDef: TableColumn<MonthlyCostItem>[] = [
    {
      key: 'month',
      title: t({ id: 'month' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
      selector: (row) => {
        return (
          <Text variant="inline_14_default">
            {formatYearMonth(row.Year, row.Month)}
          </Text>
        )
      },
    },
    {
      key: 'dataRows',
      title: t({ id: 'dataRows' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      sortable: true,
      selector: (row) => {
        return (
          <Text variant="inline_14_default">
            {
              `${row.DataRows}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // by replace we adding spaces in long numbers
            }
          </Text>
        )
      },
    },
    {
      key: 'tier',
      title: t({ id: 'tier' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return <Text variant="inline_14_default">{row.Tier}</Text>
      },
    },
    {
      key: 'tierCost',
      title: <TierCostTableTitle />,
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <Text variant="inline_14_default">
            {formatMoneyAmount(row.TierCost, t({ id: 'nok' }))}
          </Text>
        )
      },
    },
    {
      key: 'awsConsumption',
      title: t({ id: 'awsConsumption' }),
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <Text variant="inline_14_default">
            {`${formatHours(row.AWSConsumption)} ${t({ id: 'hours' })}`}
          </Text>
        )
      },
    },
    {
      key: 'awsCost',
      title: <AwsCostTableTitle />,
      cellStyle: {
        flex: 1,
        align: 'center',
      },
      selector: (row) => {
        return (
          <Text variant="inline_14_default">
            {formatMoneyAmount(row.AWSCost, t({ id: 'usd' }))}
          </Text>
        )
      },
    },
  ]

  return {
    monthlyCostTableDef,
  }
}

export default useMonthlyCostDefinitions
