import React, { useContext, useEffect, useState } from 'react'
import { Layout, BaseSpinner } from '@di-nxt/components'
import { useParams } from 'react-router-dom'

import { ApiContext } from '../../../providers/api.provider'

import UserDetails from './UserDetails'
import { withHeader, withSidebar } from '../../../hooks'
import { User } from '../../../types/user.type'

/**
 *
 * Organization admins can modify users
 * The container allows an admin to view a specific user
 */
const AdminModifyUserContainer: React.FC = () => {
  // Local state management to store user information and loading states
  const [user, setUser] = useState<User | undefined>(undefined)
  const [loadingUser, setLoadingUser] = useState<boolean>(false)

  // Load the user id from path parameters
  const { userId } = useParams()

  // Context api to get user
  const { organizationUserService } = useContext(ApiContext)
  const { getOrganizationUser } = organizationUserService

  // On loading of the page get the user
  useEffect(() => {
    if (userId) {
      setLoadingUser(true)
      getOrganizationUser(userId)
        .then((response) => {
          if (response && response.data) {
            setUser(response.data as User)
          }
        })
        .finally(() => {
          setLoadingUser(false)
        })
    }
  }, []) //eslint-disable-line

  if (loadingUser) {
    return (
      <Layout vertical justify="center" align="center" minHeight="100%" flex>
        <BaseSpinner size={32} />
      </Layout>
    )
  }

  return (
    <Layout
      borderRadius="medium"
      backgroundColor="background_mono_primary"
      borderColor="background_mono_tertiary"
      borderStyle="solid"
      borderWidth="hairline"
      height="100%"
      width="50%"
      maxWidth="500px"
      minHeight="300px"
      padding="40"
      vertical
    >
      <UserDetails user={user} />
    </Layout>
  )
}

AdminModifyUserContainer.displayName = 'AdminModifyUserContainer'

export default withSidebar(withHeader(AdminModifyUserContainer))
