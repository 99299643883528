import { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import useApi from './useApi'
import { UseWorkspace } from './useWorkspace.type'

import {
  UpdateWorkspaceInput,
  Workspace,
  WorkspaceUserInput,
  WorkspaceUserRole,
} from '../../types/workspace.type'

import { SnackbarContext } from '../../providers/snackbar.provider'
import { ENDPOINTS } from '../../types/config.api.type'

export const defaultUseWorkspace: UseWorkspace = {
  getWorkspace: () => null,
  deleteWorkspace: () => null,
  updateWorkspace: () => Promise.resolve(),
  removeUsersFromWorkspace: () => null,
  userWorkspaceRole: WorkspaceUserRole.viewer,
  resetWorkspace: () => null,
}

/**
 *
 * Use workspace api hook
 */
const useWorkspace = (
  organizationId?: string,
  userId?: string,
): UseWorkspace => {
  // Internationalization hook
  const { formatMessage: t } = useIntl()

  // Navigation hook
  const navigate = useNavigate()

  // Fetcher api that append api token
  const { fetcher } = useApi()

  // state to store workspace
  const [workspace, setWorkspace] = useState<Workspace | undefined>(undefined)

  // state to store loading state of workspace
  const [loadingWorkspace, setLoadingWorkspace] = useState<boolean>(false)

  // state to store error state if getting workspace request failed
  const [errorLoadingWorkspace, setErrorLoadingWorkspace] = useState<
    { statusCode: number } | undefined
  >(undefined)

  // state flag to determine whether workspace is being deleted
  const [deletingWorkspace, setDeletingWorkspace] = useState<boolean>(false)

  // state flag to determine whether a workspace is being updated
  const [updatingWorkspace, setUpdatingWorkspace] = useState<boolean>(false)

  // Set the currently logged in users role in the workspace
  const [userWorkspaceRole, setUserWorkspaceRole] = useState<
    WorkspaceUserRole | undefined
  >(undefined)

  // Attach context to display snackbar messages
  const { handleSetContent, handleSetSnackbarType } = useContext(
    SnackbarContext,
  )

  /**
   *
   * Use effect hook to monitor workspace
   * And determine currently logged in users role in the workspace
   */
  useEffect(() => {
    if (workspace) {
      const currentUserRole =
        workspace.workspaceUsers?.find(
          (workspaceUser) => workspaceUser.userId === userId,
        )?.workspaceRole || WorkspaceUserRole.viewer

      if (currentUserRole !== userWorkspaceRole) {
        setUserWorkspaceRole(currentUserRole)
      }
    }
  }, [workspace, userWorkspaceRole, setUserWorkspaceRole, userId])

  /**
   *
   * Method to get an individual workspace belonging to an organization
   * @param {string} workspaceId - workspace being requested
   */
  const getWorkspace = async (workspaceId: string) => {
    if (organizationId) {
      setLoadingWorkspace(true)
      setErrorLoadingWorkspace(undefined)

      try {
        const response = await fetcher.get(
          ENDPOINTS.ORGANIZATION_WORKSPACE.replace(
            '$organizationId',
            organizationId,
          ).replace('$workspaceId', workspaceId),
        )

        if (response.data) {
          setWorkspace(response.data)
        }
      } catch (error) {
        const errorResposne = (error as AxiosError).response
        setErrorLoadingWorkspace({ statusCode: Number(errorResposne?.status) })
      } finally {
        setLoadingWorkspace(false)
      }
    }
  }

  /**
   *
   * Method to delete an individual workspace
   * @param {string} workspaceId - workspace being deleted
   */
  const deleteWorkspace = async (workspaceId: string) => {
    if (organizationId) {
      setDeletingWorkspace(true)
      try {
        const uri: string = ENDPOINTS.ORGANIZATION_WORKSPACE.replace(
          '$organizationId',
          organizationId,
        ).replace('$workspaceId', workspaceId)
        await fetcher.delete(uri)
        handleSetSnackbarType('Success')
        handleSetContent(t({ id: 'workspaceDeleted' }))
        navigate('/')
      } catch {
        handleSetSnackbarType('Error')
        handleSetContent(t({ id: 'errorDeletingWorkspace' }))
      } finally {
        setDeletingWorkspace(false)
      }
    }
  }

  /**
   *
   * Method to update an exisitng workspace
   * @param {string} workspaceId - id of the workspace to update
   * @param {UpdateWorkspaceInput} data
   */
  const updateWorkspace = async (
    workspaceId: string,
    data: UpdateWorkspaceInput,
    showMessages?: boolean,
  ): Promise<any> => {
    if (organizationId) {
      setUpdatingWorkspace(true)
      try {
        const uri: string = ENDPOINTS.ORGANIZATION_WORKSPACE.replace(
          '$organizationId',
          organizationId,
        ).replace('$workspaceId', workspaceId)

        // Run the request
        const updatedWorkspace = await fetcher.put(uri, data)
        if (showMessages) {
          handleSetSnackbarType('Success')
          handleSetContent(t({ id: 'workspaceUpdated' }))
        }

        // Update workspace
        setWorkspace(updatedWorkspace.data as Workspace)
        return updatedWorkspace
      } catch (error) {
        if (showMessages) {
          handleSetSnackbarType('Error')
          handleSetContent(t({ id: 'errorUpdatingWorkspace' }))
        }
      } finally {
        setUpdatingWorkspace(false)
      }
    }
  }

  const removeUsersFromWorkspace = (workspaceUsers: WorkspaceUserInput[]) => {
    setWorkspace(
      (workspace) =>
        ({
          ...workspace,
          workspaceUsers,
        } as Workspace),
    )
  }

  const resetWorkspace = () => setWorkspace(undefined)

  return {
    deleteWorkspace,
    deletingWorkspace,
    errorLoadingWorkspace,
    getWorkspace,
    loadingWorkspace,
    workspace,
    updateWorkspace,
    removeUsersFromWorkspace,
    updatingWorkspace,
    userWorkspaceRole: userWorkspaceRole ?? WorkspaceUserRole.viewer,
    resetWorkspace,
  }
}

export default useWorkspace
