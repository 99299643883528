import React from 'react'
import { Layout, Avatar, Text, Icon } from '@di-nxt/components'

import { UserListItemProps } from './index.type'
import { WorkspaceUserRole } from '../../../../types/workspace.type'

/**
 *
 * Render each user item in list of users
 */
const UserListItem: React.FC<UserListItemProps> = ({
  user,
  onAddWorkspaceUser,
  isAddedToWorkspace,
  onRemoveWorkspaceUser,
  selectedRole,
  excludeUser,
}) => {
  /**
   *
   * Select the user
   */
  const handleSelectUser = () => {
    onAddWorkspaceUser({
      userId: user.userId,
      username: user.username,
      workspaceRole: selectedRole,
      email: user.email,
    })
  }

  /**
   *
   * Remove the user from the workspace
   */
  const handleRemoveUser = () => {
    onRemoveWorkspaceUser({
      userId: user.userId,
      username: user.username,
      workspaceRole: WorkspaceUserRole.viewer,
      email: user.email,
    })
  }

  /**
   *
   * Render action based on user and their inclusion in workspace users list
   */
  const renderAction = () => {
    if (excludeUser) {
      return null
    }

    if (!isAddedToWorkspace) {
      return (
        <Layout paddingRight="12" onPress={handleSelectUser}>
          <Icon name="plusCircle" />
        </Layout>
      )
    }

    if (isAddedToWorkspace) {
      return (
        <Layout paddingRight="12" onPress={handleRemoveUser}>
          <Icon name="minus" />
        </Layout>
      )
    }
  }

  return (
    <Layout
      horizontal
      align="center"
      paddingBottom="24"
      justify="space-between"
    >
      <Layout>
        <Avatar name={user.username.toUpperCase()} size="30" />
        <Layout paddingLeft="12" vertical>
          <Text variant="inline_12_emphasis">
            {user.firstName} {user.lastName}
          </Text>
          <Text variant="inline_12_default" textColor="content_mono_secondary">
            {user.email}
          </Text>
        </Layout>
      </Layout>
      {renderAction()}
    </Layout>
  )
}

UserListItem.displayName = 'UserListItem'

export default UserListItem
